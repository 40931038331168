import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ManageDeclarationQuestionsService} from '../../../services/master/manage-declaration-questions/manage-declaration-questions.service';
import {DeclarationQuestion} from '../../../models/declaration-question';
import {DialogService} from 'primeng/api';
import {Policies} from '../../../models/aps-policies';
import {UtilService} from '../../../services/util.service';
import {OndeleteDialogueboxComponent} from '../../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../../models/on-delete-display-dialogue-model';


@Component({
	selector: 'manage-declaration-questions',
	templateUrl: './manage-declaration-questions.component.html',
	styleUrls: ['./manage-declaration-questions.component.css']
})
export class ManageDeclarationQuestionsComponent implements OnInit {
	policies: Policies;
	disableCreateButton: boolean;
	disableDeleteButton: boolean;

	constructor(private utilService: UtilService, private router: Router, private service: ManageDeclarationQuestionsService, public dialogService: DialogService) {
	}

	declarationQuestions: DeclarationQuestion[];

	ngOnInit() {
		this.findAll();
		this.findAllPolicies();
	}

	onCreate() {
		this.router.navigate(['create-declaration-questions/create/0']);
	}

	private findAll() {
		this.service.findAll().subscribe((response: DeclarationQuestion[]) => {
			this.declarationQuestions = response;
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	onEdit(id: number) {
		this.router.navigate(['create-declaration-questions/edit/' + id]);
	}

	onDelete(id: number) {
		const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
			header: 'Confirmation',
			width: '30%',
			contentStyle: {'max-height': '500px', 'overflow': 'auto'}
		});

		deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
			if (deleteModel != null) {
				if (deleteModel.isDeleted) {
					this.service.delete(id).subscribe(value => {
						this.utilService.pushMsg('success', 'Success', 'Question deleted successfully');
						this.findAll();
					}, error => {
						this.utilService.pushMsg('error', 'Error', 'Unable to delete Question!');
					});
				} else {
					console.log('dont Delete response from dialogue box');
				}
			}
		});
	}

	private findAllPolicies() {
		this.policies = JSON.parse(sessionStorage.getItem('policies'));
		const policyName = 'eCAR:ManageDeclarationQuestions';
		this.disableCreateButton = !this.utilService.checkPermittedUpdatePolicy(this.policies, policyName);
		this.disableDeleteButton = !this.utilService.checkPermittedDeletePolicy(this.policies, policyName);
	}
}
