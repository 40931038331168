/**This file contains a constant object that has properties that are necessary
 * for the build found in the `edu` configuration in `angular.json`.
 */
export const environment = {
	envName: 'edu',
	production: true,
	tokenUrl: 'https://corp.sts.ford.com/adfs/oauth2/authorize',
	clientId: 'urn:www.ecar.ford.com:clientid:web_ecar_qa_prod:edu',
	resource: 'urn:www.ecar.ford.com:resource:web_ecar_qa_prod:edu',
	apiUrl: 'https://api-edu.ecar.ford.com/api/v1/car'
	//apiUrl: 'https://ierpcar-service-car-edu.apps.pd01i.edc1.cf.ford.com/api/v1/car'
};
