import {ProcessData} from './process-data';

export class PsPnMapTran {
	reqId: number;
	processStreamId: number;
	processNameId: number;
	processName: string;
	processOrderId: number;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	processDataList: ProcessData[];
	isEditButtonDisabled: Boolean;
	formSubmitted: boolean;
	isPrimaryStream: boolean;
	processDesc: string;
	sharedLoadingLink: string;
	masterDataFlag: boolean;

	constructor(psPnMapTran: PsPnMapTran) {
		Object.assign(this, psPnMapTran);
	}
}

export const emptyPsPnMapTran = {
	'reqId': 0,
	'processStreamId': 0,
	'processNameId': 0,
	'processName': null,
	'processOrderId': 0,
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'processDataList': [],
	'isEditButtonDisabled': true,
	'formSubmitted': false,
	'isPrimaryStream': true,
	'processDesc': '',
	'sharedLoadingLink': '',
	'masterDataFlag': false
};
