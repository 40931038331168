import {Injectable} from '@angular/core';
import {Config} from '../../../config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {SharedLoadingRoot} from '../../../models/shared-loading-root';
import {ReqGoodPartsUpdateReq} from '../../../models/req-good-parts-update-req';
import {ProcessName} from '../../../models/process-name';
import {SharedLoadingPlan} from '../../../models/shared-loading-plan';

@Injectable({
	providedIn: 'root'
})
export class ManageSharedLoadingService {

	private config = new Config();
	private httpUrl = `${this.config.url}/sharedLoading`;
	private httpUrlReqGoodParts = `${this.config.url}/sharedLoading/update`;
	private httpUrlSharedLoadingTran = `${this.config.url}/sharedLoading/ecar`;
	private httpUrlSharedLoadingUndo = `${this.config.url}/sharedLoading/fetchUndoList`;
	private httpUrlSharedLoadingOverWrite = `${this.config.url}/sharedLoading/overwriteSharedLoading`;
	isRequiredGoodPartsRefreshCompleted = new Subject<string>();

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<SharedLoadingRoot[]> {
		return this.http.get(this.httpUrl).pipe(map((sharedLoadingRoots: SharedLoadingRoot[]) => {
			return sharedLoadingRoots.map(sharedLoadingRoot => {
				return new SharedLoadingRoot(sharedLoadingRoot);
			});
		}));
	}

	findAllBySiteCodes(): Observable<SharedLoadingRoot[]> {
		return this.http.get(this.httpUrl + '/findAllBySiteCodes').pipe(map((sharedLoadingRoots: SharedLoadingRoot[]) => {
			return sharedLoadingRoots.map(sharedLoadingRoot => {
				return new SharedLoadingRoot(sharedLoadingRoot);
			});
		}));
	}

	create(sharedLoadingRoot: SharedLoadingRoot): Observable<SharedLoadingRoot> {
		return this.http.post<SharedLoadingRoot>(this.httpUrl, sharedLoadingRoot);
	}

	update(id: number, sharedLoadingRoot: SharedLoadingRoot): Observable<SharedLoadingRoot> {
		return this.http.put<SharedLoadingRoot>(this.httpUrl + '/' + id, sharedLoadingRoot);
	}

	updateRequiredGoodParts(reqGoodPartsUpdateReq: ReqGoodPartsUpdateReq): Observable<SharedLoadingRoot> {
		console.log('this.reqGoodPartsUpdateReq');
		console.log(reqGoodPartsUpdateReq);
		return this.http.put<SharedLoadingRoot>(this.httpUrlReqGoodParts, reqGoodPartsUpdateReq);
	}

	delete(id: number): Observable<SharedLoadingRoot> {
		return this.http.delete<SharedLoadingRoot>(this.httpUrl + '/' + id);
	}

	findById(id: number): Observable<SharedLoadingRoot> {
		return this.http.get<SharedLoadingRoot>(this.httpUrl + '/' + id);
	}

	findSharedLoadingBycarIdFromTransaction(id: string, processNameId: string): Observable<SharedLoadingRoot> {
		return this.http.get<SharedLoadingRoot>(this.httpUrlSharedLoadingTran + '/' + id + '/processnameId/' + processNameId);
	}

	isValidSiteCodeAndProcessId(siteCode: string, id: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrl + '/' + siteCode + '/' + id);
	}

	retrieveSharedLoadingIdBySiteCode(siteCode: string): Observable<SharedLoadingRoot[]> {
		const params = new HttpParams()
			.set('siteCode', siteCode);

		return this.http.get<SharedLoadingRoot[]>(this.httpUrl + '/retrieveSharedLoadingIdBySiteCode', {params});
	}

	retrieveSharedLoadingIdBycarId(carId: number): Observable<SharedLoadingRoot[]> {
		const params = new HttpParams()
			.set('carId', carId.toString());

		return this.http.get<SharedLoadingRoot[]>(this.httpUrl + '/retrieveSharedLoadingIdByCarReqId', {params});
	}

	retrieveSharedLoadingIdBySiteCodeAndProcessNameId(siteCode: string, processNameId: string): Observable<SharedLoadingRoot> {
		const params = new HttpParams()
			.set('siteCode', siteCode)
			.set('processNameId', processNameId);
		return this.http.get<SharedLoadingRoot>(this.httpUrl + '/retrieveSharedLoadingIdBySiteCodeAndProcessNameId', {params});
	}

	isSharedLoadingLinkedWithAnyCAR(siteCode: string, processNameId: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrl + '/eCARLinkedSL/' + siteCode + '/' + processNameId);
	}

	findAllProcessNamesWithoutHavingSLP(siteCode: string): Observable<ProcessName[]> {
		return this.http.get(this.httpUrl + '/slpNonExistProcessNames/' + siteCode).pipe(map((processNames: ProcessName[]) => {
			if (processNames && processNames.length > 0) {
				return processNames.map(processName => {
					return new ProcessName(processName);
				});
			}
		}));
	}

	findingAll(pageSizeRequest) {
		let params = new HttpParams().set('page', pageSizeRequest.page).set('size', pageSizeRequest.size);
		if (pageSizeRequest.sort && pageSizeRequest.sort.length > 0) {
			params = params.set('sort', pageSizeRequest.sort);
		}
		if (pageSizeRequest.filters && pageSizeRequest.filters.length > 0) {
			params = params.set('filters', pageSizeRequest.filters);
		}
		if (pageSizeRequest.userRole && pageSizeRequest.userRole.length > 0) {
			params = params.set('userRole', pageSizeRequest.userRole);
		}
		if (pageSizeRequest.siteCodes && pageSizeRequest.siteCodes.length > 0) {
			params = params.set('siteCodes', pageSizeRequest.siteCodes);
		}
		return this.http.get(this.httpUrl + '/findAll', {params});
	}

	fetchUndoList(undoList: Array<number>): Observable<SharedLoadingPlan[]> {
		return this.http.post<SharedLoadingPlan[]>(this.httpUrlSharedLoadingUndo + '/', undoList);
	}

	retrieveSharedLoadingByCarIdAndSiteAndProcess(carId: number, siteCode: string, processNameId: number): Observable<SharedLoadingRoot> {
		const params = new HttpParams()
			.set('carId', carId.toString())
			.set('siteCode', siteCode)
			.set('processNameId', processNameId.toString());

		return this.http.get<SharedLoadingRoot>(this.httpUrl + '/retrieveSharedLoadingByCarIdAndSiteAndProcess', {params});
	}

	public downloadSLPTemplate() {
		this.http.get(this.httpUrl + '/downloadSLPTemplate', {responseType: 'blob'})
			.subscribe((response) => {
				this.downLoadFile(response, 'text/csv');
			});
	}

	private downLoadFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'SLPTemplate.xlsm';
		anchor.href = url;
		anchor.click();
	}

	uploadFile(files, siteCode: any, processNameId: any): Observable<SharedLoadingRoot> {
		//FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
		const uploadImageDatas = new FormData();
		uploadImageDatas.append('file', files, files.name);
		uploadImageDatas.append('siteCode', siteCode);
		uploadImageDatas.append('processNameId', processNameId);
		return this.http.post<SharedLoadingRoot>(this.httpUrl + '/uploadSLPExcel', uploadImageDatas);
	}

	overwriteSharedLoading(id: number, sharedLoadingRoot: SharedLoadingRoot): Observable<SharedLoadingRoot> {
		return this.http.put<SharedLoadingRoot>(this.httpUrlSharedLoadingOverWrite + '/' + id, sharedLoadingRoot);
	}
}
