import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {emptyProcessData, ProcessData} from '../../../models/process-data';
import {SharedLoadingRoot} from '../../../models/shared-loading-root';
import {PsPnMapTran} from '../../../models/pspnmaptran';
import {CarRequest} from '../../../models/car-request';
import * as _ from 'lodash';
import {DialogService, MessageService} from 'primeng/api';
import {ManageSharedLoadingService} from '../../../services/master/manage-shared-loading/manage-shared-loading.service';
import {HistoricalMfgPerf} from '../../../models/historical-mfgperf';
import {HistoricalMfgPerfService} from '../../../services/master/historical-mfgperf/historical-mfgperf.service';
import {UtilService} from '../../../services/util.service';
import {SharedLoadingPlan, SharedLoadingPlanHolder} from '../../../models/shared-loading-plan';
import * as moment from 'moment';
import {PrimarySupportingMapTran} from '../../../models/primary-supporting-map-tran';
import {ReqGoodPartsUpdateReq} from '../../../models/req-good-parts-update-req';
import {CarRequestService} from '../../../services/request/car-request.service';
import {SupplierSiteService} from '../../../services/supplierDetails/supplier-site.service';
import * as jsPlumb from 'jsplumb';
import {OndeleteDialogueboxComponent} from '../../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../../models/on-delete-display-dialogue-model';
import {ApsAttributes} from '../../../models/aps-attributes';
import isNumber = jsPlumb.jsPlumbUtil.isNumber;

@Component({
	selector: 'create-car-process-name',
	templateUrl: './create-car-process-name.component.html',
	styleUrls: ['./create-car-process-name.component.css']
})
export class CreateCarProcessNameComponent implements OnInit {
	@Input() selectedProcessNameId: number;
	@Input() selectedSiteCode: string;
	@Output() psPnMapTransObjectEmitter = new EventEmitter<PsPnMapTran>();
	@Input() psPnMapTransObjectParent: PsPnMapTran;
	@Input() scenarioTemplateName: string;
	@Input() mode: string;
	@Input() isSTAEdit: boolean;
	@Input() carRequest: CarRequest;
	@Input() isEditDisabled;
	@Input() supportingStreamTransMapObject ?: PrimarySupportingMapTran;
	@ViewChild('processDataForm', {static: false}) processDataForm;
	@ViewChild('dialDetail', {static: false}) dialDetail;
	@Output() saveCarRequestEmitter = new EventEmitter<boolean>();
	@Input() currentPage: string;
	@Input() currentProcessName ?: string;

	psPnMapTransObject: PsPnMapTran;
	displayProcessEditPopup = false;
	isPartNameAvailableInSharedLoading = false;
	processDataAPW: ProcessData;
	processDataMPW: ProcessData;
	processDataCapacityAPW: ProcessData;
	processDataCapacityMPW: ProcessData;
	processDataAPWUField: ProcessData;
	processDataMPWUField: ProcessData;
	sharedLoadingBasePath: string;
	showNAStatus = true;
	showSLPartNumMismatchMsg = false;
	sharedLoadingLink = 'N/A';
	historicalMfgPerfObj: any;
	historicalmfgperfs: HistoricalMfgPerf[] = [];
	HMPLink: string;
	private HMPBasePath: string;
	private sharedLoadingPlanWithPartNumAPW: SharedLoadingPlan;
	private sharedLoadingPlanWithPartNumMPW: SharedLoadingPlan;
	daysPerWeekAPWSL: number;
	shiftsPerDayAPWSL: number;
	hrsPerShiftAPWSL: number;
	downtimeAPWSL: number;
	daysPerWeekMPWSL: number;
	shiftsPerDayMPWSL: number;
	hrsPerShiftMPWSL: number;
	downtimeMPWSL: number;
	reqGoodPartsAPW: number;
	reqGoodPartsMPW: number;
	nictAPW: number;
	displayName: string;
	previousScrapPercent: number;
	isHmpItemExceeds100 = false;
	isHMPAvailable = false;
	processTypeToolTip = 'Please select "Dedicated" when the process will be used exclusively for the part analyzed in this CAR. ' +
		'The Allocation % will be automatically placed in F1 as 100%. ' +
		'Please select "Shared" when more parts are or will be manufactured in the process. ' +
		'For shared process, Allocation % includes changeover time into this part number. ' +
		'For shared process, a shared loading plan must be completed. ' +
		'The Allocation % will be automatically placed in F1 once the shared loading plan is done.';

	weeklyPartEstimateToolTIp = 'Used to independently evaluate the specific process\' potential for producing good parts.' +
		'This part estimate does not take into account a blocked condition from a downstream process' +
		' nor a starved condition from an upstream process.' +
		'This part estimate assumes an unlimited supply of parts entering the process and is simply based on the specific process\' Demonstrated OEE.' +
		'This value does not represent the Overall Process Predicted Good Parts / Week.';

	observedAverageCycleTimeToolTip = 'Collect 15-20 cycles and calculate average. Study needs to be "good cycle to good cycle" with no blocked, starved, or unplanned downtime conditions. \n' +
		'For processes with multiple operations, use the constraint cycle time.';
	isProcessUsedInAnotherPartNumber = false;
	recordsUsedInAnotherPartNumbers: number[];
	isCapacityStudyScenario = false;
	capacityRecordNotFound = true;
	hmpOlderThan32WeeksWarningShown = false;
	hmpRecordsCountLessThan25WarningShown = false;
	oneOrMoreOEEPercentageGreaterThan100ErrorShown = false;
	isFocusOut = false;
	natAlert = false;
	showDefinitionCon: boolean;
	private cycleTimeHigher: boolean;
	private cycleTimelower: boolean;
	showTotalAllocationValidation: boolean;
	showSLPReDirectErrorMsg = false;
	hideCapacityStudyElements: boolean;
	hideProcessDetailsValidation: boolean;
	partNumDisplay: string;
	hideRefreshSLOnEditForCapacityAlert = true;
	srcMode: string;
	weeklyPartToolTIp: any;
	averageOEEToolTip: any;
	sharedLoadingRoot: SharedLoadingRoot;
	demonOEE: number;
	private updatedAllocPercentAPW: any;
	private updatedAllocPercentMPW: any;
	hideErrorwhileLoading: boolean;
	loggedInUser: string;
	attributes: ApsAttributes;
	private loggedInUserId: string;

	constructor(private utilService: UtilService, private hmpService: HistoricalMfgPerfService, private manageSharedLoadingService: ManageSharedLoadingService, private messageService: MessageService, private carRequestService: CarRequestService, private dialogService: DialogService, private supplierSiteService: SupplierSiteService) {
	}

	ngOnInit() {
		this.attributes = JSON.parse(sessionStorage.getItem('attributes'));
		this.loggedInUserId = sessionStorage.getItem('userId');
		if (this.attributes) {
			this.loggedInUser = this.attributes.userRole;
		}
		this.sharedLoadingBasePath = window.location.origin + '/#/create-shared-loading/edit/';
		this.HMPBasePath = window.location.origin + '/#/historical-mfgperf-master-page/';
		this.processDataAPW = Object.assign({}, emptyProcessData);
		this.processDataMPW = Object.assign({}, emptyProcessData);
		this.processDataCapacityAPW = Object.assign({}, emptyProcessData);
		this.processDataCapacityMPW = Object.assign({}, emptyProcessData);
		this.processDataAPWUField = Object.assign({}, emptyProcessData);
		this.processDataMPWUField = Object.assign({}, emptyProcessData);
		if (this.currentPage === 'summary') {
			this.displayName = this.psPnMapTransObjectParent.processName;
		} else if (this.mode === 'view') {
			this.displayName = 'View';
		} else {
			this.displayName = 'Edit';
		}
	}

	onProcessEdit() {
		this.manageFormData();
		this.displayProcessEditPopup = true;
		//this.isCapacityStudyScenario = this.carRequest.submissionScenarioObject ? this.carRequest.submissionScenarioObject['capacityStudy'] : false;
		this.isCapacityStudyScenario = this.carRequest.submissionScenarioObject && this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.capacityRequirementSource === 'What-If' ? true : false;
		this.manageSharedLoadingService.isValidSiteCodeAndProcessId(this.carRequest.siteCode, this.selectedProcessNameId).subscribe(isSharedLoadingNotPresent => {
			if (!isSharedLoadingNotPresent && (this.processDataAPW.averageHisOee === null || this.processDataAPW.averageHisOee === 0)) {
				this.processDataAPW.dedicated = false;
			}
			this.retrieveSharedLoadingIdBySiteCodeAndProcessNameId();
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
		this.loadHmpData();
		this.previousScrapPercent = this.processDataAPW.scrappedPercent;

		if (this.mode === 'view') {
			this.processDataForm.form.disable(true);
		}
		if (this.psPnMapTransObjectParent.processDataList.length > 0 && this.psPnMapTransObjectParent.processDataList[0].scenarioChange) {
			if (this.psPnMapTransObjectParent.processDataList[0].scenarioTemplateChange.startsWith('true')) {
				this.processDataAPW.weekPartEstimate = null;
				this.processDataMPW.weekPartEstimate = null;
				if (this.psPnMapTransObjectParent.processDataList[0].scenarioTemplateChange.includes('Capacity Planning to')) {
					this.processDataAPW.supplierName = '';
					this.processDataAPW.supplierLocation = '';
					this.processDataAPW.siteCodeSurProcess = '';
					this.processDataAPW.surCustomerProcess = '';
				} else if (this.psPnMapTransObjectParent.processDataList[0].scenarioTemplateChange.includes('to Capacity Planning')) {
					this.processDataAPW.totalAvailableTime = null;
					this.processDataAPW.plannedDownTime = null;
					this.processDataAPW.netAvailableTimePhase = null;
					this.processDataAPW.changeOverTimeActual = null;
					this.processDataAPW.changeOverTimeWeekly = null;
					this.processDataAPW.observedUnplannedDownTime = null;
					this.processDataAPW.operatingTime = null;
					this.processDataAPW.equipmentAvailability = null;
					this.processDataAPW.totalPartsRun = null;
					this.processDataAPW.noOfCurrentToolsORMachines = null;
					this.processDataAPW.noOfIdenticalPartsProduced = null;
					this.processDataAPW.plannedNetIdealCycleTime = null;

					this.processDataAPW.performanceEfficiency = null;
					this.processDataAPW.availablePerformanceEfficiency = null;
					this.processDataAPW.noOfPartsScrapped = null;
					this.processDataAPW.noOfPartsScrappedPercent = null;
					this.processDataAPW.noOfPartsReworked = null;
					this.processDataAPW.noOfPartsReworkedPercent = null;
					this.processDataAPW.qualityRate = null;
					this.processDataAPW.phaseOEE = null;
					this.processDataAPW.observedAvgCycleTime = null;
					this.processDataAPW.actualHeadCount = null;
				} else if (this.psPnMapTransObjectParent.processDataList[0].scenarioTemplateChange.includes('Phase 0 PPAP to Phase 3 PPAP')) {
					this.processDataAPW.noOfCurrentToolsORMachines = null;
					this.processDataAPW.noOfIdenticalPartsProduced = null;

				} else if (this.psPnMapTransObjectParent.processDataList[0].scenarioTemplateChange.includes('Phase 3 PPAP to Phase 0 PPAP')) {
					this.processDataAPW.changeOverTimeActual = null;
				}
			}
		}
		this.isProcessUsedInAnotherParNumber();
		if (this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'STADRAFT') {
			this.calculateRemainingForAvailability();
			this.calculateReqJPH();
			this.calculateDemonJPH();
			this.processDataAPW.phaseJPH = _.round(((3600 / this.processDataAPW.plannedNetIdealCycleTime) * this.processDataAPW.phaseOEE) / 100, 1);
			this.processDataAPW.phaseJPH = !this.processDataAPW.phaseJPH ? 0 : this.processDataAPW.phaseJPH;
		}
		this.loadSupplierInfo();
		this.srcMode = this.mode;
		this.isSupplierSubmittedWithinDateLimitForClosedCARs();
		this.isOldECARWithinTheDateLimit();
	}

	private isSupplierSubmittedWithinDateLimitForClosedCARs() {
		if (this.carRequest.requestStatus === 'SUBMITTED' || this.carRequest.requestStatus === 'COMPLETED' || this.carRequest.requestStatus === 'APPROVED' || this.carRequest.requestStatus === 'ACKNOWLEDGED') {
			this.carRequestService.isSupplierSubmittedWithinDateLimit(this.carRequest.reqId).subscribe(isSubmitted => {
				if (isSubmitted) {
					this.hideCapacityStudyElements = true;
				} else {
					this.hideCapacityStudyElements = false;
				}
			});
		} else {
			this.hideCapacityStudyElements = false;
		}
	}

	private isOldECARWithinTheDateLimit() {
		if (this.carRequest.requestStatus === 'SUBMITTED' || this.carRequest.requestStatus === 'COMPLETED' || this.carRequest.requestStatus === 'APPROVED' || this.carRequest.requestStatus === 'ACKNOWLEDGED') {
			this.carRequestService.isOldECARWithinTheDateLimit(this.carRequest.reqId).subscribe(isSubmitted => {
				if (isSubmitted) {
					this.hideProcessDetailsValidation = true;
				} else {
					this.hideProcessDetailsValidation = false;
				}
			});
		} else {
			this.hideProcessDetailsValidation = false;
		}
	}

	loadHmpData() {
		this.historicalmfgperfs = [];
		this.historicalMfgPerfObj = {
			processId: this.selectedProcessNameId,
			siteCode: this.carRequest.siteCode
		};
		if (this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'STADRAFT') {
			this.hmpService.findHistMfgPerfRecords(this.historicalMfgPerfObj).subscribe((response: HistoricalMfgPerf[]) => {
				this.buildHMPLink(response);
				this.demonOEE = this.utilService.calculateAverageOEE(this.historicalmfgperfs);
				this.populateDemonOEE(this.demonOEE);
				this.calculateAll();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.hmpService.findHistMfgPerfRecordsByCarId(this.historicalMfgPerfObj, this.carRequest.reqId).subscribe((response: HistoricalMfgPerf[]) => {
				this.buildHMPLink(response);
				if (!this.carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
					this.demonOEE = this.utilService.calculateAverageOEE(this.historicalmfgperfs);
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	private buildHMPLink(response: HistoricalMfgPerf[]) {
		this.historicalmfgperfs = response;
		this.isHmpItemExceeds100 = false;
		this.isHMPAvailable = false;
		if (this.historicalmfgperfs && this.historicalmfgperfs.length > 0) {
			this.isHMPAvailable = true;
			if (this.historicalmfgperfs.find(historicalObject => historicalObject.oee > 100)) {
				this.isHmpItemExceeds100 = true;
			}
		}
		if (this.isHMPAvailable) {
			this.HMPLink = this.HMPBasePath + '?siteCode=' + this.carRequest.siteCode + '&processId=' + this.selectedProcessNameId;
			if (this.carRequest.requestStatus) {
				if (this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'REJECTED' && this.carRequest.requestStatus !== 'STADRAFT') {
					this.HMPLink += '&carStatus=view&carId=' + this.carRequest.reqId;
				}
				this.validateHMP();
			}
		} else {
			this.HMPLink = 'Not Available';
			this.processDataForm.form.disable(true);
			this.messageService.add({
				key: 'processDataToast',
				severity: 'error',
				summary: 'Error',
				detail: 'Historical Manufacturing Performance (HMP) Data is not available for this Process Name / Site Code.  Please exit this screen, create the HMP master data, and return to this record to resume data entry',
				life: 15000
			});
		}
	}

	retrieveSharedLoadingIdBySiteCodeAndProcessNameId() {
		const siteCode = this.carRequest.siteCode;
		console.log('retrieveSharedLoadingIdBySiteCodeAndProcessNameId:- ' + this.carRequest.requestStatus + ' and isSTAEdit ' + this.isSTAEdit);
		if (!this.carRequest.requestStatus || this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'STADRAFT') {
			this.manageSharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(siteCode, this.selectedProcessNameId.toString()).subscribe(value => {
				if ((!this.processDataAPW.dedicated && (this.processDataAPW.sharedLoadingTotalAllocPercent > 100 || this.processDataMPW.sharedLoadingTotalAllocPercent > 100)) ||
					(this.processDataAPW.dedicated && (this.processDataAPW.dedicatedTotalAllocPercent > 100 || this.processDataMPW.dedicatedTotalAllocPercent > 100))) {
					this.weeklyPartToolTIp = 'Cannot be calculated due to allocation in U exceeding 100%, there is insufficient capacity.';
				}
				if (!this.processDataAPW.isContainAssumptions || !this.processDataMPW.isContainAssumptions) {
					this.weeklyPartToolTIp = 'Cannot be calculated due to excessive losses based on changeover, scrap and rework.  Please review Historical Manufacturing Data as it may not be representative for this part.';
				}
				if ((this.processDataAPW.plannedDownTime + this.processDataAPW.changeOverTimeActual + this.processDataAPW.observedUnplannedDownTime) > this.processDataAPW.totalAvailableTime) {
					this.averageOEEToolTip = 'Downtime cannot be higher than Total Available Time [(W+Y+AB) cannot be higher than V], please review and correct';
				}
				if (this.processDataAPW.equipmentAvailability > 100) {
					this.averageOEEToolTip = 'Equipment Availability cannot be > 100%, please review and correct';
				}
				if (this.processDataAPW.performanceEfficiency > 100) {
					this.averageOEEToolTip = 'Efficiency cannot be higher than 100%, please review and correct';
				}
				if (this.processDataAPW.noOfCurrentToolsORMachines > this.processDataAPW.noToolsMachParallel) {
					this.averageOEEToolTip = 'AF1 Cannot be higher than planned in L (above); either update plan or correct this data';
				}
				if (this.processDataAPW.noOfIdenticalPartsProduced > this.processDataAPW.noIdentityParts) {
					this.averageOEEToolTip = 'AF2 Cannot be higher than planned in M (above); either update plan or correct this data';
				}
				if (this.processDataAPW.qualityRate < 0) {
					this.averageOEEToolTip = 'Sum of scrap and rework cannot be greater than parts produced (AE), please review and correct';
				}
				this.sharedLoadingRoot = value;
				if (!this.processDataAPW.dedicated && this.sharedLoadingRoot) {
					if (this.sharedLoadingRoot.isAlertRequired) {
						if (this.mode === 'edit') {
							const dialogueRefEdit = this.dialogService.open(OndeleteDialogueboxComponent, {
								header: 'Warning',
								width: '50%',
								baseZIndex: 13000,
								contentStyle: {'max-height': '700px', 'overflow': 'auto'},
								data: {
									'message': 'CapacityStudyAlertOnEdit'
								}
							});
							dialogueRefEdit.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
								if (deleteModel != null) {
									if (deleteModel.isDeleted) {
										this.showSLPReDirectErrorMsg = true;
									}
								}
							});
							this.mode = 'view';
							this.hideRefreshSLOnEditForCapacityAlert = false;
							this.processDataForm.form.disable(true);
						} else if (this.mode === 'view') {
							const dialogueRefView = this.dialogService.open(OndeleteDialogueboxComponent, {
								header: 'Warning',
								width: '50%',
								baseZIndex: 13000,
								contentStyle: {'max-height': '700px', 'overflow': 'auto'},
								data: {
									'message': 'CapacityStudyAlertOnView'
								}
							});
							dialogueRefView.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
								if (deleteModel != null) {
									if (deleteModel.isDeleted) {
										this.showSLPReDirectErrorMsg = true;
									}
								}
							});
						}
					} else {
						if (this.srcMode === 'edit') {
							this.mode = 'edit';
							this.processDataForm.form.enable(true);
							this.showSLPReDirectErrorMsg = false;
						}
					}
				}
				this.buildSharedLoadingPath();
				this.calculateAll();
				this.hideErrorwhileLoading = true;
			});
		} else {
			this.manageSharedLoadingService.retrieveSharedLoadingByCarIdAndSiteAndProcess(this.carRequest.reqId, this.selectedSiteCode, this.selectedProcessNameId).subscribe(value => {
				this.sharedLoadingRoot = value;
				if (this.processDataAPW.dedicated) {
					this.checkProcessNameAndPartNameAvailableInSharedLoading(this.selectedProcessNameId);
				}
				if (this.sharedLoadingRoot) {
					this.buildSharedLoadingLink(this.sharedLoadingRoot);
					this.loadTotalAllocationPercentByPartNumber(this.sharedLoadingRoot);
				}
				this.hideErrorwhileLoading = true;
				if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
					this.cycleTimeValidation();
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	private buildSharedLoadingLink(sharedLoadingRoot: SharedLoadingRoot) {
		if (sharedLoadingRoot) {
			this.sharedLoadingLink = this.sharedLoadingBasePath + sharedLoadingRoot.id.toString();
			if (this.carRequest.requestStatus != null && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'REJECTED' && this.carRequest.requestStatus !== 'STADRAFT') {
				this.sharedLoadingLink += '/true/' + this.carRequest.reqId + '/' + this.selectedProcessNameId + '/N/0/10';
			} else {
				this.sharedLoadingLink += '/false/0/0';
				if (this.mode === 'view' || this.carRequest.requestStatus === 'STADRAFT') {
					this.sharedLoadingLink += '/Y/0/10';
				} else {
					this.sharedLoadingLink += '/N/0/10';
				}
			}
		} else {
			this.sharedLoadingLink = 'N/A';
		}
	}

	onClose() {
		this.showDefinitionCon = false;
		this.clearToast();
		this.validateTouchedFields();
	}

	manageFormData() {
		if (this.mode === 'create') {
			if (!this.psPnMapTransObjectParent.formSubmitted) {
				this.processDataAPW = Object.assign({}, emptyProcessData);
				this.processDataMPW = Object.assign({}, emptyProcessData);
			} else {
				this.populateProcessData();
			}
		} else {
			this.populateProcessData();
		}
	}

	populateProcessData() {
		if (this.psPnMapTransObjectParent.processDataList && this.psPnMapTransObjectParent.processDataList.length > 0) {
			this.psPnMapTransObject = Object.assign({}, this.psPnMapTransObjectParent);
			this.processDataAPW = Object.assign({}, this.psPnMapTransObjectParent.processDataList[0]);
			if (null !== this.processDataAPW.allocPercent && this.processDataAPW.allocPercent !== 100) {
				this.updatedAllocPercentAPW = (Math.round(this.processDataAPW.allocPercent * 10) / 10).toFixed(1);
			} else {
				this.updatedAllocPercentAPW = 100;
			}
			this.processDataMPW = Object.assign({}, this.psPnMapTransObjectParent.processDataList[1]);
			if (null !== this.processDataMPW.allocPercent && this.processDataMPW.allocPercent !== 100) {
				this.updatedAllocPercentMPW = (Math.round(this.processDataMPW.allocPercent * 10) / 10).toFixed(1);
			} else {
				this.updatedAllocPercentMPW = 100;
			}
			if (this.processDataAPW.dedicated) {
				this.processDataAPWUField = Object.assign({}, this.psPnMapTransObjectParent.processDataList[0]);
				this.processDataMPWUField = Object.assign({}, this.psPnMapTransObjectParent.processDataList[1]);
				this.processDataCapacityAPW = Object.assign({}, this.psPnMapTransObjectParent.processDataList[0]);
				this.processDataCapacityMPW = Object.assign({}, this.psPnMapTransObjectParent.processDataList[1]);
			}
		}
	}

	calculateAll() {
		if (this.loggedInUser.includes('SiteEngineer')) {
			if (this.updatedAllocPercentAPW) {
				this.processDataAPW.allocPercent = this.updatedAllocPercentAPW;
			}
			if (this.updatedAllocPercentMPW) {
				this.processDataMPW.allocPercent = this.updatedAllocPercentMPW;
			}
		}
		console.log('calculate all called');
		this.calculateNetAvailableTime();
		if (!this.isSTAEdit) {
			this.populateDemonOEE(this.demonOEE);
		}
		this.calculateProcessSpecificWeeklyPartEstimate();
		this.populateReqOEEBasedOnPreviousProcess();
		this.calculatePhase();
		this.calculateResultAPW();
		this.calculateResultMPW();
		this.isFocusOut = false;
		this.validateAvgOEE();
	}

	populateReqOEEBasedOnPreviousProcess() {
		if (this.psPnMapTransObjectParent.isPrimaryStream) {
			this.carRequest.psPnMapTransList.forEach((psPnMapTran) => {
				this.applyReqPartsToSupportNextProcessPrimaryStream(psPnMapTran, this.carRequest.psPnMapTransList);
			});
		} else {
			const parentProcessStreamNameId = (this.supportingStreamTransMapObject && this.supportingStreamTransMapObject.primaryProcessStreamId) ? this.supportingStreamTransMapObject.primaryProcessStreamId : null;
			const parentProcessNameId = (this.supportingStreamTransMapObject && this.supportingStreamTransMapObject.primaryProcessNameId) ? this.supportingStreamTransMapObject.primaryProcessNameId : null;
			const psPnMapTranListLength = (this.supportingStreamTransMapObject && this.supportingStreamTransMapObject.psPnMapTranList) ? this.supportingStreamTransMapObject.psPnMapTranList.length : null;
			if (this.psPnMapTransObjectParent.processOrderId === psPnMapTranListLength) {
				//search in primary stream
				if (this.carRequest.psPnMapTransList[0].processStreamId === parentProcessStreamNameId) {
					this.carRequest.psPnMapTransList.forEach((psPnMapTran) => {
						if (psPnMapTran.processNameId === parentProcessNameId) {
							this.processDataAPW.reqGoodPartsNext = _.ceil(psPnMapTran.processDataList[0].reqGoodPartsNext / (1 - (psPnMapTran.processDataList[0].scrappedPercent / 100)));
							this.processDataMPW.reqGoodPartsNext = _.ceil(psPnMapTran.processDataList[1].reqGoodPartsNext / (1 - (psPnMapTran.processDataList[0].scrappedPercent / 100)));
						}
					});
				} else {
					this.carRequest.primarySupportingMapTranList.forEach((primarySupportingMap) => {
						if (primarySupportingMap.supportingProcessStreamId === this.supportingStreamTransMapObject.primaryProcessStreamId) { //search in supporting
							primarySupportingMap.psPnMapTranList.forEach(psPnMapTran => {
								if (psPnMapTran.processNameId === parentProcessNameId) {
									this.processDataAPW.reqGoodPartsNext = _.ceil(psPnMapTran.processDataList[0].reqGoodPartsNext / (1 - (psPnMapTran.processDataList[0].scrappedPercent / 100)));
									this.processDataMPW.reqGoodPartsNext = _.ceil(psPnMapTran.processDataList[1].reqGoodPartsNext / (1 - (psPnMapTran.processDataList[0].scrappedPercent / 100)));
								}
							});
						}
					});
				}
				if (this.psPnMapTransObjectParent.processOrderId === 1) { // since it has only one process so load reqGoodPartsIncome
					this.processDataAPW.reqGoodPartsIncome = _.ceil(this.processDataAPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
					this.processDataMPW.reqGoodPartsIncome = _.ceil(this.processDataMPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
				}
			} else if (this.psPnMapTransObjectParent.processOrderId === 1) { // since it is last process so get it from previous and load reqGoodPartsIncome
				this.populateReqPartsNextFromPreviousData();
				this.processDataAPW.reqGoodPartsIncome = _.ceil(this.processDataAPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
				this.processDataMPW.reqGoodPartsIncome = _.ceil(this.processDataMPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
			} else {  // it is in mid so getting it from previous
				this.populateReqPartsNextFromPreviousData();
				this.populateReqPartsIncomeFromPreviousData();
			}

			if (this.psPnMapTransObjectParent.processOrderId === psPnMapTranListLength) {
				//search in primary stream
				if (this.carRequest.psPnMapTransList[0].processStreamId === parentProcessStreamNameId) {
					this.carRequest.psPnMapTransList.forEach((psPnMapTran) => {
						if (psPnMapTran.processNameId === parentProcessNameId) {
							this.processDataAPW.reqGoodPartsIncome = _.ceil(this.processDataAPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
							this.processDataMPW.reqGoodPartsIncome = _.ceil(this.processDataMPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
						}
					});
				} else {
					this.carRequest.primarySupportingMapTranList.forEach((primarySupportingMap) => {
						if (primarySupportingMap.supportingProcessStreamId === this.supportingStreamTransMapObject.primaryProcessStreamId) { //serach in supporting
							primarySupportingMap.psPnMapTranList.forEach(psPnMapTran => {
								if (psPnMapTran.processNameId === parentProcessNameId) {
									this.processDataAPW.reqGoodPartsIncome = _.ceil(this.processDataAPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
									this.processDataMPW.reqGoodPartsIncome = _.ceil(this.processDataMPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
								}
							});
						}
					});
				}
			}
		}
	}

	checkProcessNameAndPartNameAvailableInSharedLoading(selectedProcessNameId: number) {
		const partNumEntered = this.carRequest.prefix + '-' + this.carRequest.base + '-' + this.carRequest.suffix;
		if (this.displayProcessEditPopup) {
			if (this.sharedLoadingRoot) {
				this.isPartNameAvailableInSharedLoading = false;
				if (this.sharedLoadingRoot.sharedLoadingList[0] && this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList) {
					const sharedLoadingPlan = this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.find(slpPlan => slpPlan.partName !== partNumEntered);
					if (sharedLoadingPlan) {
						this.isPartNameAvailableInSharedLoading = true;
					}
				}
			} else {
				this.isPartNameAvailableInSharedLoading = false;
			}
		}
	}

	buildSharedLoadingPath() {
		if (this.processDataAPW.dedicated) {
			this.checkProcessNameAndPartNameAvailableInSharedLoading(this.selectedProcessNameId);
		}
		if (!this.processDataAPW.dedicated && this.sharedLoadingRoot) {
			if (this.sharedLoadingRoot) {
				this.loadTotalAllocationPercentByPartNumber(this.sharedLoadingRoot);
				this.populateSharedLoadingTotalAllocPercent(this.sharedLoadingRoot);
				if (this.sharedLoadingRoot.sharedLoadingList) {
					this.sharedLoadingRoot.sharedLoadingList.find(val => {
						if (val.isAPW) {
							this.daysPerWeekAPWSL = val.daysPerWeek;
							this.shiftsPerDayAPWSL = val.shiftsPerDay;
							this.hrsPerShiftAPWSL = val.hrsPerShift;
							this.downtimeAPWSL = val.contPlanned;
						} else {
							this.daysPerWeekMPWSL = val.daysPerWeek;
							this.shiftsPerDayMPWSL = val.shiftsPerDay;
							this.hrsPerShiftMPWSL = val.hrsPerShift;
							this.downtimeMPWSL = val.contPlanned;
						}
					});
				}
			} else {
				this.sharedLoadingLink = 'N/A';
				this.processDataAPW.allocPercent = 0;
				this.processDataMPW.allocPercent = 0;
			}
		} else {
			this.showSLPartNumMismatchMsg = false;
			this.sharedLoadingLink = 'N/A';
		}
		if (this.sharedLoadingRoot) {
			this.buildSharedLoadingLink(this.sharedLoadingRoot);
			this.populateSharedLoadingTotalAllocPercent(this.sharedLoadingRoot);
		} else {
			this.sharedLoadingLink = 'N/A';
		}
	}

	loadTotalAllocationPercentByPartNumber(sharedLoadingRoot: SharedLoadingRoot) {
		this.showSLPartNumMismatchMsg = true;
		if (this.carRequest.requestStatus !== 'STADRAFT') {
			this.processDataAPW.allocPercent = 0;
			this.processDataMPW.allocPercent = 0;
		}

		if (this.processDataAPW.dedicated && (this.carRequest.requestStatus === 'SUBMITTED' || this.carRequest.requestStatus === 'COMPLETED' || this.carRequest.requestStatus === 'APPROVED' || this.carRequest.requestStatus === 'ACKNOWLEDGED')) {
			this.processDataAPW.allocPercent = 100;
			this.processDataMPW.allocPercent = 100;
		}
		if (!this.processDataAPW.dedicated) {
			if (sharedLoadingRoot) {
				if (sharedLoadingRoot.totalAllocationPercentageAPW < sharedLoadingRoot.totalAllocationPercentageCapacityAPW && sharedLoadingRoot.totalAllocationPercentageMPW < sharedLoadingRoot.totalAllocationPercentageCapacityMPW) {
					this.processDataCapacityAPW.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageCapacityAPW;
					this.processDataCapacityMPW.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageCapacityMPW;
				} else {
					this.processDataCapacityAPW.sharedLoadingTotalAllocPercent = null;
					this.processDataCapacityMPW.sharedLoadingTotalAllocPercent = null;
				}
				if (this.carRequest.submissionScenarioObject && this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.capacityRequirementSource === 'What-If' && this.hideCapacityStudyElements) {
					this.processDataAPWUField.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageCapacityAPW;
					this.processDataMPWUField.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageCapacityMPW;
				} else {
					this.processDataAPWUField.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageAPW;
					this.processDataMPWUField.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageMPW;
				}
			}

			let partNameMatchedSlpAPW: SharedLoadingPlan;
			let partNameMatchedSlpMPW: SharedLoadingPlan;
			this.reqGoodPartsAPW = -1;
			this.reqGoodPartsMPW = -1;
			let isGoodPartsMatchedWithSlp = false;
			const partNameMatchedIndexs: any[] = [];
			const reqGoodPartsMatchedIndexes: any[] = [];
			const nictMatchedIndexes: any[] = [];
			const partNumEntered = this.carRequest.prefix + '-' + this.carRequest.base + '-' + this.carRequest.suffix;
			if (this.processDataAPW.netIdleCycleTime && this.processDataAPW.netIdleCycleTime !== null) {
				if (this.isCapacityStudyScenario) {
					const sharedLoadingPlanHolderList = [];
					sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.forEach((sharedLoadingPlan, index) => {
						const sharedLoadingPlanHolder = new SharedLoadingPlanHolder();
						if (sharedLoadingPlan.isCapacity) {
							sharedLoadingPlanHolder.capacityAPW = sharedLoadingPlan;
							sharedLoadingPlanHolder.capacityMPW = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
							let indexValue = 0;
							sharedLoadingPlanHolderList.forEach((slPlanHolder, indx) => {
								if (slPlanHolder.apw.groupId === sharedLoadingPlan.groupId) {
									indexValue = indx;
								}
							});
							sharedLoadingPlanHolderList[indexValue].capacityAPW = sharedLoadingPlan;
							sharedLoadingPlanHolderList[indexValue].capacityMPW = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
						} else {
							sharedLoadingPlanHolder.apw = sharedLoadingPlan;
							sharedLoadingPlanHolder.mpw = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
							sharedLoadingPlanHolderList.push(sharedLoadingPlanHolder);
						}
					});
					//find matching partNumber from SLPHolderList
					const matchedSLPHolderList = sharedLoadingPlanHolderList.filter(sharedLoadingPlanHolder => {
						if (sharedLoadingPlanHolder.apw.partName.toUpperCase() === partNumEntered.toUpperCase()) {
							this.showSLPartNumMismatchMsg = false;
						}
						return sharedLoadingPlanHolder.apw.partName.toUpperCase() === partNumEntered.toUpperCase();
					}).filter(sharedLoadingPlanHolder => {
						if (sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityMPW) {
							this.capacityRecordNotFound = false;
						}
						return sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityMPW;
					}).filter(sharedLoadingPlanHolder => {
						return this.processDataAPW.netIdleCycleTime && sharedLoadingPlanHolder.capacityAPW.nict === this.processDataAPW.netIdleCycleTime;
					});

					const fullyMatchedSLPHolderList = matchedSLPHolderList.filter(sharedLoadingPlanHolder => {
						if ((sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek === this.processDataAPW.reqGoodPartsNext) && (sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek === this.processDataMPW.reqGoodPartsNext)) {
							isGoodPartsMatchedWithSlp = true;
						}
						return (sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek === this.processDataAPW.reqGoodPartsNext) && (sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek === this.processDataMPW.reqGoodPartsNext);
					});

					if (isGoodPartsMatchedWithSlp && fullyMatchedSLPHolderList.length > 0) {
						this.setAllocPercentReqGoodPartsSLPWithPartNumForCapacityAPWMAP(fullyMatchedSLPHolderList[0]);
					} else if (matchedSLPHolderList.length > 0) {
						matchedSLPHolderList.forEach(matchedSLPHolder => {
							this.setAllocPercentReqGoodPartsSLPWithPartNumForCapacityAPWMAP(matchedSLPHolder);
						});
					}
				} else {
					//finding partName index list matched with sharedLoadingPlan
					if (sharedLoadingRoot) {
						sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.forEach((sharedLoadingPlan, index) => {
							if (sharedLoadingPlan.partName.toUpperCase() === partNumEntered.toUpperCase() && !sharedLoadingPlan.isOtherAllocation && !sharedLoadingPlan.isCapacity) {
								partNameMatchedIndexs.push(index);
							}
						});
					}

					// Finding req good parts matched indexes from the above part name matched list
					if (partNameMatchedIndexs.length > 0) {
						this.showSLPartNumMismatchMsg = false;
						partNameMatchedIndexs.forEach(partNameMatchedIndex => {
							partNameMatchedSlpAPW = sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex];
							partNameMatchedSlpMPW = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[partNameMatchedIndex];
							if (this.processDataAPW.netIdleCycleTime && (this.processDataAPW.netIdleCycleTime === partNameMatchedSlpAPW.nict)) {
								nictMatchedIndexes.push(partNameMatchedIndex);
							} else {
								this.nictAPW = sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex].nict;
							}
							/*if ((this.processDataAPW.reqGoodPartsNext === partNameMatchedSlpAPW.reqGoodPartsPerWeek)
								&& (this.processDataMPW.reqGoodPartsNext === partNameMatchedSlpMPW.reqGoodPartsPerWeek)) {
								reqGoodPartsMatchedIndex.push(partNameMatchedIndex);
							}*/
						});
					}

					// Finding nict match from above matched good parts
					/*if (reqGoodPartsMatchedIndex.length > 0) {
						reqGoodPartsMatchedIndex.forEach(goodPartsMatchedIndex => {
							partNameMatchedSlpAPW = sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[goodPartsMatchedIndex];
							partNameMatchedSlpMPW = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[goodPartsMatchedIndex];*/
					if (nictMatchedIndexes.length > 0) {
						nictMatchedIndexes.forEach(nictMatchedIndex => {
							partNameMatchedSlpAPW = sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[nictMatchedIndex];
							partNameMatchedSlpMPW = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[nictMatchedIndex];
							if (!isGoodPartsMatchedWithSlp) {
								if ((this.processDataAPW.reqGoodPartsNext === partNameMatchedSlpAPW.reqGoodPartsPerWeek)
									&& (this.processDataMPW.reqGoodPartsNext === partNameMatchedSlpMPW.reqGoodPartsPerWeek)) {
									isGoodPartsMatchedWithSlp = true;
									console.log('Good Parts Matched');
									this.setAllocPercentReqGoodPartsSLPwithPartNumForAPWMAP(sharedLoadingRoot, nictMatchedIndex);
								} else {
									this.setAllocPercentReqGoodPartsSLPwithPartNumForAPWMAP(sharedLoadingRoot, nictMatchedIndex);
								}
								//this.setAllocPercentReqGoodPartsSLPwithPartNumForAPWMAP(sharedLoadingRoot, goodPartsMatchedIndex);
							}
						});
					}
				}
			} else {
				if (this.isCapacityStudyScenario) {
					const sharedLoadingPlanHolderList = [];
					sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.forEach((sharedLoadingPlan, index) => {
						const sharedLoadingPlanHolder = new SharedLoadingPlanHolder();
						if (sharedLoadingPlan.isCapacity) {
							sharedLoadingPlanHolder.capacityAPW = sharedLoadingPlan;
							sharedLoadingPlanHolder.capacityMPW = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
							let indexValue = 0;
							sharedLoadingPlanHolderList.forEach((slPlanHolder, indx) => {
								if (slPlanHolder.apw.groupId === sharedLoadingPlan.groupId) {
									indexValue = indx;
								}
							});
							sharedLoadingPlanHolderList[indexValue].capacityAPW = sharedLoadingPlan;
							sharedLoadingPlanHolderList[indexValue].capacityMPW = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
						} else {
							sharedLoadingPlanHolder.apw = sharedLoadingPlan;
							sharedLoadingPlanHolder.mpw = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
							sharedLoadingPlanHolderList.push(sharedLoadingPlanHolder);
						}
					});
					//find matching partNumber from SLPHolderList
					const matchedSLPHolderList = sharedLoadingPlanHolderList.filter(sharedLoadingPlanHolder => {
						if (sharedLoadingPlanHolder.apw.partName.toUpperCase() === partNumEntered.toUpperCase()) {
							this.showSLPartNumMismatchMsg = false;
						}
						return sharedLoadingPlanHolder.apw.partName.toUpperCase() === partNumEntered.toUpperCase();
					}).filter(sharedLoadingPlanHolder => {
						if (sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityMPW) {
							this.capacityRecordNotFound = false;
						}
						return sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityMPW;
					}).filter(sharedLoadingPlanHolder => {
						if ((sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek === this.processDataAPW.reqGoodPartsNext) && (sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek === this.processDataMPW.reqGoodPartsNext)) {
							isGoodPartsMatchedWithSlp = true;
						}
						return (sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek === this.processDataAPW.reqGoodPartsNext) && (sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek === this.processDataMPW.reqGoodPartsNext);
					});
					if (isGoodPartsMatchedWithSlp && matchedSLPHolderList.length > 0) {
						this.setAllocPercentReqGoodPartsSLPWithPartNumForCapacityAPWMAP(matchedSLPHolderList[0]);
					}
				} else {
					//finding partName index list matched with sharedLoadingPlan
					if (sharedLoadingRoot) {
						sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.forEach((sharedLoadingPlan, index) => {
							if (sharedLoadingPlan.partName.toUpperCase() === partNumEntered.toUpperCase() && !sharedLoadingPlan.isOtherAllocation && !sharedLoadingPlan.isCapacity) {
								partNameMatchedIndexs.push(index);
							}
						});
					}

					// Finding req good parts matched indexes from the above part name matched list
					if (partNameMatchedIndexs.length > 0) {
						this.showSLPartNumMismatchMsg = false;
						partNameMatchedIndexs.forEach(partNameMatchedIndex => {
							partNameMatchedSlpAPW = sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex];
							partNameMatchedSlpMPW = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[partNameMatchedIndex];
							if ((this.processDataAPW.reqGoodPartsNext === partNameMatchedSlpAPW.reqGoodPartsPerWeek)
								&& (this.processDataMPW.reqGoodPartsNext === partNameMatchedSlpMPW.reqGoodPartsPerWeek)) {
								reqGoodPartsMatchedIndexes.push(partNameMatchedIndex);
							}
						});
					}

					// Finding reqGoodParts match from above matched good parts
					if (!isGoodPartsMatchedWithSlp && reqGoodPartsMatchedIndexes.length > 0) {
						isGoodPartsMatchedWithSlp = true;
						console.log('Good Parts Matched');
						this.setAllocPercentReqGoodPartsSLPwithPartNumForAPWMAP(sharedLoadingRoot, reqGoodPartsMatchedIndexes[0]);
					}
				}
			}
			if (this.carRequest.requestStatus !== 'STADRAFT' && sharedLoadingRoot) {
				this.processDataAPW.daysPerWeek = sharedLoadingRoot.sharedLoadingList[0].daysPerWeek;
				this.processDataAPW.shiftsPerDay = sharedLoadingRoot.sharedLoadingList[0].shiftsPerDay;
				this.processDataAPW.hrsPerShift = sharedLoadingRoot.sharedLoadingList[0].hrsPerShift;
				this.processDataAPW.downTime = sharedLoadingRoot.sharedLoadingList[0].contPlanned;

				this.processDataMPW.daysPerWeek = sharedLoadingRoot.sharedLoadingList[1].daysPerWeek;
				this.processDataMPW.shiftsPerDay = sharedLoadingRoot.sharedLoadingList[1].shiftsPerDay;
				this.processDataMPW.hrsPerShift = sharedLoadingRoot.sharedLoadingList[1].hrsPerShift;
				this.processDataMPW.downTime = sharedLoadingRoot.sharedLoadingList[1].contPlanned;
			}
		}
	}

	private setAllocPercentReqGoodPartsSLPwithPartNumForAPWMAP(sharedLoadingRoot: SharedLoadingRoot, partNameMatchedIndex) {
		this.sharedLoadingPlanWithPartNumAPW = sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex];
		this.sharedLoadingPlanWithPartNumMPW = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[partNameMatchedIndex];
		if (this.carRequest.requestStatus !== 'STADRAFT') {
			this.processDataAPW.allocPercent = sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex].allocationTotalRequired;
			this.processDataMPW.allocPercent = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[partNameMatchedIndex].allocationTotalRequired;
		}
		this.reqGoodPartsAPW = sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex].reqGoodPartsPerWeek;
		this.reqGoodPartsMPW = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[partNameMatchedIndex].reqGoodPartsPerWeek;
		this.nictAPW = sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex].nict;
	}

	private setAllocPercentReqGoodPartsSLPWithPartNumForCapacityAPWMAP(sharedLoadingPlanHolder: SharedLoadingPlanHolder) {
		this.sharedLoadingPlanWithPartNumAPW = sharedLoadingPlanHolder.capacityAPW;
		this.sharedLoadingPlanWithPartNumMPW = sharedLoadingPlanHolder.capacityMPW;
		this.processDataAPW.allocPercent = sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired;
		this.processDataMPW.allocPercent = sharedLoadingPlanHolder.capacityMPW.allocationTotalRequired;
		this.reqGoodPartsAPW = sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek;
		this.reqGoodPartsMPW = sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek;
		this.nictAPW = sharedLoadingPlanHolder.capacityAPW.nict;
	}

	onSubmitProcessData() {
		this.showDefinitionCon = false;
		this.enableErrorStatus();
		this.clearToast();
		if (this.mode === 'edit') {
			this.checkFlagForScrapPercent();
		}
		this.validateTouchedFields();
		if (this.isValid()) {
			if ((this.processDataMPW.resultStatus === 'Valid' || this.processDataMPW.resultStatus === 'Warning' || this.processDataMPW.resultStatus === 'Risk' || this.processDataMPW.resultStatus === 'Error' || this.processDataMPW.resultStatus === 'Incomplete') && (this.processDataAPW.resultStatus === 'Valid' || this.processDataAPW.resultStatus === 'Warning' || this.processDataAPW.resultStatus === 'Risk' || this.processDataAPW.resultStatus === 'Error' || this.processDataAPW.resultStatus === 'Incomplete')) {
				// Saving eCAR Process data in all result status (incomplete, error, risk, warning, valid)
				const saveCAR = true;
				// if (this.processDataAPW.apwMpwChange || this.processDataAPW.scenarioChange || this.processDataAPW.partPrefixBaseSuffixChange || this.processDataMPW.apwMpwChange || this.processDataAPW.hmpChange || this.processDataMPW.hmpChange || this.processDataAPW.sharedLoadChange || this.processDataMPW.sharedLoadChange || this.processDataAPW.scrapChange) {
				// 	saveCAR = true;
				// }
				this.processDataAPW.psChange = false;
				this.processDataMPW.psChange = false;
				this.processDataAPW.apwMpwChange = false;
				this.processDataAPW.scenarioChange = false;
				this.processDataAPW.partPrefixBaseSuffixChange = false;
				this.processDataMPW.apwMpwChange = false;
				this.processDataAPW.hmpChange = false;
				this.processDataMPW.hmpChange = false;
				this.processDataAPW.sharedLoadChange = false;
				this.processDataMPW.sharedLoadChange = false;
				this.processDataAPW.scrapChange = false;
				this.processDataAPW.capacityRequirementChange = false;
				this.processDataMPW.capacityRequirementChange = false;
				if (this.processDataAPW.isApprovalRobustness === true || this.processDataMPW.isApprovalRobustness === true) {
					this.processDataAPW.isApprovalRobustness = false;
					this.processDataMPW.isApprovalRobustness = false;
				}
				if (this.processDataAPW.isCopiedCar === true || this.processDataMPW.isCopiedCar === true) {
					this.processDataAPW.isCopiedCar = false;
					this.processDataMPW.isCopiedCar = false;
				}

				this.psPnMapTransObject = Object.assign({}, this.psPnMapTransObjectParent);
				this.psPnMapTransObject.processDataList = [];
				this.processDataAPW.processNameId = this.selectedProcessNameId;
				this.processDataAPW.isApw = true;

				this.psPnMapTransObject.processDataList.push(this.processDataAPW);

				this.processDataMPW.processNameId = this.selectedProcessNameId;
				this.processDataMPW.isApw = false;
				this.processDataMPW.dedicated = this.processDataAPW.dedicated;
				this.processDataMPW.partsReworkedPercent = this.processDataAPW.partsReworkedPercent;

				this.psPnMapTransObject.processDataList.push(this.processDataMPW);
				this.psPnMapTransObject.processNameId = this.selectedProcessNameId;
				this.psPnMapTransObject.formSubmitted = true;
				if (this.loggedInUser.includes('SiteEngineer')) {
					this.processDataAPW.allocPercent = this.updatedAllocPercentAPW;
					this.processDataMPW.allocPercent = this.updatedAllocPercentMPW;
				}
				this.psPnMapTransObjectEmitter.emit(this.psPnMapTransObject);
				this.saveCarRequestEmitter.emit(saveCAR);
				this.displayProcessEditPopup = false;
			} else {
				this.displayProcessEditPopup = true;
				this.messageService.add({
					key: 'processDataToast',
					severity: 'error',
					summary: 'Error',
					detail: 'APW, MPW Results are Invalid.',
					life: 15000
				});
			}
		} else {
			this.displayProcessEditPopup = true;
		}
	}

	isValid(): boolean {
		let isValid = true;
		if (this.sharedLoadingLink === 'N/A' && !this.processDataAPW.dedicated) {
			isValid = false;
		}
		if (!this.validateSharedLoadingData()) {
			isValid = false;
		}
		if (!this.validateCommonData()) {
			isValid = false;
		}
		if (isValid && !this.carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
			if (!this.validateHMP()) {
				isValid = false;
			}
		}
		// For all errors we are letting to save the data but displaying the error messages
		//return isValid;
		return true;
	}

	validateCommonData() {
		let isValid = true;
		if (this.processDataAPW.daysPerWeek > 7) {
			isValid = false;
			this.messageService.add({
				key: 'processDataToast',
				severity: 'error',
				summary: 'Error',
				detail: 'APW Cannot be greater than 7 days',
				life: 15000
			});
		}
		if (this.processDataMPW.daysPerWeek > 7) {
			isValid = false;
			this.messageService.add({
				key: 'processDataToast',
				severity: 'error',
				summary: 'Error',
				detail: 'MPW Cannot be greater than 7 days',
				life: 15000
			});
		}
		return isValid;
	}

	validateHMP() {
		let isValid = true;
		this.hmpOlderThan32WeeksWarningShown = false;
		this.hmpRecordsCountLessThan25WarningShown = false;
		this.oneOrMoreOEEPercentageGreaterThan100ErrorShown = false;

		if (this.historicalmfgperfs && this.historicalmfgperfs.length > 0) {
			if (this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'STADRAFT') {
				const thirtyTwoWeeksAgo = moment().subtract(32, 'weeks').valueOf();
				if (this.isContainAnyDataBeyondLastThirtyTwoWeeks(thirtyTwoWeeksAgo)) {
					this.hmpOlderThan32WeeksWarningShown = true;
					isValid = true;
				}
			} else {
				const thirtyTwoWeeksAgo = moment(this.carRequest.lastSubmittedDate).subtract(32, 'weeks').valueOf();
				if (this.isContainAnyDataBeyondLastThirtyTwoWeeks(thirtyTwoWeeksAgo)) {
					this.hmpOlderThan32WeeksWarningShown = true;
					isValid = true;
				}
			}

			if (this.isHMPRecordsCountLessThan25()) {
				this.hmpRecordsCountLessThan25WarningShown = true;
				isValid = true;
			}
			if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase') && this.isOneOrMoreOEEPercentageGreaterThan100()) {
				this.oneOrMoreOEEPercentageGreaterThan100ErrorShown = true;
				isValid = true;
			}
		}
		return isValid;
	}

	parseDate(curDate: any) {
		return Date.parse(curDate);
	}

	validateTouchedFields() {
		Object.keys(this.processDataForm.form.controls).forEach((key) => {
			const control = this.processDataForm.form.controls[key];
			if (control.touched) {
				control.touched = false;
			}
		});
	}

	validateSharedLoadingData() {
		let isValid = true;
		if (!this.processDataAPW.dedicated) {
			if (this.sharedLoadingPlanWithPartNumAPW && this.sharedLoadingPlanWithPartNumAPW.reqGoodPartsPerWeek > 0 && this.processDataAPW.reqGoodPartsNext > 0) {
				if (this.sharedLoadingPlanWithPartNumAPW.reqGoodPartsPerWeek !== this.processDataAPW.reqGoodPartsNext) {
					isValid = false;
					this.messageService.add({
						key: 'processDataToast',
						severity: 'error',
						summary: 'Error',
						detail: 'APW - Required Good Parts - Mismatch found between Shared Loading Plan and calculated data',
						life: 15000
					});
				}
			}
		}
		if (!this.processDataAPW.dedicated) {
			if (this.sharedLoadingPlanWithPartNumMPW && this.sharedLoadingPlanWithPartNumMPW.reqGoodPartsPerWeek > 0 && this.processDataMPW.reqGoodPartsNext > 0) {
				if (this.sharedLoadingPlanWithPartNumMPW.reqGoodPartsPerWeek !== this.processDataMPW.reqGoodPartsNext) {
					isValid = false;
					this.messageService.add({
						key: 'processDataToast',
						severity: 'error',
						summary: 'Error',
						detail: 'MPW - Required Good Parts - Mismatch found between Shared Loading Plan and calculated data',
						life: 15000
					});
				}
			}
		}

		if (!this.processDataAPW.dedicated && this.carRequest.requestStatus !== 'STADRAFT') {
			if ((this.sharedLoadingPlanWithPartNumAPW && this.sharedLoadingPlanWithPartNumAPW.nict !== _.round(this.processDataAPW.netIdleCycleTime, 4)) ||
				(this.sharedLoadingPlanWithPartNumMPW && this.sharedLoadingPlanWithPartNumMPW.nict !== _.round(this.processDataAPW.netIdleCycleTime, 4))) {
				isValid = false;
				this.messageService.add({
					key: 'processDataToast',
					severity: 'error',
					summary: 'Error',
					detail: 'NICT mismatch found between shared loading plan and entered data',
					life: 15000
				});
			}
		}
		return isValid;
	}

	calculateNetAvailableTime() {
		if (this.processDataAPW.dedicated) {
			this.processDataAPW.allocPercent = 100;
			this.processDataMPW.allocPercent = 100;
		} else {
			this.buildSharedLoadingPath();
		}
		this.calculateNetAvailableTimeAPWAndMPW();
		this.populateDedicatedTotalAllocPercent();
	}

	calculateNetIdleCycleTime() {
		this.processDataAPW.netIdleCycleTime = _.round(this.processDataAPW.idealCycleTime / (this.processDataAPW.noToolsMachParallel * this.processDataAPW.noIdentityParts), 4);
		this.processDataMPW.netIdleCycleTime = _.round(this.processDataMPW.idealCycleTime / (this.processDataMPW.noToolsMachParallel * this.processDataMPW.noIdentityParts), 4);
		this.processDataAPW.netIdleCycleTime = this.processDataAPW.netIdleCycleTime === 1 / 0 ? 0 : this.processDataAPW.netIdleCycleTime;
		this.processDataMPW.netIdleCycleTime = this.processDataMPW.netIdleCycleTime === 1 / 0 ? 0 : this.processDataMPW.netIdleCycleTime;
		this.calculateTheoreticalPartsPerWeek();
	}

	calculateTheoreticalPartsPerWeek() {
		this.processDataAPW.theoreticalParts = (this.processDataAPW.netAvailableTime * 3600) / this.processDataAPW.netIdleCycleTime;
		this.processDataMPW.theoreticalParts = (this.processDataMPW.netAvailableTime * 3600) / this.processDataAPW.netIdleCycleTime;
		this.processDataAPW.theoreticalParts = this.processDataAPW.theoreticalParts ? this.processDataAPW.theoreticalParts : 0;
		this.processDataMPW.theoreticalParts = this.processDataMPW.theoreticalParts ? this.processDataMPW.theoreticalParts : 0;
		this.calculateReqOEE();
		this.calculateRemainingForAvailability();
		this.calculateProcessSpecificWeeklyPartEstimate();
	}

	calculateReqOEE() {
		console.log('Required OEE');
		this.processDataAPW.reqOee = _.round((this.processDataAPW.reqGoodPartsNext / this.processDataAPW.theoreticalParts) * 100, 1);
		this.processDataMPW.reqOee = _.round((this.processDataMPW.reqGoodPartsNext / this.processDataMPW.theoreticalParts) * 100, 1);
		this.processDataAPW.reqOee = this.processDataAPW.reqOee === 1 / 0 ? 0 : this.processDataAPW.reqOee;
		this.processDataMPW.reqOee = this.processDataMPW.reqOee === 1 / 0 ? 0 : this.processDataMPW.reqOee;
		this.calculateReqJPH();
		this.calculateDemonJPH();
	}

	calculateDemonJPH() {
		this.processDataAPW.demonJph = _.round(((3600 / this.processDataAPW.netIdleCycleTime) * this.processDataAPW.averageHisOee) / 100, 1);
		this.processDataAPW.demonJph = this.processDataAPW.demonJph === 1 / 0 ? 0 : this.processDataAPW.demonJph;
	}

	calculateReqJPH() {
		this.processDataAPW.reqJph = _.round(((3600 / this.processDataAPW.netIdleCycleTime) * this.processDataAPW.reqOee) / 100, 1);
		this.processDataMPW.reqJph = _.round(((3600 / this.processDataAPW.netIdleCycleTime) * this.processDataMPW.reqOee) / 100, 1);
		this.processDataAPW.reqJph = this.processDataAPW.reqJph === 1 / 0 ? 0 : this.processDataAPW.reqJph;
		this.processDataMPW.reqJph = this.processDataMPW.reqJph === 1 / 0 ? 0 : this.processDataMPW.reqJph;
	}

	calculateRemainingForAvailability() {

		const firtsPartAPW = this.processDataAPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100));
		const secondPartAPW = this.processDataAPW.reqGoodPartsNext * (this.processDataAPW.partsReworkedPercent / 100);
		const thirdPartAPW = this.processDataAPW.planMinChan * 60 * this.processDataAPW.planChangFreq / this.processDataAPW.netIdleCycleTime;
		const partSumAPW = firtsPartAPW + secondPartAPW + thirdPartAPW;
		const remainingValueAPW = (this.processDataAPW.theoreticalParts - partSumAPW) / this.processDataAPW.theoreticalParts;
		this.processDataAPW.avaiRemainPercent = remainingValueAPW * 100;
		this.calculateChangeOverAssumptionsAPW(partSumAPW);

		const firtsPartMPW = this.processDataMPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100));
		const secondPartMPW = this.processDataMPW.reqGoodPartsNext * (this.processDataAPW.partsReworkedPercent / 100);
		const thirdPartMPW = this.processDataAPW.planMinChan * 60 * this.processDataAPW.planChangFreq / this.processDataAPW.netIdleCycleTime;
		const partSumMPW = firtsPartMPW + secondPartMPW + thirdPartMPW;
		const remainingValueMPW = (this.processDataMPW.theoreticalParts - partSumMPW) / this.processDataMPW.theoreticalParts;
		this.processDataMPW.avaiRemainPercent = remainingValueMPW * 100;
		this.calculateChangeOverAssumptionsMPW(partSumMPW);
	}

	calculateChangeOverAssumptionsAPW(partSumAPW) {
		this.processDataAPW.isContainAssumptions = partSumAPW <= this.processDataAPW.theoreticalParts;
		this.assignWeeklyPartTooltip();
	}

	calculateChangeOverAssumptionsMPW(partSumMPW) {
		this.processDataMPW.isContainAssumptions = partSumMPW <= this.processDataMPW.theoreticalParts;
		this.assignWeeklyPartTooltip();
	}

	assignWeeklyPartTooltip() {
		if (!this.processDataAPW.isContainAssumptions || !this.processDataMPW.isContainAssumptions) {
			this.weeklyPartToolTIp = 'Cannot be calculated due to excessive losses based on changeover, scrap and rework.  Please review Historical Manufacturing Data as it may not be representative for this part.';
		} else if ((!this.processDataAPW.dedicated && (this.processDataAPW.sharedLoadingTotalAllocPercent > 100 || this.processDataMPW.sharedLoadingTotalAllocPercent > 100)) ||
			(this.processDataAPW.dedicated && (this.processDataAPW.dedicatedTotalAllocPercent > 100 || this.processDataMPW.dedicatedTotalAllocPercent > 100))) {
			this.weeklyPartToolTIp = 'Cannot be calculated due to allocation in U exceeding 100%, there is insufficient capacity.';
		} else {
			this.weeklyPartToolTIp = '';
		}
	}

	calculateProcessSpecificWeeklyPartEstimate() {
		if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
			this.processDataAPW.weekPartEstimate = Math.floor(_.round(((this.processDataAPW.theoreticalParts * this.processDataAPW.phaseOEE) / 100), 10));
			this.processDataMPW.weekPartEstimate = Math.floor(_.round(((this.processDataMPW.theoreticalParts * this.processDataAPW.phaseOEE) / 100), 10));
		} else {
			this.processDataAPW.weekPartEstimate = Math.floor(_.round(((this.processDataAPW.theoreticalParts * this.processDataAPW.averageHisOee) / 100), 10));
			this.processDataMPW.weekPartEstimate = Math.floor(_.round(((this.processDataMPW.theoreticalParts * this.processDataAPW.averageHisOee) / 100), 10));
		}
		this.calculateResultAPW();
		this.calculateResultMPW();
	}

	calculateResultAPW() {
		let demonOEE = this.processDataAPW.averageHisOee;
		let scenario = this.carRequest.submissionScenarioObject.templateName;
		scenario = scenario.startsWith('Phase') ? scenario.startsWith('Phase 0') ? 'Phase 0' : 'Phase 3' : 'capacity';
		if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
			demonOEE = !this.processDataAPW.phaseOEE ? 0 : this.processDataAPW.phaseOEE;
			this.cycleTimeValidation();
		}
		if (!this.calculateIncompleteResultAPW()) {
			if ((!this.processDataAPW.dedicated && (this.processDataAPW.sharedLoadingTotalAllocPercent > 100 || this.processDataMPW.sharedLoadingTotalAllocPercent > 100)) ||
				(this.processDataAPW.dedicated && (this.processDataAPW.dedicatedTotalAllocPercent > 100 || this.processDataMPW.dedicatedTotalAllocPercent > 100))) {
				this.weeklyPartToolTIp = 'Cannot be calculated due to allocation in U exceeding 100%, there is insufficient capacity.';
			}
			if (!this.processDataAPW.isContainAssumptions || !this.processDataMPW.isContainAssumptions) {
				this.weeklyPartToolTIp = 'Cannot be calculated due to excessive losses based on changeover, scrap and rework.  Please review Historical Manufacturing Data as it may not be representative for this part.';
			}
			if ((this.processDataAPW.plannedDownTime + this.processDataAPW.changeOverTimeActual + this.processDataAPW.observedUnplannedDownTime) > this.processDataAPW.totalAvailableTime) {
				this.averageOEEToolTip = 'Downtime cannot be higher than Total Available Time [(W+Y+AB) cannot be higher than V], please review and correct';
			}
			if (this.processDataAPW.equipmentAvailability > 100) {
				this.averageOEEToolTip = 'Equipment Availability cannot be > 100%, please review and correct';
			}
			if (this.processDataAPW.performanceEfficiency > 100) {
				this.averageOEEToolTip = 'Efficiency cannot be higher than 100%, please review and correct';
			}
			if (this.processDataAPW.noOfCurrentToolsORMachines > this.processDataAPW.noToolsMachParallel) {
				this.averageOEEToolTip = 'AF1 Cannot be higher than planned in L (above); either update plan or correct this data';
			}
			if (this.processDataAPW.noOfIdenticalPartsProduced > this.processDataAPW.noIdentityParts) {
				this.averageOEEToolTip = 'AF2 Cannot be higher than planned in M (above); either update plan or correct this data';
			}
			if (this.processDataAPW.qualityRate < 0) {
				this.averageOEEToolTip = 'Sum of scrap and rework cannot be greater than parts produced (AE), please review and correct';
			}
			if (this.carRequest.requestStatus !== 'STADRAFT' && !this.processDataAPW.dedicated && (this.reqGoodPartsAPW != null) && (this.processDataAPW.reqGoodPartsNext !== this.reqGoodPartsAPW)) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (this.carRequest.requestStatus !== 'STADRAFT' && (!this.processDataAPW.dedicated && (this.processDataAPW.idealCycleTime !== null && this.processDataAPW.noToolsMachParallel !== null && this.processDataAPW.noIdentityParts !== null) && _.round(this.processDataAPW.netIdleCycleTime, 4) !== this.nictAPW)) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (this.processDataAPW.daysPerWeek > 7) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (this.HMPLink === 'Not Available') {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (this.sharedLoadingLink === 'N/A' && !this.processDataAPW.dedicated) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (!this.processDataAPW.dedicated && this.processDataAPW.sharedLoadingTotalAllocPercent === 0) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (this.processDataAPW.downTime / 60 >= this.processDataAPW.hrsPerShift) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (this.processDataAPW.plannedDownTime > this.processDataAPW.totalAvailableTime) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if ((this.processDataAPW.noOfPartsScrapped + this.processDataAPW.noOfPartsReworked) > this.processDataAPW.totalPartsRun) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (scenario.startsWith('capacity') && this.isHmpItemExceeds100) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (this.oneOrMoreOEEPercentageGreaterThan100ErrorShown) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (!this.processDataAPW.isContainAssumptions && !this.processDataAPW.dedicated) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (!this.hideProcessDetailsValidation && (scenario.startsWith('Phase 0') || scenario.startsWith('Phase 3')) && (this.processDataAPW.equipmentAvailability > 100 || ((this.processDataAPW.plannedDownTime + this.processDataAPW.changeOverTimeActual + this.processDataAPW.observedUnplannedDownTime) > this.processDataAPW.totalAvailableTime) || this.processDataAPW.performanceEfficiency > 100 || this.processDataAPW.qualityRate < 0)) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (!this.hideProcessDetailsValidation && scenario.startsWith('Phase 0') && ((this.processDataAPW.noToolsMachParallel < this.processDataAPW.noOfCurrentToolsORMachines) || (this.processDataAPW.noIdentityParts < this.processDataAPW.noOfIdenticalPartsProduced))) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (!this.hideProcessDetailsValidation && scenario.startsWith('Phase 3') && this.processDataAPW.changeOverTimeActual <= 0 && (this.processDataAPW.planMinChan * this.processDataAPW.planChangFreq) > 0) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (this.processDataAPW.scrappedPercent > 100) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (this.processDataAPW.partsReworkedPercent > 100) {
				this.processDataAPW.resultStatus = 'Error';
				this.processDataAPW.resultStatusMessage = 'Error';
			} else if (!this.hideProcessDetailsValidation && this.processDataAPW.reqGoodPartsNext > this.roundDownWeeklyPartEstimate(this.processDataAPW.weekPartEstimate)) {
				this.processDataAPW.resultStatus = 'Risk';
				this.processDataAPW.resultStatusMessage = 'Risk';
			} else if ((this.processDataAPW.reqOee < 100 && demonOEE < 100) && (demonOEE < this.processDataAPW.reqOee)) {
				this.processDataAPW.resultStatus = 'Risk';
				this.processDataAPW.resultStatusMessage = 'Risk';
			} else if (!this.processDataAPW.isContainAssumptions && this.processDataAPW.dedicated) {
				this.processDataAPW.resultStatus = 'Risk';
				this.processDataAPW.resultStatusMessage = 'Risk';
			} else if (this.processDataAPW.reqOee > 100 || demonOEE > 100) {
				this.processDataAPW.resultStatus = 'Risk';
				this.processDataAPW.resultStatusMessage = 'Risk';
			} else if (this.processDataAPW.avaiRemainPercent <= 0) {
				this.processDataAPW.resultStatus = 'Risk';
				this.processDataAPW.resultStatusMessage = 'Risk';
			} else if (!this.processDataAPW.dedicated && this.processDataAPW.sharedLoadingTotalAllocPercent > 100) {
				this.processDataAPW.resultStatus = 'Risk';
				this.processDataAPW.resultStatusMessage = 'Risk';
			} else if (this.processDataAPW.dedicated && this.processDataAPW.dedicatedTotalAllocPercent > 100) {
				this.processDataAPW.resultStatus = 'Risk';
				this.processDataAPW.resultStatusMessage = 'Risk';
			} else if (!scenario.startsWith('capacity') && this.processDataAPW.performanceEfficiency > 100) {
				this.processDataAPW.resultStatus = 'Risk';
				this.processDataAPW.resultStatusMessage = 'Risk';
			} else if (this.processDataAPW.daysPerWeek > 5 && this.processDataAPW.daysPerWeek <= 7) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if ((this.processDataAPW.shiftsPerDay * this.processDataAPW.hrsPerShift) > 24) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.dedicated && this.isPartNameAvailableInSharedLoading) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.dedicated && this.isProcessUsedInAnotherPartNumber && !this.isPartNameAvailableInSharedLoading) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.reqOee === 0 || this.processDataAPW.reqOee > 100) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (!this.processDataAPW.dedicated && this.processDataAPW.sharedLoadingTotalAllocPercent >= 90 && this.processDataAPW.sharedLoadingTotalAllocPercent <= 100) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.dedicated && this.processDataAPW.dedicatedTotalAllocPercent >= 90 && this.processDataAPW.dedicatedTotalAllocPercent <= 100) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if ((this.processDataAPW.avaiRemainPercent < 10) && (this.processDataAPW.avaiRemainPercent > 0)) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.noOfPartsScrappedPercent > this.processDataAPW.scrappedPercent) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.noOfPartsReworkedPercent > this.processDataAPW.partsReworkedPercent) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (this.hmpOlderThan32WeeksWarningShown) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (this.hmpRecordsCountLessThan25WarningShown) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (!scenario.startsWith('capacity') && this.processDataAPW.observedAvgCycleTime != null && (this.cycleTimeHigher || this.cycleTimelower)) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (scenario.startsWith('Phase') && this.processDataAPW.actualHeadCount != null && this.processDataAPW.actualHeadCount !== this.processDataAPW.plannedHeadCount) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (scenario.startsWith('Phase 0') && this.processDataAPW.noOfCurrentToolsORMachines != null && this.processDataAPW.noOfCurrentToolsORMachines > this.processDataAPW.noToolsMachParallel) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (scenario.startsWith('Phase 0') && this.processDataAPW.noOfIdenticalPartsProduced != null && this.processDataAPW.noOfIdenticalPartsProduced > this.processDataAPW.noIdentityParts) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3') && this.processDataAPW.changeOverTimeActual && this.processDataAPW.planMinChan && ((this.processDataAPW.changeOverTimeActual >= (this.processDataAPW.planMinChan + (this.processDataAPW.planMinChan * 0.1))) || (this.processDataAPW.changeOverTimeActual <= (0.5 * this.processDataAPW.planMinChan)))) {
				this.processDataAPW.resultStatus = 'Warning';
				this.processDataAPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.reqOee <= demonOEE) {
				this.processDataAPW.resultStatus = 'Valid';
				this.processDataAPW.resultStatusMessage = 'OK';
			} else {
				this.processDataAPW.resultStatus = 'N/A';
				this.processDataAPW.resultStatusMessage = 'N/A';
			}
		}
	}

	calculateResultMPW() {
		let demonOEE = this.processDataAPW.averageHisOee;
		let scenario = this.carRequest.submissionScenarioObject.templateName;
		scenario = scenario.startsWith('Phase') ? scenario.startsWith('Phase 0') ? 'Phase 0' : 'Phase 3' : 'capacity';
		if (!this.calculateResultMPWIncomplete(scenario)) {
			if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
				demonOEE = this.processDataAPW.phaseOEE;
			}
			if (this.natAlert) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (this.processDataMPW.daysPerWeek > 7) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (this.HMPLink === 'Not Available') {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (this.sharedLoadingLink === 'N/A' && !this.processDataAPW.dedicated) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (this.carRequest.requestStatus !== 'STADRAFT' && (!this.processDataAPW.dedicated && (this.reqGoodPartsMPW != null) && (this.processDataMPW.reqGoodPartsNext !== this.reqGoodPartsMPW))) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (!this.processDataAPW.dedicated && this.processDataMPW.sharedLoadingTotalAllocPercent === 0) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (this.processDataMPW.downTime / 60 >= this.processDataMPW.hrsPerShift) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (this.processDataAPW.plannedDownTime > this.processDataAPW.totalAvailableTime) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if ((this.processDataAPW.noOfPartsScrapped + this.processDataAPW.noOfPartsReworked) > this.processDataAPW.totalPartsRun) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (scenario.startsWith('capacity') && this.isHmpItemExceeds100) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (this.oneOrMoreOEEPercentageGreaterThan100ErrorShown) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (!this.processDataMPW.isContainAssumptions && !this.processDataAPW.dedicated) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (!this.hideProcessDetailsValidation && (scenario.startsWith('Phase 0') || scenario.startsWith('Phase 3')) && (this.processDataAPW.equipmentAvailability > 100 || ((this.processDataAPW.plannedDownTime + this.processDataAPW.changeOverTimeActual + this.processDataAPW.observedUnplannedDownTime) > this.processDataAPW.totalAvailableTime) || this.processDataAPW.performanceEfficiency > 100 || this.processDataAPW.qualityRate < 0)) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (!this.hideProcessDetailsValidation && scenario.startsWith('Phase 0') && ((this.processDataAPW.noToolsMachParallel < this.processDataAPW.noOfCurrentToolsORMachines) || (this.processDataAPW.noIdentityParts < this.processDataAPW.noOfIdenticalPartsProduced))) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (!this.hideProcessDetailsValidation && scenario.startsWith('Phase 3') && this.processDataAPW.changeOverTimeActual <= 0 && (this.processDataAPW.planMinChan * this.processDataAPW.planChangFreq) > 0) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (this.carRequest.requestStatus !== 'STADRAFT' && (!this.processDataAPW.dedicated &&
				(this.processDataAPW.idealCycleTime !== null && this.processDataAPW.noToolsMachParallel !== null && this.processDataAPW.noIdentityParts !== null) &&
				_.round(this.processDataAPW.netIdleCycleTime, 4) !== this.nictAPW)) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (this.processDataAPW.scrappedPercent > 100) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (this.processDataAPW.partsReworkedPercent > 100) {
				this.processDataMPW.resultStatus = 'Error';
				this.processDataMPW.resultStatusMessage = 'Error';
			} else if (!this.hideProcessDetailsValidation && this.processDataMPW.reqGoodPartsNext > this.roundDownWeeklyPartEstimate(this.processDataMPW.weekPartEstimate)) {
				this.processDataMPW.resultStatus = 'Risk';
				this.processDataMPW.resultStatusMessage = 'Risk';
			} else if ((demonOEE < 100 && this.processDataMPW.reqOee < 100) && demonOEE < this.processDataMPW.reqOee) {
				this.processDataMPW.resultStatus = 'Risk';
				this.processDataMPW.resultStatusMessage = 'Risk';
			} else if (this.processDataMPW.reqOee > 100 || demonOEE > 100) {
				this.processDataMPW.resultStatus = 'Risk';
				this.processDataMPW.resultStatusMessage = 'Risk';
			} else if (!this.processDataAPW.dedicated && this.processDataMPW.sharedLoadingTotalAllocPercent > 100) {
				this.processDataMPW.resultStatus = 'Risk';
				this.processDataMPW.resultStatusMessage = 'Risk';
			} else if (this.processDataAPW.dedicated && this.processDataMPW.dedicatedTotalAllocPercent > 100) {
				this.processDataMPW.resultStatus = 'Risk';
				this.processDataMPW.resultStatusMessage = 'Risk';
			} else if (this.processDataMPW.avaiRemainPercent <= 0) {
				this.processDataMPW.resultStatus = 'Risk';
				this.processDataMPW.resultStatusMessage = 'Risk';
			} else if (!this.processDataMPW.isContainAssumptions && this.processDataMPW.dedicated) {
				this.processDataMPW.resultStatus = 'Risk';
				this.processDataMPW.resultStatusMessage = 'Risk';
			} else if (!scenario.startsWith('capacity') && this.processDataAPW.performanceEfficiency > 100) {
				this.processDataMPW.resultStatus = 'Risk';
				this.processDataMPW.resultStatusMessage = 'Risk';
			} else if (this.processDataMPW.daysPerWeek > 6 && this.processDataMPW.daysPerWeek <= 7) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if ((this.processDataMPW.shiftsPerDay * this.processDataMPW.hrsPerShift) > 24) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.dedicated && this.isPartNameAvailableInSharedLoading) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.dedicated && this.isProcessUsedInAnotherPartNumber && !this.isPartNameAvailableInSharedLoading) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (this.processDataMPW.reqOee === 0 || this.processDataMPW.reqOee > 100) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (!this.processDataAPW.dedicated && this.processDataMPW.sharedLoadingTotalAllocPercent >= 90 && this.processDataMPW.sharedLoadingTotalAllocPercent <= 100) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.dedicated && this.processDataMPW.dedicatedTotalAllocPercent >= 90 && this.processDataMPW.dedicatedTotalAllocPercent <= 100) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if ((this.processDataMPW.avaiRemainPercent < 10) && (this.processDataMPW.avaiRemainPercent > 0)) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (this.hmpOlderThan32WeeksWarningShown) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (this.hmpRecordsCountLessThan25WarningShown) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (scenario.startsWith('Phase 0') && this.processDataAPW.noOfCurrentToolsORMachines != null && this.processDataAPW.noOfCurrentToolsORMachines > this.processDataAPW.noToolsMachParallel) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (scenario.startsWith('Phase 0') && this.processDataAPW.noOfIdenticalPartsProduced != null && this.processDataAPW.noOfIdenticalPartsProduced > this.processDataAPW.noIdentityParts) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.noOfPartsScrappedPercent > this.processDataAPW.scrappedPercent) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (this.processDataAPW.noOfPartsReworkedPercent > this.processDataAPW.partsReworkedPercent) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (!scenario.startsWith('capacity') && this.processDataAPW.observedAvgCycleTime != null &&
				(this.cycleTimeHigher || this.cycleTimelower)) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (scenario.startsWith('Phase') && this.processDataAPW.actualHeadCount != null &&
				this.processDataAPW.actualHeadCount !== this.processDataAPW.plannedHeadCount) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3') &&
				this.processDataAPW.changeOverTimeActual && this.processDataAPW.planMinChan &&
				((this.processDataAPW.changeOverTimeActual >= (this.processDataAPW.planMinChan + (this.processDataAPW.planMinChan * 0.1))) ||
					(this.processDataAPW.changeOverTimeActual <= (0.5 * this.processDataAPW.planMinChan)))) {
				this.processDataMPW.resultStatus = 'Warning';
				this.processDataMPW.resultStatusMessage = 'Warning';
			} else if (this.processDataMPW.reqOee <= demonOEE) {
				this.processDataMPW.resultStatus = 'Valid';
				this.processDataMPW.resultStatusMessage = 'OK';
			} else {
				this.processDataMPW.resultStatus = 'N/A';
				this.processDataMPW.resultStatusMessage = 'N/A';
			}
		}
	}

	enableErrorStatus() {
		this.showNAStatus = false;
		this.calculateNetAvailableTime();
	}

	refreshSharedLoadingAndHisManPerf() {
		console.log('calling refresh of slp and hmf');
		this.loadHmpData();
		this.retrieveSharedLoadingIdBySiteCodeAndProcessNameId();
		this.isProcessUsedInAnotherParNumber();
	}

	calculatePhase() {
		if (!this.carRequest.submissionScenarioObject.templateName.toLowerCase().startsWith('capacity')) {
			this.processDataAPW.netAvailableTimePhase = this.processDataAPW.totalAvailableTime - this.processDataAPW.plannedDownTime;
			if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 0')) {
				this.processDataAPW.changeOverTimeWeekly = (this.processDataAPW.planMinChan * this.processDataAPW.planChangFreq * this.processDataAPW.netAvailableTimePhase) / (60 * this.processDataAPW.netAvailableTime - (this.processDataAPW.planMinChan * this.processDataAPW.planChangFreq));
				this.processDataAPW.operatingTime = this.processDataAPW.netAvailableTimePhase - this.processDataAPW.observedUnplannedDownTime;
				this.processDataAPW.equipmentAvailability = ((this.processDataAPW.netAvailableTimePhase - this.processDataAPW.observedUnplannedDownTime) / (this.processDataAPW.netAvailableTimePhase + this.processDataAPW.changeOverTimeWeekly) * 100);
				this.processDataAPW.plannedNetIdealCycleTime = (this.processDataAPW.idealCycleTime / (this.processDataAPW.noOfCurrentToolsORMachines * this.processDataAPW.noOfIdenticalPartsProduced));
				this.processDataAPW.plannedNetIdealCycleTime = !this.processDataAPW.plannedNetIdealCycleTime ? 0 : this.processDataAPW.plannedNetIdealCycleTime;
			} else {
				this.processDataAPW.changeOverTimeWeekly = (this.processDataAPW.changeOverTimeActual * (this.processDataAPW.netAvailableTimePhase / 60) / (this.processDataAPW.netAvailableTime / this.processDataAPW.planChangFreq));
				this.processDataAPW.operatingTime = this.processDataAPW.netAvailableTimePhase - this.processDataAPW.changeOverTimeActual - this.processDataAPW.observedUnplannedDownTime;
				this.processDataAPW.equipmentAvailability = ((this.processDataAPW.netAvailableTimePhase - this.processDataAPW.changeOverTimeWeekly - this.processDataAPW.observedUnplannedDownTime) / this.processDataAPW.netAvailableTimePhase * 100);
				this.processDataAPW.plannedNetIdealCycleTime = this.processDataAPW.netIdleCycleTime;
			}

			this.processDataAPW.performanceEfficiency = (((this.processDataAPW.totalPartsRun * this.processDataAPW.plannedNetIdealCycleTime / 60) / this.processDataAPW.operatingTime) * 100);
			this.processDataAPW.performanceEfficiency = !this.processDataAPW.performanceEfficiency ? 0 : this.processDataAPW.performanceEfficiency;
			this.processDataAPW.availablePerformanceEfficiency = (this.processDataAPW.operatingTime - (this.processDataAPW.totalPartsRun * this.processDataAPW.plannedNetIdealCycleTime / 60));
			this.processDataAPW.availablePerformanceEfficiency = !this.processDataAPW.availablePerformanceEfficiency ? 0 : this.processDataAPW.availablePerformanceEfficiency;
			this.processDataAPW.noOfPartsScrappedPercent = (this.processDataAPW.noOfPartsScrapped / this.processDataAPW.totalPartsRun) * 100;
			this.processDataAPW.noOfPartsReworkedPercent = (this.processDataAPW.noOfPartsReworked / this.processDataAPW.totalPartsRun) * 100;
			this.processDataAPW.qualityRate = ((this.processDataAPW.totalPartsRun - this.processDataAPW.noOfPartsScrapped - this.processDataAPW.noOfPartsReworked) / this.processDataAPW.totalPartsRun) * 100;
			this.processDataAPW.qualityRate = !this.processDataAPW.qualityRate ? 0 : this.processDataAPW.qualityRate;
			this.processDataAPW.phaseOEE = _.round((this.processDataAPW.equipmentAvailability * this.processDataAPW.performanceEfficiency * this.processDataAPW.qualityRate) / 10000, 1);
			this.processDataAPW.phaseOEE = !this.processDataAPW.phaseOEE ? 0 : this.processDataAPW.phaseOEE;
			this.processDataMPW.phaseOEE = this.processDataAPW.phaseOEE;
			this.populateDedicatedTotalAllocPercent();
			this.calculateProcessSpecificWeeklyPartEstimate();
			this.processDataAPW.phaseJPH = _.round(((3600 / this.processDataAPW.plannedNetIdealCycleTime) * this.processDataAPW.phaseOEE) / 100, 1);
			this.processDataAPW.phaseJPH = !this.processDataAPW.phaseJPH ? 0 : this.processDataAPW.phaseJPH;
		}
	}

	private populateDemonOEE(demonOee) {
		if (!this.isSTAEdit) {
			this.processDataAPW.averageHisOee = demonOee;
			this.processDataMPW.averageHisOee = this.processDataAPW.averageHisOee;
		}
	}

	private populateReqPartsNextFromPreviousData() {
		const previousProcessOrder = this.psPnMapTransObjectParent.processOrderId + 1;
		const psPnMapTranListLength = (this.supportingStreamTransMapObject && this.supportingStreamTransMapObject.psPnMapTranList) ? this.supportingStreamTransMapObject.psPnMapTranList.length : null;
		if (psPnMapTranListLength > 0) {
			this.supportingStreamTransMapObject.psPnMapTranList.forEach((psPnMapTran) => {
				if (psPnMapTran.processOrderId === previousProcessOrder) {
					this.processDataAPW.reqGoodPartsNext = _.ceil(psPnMapTran.processDataList[0].reqGoodPartsNext / (1 - (psPnMapTran.processDataList[0].scrappedPercent / 100)));
					this.processDataMPW.reqGoodPartsNext = _.ceil(psPnMapTran.processDataList[1].reqGoodPartsNext / (1 - (psPnMapTran.processDataList[0].scrappedPercent / 100)));
				}
			});
		}
	}

	private populateReqPartsIncomeFromPreviousData() {
		const previousProcessOrder = this.psPnMapTransObjectParent.processOrderId + 1;
		const psPnMapTranListLength = (this.supportingStreamTransMapObject && this.supportingStreamTransMapObject.psPnMapTranList) ? this.supportingStreamTransMapObject.psPnMapTranList.length : null;
		if (psPnMapTranListLength > 0) {
			this.supportingStreamTransMapObject.psPnMapTranList.forEach((psPnMapTran) => {
				if (psPnMapTran.processOrderId === previousProcessOrder) {
					this.processDataAPW.reqGoodPartsIncome = _.ceil(this.processDataAPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
					this.processDataMPW.reqGoodPartsIncome = _.ceil(this.processDataMPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
				}
			});
		}
	}

	private applyReqPartsToSupportNextProcessPrimaryStream(psPnMapTran: PsPnMapTran, psPnMapTrans: PsPnMapTran[]) {
		if (psPnMapTran.processNameId === this.selectedProcessNameId) {
			const processOrderId = psPnMapTran.processOrderId;
			if (psPnMapTrans.length === processOrderId) {
				if (this.carRequest.requirementType === 1) {
					this.processDataAPW.reqGoodPartsNext = this.carRequest.paRequirementsApw;
					this.processDataMPW.reqGoodPartsNext = this.carRequest.paRequirementsMpw;
				} else if (this.carRequest.requirementType === 2) {
					this.processDataAPW.reqGoodPartsNext = this.carRequest.revisedRequirementsApw;
					this.processDataMPW.reqGoodPartsNext = this.carRequest.revisedRequirementsMpw;
				} else {
					this.processDataAPW.reqGoodPartsNext = this.carRequest.gcpMcpvApw;
					this.processDataMPW.reqGoodPartsNext = this.carRequest.gcpMcpvMpw;
				}
			} else {
				psPnMapTrans.forEach((psPnMapTran1) => {
					if (psPnMapTran1.processOrderId === 1) {
						this.processDataAPW.reqGoodPartsIncome = _.ceil(this.processDataAPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
						this.processDataMPW.reqGoodPartsIncome = _.ceil(this.processDataMPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
					} else {
						if (psPnMapTran1.processOrderId === (processOrderId + 1)) {
							this.processDataAPW.reqGoodPartsNext = _.ceil(psPnMapTran1.processDataList[0].reqGoodPartsNext / (1 - (psPnMapTran1.processDataList[0].scrappedPercent / 100)));
							this.processDataMPW.reqGoodPartsNext = _.ceil(psPnMapTran1.processDataList[1].reqGoodPartsNext / (1 - (psPnMapTran1.processDataList[0].scrappedPercent / 100)));
						}
					}
				});
			}
			if (psPnMapTrans.length === processOrderId) {
				this.processDataAPW.reqGoodPartsIncome = _.ceil(this.processDataAPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
				this.processDataMPW.reqGoodPartsIncome = _.ceil(this.processDataMPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
			} else {
				psPnMapTrans.forEach((psPnMapTran1) => {
					if (psPnMapTran1.processOrderId === (processOrderId + 1)) {
						this.processDataAPW.reqGoodPartsIncome = _.ceil(this.processDataAPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
						this.processDataMPW.reqGoodPartsIncome = _.ceil(this.processDataMPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
					}
				});
			}
			if (this.psPnMapTransObjectParent.processOrderId === 1) {
				this.processDataAPW.reqGoodPartsIncome = _.ceil(this.processDataAPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
				this.processDataMPW.reqGoodPartsIncome = _.ceil(this.processDataMPW.reqGoodPartsNext / (1 - (this.processDataAPW.scrappedPercent / 100)));
			}
		}
	}

	private populateSharedLoadingTotalAllocPercent(sharedLoadingRoot: SharedLoadingRoot) {
		if (this.isCapacityStudyScenario) {
			this.processDataAPW.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageCapacityAPW;
			this.processDataMPW.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageCapacityMPW;
		} else {
			this.processDataAPW.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageAPW;
			this.processDataMPW.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageMPW;
		}
		this.processDataAPWUField.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageAPW;
		this.processDataMPWUField.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageMPW;
		if (sharedLoadingRoot.totalAllocationPercentageAPW < sharedLoadingRoot.totalAllocationPercentageCapacityAPW && sharedLoadingRoot.totalAllocationPercentageMPW < sharedLoadingRoot.totalAllocationPercentageCapacityMPW) {
			this.processDataCapacityAPW.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageCapacityAPW;
			this.processDataCapacityMPW.sharedLoadingTotalAllocPercent = sharedLoadingRoot.totalAllocationPercentageCapacityMPW;
		} else {
			this.processDataCapacityAPW.sharedLoadingTotalAllocPercent = null;
			this.processDataCapacityMPW.sharedLoadingTotalAllocPercent = null;
		}


		this.calculateSLAllocPercentInSTAEdit();
	}

	private populateDedicatedTotalAllocPercent() {
		if (this.processDataAPW.dedicated) {
			if (!this.carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
				this.processDataAPW.dedicatedTotalAllocPercent = ((((this.processDataAPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / this.demonOEE) / this.processDataAPW.netAvailableTime) * 10000;
				this.processDataMPW.dedicatedTotalAllocPercent = ((((this.processDataMPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / this.demonOEE) / this.processDataMPW.netAvailableTime) * 10000;

				this.processDataAPWUField.dedicatedTotalAllocPercent = ((((this.processDataAPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / this.demonOEE) / this.processDataAPW.netAvailableTime) * 10000;
				this.processDataMPWUField.dedicatedTotalAllocPercent = ((((this.processDataMPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / this.demonOEE) / this.processDataMPW.netAvailableTime) * 10000;

				this.processDataCapacityAPW.dedicatedTotalAllocPercent = ((((this.processDataAPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / this.demonOEE) / this.processDataAPW.netAvailableTime) * 10000;
				this.processDataCapacityMPW.dedicatedTotalAllocPercent = ((((this.processDataMPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / this.demonOEE) / this.processDataMPW.netAvailableTime) * 10000;
			} else {
				let oeeForPhaseAPW, oeeForPhaseMPW;
				oeeForPhaseAPW = this.processDataAPW.phaseOEE < this.demonOEE ? this.processDataAPW.phaseOEE : this.demonOEE;
				oeeForPhaseMPW = this.processDataMPW.phaseOEE < this.demonOEE ? this.processDataMPW.phaseOEE : this.demonOEE;
				this.processDataAPW.dedicatedTotalAllocPercent = ((((this.processDataAPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / oeeForPhaseAPW) / this.processDataAPW.netAvailableTime) * 10000;
				this.processDataMPW.dedicatedTotalAllocPercent = ((((this.processDataMPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / oeeForPhaseMPW) / this.processDataMPW.netAvailableTime) * 10000;

				this.processDataAPWUField.dedicatedTotalAllocPercent = ((((this.processDataAPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / oeeForPhaseAPW) / this.processDataAPW.netAvailableTime) * 10000;
				this.processDataMPWUField.dedicatedTotalAllocPercent = ((((this.processDataMPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / oeeForPhaseMPW) / this.processDataMPW.netAvailableTime) * 10000;

				this.processDataCapacityAPW.dedicatedTotalAllocPercent = ((((this.processDataAPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / oeeForPhaseAPW) / this.processDataAPW.netAvailableTime) * 10000;
				this.processDataCapacityMPW.dedicatedTotalAllocPercent = ((((this.processDataMPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / oeeForPhaseMPW) / this.processDataMPW.netAvailableTime) * 10000;
			}
			if (isNumber(this.processDataAPW.dedicatedTotalAllocPercent)) {
				this.showTotalAllocationValidation = true;
			}
		}
	}

	isNaN(value: any) {
		return isNaN(value);
	}

	private calculateSLAllocPercentInSTAEdit() {
		if (this.isSTAEdit && !this.processDataAPW.dedicated) {
			const natSumAPW = this.processDataAPW.daysPerWeek * this.processDataAPW.shiftsPerDay * this.processDataAPW.hrsPerShift;
			const natAPW = natSumAPW - (this.processDataAPW.daysPerWeek * this.processDataAPW.shiftsPerDay * this.processDataAPW.downTime / 60);
			const natSumMPW = this.processDataMPW.daysPerWeek * this.processDataMPW.shiftsPerDay * this.processDataMPW.hrsPerShift;
			const natMPW = natSumMPW - (this.processDataMPW.daysPerWeek * this.processDataMPW.shiftsPerDay * this.processDataMPW.downTime / 60);
			this.processDataAPW.allocPercent = (((((this.processDataAPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / this.demonOEE) * 100) / natAPW) * 100;
			this.processDataMPW.allocPercent = (((((this.processDataMPW.reqGoodPartsNext * this.processDataAPW.netIdleCycleTime) / 3600) / this.demonOEE) * 100) / natMPW) * 100;
		}
	}

	private isContainAnyDataBeyondLastThirtyTwoWeeks(thirtyTwoWeeksAgo: number) {
		let isValid = false;
		this.historicalmfgperfs.forEach((historicalmfgperf) => {
			const historicalMfgPerfInMilliseconds = this.parseDate(historicalmfgperf.hmfDate);
			console.log('historicalMfgPerfInMilliseconds ' + new Date(historicalMfgPerfInMilliseconds).toLocaleString() + ' thirtyTwoWeeksAgo ' + new Date(thirtyTwoWeeksAgo).toLocaleString());
			if (historicalMfgPerfInMilliseconds < thirtyTwoWeeksAgo) {
				return isValid = true;
			}
		});
		return isValid;
	}

	private isHMPRecordsCountLessThan25(): boolean {
		let isValid = false;
		let recordsCount = 0;
		this.historicalmfgperfs.forEach(hmp => {
			if (hmp.hmfDateDisplay !== null) {
				recordsCount++;
			}
		});
		if (recordsCount < 25) {
			isValid = true;
		}
		return isValid;
	}

	private isOneOrMoreOEEPercentageGreaterThan100(): boolean {
		let isValid = false;
		this.historicalmfgperfs.forEach(hmp => {
			if (hmp.oee !== null && hmp.oee > 100) {
				isValid = true;
				return isValid;
			}
		});
		return isValid;
	}

	private calculateNetAvailableTimeAPWAndMPW() {
		this.calculateNetAvailableTimeMPW();
		this.processDataAPW.netAvailableTime = ((this.processDataAPW.daysPerWeek * this.processDataAPW.shiftsPerDay * (this.processDataAPW.hrsPerShift - (this.processDataAPW.downTime / 60))) * this.processDataAPW.allocPercent) / 100;
		console.log('this.processDataAPW.netAvailableTime:- ' + this.processDataAPW.netAvailableTime);
		console.log('this.processDataMPW.netAvailableTime:- ' + this.processDataMPW.netAvailableTime);
		if (this.processDataAPW.netAvailableTime > 0 && this.processDataMPW.netAvailableTime > 0 && this.processDataAPW.netAvailableTime > this.processDataMPW.netAvailableTime) {
			this.natAlert = true;
		} else {
			this.natAlert = false;
		}
		this.calculateNetIdleCycleTime();
	}

	private calculateNetAvailableTimeMPW() {
		const g17 = this.processDataMPW.hrsPerShift - (this.processDataMPW.downTime / 60);
		this.processDataMPW.netAvailableTime = (this.processDataMPW.daysPerWeek * this.processDataMPW.shiftsPerDay * g17 * this.processDataMPW.allocPercent) / 100;
	}

	private checkFlagForScrapPercent() {
		if (this.previousScrapPercent !== this.processDataAPW.scrappedPercent) {
			if (this.psPnMapTransObjectParent.isPrimaryStream) {
				this.carRequest.psPnMapTransList.forEach(primaryTransList => {
					if (primaryTransList.processOrderId < this.psPnMapTransObjectParent.processOrderId) {
						if (primaryTransList.processDataList.length > 0) {
							primaryTransList.processDataList[0].scrapChange = true;
						}
					}
				});
			}
			if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
				this.carRequest.primarySupportingMapTranList.forEach(primarySupportTranList => {
					if (this.psPnMapTransObjectParent.isPrimaryStream) {
						primarySupportTranList.psPnMapTranList.forEach(supportTranList => {
							supportTranList.processDataList[0].scrapChange = true;
						});
					} else {
						primarySupportTranList.psPnMapTranList.forEach(supportTranList => {
							if (supportTranList.processOrderId < this.psPnMapTransObjectParent.processOrderId) {
								supportTranList.processDataList[0].scrapChange = true;
							}
						});
					}
				});
			}
		}
	}

	inputNumberValidationWithSingleDigit(event: any) {
		return this.utilService.inputNumberValidationWithSingleDecimal(event);
	}

	inputNumberValidationWithDigits($event: KeyboardEvent, scale: number) {
		return this.utilService.inputNumberValidationWithDecimal(event, scale);
	}

	inputNumberValidationWithoutDecimal(event: any) {
		if (event.key === '.' || event.key === '-') {
			return false;
		}
	}

	nonZeroInputValidation(value: any): any {
		this.isFocusOut = true;
		if (value === '0' || value === 0) {
			return null;
		} else if (value === null) {
			return '';
		} else {
			return value;
		}
	}

	private loadSupplierInfo() {
		if (this.psPnMapTransObjectParent.processDataList.length > 0) {
			if ((!this.processDataAPW.capitalEquipment || this.processDataAPW.capitalEquipment.trim() === '') && this.psPnMapTransObjectParent.processDesc && this.psPnMapTransObjectParent.processDesc.trim() !== '') {
				this.processDataAPW.capitalEquipment = this.psPnMapTransObjectParent.processDesc;
			}
			if (!this.carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
				if (!this.processDataAPW.siteCodeSurProcess || this.processDataAPW.siteCodeSurProcess.trim() === '') {
					this.processDataAPW.siteCodeSurProcess = this.selectedSiteCode;
				}
				if (!this.processDataAPW.supplierName || this.processDataAPW.supplierName.trim() === '') {
					this.processDataAPW.supplierName = this.carRequest.supplierName;
				}
				if (!this.processDataAPW.supplierLocation || this.processDataAPW.supplierLocation.trim() === '') {
					this.supplierSiteService.findBySiteCode(this.selectedSiteCode).subscribe(supplierSiteDetails => {
						this.processDataAPW.supplierLocation = '';
						if (supplierSiteDetails.streetName && supplierSiteDetails.streetName.trim() !== '') {
							this.processDataAPW.supplierLocation = supplierSiteDetails.streetName;
						}
						if (supplierSiteDetails.cityName && supplierSiteDetails.cityName.trim() !== '') {
							this.processDataAPW.supplierLocation = this.processDataAPW.supplierLocation === '' ? supplierSiteDetails.cityName : this.processDataAPW.supplierLocation + ', ' + supplierSiteDetails.cityName;
						}
						if (supplierSiteDetails.provinceCode && supplierSiteDetails.provinceCode.trim() !== '') {
							this.processDataAPW.supplierLocation = this.processDataAPW.supplierLocation === '' ? supplierSiteDetails.provinceCode : this.processDataAPW.supplierLocation + ', ' + supplierSiteDetails.provinceCode;
						}
						if (supplierSiteDetails.countryCode && supplierSiteDetails.countryCode.trim() !== '') {
							this.processDataAPW.supplierLocation = this.processDataAPW.supplierLocation === '' ? supplierSiteDetails.countryCode : this.processDataAPW.supplierLocation + ', ' + supplierSiteDetails.countryCode;
						}
						if (supplierSiteDetails.postalCode && supplierSiteDetails.postalCode.trim() !== '') {
							this.processDataAPW.supplierLocation = this.processDataAPW.supplierLocation === '' ? supplierSiteDetails.postalCode : this.processDataAPW.supplierLocation + ', ' + supplierSiteDetails.postalCode;
						}
					});
				}
			}
		}
	}

	updateSharedLoadingRequiredGoodParts() {
		const reqGoodPartsUpdateReq: ReqGoodPartsUpdateReq = {
			nict: this.processDataAPW.netIdleCycleTime.toString(),
			partNumber: this.carRequest.partNumDisplay,
			processNameId: this.selectedProcessNameId.toString(),
			requiredGoodPartsToBeUpdatedAPW: this.processDataAPW.reqGoodPartsNext.toString(),
			requiredGoodPartsToBeUpdatedMPW: this.processDataMPW.reqGoodPartsNext.toString(),
			siteCode: this.carRequest.siteCode,
			capacityStudyRecord: this.isCapacityStudyScenario.toString()
		};
		this.manageSharedLoadingService.updateRequiredGoodParts(reqGoodPartsUpdateReq).subscribe(value => {
			console.log('Response received - updateRequiredGoodParts is done');
			console.log(value);
			this.refreshSharedLoadingAndHisManPerf();
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, please contact eCAR Support team.');
		});
	}

	/**
	 * Round down the weekly part estimate value only for Display Purpose
	 * In future, this will be replaced with custom Decimal Pipe with RoundDown option
	 * */
	roundDownWeeklyPartEstimate(weeklyPartEst: number) {
		if (weeklyPartEst !== undefined && weeklyPartEst !== null) {
			return Math.floor(weeklyPartEst);
		} else {
			return 0;
		}
	}

	calculateIncompleteResultAPW(): boolean {
		let isIncomplete = false;
		console.log('Enters into IncompleteResultAPW');
		let scenario = this.carRequest.submissionScenarioObject.templateName;
		scenario = scenario.startsWith('Phase') ? scenario.startsWith('Phase 0') ? 'Phase 0' : 'Phase 3' : 'capacity';

		if ((!this.processDataAPW.daysPerWeek && this.processDataAPW.daysPerWeek !== 0) ||
			(!this.processDataAPW.shiftsPerDay && this.processDataAPW.shiftsPerDay !== 0) ||
			(!this.processDataAPW.hrsPerShift && this.processDataAPW.hrsPerShift !== 0) ||
			this.isIncompleteInCommonFields(scenario)
		) {
			console.log('Incomplete set in result status APW');
			isIncomplete = true;
			this.processDataAPW.resultStatus = 'Incomplete';
			this.processDataAPW.resultStatusMessage = 'Incomplete';
		}
		return isIncomplete;
	}

	calculateResultMPWIncomplete(scenario) {
		let isIncomplete = false;
		console.log('Enters into IncompleteResultMPW');
		console.log('this.processDataAPW.daysPerWeek');
		console.log(this.processDataMPW.daysPerWeek);
		if ((!this.processDataMPW.daysPerWeek && this.processDataMPW.daysPerWeek !== 0) ||
			(!this.processDataMPW.shiftsPerDay && this.processDataMPW.shiftsPerDay !== 0) ||
			(!this.processDataMPW.hrsPerShift && this.processDataMPW.hrsPerShift !== 0) ||
			this.isIncompleteInCommonFields(scenario)
		) {
			console.log('Incomplete set in result status MPW');
			isIncomplete = true;
			this.processDataMPW.resultStatus = 'Incomplete';
			this.processDataMPW.resultStatusMessage = 'Incomplete';
		}
		return isIncomplete;
	}

	isIncompleteInCommonFields(scenario: string): boolean {
		return (!this.processDataAPW.planMinChan && this.processDataAPW.planMinChan !== 0) ||
			(!this.processDataAPW.planChangFreq && this.processDataAPW.planChangFreq !== 0) ||
			(!this.processDataAPW.scrappedPercent && this.processDataAPW.scrappedPercent !== 0) ||
			(!this.processDataAPW.idealCycleTime && this.processDataAPW.idealCycleTime !== 0) ||
			(!this.processDataAPW.noToolsMachParallel && this.processDataAPW.noToolsMachParallel !== 0) ||
			(!this.processDataAPW.noIdentityParts && this.processDataAPW.noIdentityParts !== 0) ||
			(!this.processDataAPW.partsReworkedPercent && this.processDataAPW.partsReworkedPercent !== 0) ||
			(!this.processDataAPW.plannedHeadCount && this.processDataAPW.plannedHeadCount !== 0) ||
			(!this.processDataAPW.capitalEquipment) ||
			((scenario.startsWith('capacity')) && (!this.processDataAPW.supplierName)) ||
			((scenario.startsWith('capacity')) && (!this.processDataAPW.supplierLocation)) ||
			((scenario.startsWith('capacity')) && (!this.processDataAPW.siteCodeSurProcess)) ||
			((scenario.startsWith('capacity')) && (!this.processDataAPW.surCustomerProcess)) ||
			((scenario.startsWith('Phase')) && (!this.processDataAPW.totalAvailableTime && this.processDataAPW.totalAvailableTime !== 0)) ||
			((scenario.startsWith('Phase')) && (!this.processDataAPW.plannedDownTime && this.processDataAPW.plannedDownTime !== 0)) ||
			((scenario.startsWith('Phase')) && (!this.processDataAPW.observedUnplannedDownTime && this.processDataAPW.observedUnplannedDownTime !== 0)) ||
			((scenario.startsWith('Phase')) && (!this.processDataAPW.totalPartsRun && this.processDataAPW.totalPartsRun !== 0)) ||
			((scenario.startsWith('Phase')) && (!this.processDataAPW.noOfPartsScrapped && this.processDataAPW.noOfPartsScrapped !== 0)) ||
			((scenario.startsWith('Phase')) && (!this.processDataAPW.noOfPartsReworked && this.processDataAPW.noOfPartsReworked !== 0)) ||
			((scenario.startsWith('Phase')) && (!this.processDataAPW.observedAvgCycleTime && this.processDataAPW.observedAvgCycleTime !== 0)) ||
			((scenario.startsWith('Phase')) && (!this.processDataAPW.actualHeadCount && this.processDataAPW.actualHeadCount !== 0)) ||
			((scenario.startsWith('Phase 0')) && (!this.processDataAPW.noOfCurrentToolsORMachines && this.processDataAPW.noOfCurrentToolsORMachines !== 0)) ||
			((scenario.startsWith('Phase 0')) && (!this.processDataAPW.noOfIdenticalPartsProduced && this.processDataAPW.noOfIdenticalPartsProduced !== 0)) ||
			((scenario.startsWith('Phase 3')) && (!this.processDataAPW.changeOverTimeActual && this.processDataAPW.changeOverTimeActual !== 0));
	}

	private isProcessUsedInAnotherParNumber() {
		console.log('this.displayProcessEditPopup && this.processDataAPW.dedicated');
		console.log(this.displayProcessEditPopup + ' - ' + this.processDataAPW.dedicated);
		this.partNumDisplay = this.carRequest.prefix + '-' + this.carRequest.base + '-' + this.carRequest.suffix;
		if (this.displayProcessEditPopup && this.processDataAPW.dedicated) {
			const chars = this.carRequest.suffix.split('');
			let currentPartNumber: string = this.carRequest.prefix + '-' + this.carRequest.base + '-' + chars[0];
			if (currentPartNumber.includes('/')) {
				let count = currentPartNumber.split('/').length - 1;
				while (count > 0) {
					currentPartNumber = currentPartNumber.replace('/', '$');
					count--;
				}
			}
			const convertedDate = Date.parse(String(this.carRequest.updatedDate));
			this.carRequestService.findAlleCARByProcessDataId(this.selectedProcessNameId, currentPartNumber, convertedDate, this.mode).subscribe((response) => {
				console.log('eCAR Record Ids');
				console.log(response);
				this.recordsUsedInAnotherPartNumbers = response;
				if (this.recordsUsedInAnotherPartNumbers.length > 0) {
					this.isProcessUsedInAnotherPartNumber = true;
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	private clearToast() {
		this.messageService.clear('processDataToast');
	}

	showDefinition() {
		this.showDefinitionCon = !this.showDefinitionCon;
	}

	cycleTimeValidation() {
		if (this.processDataAPW.idealCycleTime > 0 && this.processDataAPW.idealCycleTime < 5) {
			this.validateRange(0.5);
		} else if (this.processDataAPW.idealCycleTime >= 5 && this.processDataAPW.idealCycleTime < 20) {
			this.validateRange(1);
		} else if (this.processDataAPW.idealCycleTime >= 20 && this.processDataAPW.idealCycleTime < 100) {
			this.validateRange(1.5);
		} else if (this.processDataAPW.idealCycleTime >= 100) {
			this.validateRange(2);
		}
	}

	private validateRange(range: number) {
		if (this.processDataAPW.observedAvgCycleTime > this.processDataAPW.idealCycleTime + range) {
			this.cycleTimeHigher = true;
			this.cycleTimelower = false;
		} else if (this.processDataAPW.observedAvgCycleTime < this.processDataAPW.idealCycleTime - range) {
			this.cycleTimeHigher = false;
			this.cycleTimelower = true;
		} else {
			this.cycleTimeHigher = false;
			this.cycleTimelower = false;
		}
	}

	openSLP() {
		window.open(this.sharedLoadingLink);
	}

	private validateAvgOEE() {
		if ((this.processDataAPW.plannedDownTime + this.processDataAPW.changeOverTimeActual + this.processDataAPW.observedUnplannedDownTime) > this.processDataAPW.totalAvailableTime) {
			this.averageOEEToolTip = 'Downtime cannot be higher than Total Available Time [(W+Y+AB) cannot be higher than V], please review and correct';
		}
		if (this.processDataAPW.equipmentAvailability > 100) {
			this.averageOEEToolTip = 'Equipment Availability cannot be > 100%, please review and correct';
		}
		if (this.processDataAPW.performanceEfficiency > 100) {
			this.averageOEEToolTip = 'Efficiency cannot be higher than 100%, please review and correct';
		}
		if (this.processDataAPW.noOfCurrentToolsORMachines > this.processDataAPW.noToolsMachParallel) {
			this.averageOEEToolTip = 'AF1 Cannot be higher than planned in L (above); either update plan or correct this data';
		}
		if (this.processDataAPW.noOfIdenticalPartsProduced > this.processDataAPW.noIdentityParts) {
			this.averageOEEToolTip = 'AF2 Cannot be higher than planned in M (above); either update plan or correct this data';
		}
		if (this.processDataAPW.qualityRate < 0) {
			this.averageOEEToolTip = 'Sum of scrap and rework cannot be greater than parts produced (AE), please review and correct';
		}
	}

	isFinite(value: number): boolean {
		return isFinite(value);
	}
}
