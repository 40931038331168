import {Component, OnInit} from '@angular/core';
import {CarRequest} from '../models/car-request';
import {CarRequestService} from '../services/request/car-request.service';
import {ConfirmationService, DialogService, LazyLoadEvent, Message, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {Policies} from '../models/aps-policies';
import {UtilService} from '../services/util.service';
import {UrlConsumerService} from '../oauth/url-consumer.service';
import {ManageSubmissionScenario} from '../models/manage-submission-scenario';
import {ManageSubmissionScenarioService} from '../services/master/manage-submission-scenario/manage-submission-scenario.service';
import {OndeleteDialogueboxComponent} from '../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../models/on-delete-display-dialogue-model';
import {ApsAttributes} from '../models/aps-attributes';
import {UserIdService} from '../services/user-id.service.service';
import {PagingHelper} from '../models/paging/paging-helper';
import {Option} from '../models/option';
import {LdapResponse} from '../models/ldap-response';

@Component({
	selector: 'home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
	carRequests: CarRequest[] = [];
	displayMessage: Message[] = [];
	policies: Policies;
	displayCreateButton: boolean;
	disableEditButton: boolean;
	disableDeleteButton: boolean;
	submissionScenarioOptions: ManageSubmissionScenario[];
	parentEcarink = window.location.origin + '/#/create-car-request/';
	disableCopyButtonForSTA = true;
	loggedInUser: string;
	attributes: ApsAttributes;
	private userId: string;
	first = 0;
	totalCarRecordPages = 0;
	currentPage = 0;
	currentPageSize = 10;
	sortParam: any[];
	filterParam: any[];
	emptyParam: any[];
	pageDetailsFrom = 0;
	pageDetailsTo = 0;
	scenarioNameOptions: Option[];
	statusOptions: Option[];
	partPPAPOptions: Option[];
	Q1StatusOptions: Option[];
	isViewAlleCARs = false;
	loading: boolean;

	private pageNo = 0;
	private pageSize = 10;
	private sortingParameters: any[];
	private filterParammeters: any[];
	filterStr = [];
	selectedReqId: any;
	selectedSiteCode: any;
	selectedPartNumber: any;
	selectedProgramCode: any;
	selectedModelYear: any;
	selectedCapacityStudyId: any;
	selectedPartPpapLevel = 'Select';
	selectedSubmissionScenario = 'Select';
	selectedQ1StatusCode = 'Select';
	selectedRequestStatus = 'Select';
	selectedUpdatedBy: any;
	private clearFilter: boolean;
	private clearSubmissionScenario = false;
	private clearPartPpapLevel = false;
	private clearQ1StatusCode = false;
	private clearRequestStatus = false;
	siteCodes: string;
	private retainFilter = false;
	private sortingOrder = false;
	private sortingField: string;
	private noDataToDisplay = false;
	private enableViewAllTab = false;
	isTokenOnlyUser: boolean;
	private viewAlleCARsFlag: string;
	private scenarioName: string;
	private status: string;
	private ppapLevel: string;
	private q1Status: string;
	private errorFlag = false;
	private initialLoad: boolean;
	selectedPage = 0;
	private loggedInUserId: string;
	private loggedInUserMail: string;
	private loggedInUserOrganization: string;
	private ldapResponse: LdapResponse[];
	isViewAllBuyerCARs = false;
	PURCHASING_MANAGEMENT_ROLE = 'Purchasing Management';
	JV_PURCHASING_MANAGEMENT_ROLE = 'JV Purchasing Management';
	private purchasingManagementRoles: string[] = [this.PURCHASING_MANAGEMENT_ROLE, this.JV_PURCHASING_MANAGEMENT_ROLE];
	private isJTypeUser = false;

	constructor(private activatedRoute: ActivatedRoute, private utilService: UtilService, private urlConsumerService: UrlConsumerService, private router: Router, private service: CarRequestService, private confirmationService: ConfirmationService, private messageService: MessageService, private manageSubmissionScenarioService: ManageSubmissionScenarioService, public dialogService: DialogService, private userIdService: UserIdService, private carRequestService: CarRequestService) {
	}

	ngOnInit() {
		this.loggedInUserId = sessionStorage.getItem('userId');
		this.loggedInUserMail = sessionStorage.getItem('displayLeadMail');
		this.healthCheck();
		this.findAllPolicies();
		this.findAttributes();
		this.retrieveRouteParameter();
		this.getUserId();
		this.checkFordEmployee();
		//this.loadAwaitingApprovalReq(this.isViewAlleCARs);
		this.checkAndShowSuccessMsg();
		// this.findAllScenarioNames();
		this.getStatusDropdown();
		this.loading = true;
		this.scenarioNameOptions = [
			{name: 'RFQ Response - Planning', code: 'RFQ Response - Planning'},
			{name: 'RFQ Response - Phase 0', code: 'RFQ Response - Phase 0'},
			{name: 'RFQ Response - Phase 3', code: 'RFQ Response - Phase 3'},
			{name: 'APQP Capacity Planning', code: 'APQP Capacity Planning'},
			{name: 'APQP Phase 0 PPAP', code: 'APQP Phase 0 PPAP'},
			{name: 'APQP Phase 3 PPAP', code: 'APQP Phase 3 PPAP'},
			{name: 'Capacity Study - Planning', code: 'Capacity Study - Planning'},
			{name: 'Capacity Study - Phase 0', code: 'Capacity Study - Phase 0'},
			{name: 'Capacity Study - Phase 3', code: 'Capacity Study - Phase 3'},
			{name: 'Capacity Maintenance', code: 'Capacity Maintenance'},
			{name: 'Running Change - Planning', code: 'Running Change - Planning'},
			{name: 'Running Change - Phase 0', code: 'Running Change - Phase 0'},
			{name: 'Running Change - Phase 3', code: 'Running Change - Phase 3'}
		];
		this.partPPAPOptions = [
			{name: '1', code: '1'},
			{name: '3', code: '3'},
			{name: '5', code: '5'}
		];
		this.Q1StatusOptions = [
			{name: '2', code: '2'},
			{name: 'A', code: 'A'},
			{name: 'D', code: 'D'},
			{name: 'N', code: 'N'},
			{name: 'R', code: 'R'},
			{name: 'U', code: 'U'},
			{name: 'V', code: 'V'},
			{name: 'W', code: 'W'},
			{name: 'X', code: 'X'},
			{name: 'Y', code: 'Y'},
			{name: 'Z', code: 'Z'}
		];
		if (!['ecar_read', 'ecar_update'].includes(this.loggedInUser.toLowerCase())) {
			this.findLoggedInUserDetails();
		}
	}

	checkAndShowSuccessMsg() {
		let success = sessionStorage.getItem('CarRequestSubmitSuccess');
		if (success) {
			success = JSON.parse(success);
			this.displayMessage.push({severity: 'info', summary: '', detail: success['message']});
			sessionStorage.removeItem('CarRequestSubmitSuccess');
			setTimeout(() => {
				console.log('cleared');
				this.displayMessage = [];
			}, 2000);
		}
	}

	onView(id) {
		console.log('OnView :- id' + id);
		if (this.loggedInUser.includes('Buyer')) {
			if (this.isViewAllBuyerCARs) {
				if (this.filterParam.find(element => element.toString().includes('buyer'))) {
					const matches = this.filterParam.find(element => element.toString().includes('buyer'));
					this.filterParam.splice(this.filterParam.indexOf(matches), 1);
				}
			}
		} else {
			if (this.isViewAlleCARs) {
				if (this.loggedInUser.includes('Purchasing Management')) {
					if (this.filterParam.find(element => element.toString().includes('purchaseManager'))) {
						const matches = this.filterParam.find(element => element.toString().includes('purchaseManager'));
						this.filterParam.splice(this.filterParam.indexOf(matches), 1);
					}
				} else {
					if (this.filterParam.find(element => element.toString().includes('nextApproverUserId'))) {
						const matches = this.filterParam.find(element => element.toString().includes('nextApproverUserId'));
						this.filterParam.splice(this.filterParam.indexOf(matches), 1);
					}
				}
			}
		}
		this.sortParam = this.retainFilter === false ? this.sortParam : this.sortingParameters;
		this.filterParam = this.retainFilter === false ? this.filterParam : this.filterParammeters;
		const currentPage = this.currentPage;
		const currentPageSize = this.currentPageSize;
		const sortParam = this.sortParam;
		const filterParam = this.filterParam;
		const emptyParam = this.emptyParam;
		const isViewAlleCARs = this.loggedInUser.includes('Buyer') ? this.isViewAllBuyerCARs : this.isViewAlleCARs;

		//this.router.navigate(['create-car-request/view/' + id]);
		let param;
		if (sortParam.length > 0 && filterParam.length > 0) {
			param = sortParam + '/' + filterParam;
		} else if (sortParam.length > 0 && filterParam.length === 0) {
			param = sortParam + '/' + emptyParam;
		} else if (sortParam.length === 0 && filterParam.length > 0) {
			param = emptyParam + '/' + filterParam;
		} else {
			param = emptyParam + '/' + emptyParam;
		}
		const viewECARLink = window.location.origin + '/#/create-car-request/view/' + id + '/' + currentPage + '/' + currentPageSize + '/' + param + '/' + isViewAlleCARs;

		this.router.navigate([]).then(result => {
			window.open(viewECARLink, '_blank');
		});
	}

	onEdit(id) {
		console.log('OnEdit:- id' + id);
		if (this.loggedInUser.includes('Buyer')) {
			if (this.isViewAllBuyerCARs) {
				if (this.filterParam.find(element => element.toString().includes('buyer'))) {
					const matches = this.filterParam.find(element => element.toString().includes('buyer'));
					this.filterParam.splice(this.filterParam.indexOf(matches), 1);
				}
			}
		} else {
			if (this.isViewAlleCARs) {
				if (this.loggedInUser.includes('Purchasing Management')) {
					if (this.filterParam.find(element => element.toString().includes('purchaseManager'))) {
						const matches = this.filterParam.find(element => element.toString().includes('purchaseManager'));
						this.filterParam.splice(this.filterParam.indexOf(matches), 1);
					}
				} else {
					if (this.filterParam.find(element => element.toString().includes('nextApproverUserId'))) {
						const matches = this.filterParam.find(element => element.toString().includes('nextApproverUserId'));
						this.filterParam.splice(this.filterParam.indexOf(matches), 1);
					}
				}
			}
		}
		this.sortParam = this.retainFilter === false ? this.sortParam : this.sortingParameters;
		this.filterParam = this.retainFilter === false ? this.filterParam : this.filterParammeters;
		const currentPage = this.currentPage;
		const currentPageSize = this.currentPageSize;
		const sortParam = this.sortParam;
		const filterParam = this.filterParam;
		const emptyParam = this.emptyParam;
		const isViewAlleCARs = this.loggedInUser.includes('Buyer') ? this.isViewAllBuyerCARs : this.isViewAlleCARs;

		//this.router.navigate(['create-car-request/edit/' + id]);
		if (sortParam.length > 0 && filterParam.length > 0) {
			this.router.navigate(['create-car-request/edit/' + id + '/' + currentPage + '/' + currentPageSize + '/' + sortParam + '/' + filterParam + '/' + isViewAlleCARs]);
		} else if (sortParam.length > 0 && filterParam.length === 0) {
			this.router.navigate(['create-car-request/edit/' + id + '/' + currentPage + '/' + currentPageSize + '/' + sortParam + '/' + emptyParam + '/' + isViewAlleCARs]);
		} else if (sortParam.length === 0 && filterParam.length > 0) {
			this.router.navigate(['create-car-request/edit/' + id + '/' + currentPage + '/' + currentPageSize + '/' + emptyParam + '/' + filterParam + '/' + isViewAlleCARs]);
		} else {
			this.router.navigate(['create-car-request/edit/' + id + '/' + currentPage + '/' + currentPageSize + '/' + emptyParam + '/' + emptyParam + '/' + isViewAlleCARs]);
		}
	}

	onSTAEdit(id) {
		console.log('OnSTAEdit:- id' + id);
		if (this.loggedInUser.includes('Buyer')) {
			if (this.isViewAllBuyerCARs) {
				if (this.filterParam.find(element => element.toString().includes('buyer'))) {
					const matches = this.filterParam.find(element => element.toString().includes('buyer'));
					this.filterParam.splice(this.filterParam.indexOf(matches), 1);
				}
			}
		} else {
			if (this.isViewAlleCARs) {
				if (this.loggedInUser.includes('Purchasing Management')) {
					if (this.filterParam.find(element => element.toString().includes('purchaseManager'))) {
						const matches = this.filterParam.find(element => element.toString().includes('purchaseManager'));
						this.filterParam.splice(this.filterParam.indexOf(matches), 1);
					}
				} else {
					if (this.filterParam.find(element => element.toString().includes('nextApproverUserId'))) {
						const matches = this.filterParam.find(element => element.toString().includes('nextApproverUserId'));
						this.filterParam.splice(this.filterParam.indexOf(matches), 1);
					}
				}
			}
		}
		this.sortParam = this.retainFilter === false ? this.sortParam : this.sortingParameters;
		this.filterParam = this.retainFilter === false ? this.filterParam : this.filterParammeters;
		const currentPage = this.currentPage;
		const currentPageSize = this.currentPageSize;
		const sortParam = this.sortParam;
		const filterParam = this.filterParam;
		const emptyParam = this.emptyParam;
		const isViewAlleCARs = this.isViewAlleCARs;

		//this.router.navigate(['create-car-request/edit/' + id+'/true']);
		if (sortParam.length > 0 && filterParam.length > 0) {
			this.router.navigate(['create-car-request/edit/' + id + '/true/' + currentPage + '/' + currentPageSize + '/' + sortParam + '/' + filterParam + '/' + isViewAlleCARs]);
		} else if (sortParam.length > 0 && filterParam.length === 0) {
			this.router.navigate(['create-car-request/edit/' + id + '/true/' + currentPage + '/' + currentPageSize + '/' + sortParam + '/' + emptyParam + '/' + isViewAlleCARs]);
		} else if (sortParam.length === 0 && filterParam.length > 0) {
			this.router.navigate(['create-car-request/edit/' + id + '/true/' + currentPage + '/' + currentPageSize + '/' + emptyParam + '/' + filterParam + '/' + isViewAlleCARs]);
		} else {
			this.router.navigate(['create-car-request/edit/' + id + '/true/' + currentPage + '/' + currentPageSize + '/' + emptyParam + '/' + emptyParam + '/' + isViewAlleCARs]);
		}
	}

	onDelete(id) {
		console.log('id is ' + id);
		const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
			header: 'Confirmation',
			width: '30%',
			contentStyle: {'max-height': '500px', 'overflow': 'auto'}
		});
		deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
			console.log('Received after close');
			if (deleteModel != null) {
				if (deleteModel.isDeleted) {
					this.service.delete(id).subscribe(value => {
						sessionStorage.setItem('CarRequestSubmitSuccess', JSON.stringify({
							'status': 'success',
							'message': 'CAR Request# ' + id + ' is deleted successfully!'
						}));
						setTimeout(() => {
							this.displayMessage = [];
						}, 2000);
						this.checkAndShowSuccessMsg();
						if (this.loggedInUser.includes('Engineer') || this.loggedInUser.includes('Supervisor')) {
							this.loadAwaitingApprovalReq(false);
						} else {
							this.findAllByPaging(this.currentPage, this.currentPageSize);
						}
					}, error => {
						sessionStorage.setItem('CarRequestSubmitSuccess', JSON.stringify({
							'status': 'success',
							'message': 'Unable to delete CAR Request# ' + id + ', Please make sure it does not have any eCar associated or try again latter'
						}));
						this.checkAndShowSuccessMsg();
					});
				} else {
					console.log('dont Delete response from dialogue box');
				}
			}
		});
	}

	private findAll() {
		this.enableCopyButtonForSTAUser();
		this.service.findAll().subscribe(value => {
			this.carRequests = value;
			this.enableCopyButtonForSTAUser();
		});
	}

	private findAllByPaging(first, size, sort?: String[], filters?: String[]) {
		const uRole = this.isViewAlleCARs === null && this.isJTypeUser === false ? 'PrimeUser' : this.attributes.userRole;
		if (uRole.toLowerCase() === 'ecar_read' || uRole.toLowerCase() === 'ecar_update' || uRole.includes('Developer') || uRole.includes('SiteEngineer') || uRole.includes('Supervisor') || uRole.includes('Manager')) {
			if (uRole.toLowerCase() === 'ecar_update' || uRole.toLowerCase() === 'ecar_read') {
				this.siteCodes = this.attributes.siteCodes.map(site => site).join('-');
			} else {
				this.siteCodes = 'hi'//this.attributes.siteCodes.map(site => site).join('-');
			}
		} else {
			this.siteCodes = 'hi';
		}
		if (!this.isTokenOnlyUser) {
			this.service.findingAll({
				page: first,
				size: size,
				sort: sort,
				filters: filters,
				userRole: uRole,
				siteCodes: this.siteCodes
			}).subscribe((pageableCarRequests: PagingHelper) => {
				if (pageableCarRequests.content) {
					this.errorFlag = false;
					if (pageableCarRequests.content.length < 1) {
						this.noDataToDisplay = true;
					} else {
						this.noDataToDisplay = false;
					}
					this.carRequests = pageableCarRequests.content;
					this.totalCarRecordPages = pageableCarRequests.totalElements;
					this.pageDetailsFrom = ((pageableCarRequests.pageable.pageNumber + 1) - 1) * pageableCarRequests.pageable.pageSize + 1;
					this.pageDetailsTo = (pageableCarRequests.pageable.pageNumber + 1) * pageableCarRequests.pageable.pageSize;
					if (this.pageDetailsTo > this.totalCarRecordPages) {
						this.pageDetailsTo = this.totalCarRecordPages;
					}
					this.loading = false;
				}
			}, error => {
				this.errorFlag = true;
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	onCreate() {
		this.router.navigate(['create-car-request/create/0']);
	}

	onCopy(reqId: any, isSTACopy: boolean) {
		console.log('Req to Copy:' + reqId);
		this.carRequestService.processStreamAvailabilityCheck(reqId).subscribe(isPSPresent => {
			if (isPSPresent) {
				this.confirmationService.confirm({
					message: 'Are you sure you want to copy this CAR Data? CAR ID: ' + reqId,
					header: 'Confirmation',
					icon: 'pi pi-info-circle',
					accept: () => {
						this.service.copy(reqId, false).subscribe(value => {
							this.errorFlag = false;
							const filters = [];
							if (!this.isViewAlleCARs && this.isViewAlleCARs === false) {
								if (this.loggedInUser.includes('Engineer') || this.loggedInUser.includes('Supervisor')) {
									filters.push('nextApproverUserId:' + this.loggedInUserId);
								}
							}
							this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
							console.log('copied successfully');
							this.displayMessage.push({
								severity: 'info',
								summary: '',
								detail: 'Copied successfully, New eCAR ID: ' + value.reqId
							});
							this.first = 0;
						}, error => {
							this.errorFlag = true;
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					},
					reject: () => {
						console.log('copy cancelled successfully.');
						this.utilService.pushMsg('info', 'Information', 'Copy operation cancelled successfully.');
					}
				});
			} else {
				this.confirmationService.confirm({
					message: 'The process stream used in eCAR# ' + reqId + ' has been modified or deleted after submission. Do you still wish to copy this eCAR record?',
					header: 'Confirmation',
					icon: 'pi pi-info-circle',
					accept: () => {
						this.service.copy(reqId, false).subscribe(value => {
							this.errorFlag = false;
							const filters = [];
							if (!this.isViewAlleCARs && this.isViewAlleCARs === false) {
								if (this.loggedInUser.includes('Engineer') || this.loggedInUser.includes('Supervisor')) {
									filters.push('nextApproverUserId:' + this.loggedInUserId);
								}
							}
							this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
							console.log('copied successfully');
							this.displayMessage.push({
								severity: 'info',
								summary: '',
								detail: 'Copied successfully, New eCAR ID: ' + value.reqId
							});
							this.first = 0;
						}, error => {
							this.errorFlag = true;
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					},
					reject: () => {
						console.log('copy cancelled successfully.');
						this.utilService.pushMsg('info', 'Information', 'Copy operation cancelled successfully.');
					}
				});
			}
		}, error => {
			this.errorFlag = true;
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	onLink(parentCARId: any) {
		console.log('Req to Link:' + parentCARId);
		this.router.navigate(['create-car-request/create/0/true/' + parentCARId]);
	}

	private findAllPolicies() {
		if (!this.policies) {
			this.policies = JSON.parse(sessionStorage.getItem('policies'));
			if (this.policies) {
				this.checkPermittedPolicies();
			}
		}
		this.urlConsumerService.policiesSubject.subscribe(policies => {
			this.errorFlag = false;
			this.policies = policies;
			this.checkPermittedPolicies();
		}, error => {
			this.errorFlag = true;
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
		if (this.policies) {
			this.checkPermittedPolicies();
		}
	}

	private checkPermittedPolicies() {
		const policyName = 'eCAR:CreateCAROption';
		this.displayCreateButton = this.utilService.checkPermittedUpdatePolicy(this.policies, policyName);
		this.disableEditButton = this.utilService.checkPermittedDeletePolicy(this.policies, policyName) ? false : true;
		this.disableDeleteButton = this.utilService.checkPermittedDeletePolicy(this.policies, policyName) ? false : true;
		const noAccess = this.utilService.checkAccessPermittedPolicy(this.policies) ? false : true;
		if (noAccess) {
			this.router.navigate(['/no-access']);
		}
	}

	private findAllScenarioNames() {
		this.manageSubmissionScenarioService.findAll().subscribe((response: ManageSubmissionScenario[]) => {
			this.submissionScenarioOptions = [];
			this.submissionScenarioOptions = response;
			this.submissionScenarioOptions.unshift({
				templateName: '',
				id: 0,
				scenarioName: 'Select Submission Scenario',
				scenarioDesc: '',
				capacityStudy: false,
				rfqScenario: false,
				currentProdRun: null
			});
		});
	}

	private findAttributes() {
		if (this.loggedInUser == null || this.loggedInUser === '' && (!this.attributes)) {
			this.attributes = JSON.parse(sessionStorage.getItem('attributes'));
			if (this.attributes) {
				this.loggedInUser = this.attributes.userRole;
			} else {
				console.log('Unable to set aps attributes');
			}
		}
	}

	private enableCopyButtonForSTAUser() {
		if (this.loggedInUser == null || this.loggedInUser === '' || (!this.loggedInUser)) {
			this.findAttributes();
		} else {
			this.disableCopyButtonForSTA = (this.loggedInUser.toUpperCase().includes('SITE') || this.loggedInUser.toUpperCase().includes('STA')) ? false : true;
		}
	}

	private getUserId() {
		this.userIdService.userID.subscribe((value: string) => {
			this.userId = value;
			console.log('userId:- ' + this.userId);
		});
	}

	loadCarRequestsLazy(event: LazyLoadEvent) {
		this.loading = true;
		if (this.selectedPartPpapLevel !== 'Select') {
			this.clearPartPpapLevel = true;
		}
		if (this.selectedSubmissionScenario !== 'Select') {
			this.clearSubmissionScenario = true;
		}
		if (this.selectedQ1StatusCode !== 'Select') {
			this.clearQ1StatusCode = true;
		}
		if (this.selectedRequestStatus !== 'Select') {
			this.clearRequestStatus = true;
		}

		console.log(event.first);
		console.log(event.rows); //Number of rows per page
		console.log(event.sortField); //Field name to sort with
		console.log(event.sortOrder); //1 for asc and -1 for dec
		this.currentPage = event.first / event.rows;
		this.currentPageSize = event.rows;
		const sortingParam = [];
		if (event.sortField) {
			if (event.sortOrder < 0) {
				this.retainFilter = false;
				this.sortingField = event.sortField;
				sortingParam.push(event.sortField + ',asc');
			} else {
				this.retainFilter = false;
				this.sortingField = event.sortField;
				sortingParam.push(event.sortField + ',desc');
			}
		}
		//filter fields
		const filters = [];
		for (const field of Object.keys(event.filters)) {
			this.retainFilter = false;
			if (field === 'reqId') {
				if (/^[0-9]+$/.test(event.filters[field].value.trim())) {
					filters.push(field + ':' + event.filters[field].value.trim());
				} else {
					this.utilService.pushMsg('error', 'Error', 'Please enter a valid number');
				}
			} else if (field === 'modelYear') {
				if (/^[0-9]+$/.test(event.filters[field].value.trim())) {
					filters.push(field + ':' + event.filters[field].value.trim());
				} else {
					this.utilService.pushMsg('error', 'Error', 'Please enter a valid number');
				}
			} else if (field === 'requestStatus') {
				this.statusOptions.forEach((s) => {
					if (s.name === event.filters[field].value) {
						filters.push(field + ':' + event.filters[field].value.trim());
					}
				});
			} else {
				filters.push(field + ':' + event.filters[field].value.trim());
			}

			if (this.selectedReqId && field !== 'reqId') {
				filters.push('reqId:' + this.selectedReqId);
			}
			if (this.selectedSiteCode && field !== 'siteCode') {
				filters.push('siteCode:' + this.selectedSiteCode);
			}
			if (this.selectedPartNumber && field !== 'partNumber') {
				filters.push('partNumber:' + this.selectedPartNumber);
			}
			if (this.selectedProgramCode && field !== 'programCode') {
				filters.push('programCode:' + this.selectedProgramCode);
			}
			if (this.selectedModelYear && field !== 'modelYear') {
				filters.push('modelYear:' + this.selectedModelYear);
			}
			if (this.selectedCapacityStudyId && field !== 'capacityStudyId') {
				filters.push('capacityStudyId:' + this.selectedCapacityStudyId);
			}
			if (this.selectedRequestStatus && this.selectedRequestStatus !== 'Select' && field !== 'requestStatus') {
				filters.push('requestStatus:' + this.selectedRequestStatus);
			}
			if (this.selectedPartPpapLevel && this.selectedPartPpapLevel !== 'Select' && field !== 'partPpapLevel') {
				filters.push('partPpapLevel:' + this.selectedPartPpapLevel);
			}
			if (this.selectedSubmissionScenario && this.selectedSubmissionScenario !== 'Select' && field !== 'scenarioName') {
				filters.push('scenarioName:' + this.selectedSubmissionScenario);
			}
			if (this.selectedQ1StatusCode && this.selectedQ1StatusCode !== 'Select' && field !== 'q1StatusCode') {
				filters.push('q1StatusCode:' + this.selectedQ1StatusCode);
			}
			if (this.selectedUpdatedBy && field !== 'updatedBy') {
				filters.push('updatedBy:' + this.selectedUpdatedBy);
			}
		}
		if (!this.isViewAlleCARs && this.isViewAlleCARs === false) {
			if (this.loggedInUser.includes('Engineer') || this.loggedInUser.includes('Supervisor')) {
				filters.push('nextApproverUserId:' + this.loggedInUserId);
			} else if (this.purchasingManagementRoles.includes(this.loggedInUser)) {
				filters.push('purchaseManager:' + this.loggedInUserId);
			}
		}
		console.log('filters');
		console.log(filters);
		this.sortParam = sortingParam;
		this.filterParam = filters;
		if (this.pageNo > 0 && this.currentPage === 0 && !this.initialLoad) {
			this.initialLoad = true;
			this.selectedPage = this.pageNo * this.pageSize;
			this.currentPage = this.pageNo;
		}
		if (this.pageSize === 0 || this.pageSize === 10 || event.rows === 10) {
			this.currentPageSize = 10;
		}
		if (this.pageSize === 25 || event.rows === 25) {
			this.currentPageSize = 25;
		}
		if (this.pageSize === 50 || event.rows === 50) {
			this.currentPageSize = 50;
		}
		if (this.pageSize > 50 || event.rows > 50) {
			this.currentPageSize = 100;
		}
		if (!this.selectedRequestStatus) {
			this.selectedRequestStatus = 'Select';
			this.clearRequestStatus = false;
		}
		if (!this.selectedPartPpapLevel) {
			this.selectedPartPpapLevel = 'Select';
			this.clearPartPpapLevel = false;
		}
		if (!this.selectedSubmissionScenario) {
			this.selectedSubmissionScenario = 'Select';
			this.clearSubmissionScenario = false;
		}
		if (!this.selectedQ1StatusCode) {
			this.selectedQ1StatusCode = 'Select';
			this.clearQ1StatusCode = false;
		}
		if (this.clearFilter) {
			this.pageSize = 10;
			this.filterParammeters.forEach(filter => {
				this.filterStr = [];
				if (this.selectedReqId) {
					this.filterStr.push('reqId:' + this.selectedReqId);
				} else {
					const matches = this.filterParammeters.find(element => element.toString().includes('reqId'));
					this.filterParammeters.splice(this.filterParammeters.indexOf(matches), 1);
				}
				if (this.selectedSiteCode) {
					this.filterStr.push('siteCode:' + this.selectedSiteCode);
				} else {
					const matches = this.filterParammeters.find(element => element.toString().includes('siteCode'));
					this.filterParammeters.splice(this.filterParammeters.indexOf(matches), 1);
				}
				if (this.selectedPartNumber) {
					this.filterStr.push('partNumber:' + this.selectedPartNumber);
				} else {
					const matches = this.filterParammeters.find(element => element.toString().includes('partNumber'));
					this.filterParammeters.splice(this.filterParammeters.indexOf(matches), 1);
				}
				if (this.selectedProgramCode) {
					this.filterStr.push('programCode:' + this.selectedProgramCode);
				} else {
					const matches = this.filterParammeters.find(element => element.toString().includes('programCode'));
					this.filterParammeters.splice(this.filterParammeters.indexOf(matches), 1);
				}
				if (this.selectedModelYear) {
					this.filterStr.push('modelYear:' + this.selectedModelYear);
				} else {
					const matches = this.filterParammeters.find(element => element.toString().includes('modelYear'));
					this.filterParammeters.splice(this.filterParammeters.indexOf(matches), 1);
				}
				if (this.selectedCapacityStudyId) {
					this.filterStr.push('capacityStudyId:' + this.selectedCapacityStudyId);
				} else {
					const matches = this.filterParammeters.find(element => element.toString().includes('capacityStudyId'));
					this.filterParammeters.splice(this.filterParammeters.indexOf(matches), 1);
				}
				if (this.selectedRequestStatus && this.selectedRequestStatus !== 'Select') {
					this.filterStr.push('requestStatus:' + this.selectedRequestStatus);
				} else {
					const matches = this.filterParammeters.find(element => element.toString().includes('requestStatus'));
					this.filterParammeters.splice(this.filterParammeters.indexOf(matches), 1);
				}
				if (this.selectedPartPpapLevel && this.selectedPartPpapLevel !== 'Select') {
					this.filterStr.push('partPpapLevel:' + this.selectedPartPpapLevel);
				} else {
					const matches = this.filterParammeters.find(element => element.toString().includes('partPpapLevel'));
					this.filterParammeters.splice(this.filterParammeters.indexOf(matches), 1);
				}
				if (this.selectedSubmissionScenario && this.selectedSubmissionScenario !== 'Select') {
					this.filterStr.push('scenarioName:' + this.selectedSubmissionScenario);
				} else {
					const matches = this.filterParammeters.find(element => element.toString().includes('scenarioName'));
					this.filterParammeters.splice(this.filterParammeters.indexOf(matches), 1);
				}
				if (this.selectedQ1StatusCode && this.selectedQ1StatusCode !== 'Select') {
					this.filterStr.push('q1StatusCode:' + this.selectedQ1StatusCode);
				} else {
					const matches = this.filterParammeters.find(element => element.toString().includes('q1StatusCode'));
					this.filterParammeters.splice(this.filterParammeters.indexOf(matches), 1);
				}
				if (this.selectedUpdatedBy) {
					this.filterStr.push('updatedBy:' + this.selectedUpdatedBy);
				} else {
					const matches = this.filterParammeters.find(element => element.toString().includes('updatedBy'));
					this.filterParammeters.splice(this.filterParammeters.indexOf(matches), 1);
				}
				if (this.filterStr && this.filterStr.length > 0) {
					this.filterParammeters = [];
					this.filterParammeters[0] = this.filterStr.join();
					this.filterParam[0] = this.filterStr.join();
				}
				if (this.sortParam && this.sortParam.length > 0) {
					this.sortingParameters = [];
					this.sortingParameters[0] = this.sortParam;
				}
			});
		}
		if (this.pageSize > 0 && this.sortingParameters.length > 0 && this.filterParammeters.length > 0) {
			this.findAllByPaging(this.currentPage, this.currentPageSize, this.sortingParameters, this.filterParammeters);
			this.clearFilter = true;
		} else if (this.pageSize > 0 && this.sortingParameters.length > 0 && this.filterParammeters.length === 0) {
			this.findAllByPaging(this.currentPage, this.currentPageSize, this.sortingParameters, []);
			this.clearFilter = true;
		} else if (this.pageSize > 0 && this.sortingParameters.length === 0 && this.filterParammeters.length > 0) {
			this.findAllByPaging(this.currentPage, this.currentPageSize, [], this.filterParammeters);
			this.clearFilter = true;
		} else {
			if (this.loggedInUser.includes('Buyer')) {
				if (this.isViewAllBuyerCARs === false && (this.loggedInUser.includes('JV_Buyer') || this.loggedInUser.includes('Buyer'))) {
					filters.push('buyer:' + this.loggedInUserId);
					this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
				} else if (this.isViewAllBuyerCARs && this.loggedInUser.includes('Buyer') && !this.loggedInUser.includes('JV_Buyer')) {
					this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
				} else if (this.isViewAllBuyerCARs && this.loggedInUser.includes('JV_Buyer')) {
					if (null != this.loggedInUserOrganization) {
						filters.push('organization:' + this.loggedInUserOrganization);
					}
					if (null != this.loggedInUserMail) {
						filters.push('mail:' + this.loggedInUserMail);
					}
					this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
				}
			} else {
				this.findAllByPaging(this.currentPage, this.currentPageSize, sortingParam, filters);
			}
		}
	}

	neglectKeyOnLoading(event: KeyboardEvent) {
		/*if (this.loading) {
			console.log('Neglects Key');
			return false;
		}*/
	}

	loadAwaitingApprovalReq(isShowAlleCARs: boolean) {
		this.selectedRequestStatus = 'Select';
		this.clearRequestStatus = false;
		this.selectedQ1StatusCode = 'Select';
		this.clearQ1StatusCode = false;
		this.selectedPartPpapLevel = 'Select';
		this.selectedSubmissionScenario = 'Select';
		this.clearPartPpapLevel = false;
		this.selectedReqId = null;
		this.selectedSiteCode = null;
		this.selectedPartNumber = null;
		this.selectedProgramCode = null;
		this.selectedModelYear = null;
		this.selectedCapacityStudyId = null;
		this.selectedUpdatedBy = null;
		if (this.loggedInUser.includes('Engineer') || this.loggedInUser.includes('Supervisor')) {
			const filters = [];
			this.isViewAlleCARs = isShowAlleCARs;
			if (this.isViewAlleCARs === false) {
				filters.push('nextApproverUserId:' + this.loggedInUserId);
				this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
			} else if (this.isViewAlleCARs === null && this.isJTypeUser) {
				if (null != this.loggedInUserOrganization) {
					filters.push('organization:' + this.loggedInUserOrganization);
				}
				if (null != this.loggedInUserMail) {
					filters.push('mail:' + this.loggedInUserMail);
				}
				this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
			} else {
				this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
			}
			this.getStatusDropdown();
		}
	}

	loadManagerReq(isShowAlleCARs: boolean) {
		this.selectedRequestStatus = 'Select';
		this.clearRequestStatus = false;
		this.selectedQ1StatusCode = 'Select';
		this.clearQ1StatusCode = false;
		this.selectedPartPpapLevel = 'Select';
		this.selectedSubmissionScenario = 'Select';
		this.clearPartPpapLevel = false;
		this.selectedReqId = null;
		this.selectedSiteCode = null;
		this.selectedPartNumber = null;
		this.selectedProgramCode = null;
		this.selectedModelYear = null;
		this.selectedCapacityStudyId = null;
		this.selectedUpdatedBy = null;
		if (this.loggedInUser.includes('Manager') && !this.loggedInUser.includes('Engineer') && !this.loggedInUser.includes('Supervisor')) {
			const filters = [];
			this.isViewAlleCARs = isShowAlleCARs;
			if (!this.isViewAlleCARs && this.isViewAlleCARs === false) {
				filters.push('nextApproverUserId:' + this.loggedInUserId);
			}
			this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
		}
	}

	loadBuyerReq(isShowAlleCARs: boolean) {
		this.selectedRequestStatus = 'Select';
		this.clearRequestStatus = false;
		this.selectedQ1StatusCode = 'Select';
		this.clearQ1StatusCode = false;
		this.selectedPartPpapLevel = 'Select';
		this.selectedSubmissionScenario = 'Select';
		this.clearPartPpapLevel = false;
		this.selectedReqId = null;
		this.selectedSiteCode = null;
		this.selectedPartNumber = null;
		this.selectedProgramCode = null;
		this.selectedModelYear = null;
		this.selectedCapacityStudyId = null;
		this.selectedUpdatedBy = null;
		if (this.loggedInUser.includes('Buyer')) {
			const filters = [];
			this.isViewAllBuyerCARs = isShowAlleCARs;
			if (this.isViewAllBuyerCARs === false && (this.loggedInUser.includes('JV_Buyer') || this.loggedInUser.includes('Buyer'))) {
				filters.push('buyer:' + this.loggedInUserId);
				this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
			} else if (this.isViewAllBuyerCARs && this.loggedInUser.includes('JV_Buyer')) {
				if (null != this.loggedInUserOrganization) {
					filters.push('organization:' + this.loggedInUserOrganization);
				}
				if (null != this.loggedInUserMail) {
					filters.push('mail:' + this.loggedInUserMail);
				}
				this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
			} else {
				this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
			}
		}
	}

	loadPurchaseManagerReq(isShowAlleCARs: boolean): void {
		this.clearSelections();
		const filters = [];
		this.isViewAlleCARs = isShowAlleCARs;
		if (!this.isViewAlleCARs) {
			filters.push('purchaseManager:' + this.loggedInUserId);
		} else if (this.loggedInUser.includes(this.JV_PURCHASING_MANAGEMENT_ROLE)) {
			this.getFiltersForJVBuyer(filters);
		}
		this.findAllByPaging(this.currentPage, this.currentPageSize, [], filters);
	}

	clearSelections(): void {
		this.selectedRequestStatus = 'Select';
		this.clearRequestStatus = false;
		this.selectedQ1StatusCode = 'Select';
		this.clearQ1StatusCode = false;
		this.selectedPartPpapLevel = 'Select';
		this.selectedSubmissionScenario = 'Select';
		this.clearPartPpapLevel = false;
		this.selectedReqId = null;
		this.selectedSiteCode = null;
		this.selectedPartNumber = null;
		this.selectedProgramCode = null;
		this.selectedModelYear = null;
		this.selectedCapacityStudyId = null;
		this.selectedUpdatedBy = null;
	}

	getFiltersForJVBuyer(filters: string[]): void {
		if (null != this.loggedInUserOrganization) {
			filters.push('organization:' + this.loggedInUserOrganization);
		}
		if (null != this.loggedInUserMail) {
			filters.push('mail:' + this.loggedInUserMail);
		}
	}

	private getStatusDropdown() {
		if (this.loggedInUser != null || this.loggedInUser !== '') {
			if (this.loggedInUser.toLowerCase() === 'ecar_read' || this.loggedInUser.toLowerCase() === 'ecar_update') {
				this.statusOptions = [
					{name: 'DRAFT', code: 'DRAFT'},
					{name: 'SUBMITTED', code: 'SUBMITTED'},
					{name: 'COMPLETED', code: 'COMPLETED'},
					{name: 'APPROVED', code: 'APPROVED'},
					{name: 'ACKNOWLEDGED', code: 'ACKNOWLEDGED'},
					{name: 'REJECTED', code: 'REJECTED'}
				];
			} else if (this.loggedInUser === 'STA User') {
				this.statusOptions = [
					{name: 'STADRAFT', code: 'STADRAFT'},
					{name: 'SUBMITTED', code: 'SUBMITTED'},
					{name: 'COMPLETED', code: 'COMPLETED'},
					{name: 'APPROVED', code: 'APPROVED'},
					{name: 'ACKNOWLEDGED', code: 'ACKNOWLEDGED'},
					{name: 'REJECTED', code: 'REJECTED'}
				];
			} else if (((this.isViewAlleCARs || this.isViewAlleCARs === null) && (this.loggedInUser.includes('Engineer') || this.loggedInUser.includes('Supervisor'))) || this.loggedInUser.includes('Manager')) {
				this.statusOptions = [
					{name: 'STADRAFT', code: 'STADRAFT'},
					{name: 'SUBMITTED', code: 'SUBMITTED'},
					{name: 'COMPLETED', code: 'COMPLETED'},
					{name: 'APPROVED', code: 'APPROVED'},
					{name: 'ACKNOWLEDGED', code: 'ACKNOWLEDGED'},
					{name: 'REJECTED', code: 'REJECTED'}
				];
			} else if (this.loggedInUser.toLowerCase() === 'developer') {
				this.statusOptions = [
					{name: 'DRAFT', code: 'DRAFT'},
					{name: 'SUBMITTED', code: 'SUBMITTED'},
					{name: 'COMPLETED', code: 'COMPLETED'},
					{name: 'APPROVED', code: 'APPROVED'},
					{name: 'ACKNOWLEDGED', code: 'ACKNOWLEDGED'},
					{name: 'REJECTED', code: 'REJECTED'}
				];
			} else if (this.loggedInUser === 'Super User') {
				this.statusOptions = [
					{name: 'DRAFT', code: 'DRAFT'},
					{name: 'SUBMITTED', code: 'SUBMITTED'},
					{name: 'COMPLETED', code: 'COMPLETED'},
					{name: 'APPROVED', code: 'APPROVED'},
					{name: 'ACKNOWLEDGED', code: 'ACKNOWLEDGED'},
					{name: 'REJECTED', code: 'REJECTED'},
					{name: 'STADRAFT', code: 'STADRAFT'}
				];
			} else if (this.loggedInUser === 'Buyer' || this.loggedInUser === 'Capacity Planner' || this.loggedInUser === 'JV_Buyer') {
				this.statusOptions = [
					{name: 'SUBMITTED', code: 'SUBMITTED'},
					{name: 'COMPLETED', code: 'COMPLETED'},
					{name: 'APPROVED', code: 'APPROVED'},
					{name: 'ACKNOWLEDGED', code: 'ACKNOWLEDGED'}
				];
			} else if (this.isViewAlleCARs === false && (this.loggedInUser.includes('Engineer') || this.loggedInUser.includes('Supervisor'))) {
				this.statusOptions = [
					{name: 'SUBMITTED', code: 'SUBMITTED'},
					{name: 'STADRAFT', code: 'STADRAFT'}
				];
			} else if (this.purchasingManagementRoles.includes(this.loggedInUser)) {
				this.statusOptions = [
					{name: 'SUBMITTED', code: 'SUBMITTED'},
					{name: 'COMPLETED', code: 'COMPLETED'},
					{name: 'APPROVED', code: 'APPROVED'},
					{name: 'ACKNOWLEDGED', code: 'ACKNOWLEDGED'},
					{name: 'REJECTED', code: 'REJECTED'}
				];
			} else {
				this.statusOptions = [
					{name: 'DRAFT', code: 'DRAFT'},
					{name: 'SUBMITTED', code: 'SUBMITTED'},
					{name: 'COMPLETED', code: 'COMPLETED'},
					{name: 'APPROVED', code: 'APPROVED'},
					{name: 'ACKNOWLEDGED', code: 'ACKNOWLEDGED'},
					{name: 'REJECTED', code: 'REJECTED'},
					{name: 'STADRAFT', code: 'STADRAFT'}
				];
			}
		}
	}

	private healthCheck() {
		this.service.healthCheck().subscribe(value => {
			if (value === 'False') {
				this.router.navigate(['ecar-error-page']);
			}
		}, error => {
			this.router.navigate(['ecar-error-page']);
		});
	}

	private findLoggedInUserDetails() {
		this.carRequestService.searchFordBuyer(this.loggedInUserId).subscribe(ldapResponse => {
			console.log('findLoggedInUserDetails ldapResponse');
			this.ldapResponse = ldapResponse;
			if (this.ldapResponse) {
				this.loggedInUserOrganization = this.ldapResponse[0].company;
			} else {
				this.loggedInUserOrganization = null;
			}
		}, error => {
			this.router.navigate(['ecar-error-page']);
		});
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.clearFilter = false;
			this.pageNo = Number(params.get('pageNo'));
			this.pageSize = Number(params.get('pageSize'));
			this.sortingParameters = Array(params.get('sortParam'));
			this.filterParammeters = Array(params.get('filterParam'));
			this.viewAlleCARsFlag = params.get('isViewAlleCARs');

			if (this.viewAlleCARsFlag === 'false') {
				if (this.loggedInUser.includes('Buyer')) {
					this.isViewAllBuyerCARs = false;
				} else {
					this.isViewAlleCARs = false;
				}
			} else if (this.viewAlleCARsFlag === 'true') {
				if (this.loggedInUser.includes('Buyer')) {
					this.isViewAllBuyerCARs = true;
				} else {
					this.isViewAlleCARs = true;
				}
			} else if (null != this.viewAlleCARsFlag && this.viewAlleCARsFlag === 'null') {
				if (this.loggedInUser.includes('Buyer')) {
					this.isViewAllBuyerCARs = null;
				} else {
					this.isViewAlleCARs = null;
				}
			}
			if (this.pageSize > 0 && this.sortingParameters[0] === 'undefined') {
				this.sortingParameters = [];
			} else {
				if (this.sortingParameters[0] && (this.sortingParameters[0].split(',')[1].trim() === 'asc' || this.sortingParameters[0].split(',')[1].trim() === 'desc')) {
					this.retainFilter = true;
					this.sortingOrder = this.sortingParameters[0].split(',')[1].trim() === 'asc' ? true : false;
					this.sortingField = this.sortingParameters[0].split(',')[0].trim();
				}
			}
			if (this.pageSize > 0 && this.filterParammeters[0] === 'undefined') {
				this.filterParammeters = [];
			} else {
				this.filterParammeters.forEach(filterVal => {
					if (null != filterVal) {
						const filterArray = filterVal.split(',');
						if (filterArray.length > 1) {
							this.retainFilter = true;
							filterArray.forEach(element => {
								if (element.split(':')[0].trim() === 'reqId') {
									this.selectedReqId = element.split(':')[1].trim();
								}
								if (element.split(':')[0].trim() === 'siteCode') {
									this.selectedSiteCode = element.split(':')[1].trim();
								}
								if (element.split(':')[0].trim() === 'partNumber') {
									this.selectedPartNumber = element.split(':')[1].trim();
								}
								if (element.split(':')[0].trim() === 'programCode') {
									this.selectedProgramCode = element.split(':')[1].trim();
								}
								if (element.split(':')[0].trim() === 'modelYear') {
									this.selectedModelYear = element.split(':')[1].trim();
								}
								if (element.split(':')[0].trim() === 'capacityStudyId') {
									this.selectedCapacityStudyId = element.split(':')[1].trim();
								}
								if (element.split(':')[0].trim() === 'updatedBy') {
									this.selectedUpdatedBy = element.split(':')[1].trim();
								}
								if (element.split(':')[0].trim() === 'requestStatus') {
									this.selectedRequestStatus = element.split(':')[1].trim();
								}
								if (element.split(':')[0].trim() === 'partPpapLevel') {
									this.selectedPartPpapLevel = element.split(':')[1].trim();
								}
								if (element.split(':')[0].trim() === 'scenarioName') {
									this.selectedSubmissionScenario = element.split(':')[1].trim();
								}
								if (element.split(':')[0].trim() === 'q1StatusCode') {
									this.selectedQ1StatusCode = element.split(':')[1].trim();
								}
							});
						} else {
							this.retainFilter = true;
							if (filterArray[0].split(':')[0].trim() === 'reqId') {
								this.selectedReqId = filterArray[0].split(':')[1].trim();
							}
							if (filterArray[0].split(':')[0].trim() === 'siteCode') {
								this.selectedSiteCode = filterArray[0].split(':')[1].trim();
							}
							if (filterArray[0].split(':')[0].trim() === 'partNumber') {
								this.selectedPartNumber = filterArray[0].split(':')[1].trim();
							}
							if (filterArray[0].split(':')[0].trim() === 'programCode') {
								this.selectedProgramCode = filterArray[0].split(':')[1].trim();
							}
							if (filterArray[0].split(':')[0].trim() === 'modelYear') {
								this.selectedModelYear = filterArray[0].split(':')[1].trim();
							}
							if (filterArray[0].split(':')[0].trim() === 'capacityStudyId') {
								this.selectedCapacityStudyId = filterArray[0].split(':')[1].trim();
							}
							if (filterArray[0].split(':')[0].trim() === 'updatedBy') {
								this.selectedUpdatedBy = filterArray[0].split(':')[1].trim();
							}
							if (filterArray[0].split(':')[0].trim() === 'requestStatus') {
								this.selectedRequestStatus = filterArray[0].split(':')[1].trim();
							}
							if (filterArray[0].split(':')[0].trim() === 'partPpapLevel') {
								this.selectedPartPpapLevel = filterArray[0].split(':')[1].trim();
							}
							if (filterArray[0].split(':')[0].trim() === 'scenarioName') {
								this.selectedSubmissionScenario = filterArray[0].split(':')[1].trim();
							}
							if (filterArray[0].split(':')[0].trim() === 'q1StatusCode') {
								this.selectedQ1StatusCode = filterArray[0].split(':')[1].trim();
							}
						}
					}
				});
			}
		});
	}

	private checkFordEmployee() {
		this.enableCopyButtonForSTAUser();
		this.isTokenOnlyUser = this.utilService.isUserHasOnlyTokenOnly();
		if (this.isJTypeUser === false && this.enableViewAllTab === false && (this.loggedInUser.includes('Manager') || this.loggedInUser.includes('Engineer') || this.loggedInUser.includes('Supervisor'))) {
			this.service.isPrimeUser(this.userId).subscribe(isTrue => {
				if (isTrue) {
					this.enableViewAllTab = true;
				} else {
					this.enableViewAllTab = false;
					if (this.isJTypeUser === false) {
						this.service.isJTypeUser(this.userId).subscribe(isValid => {
							if (isValid) {
								this.isJTypeUser = true;
							} else {
								this.isJTypeUser = false;

							}
						});
					}
				}
			});
		}
	}

	changeStatusSelection() {
		if (this.selectedRequestStatus && this.selectedRequestStatus !== 'Select') {
			this.status = this.selectedRequestStatus;
			this.selectedRequestStatus = Object.values(this.status)[0];
		}
	}

	changeQ1StatusSelection() {
		if (this.selectedQ1StatusCode && this.selectedQ1StatusCode !== 'Select') {
			this.q1Status = this.selectedQ1StatusCode;
			this.selectedQ1StatusCode = Object.values(this.q1Status)[0];
		}
	}

	changePPAPSelection() {
		if (this.selectedPartPpapLevel && this.selectedPartPpapLevel !== 'Select') {
			this.ppapLevel = this.selectedPartPpapLevel;
			this.selectedPartPpapLevel = Object.values(this.ppapLevel)[0];
		}
	}

	changeScenarioNameSelection() {
		if (this.selectedSubmissionScenario && this.selectedSubmissionScenario !== 'Select') {
			this.scenarioName = this.selectedSubmissionScenario;
			this.selectedSubmissionScenario = Object.values(this.scenarioName)[0];
		}
	}
}
