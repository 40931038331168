import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OAuthCallbackComponent} from './oauth/oauth-callback.component';
import {UrlConsumerService} from './oauth/url-consumer.service';
import {LoginComponent} from './oauth/login.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {AuthGuardService} from './oauth/auth-guard.service';
import {HomeComponent} from './home/home.component';
import {ProcessNameComponent} from './master/process-name/process-name.component';
import {ManageProcessStreamComponent} from './master/process-stream/manage-process-stream/manage-process-stream.component';
import {CreateProcessStreamComponent} from './master/process-stream/create-process-stream/create-process-stream.component';
import {ViewProcessStreamComponent} from './master/process-stream/view-process-stream/view-process-stream.component';
import {HistoricalMfgPerfComponent} from './master/historical-mfgperf/historical-mfgperf.component';
import {ManageSharedLoadingComponent} from './master/shared-loading/manage-shared-loading/manage-shared-loading.component';
import {CreateSharedLoadingComponent} from './master/shared-loading/create-shared-loading/create-shared-loading.component';
import {ManageSubmissionScenarioComponent} from './master/manage-submission-scenario/manage-submission-scenario.component';
import {CreateCarRequestComponent} from './home/create-car-request/create-car-request.component';
import {ViewCarRequestComponent} from './home/view-car-request/view-car-request.component';
import {ManageDeclarationQuestionsComponent} from './master/declaration-questions/manage-declaration-questions/manage-declaration-questions.component';
import {CreateDeclarationQuestionsComponent} from './master/declaration-questions/create-declaration-questions/create-declaration-questions.component';
import {ProcessTypeComponent} from './master/process-type/process-type.component';
import {HelpComponent} from './help/help.component';
import {NoAccessComponent} from './no-access/no-access.component';
import {BrowserIncompatableComponent} from './browser-incompatable/browser-incompatable.component';
import {EcarPrintComponent} from './ecar-print/ecar-print.component';
import {ManageBulletinsComponent} from './master/bulletins/manage-bulletins/manage-bulletins.component';
import {CreateBulletinsComponent} from './master/bulletins/create-bulletins/create-bulletins.component';
import {EngineerApprovalFlowConditionsComponent} from './master/approval-flow-conditions/engineer-approval-flow-conditions/engineer-approval-flow-conditions.component';
import {SupervisorApprovalFlowConditionsComponent} from './master/approval-flow-conditions/supervisor-approval-flow-conditions/supervisor-approval-flow-conditions.component';
import {ApsErrorPageComponent} from './oauth/aps-error-page.component';
import {EcarErrorPageComponent} from './oauth/ecar-error-page.component';
import {
	ManageHistoricalMfgperfComponent
} from './master/historical-mfgperf/manage-historical-mfgperf/manage-historical-mfgperf.component';

const routes: Routes = [
	{
		path: 'browser-incompatibility',
		component: BrowserIncompatableComponent
	},
	{
		path: 'access_token',
		component: OAuthCallbackComponent,
		canActivate: [UrlConsumerService]
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: '',
		redirectTo: '/home-page',
		pathMatch: 'full',
		canActivate: [AuthGuardService]
	},
	{
		path: 'home-page',
		component: HomeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'home-page/:pageNo/:pageSize/:sortParam/:filterParam/:isViewAlleCARs',
		component: HomeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-car-request/:mode/:id',
		component: CreateCarRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-car-request/:mode/:id/:pageNo/:pageSize/:sortParam/:filterParam/:isViewAlleCARs',
		component: CreateCarRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'view-car-request/:id',
		component: ViewCarRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'process-type-master-page',
		component: ProcessTypeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'process-name-master-page',
		component: ProcessNameComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manage-process-stream',
		component: ManageProcessStreamComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manage-process-stream/:pageNo/:pageSize',
		component: ManageProcessStreamComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-process-stream/:mode/:id/:pageNo/:pageSize',
		component: CreateProcessStreamComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'view-process-stream/:id',
		component: ViewProcessStreamComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'view-process-stream/:id/:reqId/:isHistorical',
		component: ViewProcessStreamComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manage-historical-mfgperf',
		component: ManageHistoricalMfgperfComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manage-historical-mfgperf/:pageNo/:pageSize',
		component: ManageHistoricalMfgperfComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'historical-mfgperf-master-page',
		component: HistoricalMfgPerfComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manage-shared-loading',
		component: ManageSharedLoadingComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manage-shared-loading/:pageNo/:pageSize',
		component: ManageSharedLoadingComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-shared-loading/:mode/:id/:carStatus/:carReqId/:processNameId/:viewMode/:pageNo/:pageSize',
		component: CreateSharedLoadingComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manage-submission-scenario',
		component: ManageSubmissionScenarioComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manage-declaration-questions',
		component: ManageDeclarationQuestionsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-declaration-questions/:mode/:id',
		component: CreateDeclarationQuestionsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'help-page',
		component: HelpComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'eprint/:id',
		component: EcarPrintComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-car-request/:mode/:id/:isSTAEdit/:pageNo/:pageSize/:sortParam/:filterParam/:isViewAlleCARs',
		component: CreateCarRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-car-request/:mode/:id/:isParentCAR/:parentCARId',
		component: CreateCarRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manage-bulletins',
		component: ManageBulletinsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-bulletins/:mode/:id',
		component: CreateBulletinsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'engineer-approval-flow-conditions',
		component: EngineerApprovalFlowConditionsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'supervisor-approval-flow-conditions',
		component: SupervisorApprovalFlowConditionsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'aps-error-page',
		component: ApsErrorPageComponent
	},
	{
		path: 'ecar-error-page',
		component: EcarErrorPageComponent
	},
	{
		path: '404', component: NotFoundComponent
	},
	{
		path: 'no-access', component: NoAccessComponent
	},
	{
		path: '**', redirectTo: '/404'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'})],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {
}
