import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
	private valueStreamMapping = 'https://web.qpr.ford.com/sta/Value_Stream_Mapping_for_Capacity.xlsx';
	private capacityRequirements = 'https://web.qpr.ford.com/sta/Capacity_Requirements_SPL.pdf';
	private capacityPlanningWebGuide = 'https://web.fsp.ford.com/gtc/docs/capacityplan.pdf';
	private validationOfSurrogateandHistoricalData = 'https://www.lean.ford.com/cqdc/supplier_training';
	private productionSupportPlanChart = 'https://web.qpr.ford.com/sta/Capacity_Production_Support_Plan_and_Rate_of_Climb.xlsx';
	private sharedLoadingTool = 'https://web.qpr.ford.com/sta/Detailed_Shared_Equipment_Loading_Analysis_SPL.xlsx';
	private subtierAPQPPPAPMatrix = 'https://web.qpr.ford.com/sta/Sub_Tier_APQP.html';
	private weightedCycleTime = 'https://web.qpr.ford.com/sta/Weighted cycle time v2.xlsx';
	private accessAndTrainingQALink = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20Access%20and%20Training%20Questions%20and%20Answers.pdf';
	private qrg0Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc/eCAR%20QRG%200%20-%20Supplier%20Request%20for%20eCAR%20Access.pdf';
	private qrgA1Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%20A1%20-%20eCAR%20Home%20Page%20Overview.pdf';
	private qrg1Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc/eCAR%20QRG%201%20-%20Define%20Process%20Types.pdf';
	private qrg2Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%202%20-%20Manage%20Supplier%20Processes.pdf';
	private qrg3Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%203%20-%20Manage%20Process%20Streams.pdf';
	private qrg4Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%204%20-%20Historical%20Manufacturing%20Performance.pdf';
	private qrg5Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%205%20-%20Manage%20Shared%20Loading.pdf';
	private qrg8Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%208%20-%20Create%20eCAR%20-%20Header.pdf';
	private qrg9aLink = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%209a%20-%20Create%20eCAR%20-%20Primary%20Stream_Capacity%20Planning.pdf';
	private qrg9bLink = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%209b%20-%20Create%20eCAR%20-%20Primary%20Stream_Phase%200-3.pdf';
	private qrg10aLink = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%2010a%20-%20Create%20eCAR%20-%20Supporting%20Stream_Capacity%20Planning.pdf';
	private qrg10bLink = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%2010b%20-%20Create%20eCAR%20-%20Supporting%20Stream_Phase%200-3.pdf';
	private qrg10cLink = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc/eCAR%20QRG%2010c%20-%20Creating%20Supporting%20Streams%20via%20Link%20eCAR.pdf';
	private qrg11Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%2011%20-%20Create%20eCAR%20-%20Declaration%20Page.pdf';
	private qrg12Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc/eCAR%20QRG%2012%20-%20Create%20eCAR%20-%20Summary%20Page.pdf';
	private qrg13Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%2013%20-%20Approve%20or%20Reject%20eCAR.pdf';
	private qrg14Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%2014%20-%20Manage%20My%20Approvals.pdf';
	private oeeSafetyFactorLink = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FOEE%20Safety%20factor.pdf';
	private fAQsLink = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20Frequently%20Asked%20Questions.pdf';
	private hcmTrainingLink = 'https://efds.fa.em5.oraclecloud.com/hcmUI/faces/deeplink?objType=WLF_LEARN_LEARNING_ITEM&action=NONE&objKey=learningItemId%3D300000904020800';
	private germanyTrainingLink = 'https://www.ford-aus-und-weiterbildung.com/training-consulting/quality-training/technical-training/?lang=en';
	private spainTrainingLink = 'https://www.fdiformacion.com/formacion-continua/cursos/ficha-curso/?curso=257&nombre=New%20eCAR%20Training';
	private eCARSMEListLink = 'https://www.lom.ford.com/launchomatic/launch/view.jsp?chronicleId=0900cad9868e5721&docbase=edmsna1';
	private eCAR2_5BulletinLink = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20Release%202.5%20Special%20Bulletin%20-%20Impact%20to%20Capacity%20Study%20Records.pdf';
	private qrgA2Link = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20QRG%20A2%20-%20Capacity%20Study%20Response%20Guidelines.pdf';

	constructor() {
	}

	ngOnInit() {
	}

}
