export class ProcessData {
	processDataId: number;
	reqId: number;
	processStreamId: number;
	processNameId: number;
	isApw: boolean;
	daysPerWeek: number;
	shiftsPerDay: number;
	hrsPerShift: number;
	downTime: number;
	dedicated: boolean;
	allocPercent: number;
	netAvailableTime: number;
	planMinChan: number;
	planChangFreq: number;
	scrappedPercent: number;
	reqGoodPartsIncome: number;
	reqGoodPartsNext: number;
	idealCycleTime: number;
	noToolsMachParallel: number;
	noIdentityParts: number;
	netIdleCycleTime: number;
	theoreticalParts: number;
	reqOee: number;
	demonOee: number;
	reqJph: number;
	demonJph: number;
	partsReworkedPercent: number;
	isContainAssumptions: boolean;
	avaiRemainPercent: number;
	otherAssumptionX: string;
	sharedLoadingTotalAllocPercent: number;
	dedicatedTotalAllocPercent: number;
	supplierName: string;
	supplierLocation: string;
	siteCodeSurProcess: string;
	surCustomerProcess: string;
	averageHisOee: number;
	assumptionForClarification: string;
	weekPartEstimate: number;
	totalAvailableTime: number;
	plannedDownTime: number;
	netAvailableTimePhase: number;
	changeOverTimeActual: number;
	changeOverTimeWeekly: number;
	observedUnplannedDownTime: number;
	operatingTime: number;
	equipmentAvailability: number;
	totalPartsRun: number;
	noOfCurrentToolsORMachines: number;
	noOfIdenticalPartsProduced: number;
	plannedNetIdealCycleTime: number;
	performanceEfficiency: number;
	availablePerformanceEfficiency: number;
	noOfPartsScrapped: number;
	noOfPartsReworked: number;
	qualityRate: number;
	phaseOEE: number;
	phaseJPH: number;
	observedAvgCycleTime: number;
	actualHeadCount: number;
	capitalEquipment: string;
	plannedHeadCount: number;
	noOfPartsScrappedPercent: number;
	noOfPartsReworkedPercent: number;
	result: boolean;
	resultStatus: string;
	resultStatusMessage: string;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	apwMpwChange: boolean;
	psChange: boolean;
	sharedLoadChange: boolean;
	hmpChange: boolean;
	scrapChange: boolean;
	scenarioChange: boolean;
	capacityRequirementChange: boolean;
	partPrefixBaseSuffixChange: boolean;
	totalAllocationPercentageAPW: number;
	totalAllocationPercentageMPW: number;
	totalAllocationPercentageCapacityAPW: number;
	totalAllocationPercentageCapacityMPW: number;
	isApprovalRobustness: boolean;
	isSLPUpdated: boolean;
	isHMPUpdated: boolean;
	isCopiedCar: boolean;
	scenarioTemplateChange: string;

	constructor(processData: ProcessData) {
		Object.assign(this, processData);
	}
}

export const emptyProcessData = {
	'processDataId': null,
	'reqId': null,
	'processStreamId': null,
	'processNameId': null,
	'isApw': false,
	'daysPerWeek': null,
	'shiftsPerDay': null,
	'hrsPerShift': null,
	'downTime': null,
	'dedicated': true,
	'allocPercent': 100,
	'netAvailableTime': null,
	'planMinChan': null,
	'planChangFreq': null,
	'scrappedPercent': null,
	'reqGoodPartsIncome': null,
	'reqGoodPartsNext': null,
	'idealCycleTime': null,
	'noToolsMachParallel': null,
	'noIdentityParts': null,
	'netIdleCycleTime': null,
	'theoreticalParts': null,
	'reqOee': null,
	'demonOee': null,
	'reqJph': null,
	'demonJph': null,
	'partsReworkedPercent': null,
	'isContainAssumptions': false,
	'avaiRemainPercent': null,
	'otherAssumptionX': '',
	'sharedLoadingTotalAllocPercent': null,
	'dedicatedTotalAllocPercent': null,
	'supplierName': '',
	'supplierLocation': '',
	'siteCodeSurProcess': '',
	'surCustomerProcess': '',
	'averageHisOee': null,
	'assumptionForClarification': '',
	'weekPartEstimate': null,
	'totalAvailableTime': null,
	'plannedDownTime': null,
	'netAvailableTimePhase': null,
	'changeOverTimeWeekly': null,
	'changeOverTimeActual': null,
	'operatingTime': null,
	'observedUnplannedDownTime': null,
	'totalPartsRun': null,
	'equipmentAvailability': null,
	'noOfIdenticalPartsProduced': null,
	'noOfCurrentToolsORMachines': null,
	'performanceEfficiency': null,
	'plannedNetIdealCycleTime': null,
	'noOfPartsScrapped': null,
	'availablePerformanceEfficiency': null,
	'qualityRate': null,
	'noOfPartsReworked': null,
	'observedAvgCycleTime': null,
	'phaseOEE': null,
	'phaseJPH': null,
	'actualHeadCount': null,
	'capitalEquipment': '',
	'plannedHeadCount': null,
	'noOfPartsScrappedPercent': null,
	'noOfPartsReworkedPercent': null,
	'result': false,
	'resultStatus': '',
	'resultStatusMessage': '',
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'apwMpwChange': false,
	'psChange': false,
	'sharedLoadChange': false,
	'hmpChange': false,
	'scrapChange': false,
	'scenarioChange': false,
	'capacityRequirementChange': false,
	'partPrefixBaseSuffixChange': false,
	'totalAllocationPercentageAPW': null,
	'totalAllocationPercentageMPW': null,
	'totalAllocationPercentageCapacityAPW': null,
	'totalAllocationPercentageCapacityMPW': null,
	'isApprovalRobustness': null,
	'isCopiedCar': null,
	'isSLPUpdated': null,
	'isHMPUpdated': null,
	'scenarioTemplateChange': null
};

export const testProcessDataAPW = {
	'processDataId': null,
	'reqId': null,
	'processStreamId': null,
	'processNameId': null,
	'isApw': true,
	'daysPerWeek': 5,
	'shiftsPerDay': 2,
	'hrsPerShift': 12,
	'downTime': 60,
	'dedicated': true,
	'allocPercent': 100,
	'netAvailableTime': 0,
	'planMinChan': 15,
	'planChangFreq': 2,
	'scrappedPercent': 1,
	'reqGoodPartsIncome': 0,
	'reqGoodPartsNext': 0,
	'idealCycleTime': 8.2,
	'noToolsMachParallel': 1,
	'noIdentityParts': 1,
	'netIdleCycleTime': 0,
	'theoreticalParts': 0,
	'reqOee': 0,
	'demonOee': 0,
	'reqJph': 0,
	'demonJph': 0,
	'partsReworkedPercent': 0.5,
	'isContainAssumptions': false,
	'avaiRemainPercent': 0,
	'otherAssumptionX': '',
	'sharedLoadingTotalAllocPercent': 0,
	'dedicatedTotalAllocPercent': 0,
	'supplierName': 'dsd',
	'supplierLocation': 'dsd',
	'siteCodeSurProcess': 'dsd',
	'surCustomerProcess': 'dsd',
	'averageHisOee': 0.0,
	'assumptionForClarification': 'dsds',
	'weekPartEstimate': 0,
	'totalAvailableTime': 52,
	'plannedDownTime': 0,
	'netAvailableTimePhase': null,
	'changeOverTimeWeekly': 0,
	'changeOverTimeActual': 0,
	'operatingTime': null,
	'observedUnplannedDownTime': null,
	'totalPartsRun': 140,
	'equipmentAvailability': null,
	'noOfIdenticalPartsProduced': 1,
	'noOfCurrentToolsORMachines': 1,
	'performanceEfficiency': null,
	'plannedNetIdealCycleTime': null,
	'noOfPartsScrapped': 1,
	'availablePerformanceEfficiency': null,
	'qualityRate': null,
	'noOfPartsReworked': 0,
	'observedAvgCycleTime': null,
	'phaseOEE': 0,
	'phaseJPH': 0,
	'actualHeadCount': null,
	'capitalEquipment': '',
	'plannedHeadCount': null,
	'noOfPartsScrappedPercent': null,
	'noOfPartsReworkedPercent': null,
	'result': false,
	'resultStatus': '',
	'resultStatusMessage': '',
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'apwMpwChange': false,
	'psChange': false,
	'sharedLoadChange': false,
	'hmpChange': false,
	'scrapChange': false,
	'scenarioChange': false,
	'capacityRequirementChange': false,
	'partPrefixBaseSuffixChange': false,
	'totalAllocationPercentageAPW': null,
	'totalAllocationPercentageMPW': null,
	'totalAllocationPercentageCapacityAPW': null,
	'totalAllocationPercentageCapacityMPW': null,
	'isApprovalRobustness': null,
	'isCopiedCar': null,
	'isSLPUpdated': null,
	'isHMPUpdated': null,
	'scenarioTemplateChange': null
};

export const testProcessDataMPW = {
	'processDataId': null,
	'reqId': null,
	'processStreamId': null,
	'processNameId': null,
	'isApw': false,
	'daysPerWeek': 6,
	'shiftsPerDay': 2,
	'hrsPerShift': 12,
	'downTime': 60,
	'dedicated': true,
	'allocPercent': 100,
	'netAvailableTime': 0,
	'planMinChan': 15,
	'planChangFreq': 2,
	'scrappedPercent': 1,
	'reqGoodPartsIncome': 0,
	'reqGoodPartsNext': 0,
	'idealCycleTime': 8.2,
	'noToolsMachParallel': 1,
	'noIdentityParts': 1,
	'netIdleCycleTime': 0,
	'theoreticalParts': 0,
	'reqOee': 0,
	'demonOee': 0,
	'reqJph': 0,
	'demonJph': 0,
	'partsReworkedPercent': 1,
	'isContainAssumptions': false,
	'avaiRemainPercent': 0,
	'otherAssumptionX': '',
	'sharedLoadingTotalAllocPercent': 0,
	'dedicatedTotalAllocPercent': 0,
	'supplierName': '',
	'supplierLocation': '',
	'siteCodeSurProcess': '',
	'surCustomerProcess': '',
	'averageHisOee': 0.0,
	'assumptionForClarification': '',
	'weekPartEstimate': 0,
	'totalAvailableTime': null,
	'plannedDownTime': null,
	'netAvailableTimePhase': null,
	'changeOverTimeWeekly': null,
	'changeOverTimeActual': null,
	'operatingTime': null,
	'observedUnplannedDownTime': null,
	'totalPartsRun': null,
	'equipmentAvailability': null,
	'noOfIdenticalPartsProduced': null,
	'noOfCurrentToolsORMachines': null,
	'performanceEfficiency': null,
	'plannedNetIdealCycleTime': null,
	'noOfPartsScrapped': null,
	'availablePerformanceEfficiency': null,
	'qualityRate': null,
	'noOfPartsReworked': null,
	'observedAvgCycleTime': null,
	'phaseOEE': 0,
	'phaseJPH': 0,
	'actualHeadCount': null,
	'capitalEquipment': '',
	'plannedHeadCount': null,
	'noOfPartsScrappedPercent': null,
	'noOfPartsReworkedPercent': null,
	'result': false,
	'resultStatus': '',
	'resultStatusMessage': '',
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'apwMpwChange': false,
	'psChange': false,
	'sharedLoadChange': false,
	'hmpChange': false,
	'scrapChange': false,
	'scenarioChange': false,
	'capacityRequirementChange': false,
	'partPrefixBaseSuffixChange': false,
	'totalAllocationPercentageAPW': null,
	'totalAllocationPercentageMPW': null,
	'totalAllocationPercentageCapacityAPW': null,
	'totalAllocationPercentageCapacityMPW': null,
	'isApprovalRobustness': null,
	'isCopiedCar': null,
	'isSLPUpdated': null,
	'isHMPUpdated': null,
	'scenarioTemplateChange': null
};
