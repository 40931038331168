import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HistoricalMfgPerfService} from '../../../services/master/historical-mfgperf/historical-mfgperf.service';
import {SiteCodeService} from '../../../services/sitecode.service';
import {HistoricalMfgPerfRoot} from '../../../models/historical-mfg-perf-root';
import {PagingHelper} from '../../../models/paging/paging-helper';
import {ApsAttributes} from '../../../models/aps-attributes';
import {UtilService} from '../../../services/util.service';
import {OndeleteDialogueboxComponent} from '../../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../../models/on-delete-display-dialogue-model';
import {DialogService} from 'primeng/api';
import {CarRequestService} from '../../../services/request/car-request.service';
import {HttpParams} from '@angular/common/http';

@Component({
	selector: 'manage-historical-mfgperf',
	templateUrl: './manage-historical-mfgperf.component.html',
	styleUrls: ['./manage-historical-mfgperf.component.css']
})
export class ManageHistoricalMfgperfComponent implements OnInit {

	siteCodes: string;
	historicalMfgPerfRoot: HistoricalMfgPerfRoot[];
	apsAttributes: ApsAttributes;
	totalHMPRootRecords: number;
	pageDetailsFrom: number;
	pageDetailsTo: number;
	private currentPage = 0;
	private currentPageSize = 10;
	private sortingParam: any[];
	private filters: any[];
	private isPrimeUser: boolean;
	private pageNo = 0;
	private pageSize = 10;
	selectedPage = 0;
	private initialLoad: boolean;

	constructor(private activatedRoute: ActivatedRoute, private router: Router, private hmfService: HistoricalMfgPerfService,
				private siteCodeService: SiteCodeService, private utilService: UtilService,
				private dialogService: DialogService, private carRequestService: CarRequestService) {
	}

	ngOnInit() {
		this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
		this.siteCodes = this.apsAttributes.siteCodes.map(site => site).join('-');
		this.retrieveRouteParameter();
	}

	loadHMPRootLazy(event) {
		this.selectedPage = this.pageNo * this.pageSize;
		this.currentPage = event.first / event.rows;
		this.currentPageSize = event.rows;
		if (this.pageNo > 0 && this.currentPage === 0 && !this.initialLoad) {
			this.initialLoad = true;
			this.selectedPage = this.pageNo * this.pageSize;
			this.currentPage = this.pageNo;
		}
		if (this.pageSize === 0 || this.pageSize === 10 || event.rows === 10) {
			this.currentPageSize = 10;
		}
		if (this.pageSize === 25 || event.rows === 25) {
			this.currentPageSize = 25;
		}
		if (this.pageSize === 50 || event.rows === 50) {
			this.currentPageSize = 50;
		}
		if (this.pageSize > 50 || event.rows > 50) {
			this.currentPageSize = 100;
		}
		this.sortingParam = [];
		if (event.sortField) {
			console.log('event.sortField ' + event.sortField.toString());
			if (event.sortOrder < 0) {
				this.sortingParam.push(event.sortField + ',asc');
			} else {
				this.sortingParam.push(event.sortField + ',desc');
			}
		}
		this.filters = [];
		for (const field of Object.keys(event.filters)) {
			this.filters.push(field + ':' + event.filters[field].value);
		}
		this.checkPrimeUser(sessionStorage.getItem('userId'), this.apsAttributes.userRole, this.currentPage, this.currentPageSize, this.sortingParam, this.filters);
	}

	findAllHMPRootByPaging(page: number, size: number, sort?: string[], filters?: string[]) {
		let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
		if (sort && sort.length > 0) {
			params = params.set('sort', sort.toString());
		}
		if (filters && filters.length > 0) {
			params = params.set('filters', filters.toString());
		}
		let uRole = this.apsAttributes.userRole;
		if (this.isPrimeUser) {
			uRole = 'Prime User';
			this.siteCodes = 'hi';
		} else if (uRole.toLowerCase() === 'ecar_read' || uRole.toLowerCase() === 'ecar_update' || uRole.includes('Developer') || uRole.includes('SiteEngineer') || uRole.includes('Supervisor') || uRole.includes('Manager')) {
			if (uRole.toLowerCase() === 'ecar_update' || uRole.toLowerCase() === 'ecar_read') {
				this.siteCodes = this.apsAttributes.siteCodes.map(site => site).join('-');
			} else {
				this.siteCodes = 'hi';//this.apsAttributes.siteCodes.map(site => site).join('-');
			}
		} else {
			this.siteCodes = 'hi';
		}
		if (uRole && uRole.length > 0) {
			params = params.set('userRole', uRole);
		}
		if (this.siteCodes && this.siteCodes.length > 0) {
			params = params.set('siteCodes', this.siteCodes);
		}
		this.hmfService.findAllHMPRootRecords(params).subscribe((response: PagingHelper) => {
			console.log(response);
			this.historicalMfgPerfRoot = response.content;
			this.totalHMPRootRecords = response.totalElements;
			this.pageDetailsFrom = ((response.pageable.pageNumber + 1) - 1) * response.pageable.pageSize + 1;
			this.pageDetailsTo = (response.pageable.pageNumber + 1) * response.pageable.pageSize;
			if (this.pageDetailsTo > this.totalHMPRootRecords) {
				this.pageDetailsTo = this.totalHMPRootRecords;
			}
		});
	}

	navigateToHMPPage(siteCode?: string, processNameId?: number, mode?: string) {
		const currentPage = this.currentPage;
		const currentPageSize = this.currentPageSize;
		this.router.navigate(['/historical-mfgperf-master-page'], {
			queryParams: {
				siteCode: siteCode,
				processId: processNameId,
				mode: mode,
				pageNo: currentPage,
				pageSize: currentPageSize
			}
		});
	}

	onDelete(siteCode: string, processNameId: number, avgOeeWithSafetyFactor: number) {
		this.hmfService.findSharedLoadingBySiteCodeProcessNameId(siteCode, processNameId).subscribe(value => {
			if (value) {
				this.utilService.pushMsg('error', 'Error', 'HMP records cannot be deleted since a Shared Loading Plan (SLP) exists for this process.  Please delete the SLP before deleting the records.');
			} else {
				this.callDeleteDialoguePop(siteCode, processNameId, avgOeeWithSafetyFactor);
			}
		});
	}

	callDeleteDialoguePop(siteCode: string, processNameId: number, avgOeeWithSafetyFactor: number) {
		const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
			header: 'Confirmation',
			width: '30%',
			contentStyle: {'max-height': '500px', 'overflow': 'auto'}
		});

		deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
			if (deleteModel != null) {
				if (deleteModel.isDeleted) {
					const params = new HttpParams()
						.set('siteCode', siteCode)
						.set('processNameId', processNameId.toString());
					this.hmfService.deleteAllHMPBySiteCodeAndProcessNameId(params).subscribe(value => {
						this.utilService.pushMsg('success', 'Success', 'Row is deleted successfully');
						this.hmfService.triggerSharedLoadingSubject.next(siteCode + '~' + processNameId);
						this.updateHMPFlagInCAR(siteCode, processNameId, avgOeeWithSafetyFactor);
						this.findAllHMPRootByPaging(this.currentPage, this.currentPageSize, this.sortingParam, this.filters);
					}, error => {
						this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
					});
				}
			}
		});
	}

	private updateHMPFlagInCAR(siteCode: string, processNameId: number, avgOeeWithSafetyFactor: number) {
		this.carRequestService.updateHMPFlagForOEEChange(siteCode, processNameId, avgOeeWithSafetyFactor).subscribe(isUpdated => {
			isUpdated ? console.log('HMP flags updated') : console.log('HMP flags not updated');
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private checkPrimeUser(userId: string, userRole: string, first, size, sort?: string[], filters?: string[]) {
		if (userRole.includes('Manager') || userRole.includes('Engineer') || userRole.includes('Supervisor')) {
			this.carRequestService.isPrimeUser(userId).subscribe(isValid => {
				if (isValid) {
					this.isPrimeUser = true;
					this.findAllHMPRootByPaging(first, size, sort, filters);
				} else {
					this.isPrimeUser = false;
					this.findAllHMPRootByPaging(first, size, sort, filters);
				}
			});
		} else {
			this.isPrimeUser = false;
			this.findAllHMPRootByPaging(first, size, sort, filters);
		}
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.pageNo = Number(params.get('pageNo'));
			this.pageSize = Number(params.get('pageSize'));
		});
	}
}
