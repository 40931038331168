import {ProcessNameService} from '../../services/master/process-name/process-name.service';
import {Component, OnInit} from '@angular/core';
import {ConfirmationService, DialogService, MessageService} from 'primeng/api';
import {HistoricalMfgPerfService} from '../../services/master/historical-mfgperf/historical-mfgperf.service';
import {ProcessName} from '../../models/process-name';
import {emptyHMP, HistoricalMfgPerf} from '../../models/historical-mfgperf';
import {SiteCode} from '../../models/site-code';
import {DatePipe} from '@angular/common';
import {CarmessageService} from '../../services/message/carmessage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DropdownOption} from '../../models/dropdown-option';
import {UtilService} from '../../services/util.service';
import {Policies} from '../../models/aps-policies';
import {SiteCodeService} from '../../services/sitecode.service';
import {ApsAttributes} from '../../models/aps-attributes';
import {OndeleteDialogueboxComponent} from '../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../models/on-delete-display-dialogue-model';
import * as moment from 'moment';
import * as _ from 'lodash';
import {OEEChart, oeeChartTemplate} from '../../models/chart-data';
import {CarRequestService} from '../../services/request/car-request.service';
import {HistoricalMfgPerfRoot} from '../../models/historical-mfg-perf-root';
import {ApsService} from '../../aps.service';

@Component({
	selector: 'historical-mfgperf',
	templateUrl: './historical-mfgperf.component.html',
	styleUrls: ['./historical-mfgperf.component.css']
})
export class HistoricalMfgPerfComponent implements OnInit {
	selectedSiteCode: string;
	selectedProcessId: ProcessName;
	clonedHistoricalMfgPerf: { [s: string]: HistoricalMfgPerf; } = {};
	averageOEE: any;
	noOfRecords: number;
	maxDateValue: Date;
	yearRange: string;
	siteCodes: SiteCode[];
	historicalmfgperfs: HistoricalMfgPerf[];
	historicalMfgPerfObj: any;
	processNames: ProcessName[];
	oee: number;
	sources: DropdownOption[];
	displayOldWarningBanner = false;
	displayOEEerrorBanner = false;
	displayLowRecordsCountWarning = false;
	disableSelection = false;
	prePopSiteCode: string;
	prePopProcessName: number;
	isHMPTriggered = true;
	carStatus: string;
	carId: number;
	disableEditButton: boolean;
	disableDeleteButton: boolean;
	policies: Policies;
	apsAttributes: ApsAttributes;
	disableUpload = true;

	hmpDateDisplay: Date;
	gpp: number;
	nict: number;
	nat: number;
	fieldOee: number;
	source: DropdownOption;
	comments: string;

	historicalMfgPerf: HistoricalMfgPerf;
	disableCreateButton = true;
	disableSafetyFactor = true;
	safetyFactor: number;
	averageOEEWithoutSafetyFactor: any;
	isSharedLoadingExists = false;
	showChart = false;
	histMfgChart: OEEChart[] = [];
	histMfgChartYAxis: OEEChart;
	displayChartLink = false;
	safetyFactorExceed = false;
	isSitePresentInSIM = true;
	isTokenOnlyUser: boolean;
	initialAvgOEE: number;
	private isNonSiteUser: boolean;
	isViewMode = false;
	private mode: string;
	chartWidth: number;
	displayRecords: string;
	private masterHMPLinkInvokedFromSnapshot: string;
	hmpPresent: boolean;
	private currentPage: number;
	private currentPageSize: number;
	placeholder = 'Please Enter Site Code';
	siteCodeSuggestions: string[];

	constructor(private apsService: ApsService, private siteCodeService: SiteCodeService, private utilService: UtilService, private route: ActivatedRoute, private processService: ProcessNameService, private service: HistoricalMfgPerfService, private messageService: MessageService, private carMessagesService: CarmessageService, private router: Router, private confirmationService: ConfirmationService, public dialogService: DialogService, private datepipe: DatePipe, private carRequestService: CarRequestService) {
	}

	ngOnInit() {
		this.loadSiteCodes();
		this.retrievePathVariables();
		this.checkNonSiteUser(sessionStorage.getItem('userId'), JSON.parse(sessionStorage.getItem('attributes')).userRole);
		this.maxDateValue = new Date();
		this.yearRange = (this.maxDateValue.getFullYear() - 100) + ':' + this.maxDateValue.getFullYear();
		this.sources = this.service.hmpSources;
		this.findAllPolicies();
		this.historicalMfgPerf = Object.assign({}, emptyHMP);
	}

	private retrievePathVariables() {
		this.route.queryParamMap.subscribe(params => {
			this.prePopSiteCode = params.get('siteCode');
			this.prePopProcessName = Number(params.get('processId'));
			this.carStatus = params.get('carStatus');
			this.carId = Number(params.get('carId'));
			this.mode = params.get('mode');
			this.currentPage = Number(params.get('pageNo'));
			this.currentPageSize = Number(params.get('pageSize'));
			if (this.mode === 'view') {
				this.isViewMode = true;
			}
			if (this.prePopSiteCode != null && this.prePopProcessName != null) {
				this.checkNonSiteUser(sessionStorage.getItem('userId'), JSON.parse(sessionStorage.getItem('attributes')).userRole);
			}
		});
	}

	getHMPRootAndSiteCodeChange() {
		if (this.prePopSiteCode != null && this.prePopProcessName != null) {
			if (!this.isNonSiteUser) {
				//this.selectedSiteCode = this.siteCodes.find(temp => temp.code === this.prePopSiteCode);
				this.selectedSiteCode = this.prePopSiteCode;
				this.placeholder = this.prePopSiteCode;
			}
			this.getHMPRootBySiteCodeAndProcessId(this.prePopSiteCode, this.prePopProcessName);
			this.onChangeSiteCode();
		}
	}

	getHMPRootBySiteCodeAndProcessId(siteCode: string, processNameId: number) {
		this.service.getHMPRootBySiteCodeAndProcessId(siteCode, processNameId).subscribe((response: HistoricalMfgPerfRoot) => {
			this.averageOEE = response.avgOeeWithSafetyFactor;
			this.safetyFactor = response.safetyFactor;
			this.averageOEEWithoutSafetyFactor = response.avgOee;
			this.noOfRecords = response.recordsCount;
		});
	}

	onChangeSiteCode() {
		this.historicalmfgperfs = [];
		this.selectedProcessId = null;
		this.findAllProcessNames();
		this.isSiteCodeAvailableInSIM(this.selectedSiteCode);
		this.isMasterHMPPresent();
	}

	onChangeProcess() {
		this.displayOEEerrorBanner = false;
		this.displayOldWarningBanner = false;
		this.displayLowRecordsCountWarning = false;
		if (!this.selectedSiteCode || this.selectedSiteCode.length < 5 || !this.isSitePresentInSIM) {
			this.utilService.pushMsg('error', 'Error', 'Please Select Site Code');
		} else if (!this.selectedProcessId) {
			this.utilService.pushMsg('error', 'Error', 'Please Select Process Name');
		} else {
			this.disableUpload = this.disableEditButton; //Disabling based on Update policy
			this.disableCreateButton = this.disableEditButton;
			this.disableSafetyFactor = this.disableEditButton;
			this.loadHistoricalMfgPerf(false);
		}
	}

	convertAndUpdateDate(rowData) {
		const datePipe = new DatePipe('en-US');
		if (rowData.hmfDateDisplay !== null) {
			rowData.hmfDateDisplay = datePipe.transform(rowData.hmfDateDisplay, 'MM/dd/yyyy');
		}
	}

	onRowEditInit(historicalmfgperf: HistoricalMfgPerf) {
		this.clonedHistoricalMfgPerf[historicalmfgperf.hmfId] = {...historicalmfgperf};
		this.convertAndUpdateDate(historicalmfgperf);
	}

	onRowDelete(historicalMfgPerf) {
		const isRowDeleteValid = this.validateDeleteRequest(historicalMfgPerf);
		if (isRowDeleteValid) {
			if (this.historicalmfgperfs.length === 1 && this.isSharedLoadingExists) {
				this.utilService.pushMsg('error', 'Error', 'Final HMP record cannot be deleted since a Shared Loading Plan (SLP) exists for this process.  Please delete the SLP before deleting this record.');
			} else {
				this.callDeleteDialoguePop(historicalMfgPerf);
			}
		}
	}

	callDeleteDialoguePop(historicalmfgperf: HistoricalMfgPerf) {
		const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
			header: 'Confirmation',
			width: '30%',
			contentStyle: {'max-height': '500px', 'overflow': 'auto'}
		});

		deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
			if (deleteModel != null) {
				if (deleteModel.isDeleted) {
					this.service.delete(historicalmfgperf.hmfId).subscribe(value => {
						historicalmfgperf = value;
						this.utilService.pushMsg('success', 'Success', 'Row is deleted successfully');
						this.loadHistoricalMfgPerf(true);
						this.service.triggerSharedLoadingSubject.next(this.historicalmfgperfs[0].siteCode + '~' + this.historicalmfgperfs[0].processId);
					}, error => {
						this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
					});
				}
			}
		});
	}

	onUpdate(historicalMfgPerf: HistoricalMfgPerf, index: BigInteger) {
		const isValidUpdate = this.validateHistoricalMfgPerfData(historicalMfgPerf);
		if (isValidUpdate) {
			historicalMfgPerf.processId = this.selectedProcessId.id;
			historicalMfgPerf.siteCode = this.selectedSiteCode;
			this.initialAvgOEE = this.averageOEE;
			if (historicalMfgPerf.hmfDateDisplay != null && this.compareDate32WeeksOlder(historicalMfgPerf.hmfDateDisplay)) {
				this.confirmationService.confirm({
					message: 'Entered Data is over 32 weeks old, please ensure that it is accurate',
					header: 'Confirmation',
					icon: 'pi pi-info-circle',
					accept: () => {
						this.updateHMF();
						delete this.clonedHistoricalMfgPerf[historicalMfgPerf.hmfId];
					},
					reject: () => {
						this.onRowEditCancel(historicalMfgPerf, index);
						this.checkForOlderData();
						this.checkOEEPercentage();
					}
				});
			} else {
				this.updateHMF();
				delete this.clonedHistoricalMfgPerf[historicalMfgPerf.hmfId];
			}
			this.calculateAverageOEEAndNofRecords(this.historicalmfgperfs);
		} else {
			this.onRowEditCancel(historicalMfgPerf, index);
		}
	}

	private updateHMF() {
		const sf = this.safetyFactor;
		this.historicalmfgperfs.forEach(function (data) {
			if (data.hmfDate !== null) {
				data.hmfDate = new Date(data.hmfDateDisplay).toDateString();
				data.hmfDateDisplay = Date.parse(data.hmfDate);
			}
			if (sf !== null) {	//'this' is undefined inside forEach, so used const sf
				data.safetyFactor = sf;
			}
		});
		this.service.update(this.historicalmfgperfs).subscribe(responseData => {
			this.historicalmfgperfs = responseData;
			this.historicalmfgperfs.forEach(hmp => {
				if (hmp.oee) {
					//hmp.hmfDateDisplay = Date.parse(String(new Date(hmp.hmfDate)));
					hmp.hmfDateDisplay = Date.parse(this.utilService.convertDateStringInFormat(hmp.hmfDate));
				}
			});
			this.utilService.pushMsg('success', 'Success', 'Row updated successfully');
			this.service.getHMPRootBySiteCodeAndProcessId(this.historicalmfgperfs[0].siteCode, this.historicalmfgperfs[0].processId).subscribe((response: HistoricalMfgPerfRoot) => {
				this.averageOEE = response.avgOeeWithSafetyFactor;
				this.safetyFactor = response.safetyFactor;
				this.averageOEEWithoutSafetyFactor = response.avgOee;
				this.noOfRecords = response.recordsCount;

				console.log('UPDATE initial ' + this.initialAvgOEE + ' avg ' + this.averageOEE);
				if (this.initialAvgOEE !== this.averageOEE) {
					this.service.triggerSharedLoadingSubject.next(this.historicalmfgperfs[0].siteCode + '~' + this.historicalmfgperfs[0].processId);
					this.updateHMPFlagInCAR();
				}
				this.loadHistoricalMfgPerf(false);
			});
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Update operation failed, please try again latter');
			this.loadHistoricalMfgPerf(false);
		});
	}

	onRowEditCancel(rowData, index) {
		this.historicalmfgperfs[index] = this.clonedHistoricalMfgPerf[rowData.hmfId];
		delete this.clonedHistoricalMfgPerf[rowData.hmfId];
	}

	calcIndividualOEE(obj) {
		if (obj.gpp > 0 && obj.nat > 0 && obj.nict > 0) {
			this.oee = (obj.gpp / ((obj.nat * 3600) / obj.nict));
			obj.oee = (this.oee * 100).toFixed(1);
		}
		return obj.oee;
	}

	private loadHistoricalMfgPerf(updateECARHMPFlag: boolean) {
		this.historicalMfgPerfObj = {
			processId: this.selectedProcessId.id,
			siteCode: this.selectedSiteCode
		};
		if (this.carStatus === 'view' && this.carId > 0) {
			this.service.findHistMfgPerfRecordsByCarId(this.historicalMfgPerfObj, this.carId).subscribe((response: HistoricalMfgPerf[]) => {
				this.historicalmfgperfs = response;
				this.historicalmfgperfs.forEach(hmp => {
					if (hmp.oee) {
						//hmp.hmfDateDisplay = Date.parse(String(new Date(hmp.hmfDate)));
						hmp.hmfDateDisplay = Date.parse(this.utilService.convertDateStringInFormat(hmp.hmfDate));
					}
				});
				this.getHMPRootBySiteCodeAndProcessId(this.historicalMfgPerfObj.siteCode, this.historicalMfgPerfObj.processId);
				this.calculateAverageOEEAndNofRecords(this.historicalmfgperfs);
				this.displayChartLink = true;
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.service.findHistMfgPerfRecords(this.historicalMfgPerfObj).subscribe((response: HistoricalMfgPerf[]) => {
				this.historicalmfgperfs = response;
				if (this.historicalmfgperfs.length > 0) {
					this.historicalmfgperfs.forEach(hmp => {
						if (hmp.oee) {
							console.log('date before conversion ' + hmp.hmfDate);
							hmp.hmfDateDisplay = Date.parse(this.utilService.convertDateStringInFormat(hmp.hmfDate));
							console.log('date after conversion ' + this.utilService.convertDateStringInFormat(hmp.hmfDate));
							console.log('date object ' + new Date(hmp.hmfDate));
							//hmp.hmfDateDisplay = Date.parse(String(new Date(hmp.hmfDate)));
						}
					});
					this.initialAvgOEE = this.averageOEE;
					this.service.getHMPRootBySiteCodeAndProcessId(this.historicalMfgPerfObj.siteCode, this.historicalMfgPerfObj.processId).subscribe((resp: HistoricalMfgPerfRoot) => {
						this.averageOEE = resp.avgOeeWithSafetyFactor;
						this.safetyFactor = resp.safetyFactor;
						this.averageOEEWithoutSafetyFactor = resp.avgOee;
						this.noOfRecords = resp.recordsCount;

						this.calculateAverageOEEAndNofRecords(this.historicalmfgperfs);
						if (updateECARHMPFlag && this.initialAvgOEE !== this.averageOEE) {
							this.updateHMPFlagInCAR();
						}
						this.displayChartLink = true;
					});
				} else if (updateECARHMPFlag) {
					this.averageOEE = 0;
					this.updateHMPFlagInCAR();
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	private calculateAverageOEEAndNofRecords(historicalmfgperfs: HistoricalMfgPerf[]) {
		this.checkForOlderData();
		this.checkForLowRecordsCount();
		this.checkOEEPercentage();
		if (historicalmfgperfs.length > 0) {
			this.verifySharedLoadingExistsForSiteCodeProcessNameId(this.selectedSiteCode, this.selectedProcessId.id);
		}
	}

	private updateHMPFlagInCAR() {
		this.carRequestService.updateHMPFlagForOEEChange(this.selectedSiteCode, this.selectedProcessId.id, this.averageOEE).subscribe(isUpdated => {
			isUpdated ? console.log('HMP flags updated') : console.log('HMP flags not updated');
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private findAllProcessNames() {
		if (this.mode === 'create') {
			this.service.findProcessNameIdsWithHmp(this.selectedSiteCode).subscribe((idList: number[]) => {
				this.getAllProcessBySiteCode(idList);
			});
		} else {
			this.getAllProcessBySiteCode([]);
		}
	}

	public getAllProcessBySiteCode(idList: number[]) {
		this.processService.findAllProcessBySiteCode(this.selectedSiteCode).subscribe((response: ProcessName[]) => {
			this.processNames = response.filter(processName => !idList.includes(processName.id));
			this.processNames = this.processNames.filter(processName => processName.active === true);
			this.processNames = this.processNames.sort(function (a, b) {
				return a.processName.localeCompare(b.processName, undefined, {
					numeric: true,
					sensitivity: 'base'
				});
			});
			this.prePopulateData(); //get parameters once processNames received
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private validateHistoricalMfgPerfData(data: HistoricalMfgPerf): boolean {
		data.hmfDateDisplay = Date.parse(String(new Date(data.hmfDateDisplay)));
		return this.validateCheck(data);
	}

	private validateDeleteRequest(data: HistoricalMfgPerf) {
		if (data.hmfDateDisplay === null && data.gpp === null && data.nict === null && data.nat === null && data.oee === null) {
			this.utilService.pushMsg('error', 'Error', 'No data found for deletion');
			return false;
		}
		return true;
	}

	private validateCheck(currentHMfP: HistoricalMfgPerf) {
		if (currentHMfP.hmfDateDisplay === null || !currentHMfP.hmfDateDisplay) {
			this.utilService.pushMsg('error', 'Error', 'Please enter Date value');
			return false;
		} else if (currentHMfP.gpp === null || !currentHMfP.gpp || currentHMfP.gpp === 0.0 || currentHMfP.gpp === 0.00) {
			this.utilService.pushMsg('error', 'Error', 'Please enter GPP value');
			return false;
		} else if (currentHMfP.nict === null || !currentHMfP.nict || currentHMfP.nict === 0.0 || currentHMfP.nict === 0.00) {
			this.utilService.pushMsg('error', 'Error', 'Please enter NICT value');
			return false;
		} else if (currentHMfP.nat === null || !currentHMfP.nat || currentHMfP.nat === 0.0 || currentHMfP.nat === 0.00) {
			this.utilService.pushMsg('error', 'Error', 'Please enter NAT value');
			return false;
		} else if (currentHMfP.source === null || !currentHMfP.source) {
			this.utilService.pushMsg('error', 'Error', 'Please enter Source value');
			return false;
		} else if (currentHMfP.source.name === 'Other (to be explained in comments)') {
			if (currentHMfP.comments === null || !currentHMfP.comments) {
				this.utilService.pushMsg('error', 'Error', 'If Source is "Others", then Comments field is mandatory');
				return false;
			}
		}
		return true;
	}

	parseDate(curDate: any) {
		return Date.parse(curDate);
	}

	validateDate(rowData, inputIndex) {
		let curDate = rowData.hmfDateDisplay;
		curDate = this.parseDate(curDate);
		const historicalmfgperfsCloned = Object.assign([], this.historicalmfgperfs);
		delete historicalmfgperfsCloned[inputIndex];
		historicalmfgperfsCloned.forEach((historicalmfgperf) => {
			const number = historicalmfgperf.hmfDateDisplay;
			if (curDate === number) {
				this.utilService.pushMsg('error', 'Error', 'Historical Manufacturing Date should not be duplicate !.');
				rowData.hmfDateDisplay = undefined;
				return false;
			}
		});
	}

	compareDate32WeeksOlder(selectedDate): boolean {
		const today = new Date();
		const selectedDt = new Date(selectedDate);
		const diffInTime = today.getTime() - selectedDt.getTime();
		const difference_In_Days = diffInTime / (1000 * 3600 * 24);
		return difference_In_Days > 224;
	}

	compareDateWithOlderRecords(selectedDate): boolean {
		let res = false;
		this.historicalmfgperfs.forEach(hmpData => {
			if (this.formatDate(hmpData.hmfDate) === this.formatDate(selectedDate)) {
				res = true;
			}
		});
		return res;
	}

	formatDate(dateString): string {
		let month = '' + (new Date(dateString).getMonth() + 1);
		let day = '' + new Date(dateString).getDate();
		if (month.length < 2) {
			month = '0' + month;
		}
		if (day.length < 2) {
			day = '0' + day;
		}
		return [new Date(dateString).getFullYear(), month, day].join('-');
	}

	checkForOlderData() {
		this.displayOldWarningBanner = false;
		if (this.historicalmfgperfs) {
			this.historicalmfgperfs.forEach(value => {
				if (value.hmfDateDisplay != null && this.compareDate32WeeksOlder(value.hmfDateDisplay)) {
					this.displayOldWarningBanner = true;
				}
			});
		}
	}

	checkOEEPercentage() {
		this.displayOEEerrorBanner = false;
		if (this.historicalmfgperfs) {
			this.historicalmfgperfs.forEach(value => {
				if (value.oee != null && value.oee > 100) {
					this.displayOEEerrorBanner = true;
				}
			});
		}
	}

	checkForLowRecordsCount() {
		this.displayLowRecordsCountWarning = false;
		let recordsCount = 0;
		if (this.historicalmfgperfs) {
			this.historicalmfgperfs.forEach(hmp => {
				if (hmp.hmfDateDisplay !== null) {
					recordsCount++;
				}
			});
			if (recordsCount < 25) {
				this.displayLowRecordsCountWarning = true;
			}
		}
	}

	private prePopulateData() {
		if (this.prePopSiteCode != null && this.prePopProcessName != null) {
			this.selectedProcessId = this.processNames.find(temp => temp.id === this.prePopProcessName);
			this.onChangeProcess();
			this.disableSelection = true;
			if (this.carStatus === 'view') { //disable edit options if not draft (changing options: create, safetyFactor, upload)
				this.disableCreateButton = true;
				this.disableSafetyFactor = true;
				this.disableUpload = true;
			}
		}
	}

	private findAllPolicies() {
		this.policies = JSON.parse(sessionStorage.getItem('policies'));
		const policyName = 'eCAR:HMP';
		this.disableEditButton = !this.utilService.checkPermittedUpdatePolicy(this.policies, policyName);
		this.disableDeleteButton = !this.utilService.checkPermittedDeletePolicy(this.policies, policyName);
	}

	private loadSiteCodes() {
		this.isTokenOnlyUser = this.utilService.isUserHasOnlyTokenOnly();
		//this.siteCodes = this.siteCodeService.fetchSiteCodes();
	}

	downloadHstMfgExcelTemplate() {
		this.service.downloadHstMfgTemplate();
	}

	downloadHstMfgExcelEuropeTemplate() {
		this.service.downloadHstMfgExcelEuropeTemplate();
	}

	uploadHMPExcel(event, hmpFileUpload) {
		console.log('uploading called...');
		const file = event.files[0];

		this.service.uploadFile(file, this.selectedSiteCode, this.selectedProcessId.id).subscribe((value) => {
			this.utilService.pushMsg('success', 'Success', 'File Uploaded successfully');
			this.loadHistoricalMfgPerf(true);
			if (this.historicalmfgperfs.length > 0) {
				this.service.triggerSharedLoadingSubject.next(this.historicalmfgperfs[0].siteCode + '~' + this.historicalmfgperfs[0].processId);
			}
		}, error => {
			if (error.error.error.messages[0].includes('Uploaded Template is not valid.')) {
				this.utilService.pushMsg('error', 'Error', error.error.error.messages[0]);
			} else if (error.error.error.messages[0].includes('Uploaded Template is not recent one, Please download the latest version.')) {
				this.utilService.pushMsg('error', 'Error', error.error.error.messages[0]);
			} else if (error.error.error.messages[0].includes('One or more weeks of the upload file have an NAT greater than 168 hours, please correct the data and try again.')) {
				this.utilService.pushMsg('error', 'Error', error.error.error.messages[0]);
			} else if (error.error.error.messages[0].includes('One or more lines have an OEE greater than 100%, please correct the data and try again.')) {
				this.utilService.pushMsg('error', 'Error', error.error.error.messages[0]);
			} else {
				this.utilService.pushMsg('error', 'Error', 'Upload Failed, Please review template.');
			}
		});
		hmpFileUpload.clear();
	}

	createHMPData() {
		console.log('create new HMP data');
		if (this.hmpDateDisplay) {
			this.historicalMfgPerf.hmfDate = this.hmpDateDisplay.toDateString();
			this.historicalMfgPerf.hmfDateDisplay = Date.parse(this.historicalMfgPerf.hmfDate);
		}
		this.historicalMfgPerf.siteCode = this.selectedSiteCode;
		this.historicalMfgPerf.processId = this.selectedProcessId.id;
		this.historicalMfgPerf.gpp = this.gpp;
		this.historicalMfgPerf.nict = this.nict;
		this.historicalMfgPerf.nat = this.nat;
		this.historicalMfgPerf.oee = this.fieldOee;
		this.historicalMfgPerf.source = this.source;
		this.historicalMfgPerf.comments = this.comments;
		this.historicalMfgPerf.safetyFactor = this.safetyFactor;

		if (!this.selectedSiteCode) {
			this.utilService.pushMsg('error', 'Error', 'Please Select Site Code');
			return false;
		} else if (!this.selectedProcessId) {
			this.utilService.pushMsg('error', 'Error', 'Please Select Process Name');
			return false;
		} else if (!this.validateCheck(this.historicalMfgPerf)) {
			return false;
		} else if (this.compareDateWithOlderRecords(this.historicalMfgPerf.hmfDateDisplay)) {
			this.confirmationService.confirm({
				message: 'Data is already available in the system for the selected Date, Are you sure you want to update?',
				header: 'Confirmation',
				icon: 'pi pi-info-circle',
				accept: () => {
					this.createHMP(this.historicalMfgPerf);
				},
				reject: () => {
				}
			});
		} else {
			if (this.historicalMfgPerf.hmfDateDisplay != null && this.compareDate32WeeksOlder(this.historicalMfgPerf.hmfDateDisplay)) {
				this.confirmationService.confirm({
					message: 'Entered Data is over 32 weeks old, please ensure that it is accurate',
					header: 'Confirmation',
					icon: 'pi pi-info-circle',
					accept: () => {
						this.createHMP(this.historicalMfgPerf);
					},
					reject: () => {
					}
				});
			} else {
				this.createHMP(this.historicalMfgPerf);
			}
		}
	}

	private createHMP(historicalMfgPerf) {
		this.service.create(historicalMfgPerf).subscribe(value => {
			this.utilService.pushMsg('success', 'Success', 'New HMP record added successfully');
			this.clearFields();
			this.loadHistoricalMfgPerf(true);
			if (this.historicalmfgperfs.length > 0) {
				this.service.triggerSharedLoadingSubject.next(this.historicalmfgperfs[0].siteCode + '~' + this.historicalmfgperfs[0].processId);
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Error while creating HMP record');
		});
	}

	calculateOEE() {
		if (this.gpp > 0 && this.nat > 0 && this.nict > 0) {
			this.fieldOee = (this.gpp / ((this.nat * 3600) / this.nict));
			this.fieldOee = Math.round((this.fieldOee * 100) * 10) / 10; //Math.round(number * 10) / 10 for rounding to single decimal place
		}
	}

	clearFields() {
		this.hmpDateDisplay = null;
		this.gpp = null;
		this.nict = null;
		this.nat = null;
		this.fieldOee = null;
		this.source = null;
		this.comments = null;
	}

	validateDateExists() {
		return !!this.historicalmfgperfs.find(historicalPerfObj =>
			(new Date(historicalPerfObj.hmfDateDisplay)).getTime() === (new Date(this.hmpDateDisplay)).getTime()
		);
	}

	updateSafetyFactor() {
		if (this.safetyFactor !== null && this.safetyFactor <= 70) {
			this.safetyFactorExceed = false;
			this.updateHMF();
			this.getHMPRootBySiteCodeAndProcessId(this.historicalMfgPerfObj.siteCode, this.historicalMfgPerfObj.processId);
			this.calculateAverageOEEAndNofRecords(this.historicalmfgperfs);
		} else {
			this.safetyFactorExceed = true;
		}
	}

	inputNumberValidation(event: any) {
		return this.utilService.inputNumberValidationWithSingleDecimal(event);
	}

	nonZeroInputValidation(value: any): any {
		if (value === '0' || value === '0.0' || value === '0.00') {
			return null;
		} else {
			return value;
		}
	}

	verifySharedLoadingExistsForSiteCodeProcessNameId(siteCode, processNameId) {
		this.service.findSharedLoadingBySiteCodeProcessNameId(siteCode, processNameId).subscribe(value => {
			this.isSharedLoadingExists = !!value;
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, please try again later');
		});
	}

	loadHMPChart() {
		this.showChart = true;
		this.displayRecords = 'all';
		console.log('process id ' + this.selectedProcessId.id);
		setTimeout(() => {
			this.histMfgChart = this.buildChartData(this.historicalmfgperfs, this.selectedProcessId.id);
		}, 0);
	}

	private buildChartData(hmpData: HistoricalMfgPerf[], processNameId: number) {
		const chartData = [];
		const oee = [];
		const labels = [];
		let demonOEEMax;
		let yMax;
		this.historicalMfgPerfObj = {
			processId: processNameId,
			siteCode: this.selectedSiteCode
		};

		const filteredHmpData = hmpData.filter(value => value.processId === processNameId);

		//Sort the response by date
		filteredHmpData.sort(function (left, right) {
			return moment.utc(left.hmfDate).diff(moment.utc(right.hmfDate));
		});
		filteredHmpData.forEach((historicalObj) => {
			oee.push(historicalObj.oee);
			if (historicalObj.hmfDate) {
				labels.push(this.datepipe.transform(historicalObj.hmfDate, 'dd-MMM-yyyy'));
			}
		});
		this.chartWidth = labels.length > 25 ? labels.length * 35 : 855;

		demonOEEMax = Math.max(...oee);
		const tempChart = _.cloneDeep(oeeChartTemplate);
		if (demonOEEMax < 100) {
			yMax = 100;
		} else {
			if (demonOEEMax > 100) {
				yMax = Math.ceil(demonOEEMax / 20) * 20;
				tempChart.isDemonOEEExceeds100Percent = true;
			}
		}
		// Chart 1
		tempChart.oeeChart = {
			labels: labels,
			datasets: [
				{
					type: 'line',
					label: 'Demonstrated OEE',
					borderColor: '#2B60DE', //#5DADE2,#0909FF
					borderWidth: 2,
					fill: false,
					data: oee,
					pointRadius: 5
				}
			]
		};
		tempChart.oeeChartOptions = {
			onAnimationComplete: function () {
				this.showTooltip(this.segments, true);
			},
			tooltipEvents: [],
			tooltips: {
				intersect: false,
				callbacks: {
					label: function (tooltipItem, data) {
						const label = [];
						label.push(data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel.toFixed(1));
						return label;
					}
				}
			},
			showTooltips: true,
			responsive: false,
			maintainAspectRatio: false,
			layout: {
				padding: {
					top: 7
				}
			},
			scales: {
				yAxes: [{
					ticks: {
						beginAtZero: true,
						max: yMax,
						callback: function (value, index, values) {
							return value + ' %';
						},
						display: labels.length <= 25
					},
					grid: {
						drawTicks: false,
						drawBorder: false
					}
				}],
				xAxes: [{
					ticks: {
						beginAtZero: true
					}
				}]
			},
			legend: {
				display: false
			}
		};
		// Chart 2 - Y Axis
		const tempChart2 = _.cloneDeep(oeeChartTemplate);
		if (demonOEEMax < 100) {
			yMax = 100;
		} else {
			if (demonOEEMax > 100) {
				yMax = Math.ceil(demonOEEMax / 20) * 20;
				tempChart2.isDemonOEEExceeds100Percent = true;
			}
		}
		tempChart2.oeeChart = {
			labels: labels,
			datasets: [
				{
					type: 'line',
					label: 'Demonstrated OEE',
					data: oee
				}
			]
		};

		tempChart2.oeeChartOptions = {
			responsive: false,
			maintainAspectRatio: false,
			layout: {
				padding: {
					bottom: 46.5
				}
			},
			scales: {
				yAxes: [{
					ticks: {
						beginAtZero: true,
						max: yMax,
						callback: function (value, index, values) {
							console.log('callback', value);
							return value + ' %';
						}
					},
					afterFit: (ctx) => {
						console.log('afterFit', ctx);
						ctx.width = 50;
					}
				}],
				xAxes: [{
					ticks: {
						beginAtZero: true,
						display: false
					},
					grid: {
						drawTicks: false
					}
				}]
			},
			legend: {
				display: false
			}
		};
		this.histMfgChartYAxis = tempChart2;
		chartData.push(tempChart);
		return chartData;
	}

	private onCloseChart() {
		this.showChart = false;
	}

	isSiteCodeAvailableInSIM(siteCode: string) {
		if (siteCode && siteCode.length > 4) {
			this.processService.isSiteCodePresentInSIM(siteCode).subscribe(isPresent => {
				this.isSitePresentInSIM = isPresent;
			});
		}
	}

	private checkNonSiteUser(userId: string, userRole: string) {
		if (userRole.includes('Manager') || userRole.includes('Engineer') || userRole.includes('Supervisor')) {
			this.carRequestService.isPrimeUser(userId).subscribe(isValid => {
				if (isValid) {
					this.isNonSiteUser = true;
					if (!this.prePopSiteCode) {
						this.processService.getAllSiteCodesFromProcess().subscribe(value => {
							this.siteCodes = value;
						});
					} else {
						/*this.siteCodes = [];
						this.selectedSiteCode = {name: '', code: this.prePopSiteCode};
						this.siteCodes.push(this.selectedSiteCode);*/
						this.selectedSiteCode = this.prePopSiteCode;
						this.placeholder = this.prePopSiteCode;
					}
				} else {
					this.siteCodes = this.siteCodeService.fetchSiteCodes();
					this.isNonSiteUser = false;
				}
				this.getHMPRootAndSiteCodeChange();
			});
		} else if (!userRole.toLowerCase().includes('ecar') && !userRole.includes('Developer')) {
			this.isNonSiteUser = true;
			if (!this.prePopSiteCode) {
				this.processService.getAllSiteCodesFromProcess().subscribe(value => {
					this.siteCodes = value;
				});
			} else {
				/*this.siteCodes = [];
				this.selectedSiteCode = {name: '', code: this.prePopSiteCode};
				this.siteCodes.push(this.selectedSiteCode);*/
				this.selectedSiteCode = this.prePopSiteCode;
				this.placeholder = this.prePopSiteCode;
			}
			this.getHMPRootAndSiteCodeChange();
		} else {
			this.siteCodes = this.siteCodeService.fetchSiteCodes();
			this.isNonSiteUser = false;
			this.getHMPRootAndSiteCodeChange();
		}
	}

	onCancel(): void {
		const pageNo = this.currentPage;
		const pageSize = this.currentPageSize;
		this.router.navigate(['/manage-historical-mfgperf/' + pageNo + '/' + pageSize]);
	}

	redrawChart(): void {
		if (this.displayRecords === 'all') {
			this.histMfgChart = this.buildChartData(this.historicalmfgperfs, this.selectedProcessId.id);
		} else {
			const arrLength = this.historicalmfgperfs.length;

			//Sort the response by date
			this.historicalmfgperfs.sort(function (left, right) {
				return moment.utc(left.hmfDate).diff(moment.utc(right.hmfDate));
			});
			const slicedVal = arrLength > 25 ?
				this.historicalmfgperfs.slice(arrLength - 25, arrLength) : this.historicalmfgperfs;

			this.histMfgChart = this.buildChartData(slicedVal, this.selectedProcessId.id);
		}
	}

	private buildMasterHMPLink() {
		if (this.masterHMPLinkInvokedFromSnapshot !== 'N/A') {
			window.open(this.masterHMPLinkInvokedFromSnapshot);
		}
	}

	private isMasterHMPPresent() {
		if (this.carStatus === 'view' && this.carId > 0) {
			this.historicalMfgPerfObj = {
				processId: this.prePopProcessName,
				siteCode: this.prePopSiteCode
			};
			this.service.findHistMfgPerfRecords(this.historicalMfgPerfObj).subscribe((hmp: HistoricalMfgPerf[]) => {
				if (hmp && hmp.length > 0) {
					this.hmpPresent = true;
					this.masterHMPLinkInvokedFromSnapshot = window.location.origin + '/#/historical-mfgperf-master-page/?siteCode=' + this.prePopSiteCode + '&processId=' + this.prePopProcessName + '&mode=view';
				} else {
					this.hmpPresent = false;
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.masterHMPLinkInvokedFromSnapshot = 'N/A';
		}
	}

	fetchSiteCodes(event: any) {
		const searchString = event.query;
		if (searchString && searchString.length > 2) {
			this.apsService.retrieveAttributesBySearchString(searchString).subscribe(apsAttribute => {
				if (apsAttribute && apsAttribute.siteCodes) {
					this.siteCodeSuggestions = this.buildDisplaySiteCodes(apsAttribute);
				}
			});
		}
	}

	private buildDisplaySiteCodes(apsAttribute: ApsAttributes) {
		return apsAttribute.siteCodes.map(value => value.length > 4 ? value : '');
	}
}
