import {Component, OnInit} from '@angular/core';
import {ProcessStreamService} from '../../../services/master/process-stream/process-stream.service';
import {ProcessStream} from '../../../models/process-stream';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService, LazyLoadEvent, Message} from 'primeng/api';
import {CarmessageService} from '../../../services/message/carmessage.service';
import {Policies} from '../../../models/aps-policies';
import {UtilService} from '../../../services/util.service';
import {ApsAttributes} from '../../../models/aps-attributes';
import {OndeleteDialogueboxComponent} from '../../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../../models/on-delete-display-dialogue-model';
import {PagingHelper} from '../../../models/paging/paging-helper';
import {CarRequestService} from '../../../services/request/car-request.service';
import {HttpParams} from '@angular/common/http';

@Component({
	selector: 'manage-process-stream',
	templateUrl: './manage-process-stream.component.html',
	styleUrls: ['./manage-process-stream.component.css']
})
export class ManageProcessStreamComponent implements OnInit {
	private processStreams: ProcessStream[];
	displayMessage: Message[] = [];
	disableCreateButton: boolean;
	disableDeleteButton: boolean;
	policies: Policies;
	apsAttributes: ApsAttributes;
	totalProcessStreamRecordPages = 0;
	pageDetailsFrom = 0;
	pageDetailsTo = 0;
	currentPage = 0;
	currentPageSize = 10;
	siteCodes: string;
	private isPrimeUser: boolean;
	private pageNo = 0;
	private pageSize = 10;
	selectedPage = 0;
	private initialLoad: boolean;

	constructor(private activatedRoute: ActivatedRoute, private utilService: UtilService, private processStreamService: ProcessStreamService, private carMessagesService: CarmessageService, private router: Router, private service: ProcessStreamService, public dialogService: DialogService, public carRequestService: CarRequestService) {
	}

	ngOnInit() {
		this.checkAndShowSuccessMsg('info');
		this.retrieveRouteParameter();
		//this.findAllProcessStreams();
		this.findAllPolicies();
	}

	checkAndShowSuccessMsg(msg: string) {
		let success = sessionStorage.getItem('ProcessNameCreateSuccess');
		if (success) {
			success = JSON.parse(success);
			this.displayMessage.push({severity: msg, summary: '', detail: success['message']});
			window.scrollTo(0, 0);
			sessionStorage.removeItem('ProcessNameCreateSuccess');
			setTimeout(() => {
				this.displayMessage = [];
			}, 10000);
		}
	}

	private findAllProcessStreams() {
		this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
		if (this.apsAttributes.userRole === 'Super User' || this.apsAttributes.userRole === 'Buyer' || this.apsAttributes.userRole === 'STA User' || this.apsAttributes.userRole === 'Capacity Planner' || this.apsAttributes.userRole === 'Developer') {
			this.service.findAll().subscribe((response: ProcessStream[]) => {
				this.processStreams = response;
			});
		} else {
			this.service.findAllProcessStreamsBySiteCodes().subscribe((response: ProcessStream[]) => {
				this.processStreams = response;
			});
		}
	}

	onView(id) {
		console.log('OnView: id' + id);
		const currentPage = this.currentPage;
		const currentPageSize = this.currentPageSize;
		this.router.navigate(['create-process-stream/view/' + id + '/' + currentPage + '/' + currentPageSize]);
	}

	onEdit(rowData) {
		console.log('On Edit: id' + rowData.id);
		const currentPage = this.currentPage;
		const currentPageSize = this.currentPageSize;
		this.processStreamService.findAllReqStatusByProcessStreamId(rowData.id, rowData.isPrimaryStream).subscribe(value => {
			if ((value.length === 1 && value[0] === 'DRAFT') || (value.length > 1 && value.includes('DRAFT') && !value.includes('REJECTED'))) {
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '50%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'},
					data: {
						'message': 'ProcessStreamEditConfirmBoxForDraft'
					}
				});
				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.router.navigate(['create-process-stream/edit/' + rowData.id + '/' + currentPage + '/' + currentPageSize]);
						}
					}
				});
			} else if ((value.length === 1 && value[0] === 'REJECTED') || (value.length > 1 && !value.includes('DRAFT') && value.includes('REJECTED'))) {
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '50%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'},
					data: {
						'message': 'displaySupportProcessStreamCreateForReject'
					}
				});
				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.router.navigate(['create-process-stream/edit/' + rowData.id + '/' + currentPage + '/' + currentPageSize]);
						}
					}
				});
			} else if (value.length > 1 && value.includes('DRAFT') && value.includes('REJECTED')) {
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '50%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'},
					data: {
						'message': 'displaySupportProcessStreamCreateForDraftAndReject'
					}
				});
				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.router.navigate(['create-process-stream/edit/' + rowData.id + '/' + currentPage + '/' + currentPageSize]);
						}
					}
				});
			} else if (value.length >= 1 && !value.includes('DRAFT') && !value.includes('REJECTED')) {
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '50%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'},
					data: {
						'message': 'ProcessStreamEditorDeleteConfirmBoxForNonDraft'
					}
				});
				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.router.navigate(['create-process-stream/edit/' + rowData.id + '/' + currentPage + '/' + currentPageSize]);
						}
					}
				});
			} else {
				this.router.navigate(['create-process-stream/edit/' + rowData.id + '/' + currentPage + '/' + currentPageSize]);
			}
		});
	}

	onDelete(rowData) {
		if (rowData.isPrimaryStream) {
			this.processStreamService.findAllReqStatusByProcessStreamId(rowData.id, rowData.isPrimaryStream).subscribe(value => {
				if ((value.length === 1 && value[0] === 'DRAFT') || (value.length > 1 && value.includes('DRAFT') && !value.includes('REJECTED'))) {
					const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '50%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'},
						data: {
							'message': 'PriProcessStreamDeleteConfirmBoxForDraft'
						}
					});
					deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
						if (deleteModel != null) {
							if (deleteModel.isDeleted) {
								this.onDeleteSuccessResponseFromDiag(rowData);
							}
						}
					});
				} else if ((value.length === 1 && value[0] === 'REJECTED') || (value.length > 1 && !value.includes('DRAFT') && value.includes('REJECTED'))) {
					const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '50%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'},
						data: {
							'message': 'PriProcessStreamDeleteConfirmBoxForReject'
						}
					});
					deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
						if (deleteModel != null) {
							if (deleteModel.isDeleted) {
								this.onDeleteSuccessResponseFromDiag(rowData);
							}
						}
					});
				} else if (value.length > 1 && value.includes('DRAFT') && value.includes('REJECTED')) {
					const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '50%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'},
						data: {
							'message': 'PriProcessStreamDeleteConfirmBoxForDraftAndReject'
						}
					});
					deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
						if (deleteModel != null) {
							if (deleteModel.isDeleted) {
								this.onDeleteSuccessResponseFromDiag(rowData);
							}
						}
					});
				} else if (value.length >= 1 && !value.includes('DRAFT') && !value.includes('REJECTED')) {
					const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '50%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'},
						data: {
							'message': 'PriProcessStreamDeleteConfirmBoxForNonDraft'
						}
					});
					deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
						if (deleteModel != null) {
							if (deleteModel.isDeleted) {
								this.onDeleteSuccessResponseFromDiag(rowData);
							}
						}
					});
				} else {
					const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '40%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'},
						data: {
							'message': 'PrimaryProcessStreamStandardDelete'
						}
					});
					deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
						if (deleteModel != null) {
							if (deleteModel.isDeleted) {
								this.onDeleteSuccessResponseFromDiag(rowData);
							}
						}
					});
				}
			});
		} else {
			this.processStreamService.findAllReqStatusByProcessStreamId(rowData.id, rowData.isPrimaryStream).subscribe(value => {
				if ((value.length === 1 && value[0] === 'DRAFT') || (value.length > 1 && value.includes('DRAFT') && !value.includes('REJECTED'))) {
					const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '50%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'},
						data: {
							'message': 'SupProcessStreamDeleteConfirmBoxForDraft'
						}
					});
					deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
						if (deleteModel != null) {
							if (deleteModel.isDeleted) {
								this.onDeleteSuccessResponseFromDiag(rowData);
							}
						}
					});
				} else if ((value.length === 1 && value[0] === 'REJECTED') || (value.length > 1 && !value.includes('DRAFT') && value.includes('REJECTED'))) {
					const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '50%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'},
						data: {
							'message': 'SupProcessStreamDeleteConfirmBoxForReject'
						}
					});
					deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
						if (deleteModel != null) {
							if (deleteModel.isDeleted) {
								this.onDeleteSuccessResponseFromDiag(rowData);
							}
						}
					});
				} else if (value.length > 1 && value.includes('DRAFT') && value.includes('REJECTED')) {
					const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '50%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'},
						data: {
							'message': 'SupProcessStreamDeleteConfirmBoxForDraftAndReject'
						}
					});
					deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
						if (deleteModel != null) {
							if (deleteModel.isDeleted) {
								this.onDeleteSuccessResponseFromDiag(rowData);
							}
						}
					});
				} else if (value.length >= 1 && !value.includes('DRAFT') && !value.includes('REJECTED')) {
					const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '50%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'},
						data: {
							'message': 'SupProcessStreamDeleteConfirmBoxForNonDraft'
						}
					});
					deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
						if (deleteModel != null) {
							if (deleteModel.isDeleted) {
								this.onDeleteSuccessResponseFromDiag(rowData);
							}
						}
					});
				} else {
					const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '40%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'},
						data: {
							'message': 'SupportProcessStreamStandardDelete'
						}
					});
					deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
						if (deleteModel != null) {
							if (deleteModel.isDeleted) {
								this.onDeleteSuccessResponseFromDiag(rowData);
							}
						}
					});
				}
			});
		}
	}

	private onDeleteSuccessResponseFromDiag(rowData) {
		console.log('Deleting processStream id:- ' + rowData.id);
		this.processStreamService.getOriginStreamIdBySuppStreamId(rowData.id).subscribe(originId => {
			this.processStreamService.deleteProcessStreamTreeForSelectedStreamId(rowData.id).subscribe(deleteRes => {
				sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
					'status': 'success',
					'message': 'Process Stream is deleted successfully!'
				}));
				window.scrollTo(0, 0);
				setTimeout(() => {
					this.displayMessage = [];
				}, 5000);
				this.checkAndShowSuccessMsg('info');
				//this.findAllProcessStreams();
				this.findAllByPaging(this.currentPage, this.currentPageSize);
				let params = new HttpParams().set('primaryStreamId', rowData.isPrimaryStream ? rowData.id.toString() : originId.toString())
					.set('isPrimary', rowData.isPrimaryStream.toString())
					.set('isChanged', 'delete');
				if (!rowData.isPrimaryStream) {
					params = params.set('supportingStreamId', rowData.id.toString());
				}
				this.carRequestService.updatePSChangedFlag(params).subscribe(res => {
					console.log('PSChanged Flag is updated successfully');
				});
			}, error => {
				console.log('Error on delete');
			});
		}, error => {
			console.log('Error on finding Origin Stream');
		});
	}

	onCreate() {
		this.router.navigate(['create-process-stream/create/0/0/10']);
	}

	private findAllPolicies() {
		this.policies = JSON.parse(sessionStorage.getItem('policies'));
		const policyName = 'eCAR:ManageProcessStream';
		this.disableCreateButton = this.utilService.checkPermittedUpdatePolicy(this.policies, policyName) ? false : true;
		this.disableDeleteButton = this.utilService.checkPermittedDeletePolicy(this.policies, policyName) ? false : true;

	}

	loadProcessStreamLazy(event: LazyLoadEvent) {
		this.selectedPage = this.pageNo * this.pageSize;
		this.currentPage = event.first / event.rows;
		this.currentPageSize = event.rows;
		if (this.pageNo > 0 && this.currentPage === 0 && !this.initialLoad) {
			this.initialLoad = true;
			this.selectedPage = this.pageNo * this.pageSize;
			this.currentPage = this.pageNo;
		}
		if (this.pageSize === 0 || this.pageSize === 10 || event.rows === 10) {
			this.currentPageSize = 10;
		}
		if (this.pageSize === 25 || event.rows === 25) {
			this.currentPageSize = 25;
		}
		if (this.pageSize === 50 || event.rows === 50) {
			this.currentPageSize = 50;
		}
		if (this.pageSize > 50 || event.rows > 50) {
			this.currentPageSize = 100;
		}
		const sortingParam = [];
		if (event.sortField) {
			if (event.sortField === 'processStreamName') {
				if (event.sortOrder < 0) {
					sortingParam.push('processName' + ',asc');
				} else {
					sortingParam.push('processName' + ',desc');
				}
			} else {
				if (event.sortOrder < 0) {
					sortingParam.push(event.sortField + ',asc');
				} else {
					sortingParam.push(event.sortField + ',desc');
				}
			}
		}
		const filters = [];
		for (const field of Object.keys(event.filters)) {
			filters.push(field + ':' + event.filters[field].value);
		}
		this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
		this.checkPrimeUser(sessionStorage.getItem('userId'), this.apsAttributes.userRole, this.currentPage, this.currentPageSize, sortingParam, filters);
	}

	private findAllByPaging(first, size, sort?: String[], filters?: String[]) {
		let uRole = this.apsAttributes.userRole;
		if (this.isPrimeUser) {
			uRole = 'Prime User';
			this.siteCodes = 'hi';
		} else if (uRole.toLowerCase() === 'ecar_read' || uRole.toLowerCase() === 'ecar_update' || uRole.includes('Developer') || uRole.includes('SiteEngineer') || uRole.includes('Supervisor') || uRole.includes('Manager')) {
			if (uRole.toLowerCase() === 'ecar_update' || uRole.toLowerCase() === 'ecar_read') {
				this.siteCodes = this.apsAttributes.siteCodes.map(site => site).join('-');
			} else {
				this.siteCodes = 'hi';//this.apsAttributes.siteCodes.map(site => site).join('-');
			}
		} else {
			this.siteCodes = 'hi';
		}
		this.service.findAllProcessStream({
			page: first,
			size: size,
			sort: sort,
			filters: filters,
			userRole: uRole,
			siteCodes: this.siteCodes
		}).subscribe((pageableProcessStreams: PagingHelper) => {
			if (pageableProcessStreams.content) {
				this.processStreams = pageableProcessStreams.content;
				this.totalProcessStreamRecordPages = pageableProcessStreams.totalElements;
				this.pageDetailsFrom = ((pageableProcessStreams.pageable.pageNumber + 1) - 1) * pageableProcessStreams.pageable.pageSize + 1;
				this.pageDetailsTo = (pageableProcessStreams.pageable.pageNumber + 1) * pageableProcessStreams.pageable.pageSize;
				if (this.pageDetailsTo > this.totalProcessStreamRecordPages) {
					this.pageDetailsTo = this.totalProcessStreamRecordPages;
				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	onCopy(rowData: any) {
		console.log('selectedProcessStreamId : ' + rowData.id);
		if (!rowData.isPrimaryStream) {
			this.processStreamService.findAllReqStatusByProcessStreamId(rowData.id, rowData.isPrimaryStream).subscribe(value => {
				if ((value.length === 1 && value[0] === 'DRAFT') || (value.length > 1 && value.includes('DRAFT') && !value.includes('REJECTED'))) {
					this.copyConfirmBox(rowData, 'onlyDraft');
				} else if ((value.length === 1 && value[0] === 'REJECTED') || (value.length > 1 && !value.includes('DRAFT') && value.includes('REJECTED'))) {
					this.copyConfirmBox(rowData, 'onlyReject');
				} else if (value.length > 1 && value.includes('DRAFT') && value.includes('REJECTED')) {
					this.copyConfirmBox(rowData, 'onlyDraftandReject');
				} else if (value.length >= 1 && !value.includes('DRAFT') && !value.includes('REJECTED')) {
					this.copyConfirmBox(rowData, 'nondraft');
				} else {
					this.copyConfirmBox(rowData, 'none');
				}
			});
		} else {
			this.copyConfirmBox(rowData, 'none');
		}
	}

	private checkPrimeUser(userId: string, userRole: string, first, size, sort?: String[], filters?: String[]) {
		if (userRole.includes('Manager') || userRole.includes('Engineer') || userRole.includes('Supervisor')) {
			this.carRequestService.isPrimeUser(userId).subscribe(isValid => {
				if (isValid) {
					this.isPrimeUser = true;
					this.findAllByPaging(first, size, sort, filters);
				} else {
					this.isPrimeUser = false;
					this.findAllByPaging(first, size, sort, filters);
				}
			});
		} else {
			this.isPrimeUser = false;
			this.findAllByPaging(first, size, sort, filters);
		}
	}

	private copyConfirmBox(rowData: any, eCarsStatus: string) {
		if (eCarsStatus === 'none') {
			const dialogueRefCopy = this.dialogService.open(OndeleteDialogueboxComponent, {
				header: 'Confirmation',
				width: '50%',
				contentStyle: {'max-height': '700px', 'overflow': 'auto'},
				data: {
					'message': 'copyProcessStreamDialogPrimary'
				}
			});
			dialogueRefCopy.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
				if (deleteModel != null) {
					if (deleteModel.isCopy) {
						if (rowData.processStreamName.includes('Copied (please re-name)')) {
							sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
								'status': 'success',
								'message': 'Error : This process stream cannot be copied until it is renamed.'
							}));
							window.scrollTo(0, 0);
							setTimeout(() => {
								this.displayMessage = [];
							}, 10000);
							this.checkAndShowSuccessMsg('error');
						} else {
							this.processStreamService.copySelectedProcessStream(rowData.id, deleteModel.selectedCopyOption).subscribe(response => {
								if (response) {
									sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
										'status': 'success',
										'message': 'Selected process streams copied successfully'
									}));
									window.scrollTo(0, 0);
									setTimeout(() => {
										this.displayMessage = [];
									}, 5000);
									this.checkAndShowSuccessMsg('info');
									this.findAllByPaging(this.currentPage, this.currentPageSize);
								}
								if (!response) {
									sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
										'status': 'success',
										'message': 'Error : This process stream cannot be copied until the connected streams/already copied streams have been renamed (where applicable).'
									}));
									window.scrollTo(0, 0);
									setTimeout(() => {
										this.displayMessage = [];
									}, 10000);
									this.checkAndShowSuccessMsg('error');
								}
							}, error => {
								this.utilService.pushMsg('error', 'Error', 'Copy operation failed, please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
							});
						}
					} else {
						console.log('Do not Copy response from dialogue box');
					}
				}
			});
		} else if (eCarsStatus === 'onlyDraft') {
			const dialogueRefCopy = this.dialogService.open(OndeleteDialogueboxComponent, {
				header: 'Confirmation',
				width: '50%',
				contentStyle: {'max-height': '700px', 'overflow': 'auto'},
				data: {
					'message': 'copyProcessStreamDialogSupportWithDraft'
				}
			});
			dialogueRefCopy.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
				if (deleteModel != null) {
					if (deleteModel.isCopy) {
						if (rowData.processStreamName.includes('Copied (please re-name)')) {
							sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
								'status': 'success',
								'message': 'Error : This process stream cannot be copied until it is renamed.'
							}));
							window.scrollTo(0, 0);
							setTimeout(() => {
								this.displayMessage = [];
							}, 10000);
							this.checkAndShowSuccessMsg('error');
						} else {
							this.processStreamService.copySelectedProcessStream(rowData.id, deleteModel.selectedCopyOption).subscribe(response => {
								if (response) {
									sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
										'status': 'success',
										'message': 'Selected process streams copied successfully'
									}));
									window.scrollTo(0, 0);
									setTimeout(() => {
										this.displayMessage = [];
									}, 5000);
									this.checkAndShowSuccessMsg('info');
									this.findAllByPaging(this.currentPage, this.currentPageSize);
								}
								if (!response) {
									sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
										'status': 'success',
										'message': 'Error : This process stream cannot be copied until the connected streams/already copied streams have been renamed (where applicable).'
									}));
									window.scrollTo(0, 0);
									setTimeout(() => {
										this.displayMessage = [];
									}, 10000);
									this.checkAndShowSuccessMsg('error');
								}
							}, error => {
								this.utilService.pushMsg('error', 'Error', 'Copy operation failed, please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
							});
						}
					} else {
						console.log('Do not Copy response from dialogue box');
					}
				}
			});
		} else if (eCarsStatus === 'onlyReject') {
			const dialogueRefCopy = this.dialogService.open(OndeleteDialogueboxComponent, {
				header: 'Confirmation',
				width: '50%',
				contentStyle: {'max-height': '700px', 'overflow': 'auto'},
				data: {
					'message': 'copyProcessStreamDialogSupportWithReject'
				}
			});
			dialogueRefCopy.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
				if (deleteModel != null) {
					if (deleteModel.isCopy) {
						if (rowData.processStreamName.includes('Copied (please re-name)')) {
							sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
								'status': 'success',
								'message': 'Error : This process stream cannot be copied until it is renamed.'
							}));
							window.scrollTo(0, 0);
							setTimeout(() => {
								this.displayMessage = [];
							}, 10000);
							this.checkAndShowSuccessMsg('error');
						} else {
							this.processStreamService.copySelectedProcessStream(rowData.id, deleteModel.selectedCopyOption).subscribe(response => {
								if (response) {
									sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
										'status': 'success',
										'message': 'Selected process streams copied successfully'
									}));
									window.scrollTo(0, 0);
									setTimeout(() => {
										this.displayMessage = [];
									}, 5000);
									this.checkAndShowSuccessMsg('info');
									this.findAllByPaging(this.currentPage, this.currentPageSize);
								}
								if (!response) {
									sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
										'status': 'success',
										'message': 'Error : This process stream cannot be copied until the connected streams/already copied streams have been renamed (where applicable).'
									}));
									window.scrollTo(0, 0);
									setTimeout(() => {
										this.displayMessage = [];
									}, 10000);
									this.checkAndShowSuccessMsg('error');
								}
							}, error => {
								this.utilService.pushMsg('error', 'Error', 'Copy operation failed, please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
							});
						}
					} else {
						console.log('Do not Copy response from dialogue box');
					}
				}
			});
		} else if (eCarsStatus === 'onlyDraftandReject') {
			const dialogueRefCopy = this.dialogService.open(OndeleteDialogueboxComponent, {
				header: 'Confirmation',
				width: '50%',
				contentStyle: {'max-height': '700px', 'overflow': 'auto'},
				data: {
					'message': 'copyProcessStreamDialogSupportWithDraftAndReject'
				}
			});
			dialogueRefCopy.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
				if (deleteModel != null) {
					if (deleteModel.isCopy) {
						if (rowData.processStreamName.includes('Copied (please re-name)')) {
							sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
								'status': 'success',
								'message': 'Error : This process stream cannot be copied until it is renamed.'
							}));
							window.scrollTo(0, 0);
							setTimeout(() => {
								this.displayMessage = [];
							}, 10000);
							this.checkAndShowSuccessMsg('error');
						} else {
							this.processStreamService.copySelectedProcessStream(rowData.id, deleteModel.selectedCopyOption).subscribe(response => {
								if (response) {
									sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
										'status': 'success',
										'message': 'Selected process streams copied successfully'
									}));
									window.scrollTo(0, 0);
									setTimeout(() => {
										this.displayMessage = [];
									}, 5000);
									this.checkAndShowSuccessMsg('info');
									this.findAllByPaging(this.currentPage, this.currentPageSize);
								}
								if (!response) {
									sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
										'status': 'success',
										'message': 'Error : This process stream cannot be copied until the connected streams/already copied streams have been renamed (where applicable).'
									}));
									window.scrollTo(0, 0);
									setTimeout(() => {
										this.displayMessage = [];
									}, 10000);
									this.checkAndShowSuccessMsg('error');
								}
							}, error => {
								this.utilService.pushMsg('error', 'Error', 'Copy operation failed, please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
							});
						}
					} else {
						console.log('Do not Copy response from dialogue box');
					}
				}
			});
		} else if (eCarsStatus === 'nondraft') {
			const dialogueRefCopy = this.dialogService.open(OndeleteDialogueboxComponent, {
				header: 'Confirmation',
				width: '50%',
				contentStyle: {'max-height': '700px', 'overflow': 'auto'},
				data: {
					'message': 'copyProcessStreamDialogSupportWithNonDraft'
				}
			});
			dialogueRefCopy.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
				if (deleteModel != null) {
					if (deleteModel.isCopy) {
						if (rowData.processStreamName.includes('Copied (please re-name)')) {
							sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
								'status': 'success',
								'message': 'Error : This process stream cannot be copied until it is renamed.'
							}));
							window.scrollTo(0, 0);
							setTimeout(() => {
								this.displayMessage = [];
							}, 10000);
							this.checkAndShowSuccessMsg('error');
						} else {
							this.processStreamService.copySelectedProcessStream(rowData.id, deleteModel.selectedCopyOption).subscribe(response => {
								if (response) {
									sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
										'status': 'success',
										'message': 'Selected process streams copied successfully'
									}));
									window.scrollTo(0, 0);
									setTimeout(() => {
										this.displayMessage = [];
									}, 5000);
									this.checkAndShowSuccessMsg('info');
									this.findAllByPaging(this.currentPage, this.currentPageSize);
								}
								if (!response) {
									sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
										'status': 'success',
										'message': 'Error : This process stream cannot be copied until the connected streams/already copied streams have been renamed (where applicable).'
									}));
									window.scrollTo(0, 0);
									setTimeout(() => {
										this.displayMessage = [];
									}, 10000);
									this.checkAndShowSuccessMsg('error');
								}
							}, error => {
								this.utilService.pushMsg('error', 'Error', 'Copy operation failed, please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
							});
						}
					} else {
						console.log('Do not Copy response from dialogue box');
					}
				}
			});
		}
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.pageNo = Number(params.get('pageNo'));
			this.pageSize = Number(params.get('pageSize'));
		});
	}

	supportStreamCheckBeforeCopy(rowData: any) {
		this.processStreamService.isSupProcessStreamsPresentForSelectedStreamId(rowData.id).subscribe(result => {
			if (result) {
				this.onCopy(rowData);
			} else {
				if (rowData.isPrimaryStream) {
					this.copyProcessStream(rowData, 1);
				} else {
					this.processStreamService.findAllReqStatusByProcessStreamId(rowData.id, rowData.isPrimaryStream).subscribe(value => {
						if ((value.length === 1 && value[0] === 'DRAFT') || (value.length > 1 && value.includes('DRAFT') && !value.includes('REJECTED'))) {
							const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
								header: 'Confirmation',
								width: '50%',
								contentStyle: {'max-height': '500px', 'overflow': 'auto'},
								data: {
									'message': 'copyProcessStreamDialogSupportWithDraft'
								}
							});
							deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
								if (deleteModel != null) {
									if (deleteModel.isCopy) {
										this.copyProcessStream(rowData, 2);
									}
								}
							});
						} else if ((value.length === 1 && value[0] === 'REJECTED') || (value.length > 1 && !value.includes('DRAFT') && value.includes('REJECTED'))) {
							const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
								header: 'Confirmation',
								width: '50%',
								contentStyle: {'max-height': '500px', 'overflow': 'auto'},
								data: {
									'message': 'copyProcessStreamDialogSupportWithReject'
								}
							});
							deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
								if (deleteModel != null) {
									if (deleteModel.isCopy) {
										this.copyProcessStream(rowData, 2);
									}
								}
							});
						} else if (value.length > 1 && value.includes('DRAFT') && value.includes('REJECTED')) {
							const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
								header: 'Confirmation',
								width: '50%',
								contentStyle: {'max-height': '500px', 'overflow': 'auto'},
								data: {
									'message': 'copyProcessStreamDialogSupportWithDraftAndReject'
								}
							});
							deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
								if (deleteModel != null) {
									if (deleteModel.isCopy) {
										this.copyProcessStream(rowData, 2);
									}
								}
							});
						} else if (value.length >= 1 && !value.includes('DRAFT') && !value.includes('REJECTED')) {
							const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
								header: 'Confirmation',
								width: '50%',
								contentStyle: {'max-height': '500px', 'overflow': 'auto'},
								data: {
									'message': 'copyProcessStreamDialogSupportWithNonDraft'
								}
							});
							deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
								if (deleteModel != null) {
									if (deleteModel.isCopy) {
										this.copyProcessStream(rowData, 2);
									}
								}
							});
						} else {
							this.copyProcessStream(rowData, 2);
						}
					});
				}
			}
		});
	}

	copyProcessStream(rowData: any, selectedOption: any) {
		if (rowData.processStreamName.includes('Copied (please re-name)')) {
			sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
				'status': 'success',
				'message': 'Error : This process stream cannot be copied until it is renamed.'
			}));
			window.scrollTo(0, 0);
			setTimeout(() => {
				this.displayMessage = [];
			}, 10000);
			this.checkAndShowSuccessMsg('error');
		} else {
			this.processStreamService.copySelectedProcessStream(rowData.id, selectedOption).subscribe(response => {
				if (response) {
					sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
						'status': 'success',
						'message': 'Selected process streams copied successfully'
					}));
					window.scrollTo(0, 0);
					setTimeout(() => {
						this.displayMessage = [];
					}, 5000);
					this.checkAndShowSuccessMsg('info');
					this.findAllByPaging(this.currentPage, this.currentPageSize);
				}
				if (!response) {
					sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
						'status': 'success',
						'message': 'Error : This process stream cannot be copied until the connected streams/already copied streams have been renamed (where applicable).'
					}));
					window.scrollTo(0, 0);
					setTimeout(() => {
						this.displayMessage = [];
					}, 10000);
					this.checkAndShowSuccessMsg('error');
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Copy operation failed, please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}
}
