import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/api';
import {emptyOnDeleteDisplayModel, OnDeleteDisplayDialogueModel} from '../models/on-delete-display-dialogue-model';

@Component({
	selector: 'ondelete-dialoguebox',
	templateUrl: './ondelete-dialoguebox.component.html',
	styleUrls: ['./ondelete-dialoguebox.component.css']
})
export class OndeleteDialogueboxComponent implements OnInit {
	private readonly params;
	standardDialog = false;
	displayMasterSLProcessDataDialog = false;
	displaySLLinkedWithECARDialog = false;
	displaySupportingStreamDialog = false;
	displaySLPReviewPartsForCopy = false;
	displayCapacityStudyCarReq = false;
	displayCapacityStudyForView = false;
	displayCapacityStudyForEdit = false;
	displayCapacityStudyForViewInMSL = false;
	displayCapacityStudyForEditInMSL = false;
	displayHeaderForEmptyCapacitySource = false;
	copyProcessStreamDialogPrimary = false;
	copyProcessStreamDialogSupportWithDraft = false;
	copyProcessStreamDialogSupportWithNonDraft = false;
	displaySLPExistForTheProcess = false;
	displayHeaderForProcessStreamSourceGotAffected = false;
	displayProcessStreamEditForDraft = false;
	displayProcessStreamDeleteForDraft = false;
	displayProcessStreamEditorDeleteForNonDraft = false;
	displayProcessStreamCreateForDRAFT = false;
	displayProcessStreamCreateForREJECT = false;
	displayProcessStreamCreateForDRAFTandREJECT = false;
	displayPrimaryStandardDeleteDialog = false;
	displaySupportStandardDeleteDialog = false;
	copyProcessStreamDialogSupportWithReject = false;
	copyProcessStreamDialogSupportWithDraftAndReject = false;

	PriProcessStreamDeleteConfirmBoxForDraft = false;
	PriProcessStreamDeleteConfirmBoxForReject = false;
	PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
	PriProcessStreamDeleteConfirmBoxForNonDraft = false;
	SupProcessStreamDeleteConfirmBoxForDraft = false;
	SupProcessStreamDeleteConfirmBoxForReject = false;
	SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
	SupProcessStreamDeleteConfirmBoxForNonDraft = false;

	constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
		this.params = this.config.data;
		console.log(this.params);
	}

	deleteRes: OnDeleteDisplayDialogueModel = emptyOnDeleteDisplayModel;
	enableProceed = false;

	ngOnInit() {
		if (this.params) {
			if (this.params.message === 'SLProcessData') {
				this.displayMasterSLProcessDataDialog = true;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayCapacityStudyCarReq = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displaySLPExistForTheProcess = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
				console.log('displayMasterSLProcessDataDialog to true');
			} else if (this.params.message === 'eCARLinkedSL') {
				this.displaySLLinkedWithECARDialog = true;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayCapacityStudyCarReq = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displaySLPExistForTheProcess = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
				console.log('displayMasterSLProcessDataDialog to true');
			} else if (this.params.message === 'SupStreamConfirmationOnDelete') {
				this.displaySupportingStreamDialog = true;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayCapacityStudyCarReq = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displaySLPExistForTheProcess = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'SLPReviewPartsForCopy') {
				this.displaySLPReviewPartsForCopy = true;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displayCapacityStudyCarReq = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displaySLPExistForTheProcess = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'CapacityStudyAlertCarReq') {
				this.displayCapacityStudyCarReq = true;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayCapacityStudyForEdit = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displaySLPExistForTheProcess = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'CapacityStudyAlertOnView') {
				this.displayCapacityStudyForView = true;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayCapacityStudyCarReq = false;
				this.displayCapacityStudyForEdit = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displaySLPExistForTheProcess = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'CapacityStudyAlertOnEdit') {
				this.displayCapacityStudyForEdit = true;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayCapacityStudyCarReq = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displaySLPExistForTheProcess = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'CapacityStudyAlertOnViewInMSL') {
				this.displayCapacityStudyForViewInMSL = true;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displaySLPExistForTheProcess = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'CapacityStudyAlertOnEditInMSL') {
				this.displayCapacityStudyForEditInMSL = true;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displaySLPExistForTheProcess = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'CapacitySourceAlertOnEditeCAR') {
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = true;
				this.copyProcessStreamDialogPrimary = false;
				this.displaySLPExistForTheProcess = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'copyProcessStreamDialogPrimary') {
				this.copyProcessStreamDialogPrimary = true;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.displaySLPExistForTheProcess = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.standardDialog = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'copyProcessStreamDialogSupportWithDraft') {
				this.copyProcessStreamDialogSupportWithDraft = true;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.displaySLPExistForTheProcess = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.standardDialog = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'copyProcessStreamDialogSupportWithNonDraft') {
				this.copyProcessStreamDialogSupportWithNonDraft = true;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.displaySLPExistForTheProcess = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.standardDialog = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'SLPAlreadyExistForTheProcess') {
				this.displaySLPExistForTheProcess = true;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'ProcessStreamSourceGotAffected') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = true;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'ProcessStreamEditConfirmBoxForDraft') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = true;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'ProcessStreamDeleteConfirmBoxForDraft') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = true;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'ProcessStreamEditorDeleteConfirmBoxForNonDraft') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = true;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'displaySupportProcessStreamCreateForDraft') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = true;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'displaySupportProcessStreamCreateForReject') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = true;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'displaySupportProcessStreamCreateForDraftAndReject') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = true;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'PrimaryProcessStreamStandardDelete') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = true;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'SupportProcessStreamStandardDelete') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = true;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'copyProcessStreamDialogSupportWithReject') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = true;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'copyProcessStreamDialogSupportWithDraftAndReject') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = true;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'PriProcessStreamDeleteConfirmBoxForDraft') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = true;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'PriProcessStreamDeleteConfirmBoxForReject') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = true;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'PriProcessStreamDeleteConfirmBoxForDraftAndReject') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = true;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'PriProcessStreamDeleteConfirmBoxForNonDraft') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = true;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'SupProcessStreamDeleteConfirmBoxForDraft') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = true;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'SupProcessStreamDeleteConfirmBoxForReject') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = true;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'SupProcessStreamDeleteConfirmBoxForDraftAndReject') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = true;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			} else if (this.params.message === 'SupProcessStreamDeleteConfirmBoxForNonDraft') {
				this.displaySLPExistForTheProcess = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.standardDialog = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = true;
			} else {
				this.standardDialog = true;
				this.displayMasterSLProcessDataDialog = false;
				this.displaySLLinkedWithECARDialog = false;
				this.displaySupportingStreamDialog = false;
				this.displaySLPReviewPartsForCopy = false;
				this.displayCapacityStudyCarReq = false;
				this.displayCapacityStudyForView = false;
				this.displayCapacityStudyForEdit = false;
				this.displayCapacityStudyForViewInMSL = false;
				this.displayCapacityStudyForEditInMSL = false;
				this.displayHeaderForEmptyCapacitySource = false;
				this.copyProcessStreamDialogPrimary = false;
				this.displaySLPExistForTheProcess = false;
				this.displayHeaderForProcessStreamSourceGotAffected = false;
				this.displayProcessStreamEditForDraft = false;
				this.displayProcessStreamDeleteForDraft = false;
				this.displayProcessStreamEditorDeleteForNonDraft = false;
				this.copyProcessStreamDialogSupportWithDraft = false;
				this.copyProcessStreamDialogSupportWithNonDraft = false;
				this.displayProcessStreamCreateForDRAFT = false;
				this.displayProcessStreamCreateForREJECT = false;
				this.displayProcessStreamCreateForDRAFTandREJECT = false;
				this.displayPrimaryStandardDeleteDialog = false;
				this.displaySupportStandardDeleteDialog = false;
				this.copyProcessStreamDialogSupportWithReject = false;
				this.copyProcessStreamDialogSupportWithDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraft = false;
				this.PriProcessStreamDeleteConfirmBoxForReject = false;
				this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForDraft = false;
				this.SupProcessStreamDeleteConfirmBoxForReject = false;
				this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
				this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
			}
		} else {
			this.standardDialog = true;
			this.displayMasterSLProcessDataDialog = false;
			this.displaySLLinkedWithECARDialog = false;
			this.displaySupportingStreamDialog = false;
			this.displaySLPReviewPartsForCopy = false;
			this.displayCapacityStudyCarReq = false;
			this.displayCapacityStudyForView = false;
			this.displayCapacityStudyForEdit = false;
			this.displayCapacityStudyForViewInMSL = false;
			this.displayCapacityStudyForEditInMSL = false;
			this.displayHeaderForEmptyCapacitySource = false;
			this.copyProcessStreamDialogPrimary = false;
			this.displaySLPExistForTheProcess = false;
			this.displayHeaderForProcessStreamSourceGotAffected = false;
			this.displayProcessStreamEditForDraft = false;
			this.displayProcessStreamDeleteForDraft = false;
			this.displayProcessStreamEditorDeleteForNonDraft = false;
			this.copyProcessStreamDialogSupportWithDraft = false;
			this.copyProcessStreamDialogSupportWithNonDraft = false;
			this.displayProcessStreamCreateForDRAFT = false;
			this.displayProcessStreamCreateForREJECT = false;
			this.displayProcessStreamCreateForDRAFTandREJECT = false;
			this.displayPrimaryStandardDeleteDialog = false;
			this.displaySupportStandardDeleteDialog = false;
			this.copyProcessStreamDialogSupportWithReject = false;
			this.copyProcessStreamDialogSupportWithDraftAndReject = false;
			this.PriProcessStreamDeleteConfirmBoxForDraft = false;
			this.PriProcessStreamDeleteConfirmBoxForReject = false;
			this.PriProcessStreamDeleteConfirmBoxForDraftAndReject = false;
			this.PriProcessStreamDeleteConfirmBoxForNonDraft = false;
			this.SupProcessStreamDeleteConfirmBoxForDraft = false;
			this.SupProcessStreamDeleteConfirmBoxForReject = false;
			this.SupProcessStreamDeleteConfirmBoxForDraftAndReject = false;
			this.SupProcessStreamDeleteConfirmBoxForNonDraft = false;
		}
	}

	delete() {
		this.deleteRes.isDeleted = true;
		this.deleteRes.message = 'Delete this field';
		this.ref.close(this.deleteRes);
	}

	dontDelete() {
		this.deleteRes.isDeleted = false;
		this.deleteRes.message = 'Dont delete this field';
		this.ref.close(this.deleteRes);
	}

	selectedOption(choice: boolean) {
		this.enableProceed = true;
		if (choice === true) {
			this.deleteRes.selectedCopyOption = 1;
		} else {
			this.deleteRes.selectedCopyOption = 2;
		}
	}

	copyStream() {
		console.log('selectedSteamOption : ' + this.deleteRes.selectedCopyOption);
		if (this.deleteRes.selectedCopyOption === 1 || this.deleteRes.selectedCopyOption === 2) {
			this.deleteRes.isCopy = true;
		} else {
			this.deleteRes.isCopy = false;
		}
		this.ref.close(this.deleteRes);
	}

	cancelCopy() {
		this.deleteRes.isCopy = false;
		this.ref.close(this.deleteRes);
	}
}
