import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MenuItem} from 'primeng/primeng';
import {UrlConsumerService} from '../oauth/url-consumer.service';
import {Policies} from '../models/aps-policies';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ApsAttributes} from '../models/aps-attributes';
import {CarRequestService} from '../services/request/car-request.service';

/** Angular component that utilizes the PrimeNG p-menubar component
 * to display the navigation menu for the application
 */
@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
	loggedInUserId: string;
	policies: Policies;
	attributes: ApsAttributes;
	routerLinkForApprovalFlow: string;
	/** Emits an event when the menu is closed when it is in hamburger menu mode */
	@Output() closeClicked = new EventEmitter<boolean>();
	/** Array of PrimeNG MenuItems that are used to populate the p-menubar */
	navMenuItems: MenuItem[];
	showNavBar = true;
	isReportingVisible: boolean;
	rolesToHideReporting = ['ECAR_READ', 'ECAR_UPDATE', 'JV_Buyer', 'JV Purchasing Management'];
	reportingUrl = 'https://www.sharedanalyticsweb.ford.com/sense/app/83331a0e-9c9c-43c9-af9e-2f94410d0a71/sheet/94154f48-1211-468f-b80f-ca615965a960/state/analysis';
	private destroyed = new Subject<any>();
	/** Anonymous function that is used to close the hamburger menu */
	closeHamMenu = () => {
		this.closeClicked.emit(false);
	};

	/** Create a nav component and populate with necessary MenuItems (static data)*/
	constructor(private urlConsumerService: UrlConsumerService, private router: Router, private carRequestService: CarRequestService) {
	}

	ngOnInit(): void {
		this.loggedInUserId = sessionStorage.getItem('userId');
		if (!this.policies) {
			this.policies = JSON.parse(sessionStorage.getItem('policies'));
			if (this.policies) {
				this.rebuildNavigations();
			}
		}
		this.urlConsumerService.policiesSubject.subscribe(policies => {
			this.policies = policies;
			if (this.policies) {
				this.rebuildNavigations();
			}
		});
		if (this.policies) {
			this.rebuildNavigations();
		}
		if (!this.attributes) {
			this.attributes = JSON.parse(sessionStorage.getItem('attributes'));
		}
		this.urlConsumerService.apsAttributesSubject.subscribe(attributes => {
			this.attributes = attributes;
			if (this.attributes) {
				if (this.attributes.userRole.includes('Engineer')) {
					this.routerLinkForApprovalFlow = '/engineer-approval-flow-conditions';
				} else if (this.attributes.userRole.includes('Supervisor')) {
					this.routerLinkForApprovalFlow = '/supervisor-approval-flow-conditions';
				}
			}
			this.rebuildNavigations();
		});
		if (this.attributes) {
			if (this.attributes.userRole.includes('Engineer')) {
				this.routerLinkForApprovalFlow = '/engineer-approval-flow-conditions';
			} else if (this.attributes.userRole.includes('Supervisor')) {
				this.routerLinkForApprovalFlow = '/supervisor-approval-flow-conditions';
			}
			this.rebuildNavigations();
		}
		if (sessionStorage.getItem('redirectURL') && window.location.href.includes('eprint')) {
			this.showNavBar = false;
		}
	}

	private rebuildNavigations() {
		const isProcessTypeVisible = this.hasAccess('eCAR:DefineProcessType');
		const isDefineProcessVisible = this.hasAccess('eCAR:DefineProcess');
		const isManageProcessStreamVisible = this.hasAccess('eCAR:ManageProcessStream');
		const isHMPVisible = this.hasAccess('eCAR:HMP');
		const isManageSharedLoadingVisible = this.hasAccess('eCAR:ManageSharedLoading');
		const isManageScenarioVisible = this.hasAccess('eCAR:ManageScenario');
		const isManageDeclarationQuestionsVisible = this.hasAccess('eCAR:ManageDeclarationQuestions');
		const isManageBulletinsVisible = this.hasAccess('eCAR:ManageBulletins');
		const isManageApprovalConditions = this.hasAccess('eCAR:ManageApprovalConditions');
		if (!this.loggedInUserId) {
			this.loggedInUserId = sessionStorage.getItem('userId');
		}
		this.carRequestService.isJTypeUser(this.loggedInUserId).subscribe(value => {
			if (value) {
				this.isReportingVisible = false;
			} else {
				this.isReportingVisible = this.attributes && !this.rolesToHideReporting.includes(this.attributes.userRole);
			}
			this.navMenuItems = [
				{
					label: 'Home',
					routerLink: '/home-page',
					visible: true,
					id: 'home',
					command: () => {
						this.refreshHome();
					}
				},
				{
					label: 'Master',
					items: [
						{
							label: 'Define Process Type',
							routerLink: '/process-type-master-page',
							visible: isProcessTypeVisible,
							id: 'processTypeMasterPage'
						},
						{
							label: 'Manage Supplier Processes',
							routerLink: '/process-name-master-page',
							visible: isDefineProcessVisible,
							id: 'processNameMasterPage'
						},
						{
							label: 'Manage Process Stream',
							routerLink: '/manage-process-stream',
							visible: isManageProcessStreamVisible,
							id: 'processStreamMasterPage'
						},
						{
							label: 'Historical Manufacturing Performance',
							routerLink: '/manage-historical-mfgperf',
							visible: isHMPVisible,
							id: 'historicalManufacturingPerformance'
						},
						{
							label: 'Manage Shared Loading',
							routerLink: '/manage-shared-loading',
							visible: isManageSharedLoadingVisible,
							id: 'manageSharedLoading'
						},
						{
							label: 'Manage eCAR Scenario',
							routerLink: '/manage-submission-scenario',
							visible: isManageScenarioVisible,
							id: 'manageecarscenario'
						},
						{
							label: 'Manage Declaration Questions',
							routerLink: '/manage-declaration-questions',
							visible: isManageDeclarationQuestionsVisible,
							id: 'manageDeclarationQuestions'
						},
						{
							label: 'Manage Bulletins',
							routerLink: '/manage-bulletins',
							visible: isManageBulletinsVisible,
							id: 'manageBulletins'
						},
						{
							label: 'Manage My Approvals',
							routerLink: this.routerLinkForApprovalFlow,
							visible: isManageApprovalConditions,
							id: 'manageApprovalConditions'
						}
					]
				},
				{
					label: 'Reporting',
					visible: this.isReportingVisible,
					id: 'reporting',
					url: this.reportingUrl,
					target: '_blank'
				},
				{
					label: 'Help',
					routerLink: '/help-page',
					visible: true,
					id: 'helpPage'
				}
			];
		});
	}

	private hasAccess(roleName: string) {
		let isExist = false;
		if (this.policies.Permitted !== null) {
			if (this.policies.Permitted[0].read !== null) {
				isExist = this.policies.Permitted[0].read.includes(roleName);
			}
			if (this.policies.Permitted[0].update !== null) {
				if (!isExist) {
					isExist = this.policies.Permitted[0].update.includes(roleName);
				}
			}
			if (this.policies.Permitted[0].delete !== null) {
				if (!isExist) {
					isExist = this.policies.Permitted[0].delete.includes(roleName);
				}
			}
			return isExist;
		} else {
			return false;
		}
	}

	private refreshHome() {
		const currentUrl = this.router.url;
		if (currentUrl.includes('/home-page')) {
			this.router.routeReuseStrategy.shouldReuseRoute = function () {
				return false;
			};
			this.router.events.pipe(takeUntil(this.destroyed)).subscribe((routerEvent) => {
				if (routerEvent instanceof NavigationEnd) {
					this.router.navigated = false;
				}
			});
		}
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.complete();
	}
}
