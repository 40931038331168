export class DeclarationQuestion {

	id: number;
	question: string;
	template: string;
	ansType: string;
	option: string;
	active: boolean;
	editable: boolean;
	mandatory: boolean;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	commentsPlaceholder: string;

	constructor(declarationQuestion: DeclarationQuestion) {
		Object.assign(this, declarationQuestion);
	}
}

export const emptyDCLRTNQuestion = {
	'id': 0,
	'question': '',
	'template': '',
	'ansType': '',
	'option': '',
	'active': true,
	'editable': true,
	'mandatory': false,
	'createdBy': '',
	'createdDate': 0,
	'updatedBy': '',
	'updatedDate': 0,
	'commentsPlaceholder': ''
};
