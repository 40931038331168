import {AfterViewChecked, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, NgForm} from '@angular/forms';
import {SiteCode} from '../../models/site-code';
import {
	CapacityRequirementSource,
	capacityRequirementSourceDropdown,
	CarRequest,
	DeviationReason,
	deviationReasonDropdown, carVisibilityDropdownFord, carVisibilityDropdownSupplier, CARVisibilityObj,
	emptyCarRequest,
	emptyPartPPAPLevelDropdown,
	PartPPAPLevel,
	Priority,
	priorityDropdown,
	ProposedPlan,
	proposedPlanDropdown,
	testValueCarRequest
} from '../../models/car-request';
import {ManageSubmissionScenario} from '../../models/manage-submission-scenario';
import {ManageSubmissionScenarioService} from '../../services/master/manage-submission-scenario/manage-submission-scenario.service';
import {CarRequestService} from '../../services/request/car-request.service';
import {ConfirmationService, DialogService, MessageService, SelectItem} from 'primeng/api';
import {emptyProcessStreamDroprdown, emptyProcessStreamModel, ProcessStream} from '../../models/process-stream';
import {ProcessStreamService} from '../../services/master/process-stream/process-stream.service';
import {emptyProcessNameDropDown, ProcessName} from '../../models/process-name';
import {emptyProcessData, ProcessData} from '../../models/process-data';
import {ManageSharedLoadingService} from '../../services/master/manage-shared-loading/manage-shared-loading.service';
import {PsPnMapTran} from '../../models/pspnmaptran';
import {ProcessStreamProcessNameMap} from '../../models/process-stream-process-name-map';
import {HistoricalMfgPerf} from '../../models/historical-mfgperf';
import {HistoricalMfgPerfService} from '../../services/master/historical-mfgperf/historical-mfgperf.service';
import {DemonOEEMap} from '../../models/processid-demon-oee';
import {Config} from '../../config';
import * as _ from 'lodash';
import {UtilService} from '../../services/util.service';
import {emptyKeyContacts, KeyContacts} from '../../models/key-contacts';
import {dummyPrimarySupportingMapTran, PrimarySupportingMapTran} from '../../models/primary-supporting-map-tran';
import {ManageDeclarationQuestionsService} from '../../services/master/manage-declaration-questions/manage-declaration-questions.service';
import {DeclarationQuestionsTran} from '../../models/declaration-question-trans';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {ProcessNameIdAllocationPercentMap} from '../../models/processnameid-allocation-percent';
import {SharedLoadingRoot} from '../../models/shared-loading-root';
import {SupplierSiteService} from '../../services/supplierDetails/supplier-site.service';
import {emptySupplierSiteDetails, SupplierSiteDetails} from '../../models/supplier-site-details';
import {RequestStateHistory} from '../../models/request-state-history';
import {LdapResponse} from '../../models/ldap-response';
import {SiteCodeService} from '../../services/sitecode.service';
import {Option} from '../../models/option';
import {ProcessNameService} from '../../services/master/process-name/process-name.service';
import {ApsAttributes} from '../../models/aps-attributes';
import {UserIdService} from '../../services/user-id.service.service';
import {ApsService} from '../../aps.service';
import {OndeleteDialogueboxComponent} from '../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../models/on-delete-display-dialogue-model';
import * as moment from 'moment';
import {HttpParams} from '@angular/common/http';

@Component({
	selector: 'create-car-request',
	templateUrl: './create-car-request.component.html',
	styleUrls: ['./create-car-request.component.css']
})
export class CreateCarRequestComponent implements OnInit, OnDestroy, AfterViewChecked {
	@ViewChild('stepper', {static: false}) stepper;
	@ViewChild('headerForm', {static: false}) headerForm;
	@ViewChild('primaryStreamForm', {static: false}) primaryStreamForm;
	@ViewChild('summaryForm', {static: false}) summaryForm;
	private config = new Config();
	demonOEEList: DemonOEEMap[] = [];
	psProcessNamesOptions: ProcessName[] = [];
	processStreamMappingPath: string;
	requirementsAPWFromDraft: number;
	requirementsMPWFromDraft: number;
	primaryHMPValFromDraft: DemonOEEMap[] = [];
	secondaryHMPValFromDraft: DemonOEEMap[] = [];
	primaryHMPValFromMaster: DemonOEEMap[] = [];
	secondaryHMPValFromMaster: DemonOEEMap[] = [];
	primarySLAllocationFromDraft: ProcessNameIdAllocationPercentMap[] = [];
	secondarySLAllocationFromDraft: ProcessNameIdAllocationPercentMap[] = [];
	primarySLAllocationFromMaster: ProcessNameIdAllocationPercentMap[] = [];
	secondarySLAllocationFromMaster: ProcessNameIdAllocationPercentMap[] = [];
	private ldapResponse: LdapResponse[];
	submissionScenarioObjectDraft: ManageSubmissionScenario;
	capacityRequirementSourceDraft: string;
	partPrefixBaseSuffixDraft: string;

	headerFormSubmitted = false;
	primaryStreamFormSubmitted = false;
	supportingStreamFormSubmitted = false;
	declarationFormSubmitted = false;
	summaryFormSubmitted = false;
	checkSummary = false;
	isViewMode = false;
	processStream: ProcessStream;

	siteCodes: SiteCode[];
	carRequest: CarRequest;
	mode: string;
	isViewAlleCARs: string;
	private carId: number;
	parentCARId: number;
	screenName = 'Create eCAR Request';
	siteCode = 'test';
	partPPAPLevelOptions: PartPPAPLevel[] = emptyPartPPAPLevelDropdown;
	submissionScenarioOptions: ManageSubmissionScenario[];
	capacityRequirementSourceOptions: CapacityRequirementSource[] = capacityRequirementSourceDropdown;
	priorityOptions: Priority[] = priorityDropdown;
	deviationReasonOptions: DeviationReason[] = deviationReasonDropdown;
	supplierSiteDetails: SupplierSiteDetails = emptySupplierSiteDetails;
	reqStateHistories: RequestStateHistory[];
	isRejected = false;
	isSaveAndPrint = true;
	areAllHeaderMandatoryFieldsFilled = true;
	showScenarioDescriptionDialog = false;
	disableSupplierName = false;

	primary = 'primary';
	supporting = 'supporting';

	/*Primary Stream screen*/
	availablePrimaryStream: ProcessStream[];
	selectedPrimaryStream: ProcessStream = emptyProcessStreamModel;
	previousPrimaryStream: ProcessStream = null;
	processNames: ProcessName[];
	historicalMfgPerfObj: any;
	historicalMfgPerfObjTemp: any;
	historicalmfgperfs: HistoricalMfgPerf[];
	historicalmfgperfsTemp: HistoricalMfgPerf[];

	declarationQuestionTranTmpList: DeclarationQuestionsTran[] = [];
	yesNoOptions: SelectItem[] = [{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}];
	dateTime: Date;
	yearRange: string;
	questionLoaded: boolean;
	isMandatoryQuestionsValid: boolean;
	isEditDeclDate = false;
	fordBuyerSuggestions: KeyContacts[];
	selectedFordBuyer: KeyContacts;
	fordSiteEngSuggestions: KeyContacts[];
	selectedSiteEng: KeyContacts;
	fordCapacityStudyBuyerSuggestions: KeyContacts[];
	selectedFordCapacityStudyBuyer: KeyContacts;
	fordSiteSupervisorSuggestions: KeyContacts[];
	selectedFordSiteSupervisor: KeyContacts;
	noMatchFoundFordBuyer = false;
	noMatchFoundSiteEngineer = false;
	noMatchFoundCapacityStudyBuyer = false;
	isAPPCCommitmentLower = false;
	isAPPCCommitmentHigher = false;
	isMPPCCommitmentLower = false;
	isMPPCCommitmentHigher = false;
	isAPPCCommitment10PercentHigher = false;
	isMPPCCommitment10PercentHigher = false;
	allProcessContainsAnyErrors = 'Valid';
	apsAttributes: any;
	disableSiteCode = false;
	isStepperLeaner = true;
	originPage = 'HEADER';
	//Link eCAR
	isNeweCAR = false;
	isValidModelYear = true;
	isPrint = false;
	printLabel: string;
	carIdLinkOptions: Option[];
	streamNameDropDownOptions: Option[];
	processNameDropDownOptions: Option[];
	disableForEcarLink = false;
	emptyCar = emptyCarRequest;
	isSTAEdit = false;
	descriptionLabel = 'Description';
	private mapId: number;
	private primeMapView: boolean;
	private hisoricalPrimeMapView: boolean;
	private SupportMapView: boolean;
	private hisoricalSupportMapView: boolean;

	siteSupCdsId: string;
	noMatchFoundSiteSupervisorCDSID = false;
	noValueSelecte = false;
	siteSupervisorRequired = false;
	workflowCheck = false;
	recallReasonField: string;
	loggedInUser: string;
	attributes: ApsAttributes;
	private enableRejection: boolean;
	private inValidProgramCode: boolean;
	private userId: string;
	private capacityStudy: boolean;
	showPrimaryProcessStream: boolean;
	showHistoricalPrimaryProcessStream: boolean;
	showSecondaryProcessStream: boolean;
	showHistoricalSecondaryProcessStream: boolean;
	private totalAllocationPercentageStaus: boolean;
	hideCapacityStudyElements = false;
	hideCapacityStudyElementsForSummary = false;
	private showGcpMcpvValidationError = false;
	private showRevisedValidationError = false;
	private capacityRequirementSourceRequired = false;
	capacityReqSourceForCapacityStudyFromDraft: string;
	private capacityRequirementSourceObjectRequired = false;
	isSitePresentInSIM = true;
	totalAllocationPercentageGreaterThan100 = false;
	containAssumptionsHasFalse = false;
	private pageNo = 0;
	private pageSize = 0;
	private sortingParameters: any[];
	private filterParammeters: any[];
	bottleNeckProcessName: string;
	hideProcessDetailsValidation: boolean;
	isPlannedLessThanReqCapacity = false;
	lessPlannedCapacityMpw = false;
	lessPlannedCapacityApw = false;
	isNoteMandatory = false;
	sharedLoadingRoot: SharedLoadingRoot;
	isReqNeedsAcknowledgment = false;
	private invalidModelYearFormat = false;
	private loggedInUserId: string;
	isTokenOnlyUser: boolean;
	proposedPlanOptions: ProposedPlan[] = proposedPlanDropdown;
	maxPlanDateTime: Date;
	planYearRange: string;
	planDate: Date;
	isEditProposedDate = false;
	planDateDisplay: number;
	private allowEngChange: boolean;
	private disableReassign: boolean;
	curSiteEngcdsid = '';
	isSubmitted = false;
	isSiteSupervisorPresent = true;
	commentsMaxLength = 1000;
	private isNonSiteUser: boolean;
	allowSupervisorChange: boolean;
	oldSupervisor: KeyContacts;
	private oldSiteEng: KeyContacts;
	engNotAvailableInAps = false;
	supNotAvailableInAps = false;
	private isProcessStreamAvailable: boolean;
	private isPrimaryStreamReset: boolean;
	onSupportingStreamPage: boolean;
	private processStreamRemovedAlert: boolean;
	private hideProcessStreamInfo = false;
	placeholder = 'Please Enter Site Code';
	siteCodeSuggestions: string[];
	selectedSiteCode: string;
	carVisibilityFordOption: CARVisibilityObj[] = carVisibilityDropdownFord;
	carVisibilitySupplierOption: CARVisibilityObj[] = carVisibilityDropdownSupplier;

	constructor(private userIdService: UserIdService, private siteCodeService: SiteCodeService, private utilService: UtilService, private hmpService: HistoricalMfgPerfService, private manageSharedLoadingService: ManageSharedLoadingService, private processStreamService: ProcessStreamService, private processNameService: ProcessNameService, private messageService: MessageService, private carRequestService: CarRequestService, private manageSubmissionScenarioService: ManageSubmissionScenarioService, private router: Router, private _formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private declarationQuestionsService: ManageDeclarationQuestionsService, private confirmationService: ConfirmationService, private supplierSiteService: SupplierSiteService, private apsService: ApsService, private dialogService: DialogService) {
		this.findAllScenarioNames();
		this.processStreamMappingPath = window.location.origin + '/#/view-process-stream/';
	}

	ngOnInit() {
		this.retrieveRouteParameter();
		this.checkNonSiteUser(sessionStorage.getItem('userId'), JSON.parse(sessionStorage.getItem('attributes')).userRole);
		this.attributes = JSON.parse(sessionStorage.getItem('attributes'));
		this.loggedInUserId = sessionStorage.getItem('userId');
		if (this.attributes) {
			this.loggedInUser = this.attributes.userRole;
			this.isTokenOnlyUser = this.utilService.isUserHasOnlyTokenOnly();
		} else {
			console.log('Unable to set aps attributes');
		}
		if (this.mode === 'create') {
			if (this.isNeweCAR) {
				console.log('create mode newly saved eCar');
				this.populateErrorFlagValuesForComparison(this.carRequest);
			} else {
				console.log('create mode new eCar');
				this.carRequest = null;
				this.carRequest = Object.assign({}, emptyCarRequest);
				this.carRequest.keyContacts = this.buildEmptyKeyContact();
				this.retrieveAndDisplaySupplierLeadDetailsOnKeyContacts();
				this.hideCapacityStudyElements = true;
				//this.retrieveAvailablePrimaryStream();
				if (this.parentCARId) {
					this.carRequestService.loadEcarIdOptions().subscribe(value => {
						this.carIdLinkOptions = value;
						this.carRequest.selectedCarIdForLinkageObject = this.carIdLinkOptions.find(value1 => value1.code === this.parentCARId.toString());
					}, error => {
						this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
					});
					this.carRequestService.findById(this.parentCARId).subscribe(value => {
						this.carRequest.selectedCarIdForLinkage = this.parentCARId;
						this.carRequest.isLinkCar = true;
						this.disableForEcarLink = true;
						this.loadStreamDropdownLinkCAR();
						this.loadProcessNameDropdownLinkCar();
						if (value.keyContacts.length > 3) {
							this.siteSupCdsId = value.keyContacts[3].cdsid;
						}
						if (value.workflowId === 1 || value.workflowId === 2 || value.workflowId === 3 || value.workflowId === 4 || value.workflowId === 9) {
							this.workflowCheck = true;
						}
						this.populateParenteEcarInfo(this.parentCARId, value.selectedStreamIdForLinkage, value.selectedProcessNameIdForLinkage);
					}, error => {
						this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
					});
				}
			}
		} else {
			this.loadCarDataForEditAndView();
		}
		//TODO for test need to removed
		if (this.config.env === 'local') {
			if (this.mode === 'create') {
				//this.populateTestDataHeader();
			}
		}
		if (this.mode === 'view') {
			this.isViewMode = true;
			this.isStepperLeaner = false;
		}
		//this.loadSiteCodes();
	}

	private showAlertPopupMessage() {
		const dialogueRefCarReq = this.dialogService.open(OndeleteDialogueboxComponent, {
			header: 'Alert',
			width: '50%',
			contentStyle: {'max-height': '700px', 'overflow': 'auto'},
			data: {
				'message': 'CapacityStudyAlertCarReq'
			}
		});
		dialogueRefCarReq.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
			if (deleteModel != null) {
				if (deleteModel.isDeleted) {
					this.carRequest.isAlertRequired = false;
					this.carRequest.userAction = 'DRAFT';
					this.carRequest.loggedInUserId = this.loggedInUserId;
					this.carRequestService.update(this.carRequest.reqId, this.carRequest).subscribe(value => {
					});
				}
			}
		});
	}

	private showAlertPopupMessageForProcessStreamChanges() {
		const dialogueRefCarReq = this.dialogService.open(OndeleteDialogueboxComponent, {
			header: 'Alert',
			width: '50%',
			contentStyle: {'max-height': '700px', 'overflow': 'auto'},
			data: {
				'message': 'ProcessStreamSourceGotAffected'
			}
		});
		dialogueRefCarReq.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
			if (deleteModel != null) {
				if (deleteModel.isDeleted) {
					this.carRequest.isPSUpdated = false;
					this.carRequest.keepHistoricalPSInRejRecall = false;
					this.carRequest.userAction = 'DRAFT';
					this.carRequest.loggedInUserId = this.loggedInUserId;
					this.carRequestService.update(this.carRequest.reqId, this.carRequest).subscribe(value => {
						if (value) {
							this.carRequest.isPSUpdatedToClearData = value.isPSUpdatedToClearData;
							this.populatePrimaryStreamData(value);
							this.populateSuppStreamDataOnPrimaryStreamSave(value);
						}
					});
				}
			}
		});
	}

	private buildEmptyKeyContact() {
		return [Object.assign({}, emptyKeyContacts), Object.assign({}, emptyKeyContacts), Object.assign({}, emptyKeyContacts), Object.assign({}, emptyKeyContacts), Object.assign({}, emptyKeyContacts)];
	}

	private capacityReqSrcCheck(carRequest: CarRequest) {
		if (carRequest.capacityRequirementSource === 'Confirmed' || carRequest.capacityRequirementSource === 'What-If') {
			this.capacityStudy = true;
		} else {
			this.manageSubmissionScenarioService.findById(carRequest.submissionScenario).subscribe((scenario: ManageSubmissionScenario) => {
				if (scenario.capacityStudy) {
					this.capacityStudy = true;
					if (carRequest.requestStatus === 'DRAFT') {
						this.hideCapacityStudyElements = false;
					}
				} else {
					this.capacityStudy = false;
					if (carRequest.requestStatus === 'DRAFT') {
						this.hideCapacityStudyElements = true;
					}
				}
			});
		}
	}

	private loadECar(value: CarRequest) {
		console.log('in edit loaded this.carRequest');
		this.carRequest = value;
		if (this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'STADRAFT' || this.carRequest.requestStatus === 'REJECTED') {
			this.isSubmitted = false;
		} else {
			this.isSubmitted = true;
		}
		let keepGoing = true;
		if (this.carRequest && this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0 && this.carRequest.capacityRequirementSource !== 'Confirmed' && this.carRequest.capacityRequirementSource !== 'What-If') {
			this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
				if (psPnMapTran.processDataList) {
					if (keepGoing) {
						if (!this.capacityStudy && (psPnMapTran.processDataList[0].totalAllocationPercentageAPW < psPnMapTran.processDataList[0].totalAllocationPercentageCapacityAPW && psPnMapTran.processDataList[1].totalAllocationPercentageMPW < psPnMapTran.processDataList[1].totalAllocationPercentageCapacityMPW) && (psPnMapTran.processDataList[0].totalAllocationPercentageCapacityAPW > 90 || psPnMapTran.processDataList[1].totalAllocationPercentageCapacityMPW > 90)) {
							this.totalAllocationPercentageStaus = true;
							keepGoing = false;
						} else {
							this.totalAllocationPercentageStaus = false;
						}
					}
				}
			});
		}
		this.prePopulateHeaderDropdown();
		this.doCapacityStudyElementsPresentInClosedCars();
		this.isOldECARWithinTheDateLimit();
		// this.retrieveAvailablePrimaryStream();
		this.retrieveAvailablePrimaryStreamBySiteCode();
		this.manageFormSubmitFlag();
		this.requirementsAPWFromDraft = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsApw : (this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsApw : this.carRequest.gcpMcpvApw);
		this.requirementsMPWFromDraft = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsMpw : (this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsMpw : this.carRequest.gcpMcpvMpw);

		//emptying if exists
		this.emptyHMPandSLP();

		/*Optimization
		this.initializePrimaryStream();
		this.initializeSupportingStream();
		this.retrieveSharedLoadingIdBySiteCode();*/
		this.initializeDeclaration();
		this.submissionScenarioObjectDraft = this.carRequest.submissionScenarioObject;
		this.capacityRequirementSourceDraft = this.carRequest.capacityRequirementSource;
		this.partPrefixBaseSuffixDraft = this.carRequest.prefix.concat('-' + this.carRequest.base).concat('-' + this.carRequest.suffix);
		this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
		this.disableSiteCode = true;
		this.initializeQ1Status();
		this.initializePPAPOptions();
		this.manageSupplierName(this.carRequest.supplierName);
		this.initializeKeyContactsSelection();
	}

	private prePopulateHeaderDropdown() {
		if (this.isNonSiteUser && (this.mode === 'edit' || this.mode === 'view')) {
			/*this.siteCodes = [];
			this.carRequest.siteCodeObject = {name: '', code: this.carRequest.siteCode};
			this.siteCodes.push(this.carRequest.siteCodeObject);*/
			this.carRequest.siteCodeObject = this.carRequest.siteCode;
		} else {
			if (!this.siteCodes || this.siteCodes.length === 0) {
				this.siteCodes = this.siteCodeService.fetchSiteCodes();
			}
			//this.carRequest.siteCodeObject = this.siteCodes.find(value1 => value1.code === this.carRequest.siteCode);
			this.carRequest.siteCodeObject = this.carRequest.siteCode;
		}
		this.carRequest.capacityRequirementSourceObject = this.capacityRequirementSourceOptions.find(value1 => value1.code === this.carRequest.capacityRequirementSource);
		this.carRequest.submissionScenarioObject = this.submissionScenarioOptions.find(value1 => value1.id === this.carRequest.submissionScenario);
		this.carRequest.partPPAPLevelObject = this.partPPAPLevelOptions.find(value1 => value1.code === this.carRequest.partPpapLevel);
		this.carRequest.priorityObject = this.priorityOptions.find(value1 => value1.code === this.carRequest.priority);
	}

	private doCapacityStudyElementsPresentInClosedCars() {
		if (this.carRequest.requestStatus === 'SUBMITTED' || this.carRequest.requestStatus === 'COMPLETED' || this.carRequest.requestStatus === 'APPROVED' || this.carRequest.requestStatus === 'ACKNOWLEDGED') {
			if (this.carRequest.submissionScenarioObject['capacityStudy']) {
				this.carRequestService.isSupplierSubmittedWithinDateLimit(this.carRequest.reqId).subscribe(isSubmitted => {
					if (isSubmitted) {
						this.hideCapacityStudyElementsForSummary = true;
						this.hideCapacityStudyElements = true;
					}
				});
			} else {
				this.hideCapacityStudyElements = true;
				this.carRequestService.isSupplierSubmittedWithinDateLimit(this.carRequest.reqId).subscribe(isSubmitted => {
					if (isSubmitted) {
						this.hideCapacityStudyElementsForSummary = true;
					}
				});
			}
		} else {
			if (!this.capacityStudy) {
				this.hideCapacityStudyElements = true;
			}
		}
	}

	private isOldECARWithinTheDateLimit() {
		if (this.carRequest.requestStatus === 'SUBMITTED' || this.carRequest.requestStatus === 'COMPLETED' || this.carRequest.requestStatus === 'APPROVED' || this.carRequest.requestStatus === 'ACKNOWLEDGED') {
			this.carRequestService.isOldECARWithinTheDateLimit(this.carRequest.reqId).subscribe(isSubmitted => {
				if (isSubmitted) {
					this.hideProcessDetailsValidation = true;
				} else {
					this.hideProcessDetailsValidation = false;
				}
			});
		} else {
			this.hideProcessDetailsValidation = false;
		}
	}

	private emptyHMPandSLP() {
		this.primaryHMPValFromDraft = [];
		this.primarySLAllocationFromDraft = [];
		this.secondaryHMPValFromDraft = [];
		this.secondarySLAllocationFromDraft = [];
	}

	private initializeQ1Status() {
		if (this.mode === 'edit') {
			console.log('Supplier site details retrieved information called edit mode');
			//	this.retrieveSupplierSite(this.carRequest.siteCodeObject.code);
			this.supplierSiteDetails.siteDetailsLoaded = true;
			this.retrieveQ1FromSupplierSite(this.carRequest.siteCodeObject);
		}
	}

	private initializeDeclaration() {
		if (this.carRequest.declarationQuestionsTranList && this.carRequest.declarationQuestionsTranList.length > 0) {
			this.carRequest.declarationQuestionsTranList.forEach(question => {
				if (question.ansType === 'Yes / No') {
					question.optionObj = JSON.parse(question.optionStr);
				} else if (question.ansType === 'Date Picker' && question.dateAnswer) {
					question.dateDisplayAnswer = new Date(question.dateAnswer);
					question.dateAnswerDisplay = Date.parse(this.utilService.convertDateStringInFormat(question.dateAnswer));
					console.log('Stored date value ' + this.utilService.convertDateStringInFormat(question.dateAnswer));
					console.log('date object ' + question.dateDisplayAnswer + ' in ms ' + question.dateAnswerDisplay);
				}
			});
			if (this.carRequest.requestStatus && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'REJECTED') {
				this.declarationQuestionTranTmpList = this.carRequest.declarationQuestionsTranList;
				this.questionLoaded = true;
				// For fixing question arrangement issue: Sorting the question list by its ID
				this.declarationQuestionTranTmpList.sort(function (left, right) {
					return left.masterQuestionId - right.masterQuestionId;
				});
				this.disableDeclarationFields();
			} else {
				this.fetchDeclarationQuestions(this.carRequest.submissionScenarioObject.templateName);
			}
		} else {
			this.fetchDeclarationQuestions(this.carRequest.submissionScenarioObject.templateName);
		}
	}

	private disableDeclarationFields() {
		if (this.declarationQuestionTranTmpList[0].yesNoAnswer === 'NA') {
			this.declarationQuestionTranTmpList[1].disableProp = true;
			this.declarationQuestionTranTmpList[2].disableProp = true;
			this.declarationQuestionTranTmpList[3].disableProp = true;
		} else {
			this.declarationQuestionTranTmpList[1].disableProp = false;
			this.declarationQuestionTranTmpList[2].disableProp = false;
			this.declarationQuestionTranTmpList[3].disableProp = false;
		}
	}

	private initializeSupportingStream() {
		if (this.carRequest && this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			this.carRequest.primarySupportingMapTranList.forEach(primarySupportingMapTran => {
				primarySupportingMapTran.psPnMapTranList.forEach(psPnMapTran => {
					this.secondaryHMPValFromDraft.push(new DemonOEEMap({
						'id': psPnMapTran.processNameId,
						'demonOee': psPnMapTran.processDataList[0].averageHisOee
					}));
					this.secondarySLAllocationFromDraft.push(new ProcessNameIdAllocationPercentMap({
						'processNameId': psPnMapTran.processNameId,
						'allocationPercent': [_.round(psPnMapTran.processDataList[0].sharedLoadingTotalAllocPercent, 2), _.round(psPnMapTran.processDataList[1].sharedLoadingTotalAllocPercent, 2)]
					}));
				});
			});
		}
	}

	private initializePrimaryStream() {
		if (this.carRequest && this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
				this.primaryHMPValFromDraft.push(new DemonOEEMap({
					'id': psPnMapTran.processNameId,
					'demonOee': psPnMapTran.processDataList[0].averageHisOee
				}));

				this.primarySLAllocationFromDraft.push(new ProcessNameIdAllocationPercentMap({
					'processNameId': psPnMapTran.processNameId,
					'allocationPercent': [_.round(psPnMapTran.processDataList[0].sharedLoadingTotalAllocPercent, 2), _.round(psPnMapTran.processDataList[1].sharedLoadingTotalAllocPercent, 2)]
				}));
			});
		}
	}

	private initializePPAPOptions() {
		if (this.mode === 'edit') {
			this.supplierSiteService.findBySiteCode(this.carRequest.siteCode).subscribe(response => {
				const supplierSiteDetails = response;
				if (supplierSiteDetails.prodPPAPLevel !== 1) {
					if (supplierSiteDetails.prodPPAPLevel === 3) {
						this.partPPAPLevelOptions = [{name: '3', code: 3}, {name: '5', code: 5}];
					} else {
						this.partPPAPLevelOptions = [{name: '5', code: 5}];
					}
				}
			}, error => {
				this.partPPAPLevelOptions = [{name: '5', code: 5}];
			});
		}
	}

	ngAfterViewChecked(): void {
		this.setFormInvalidIfDropdownEmpty();
	}

	manageFormSubmitFlag() {
		if (this.carRequest && this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach((psPnMapTran, index) => {
				if (psPnMapTran.processDataList && psPnMapTran.processDataList.length > 0 && psPnMapTran.processDataList[0].reqOee > 0) {
					psPnMapTran.formSubmitted = true;
				} else {
					psPnMapTran.formSubmitted = false;
				}
			});
		}
	}

	private retrieveAvailablePrimaryStream() {
		this.processStreamService.findAllPrimaryProcessStream().subscribe(value => {
			this.availablePrimaryStream = value;
			this.availablePrimaryStream.unshift(emptyProcessStreamDroprdown);
			if (this.mode === 'edit') {
				this.selectedPrimaryStream = this.availablePrimaryStream.find(value1 => value1.id === this.carRequest.primaryStreamId);
				this.loadProcessNamesByPrimaryStreamId(this.selectedPrimaryStream);
			}
		});
	}


	private populateTestDataHeader() {
		this.carRequest = Object.assign({}, testValueCarRequest);
		this.findAllScenarioNames();
		this.manageSubmissionScenarioService.findAll().subscribe(value =>
			this.carRequest.submissionScenarioObject = this.submissionScenarioOptions[1]
		);
	}

	private findAllScenarioNames() {
		this.manageSubmissionScenarioService.findAll().subscribe((response: ManageSubmissionScenario[]) => {
			this.submissionScenarioOptions = [];
			this.submissionScenarioOptions = response;
			this.submissionScenarioOptions.sort(function (a, b) {
				return a.id - b.id;
			});
			/*this.submissionScenarioOptions.unshift({
				templateName: '',
				id: 0,
				scenarioName: 'Select Submission Scenario',
				scenarioDesc: ''
			});*/
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private retrieveMapParameter(id: number) {
		this.mapId = id;
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.mode = params.get('mode');
			this.carId = Number(params.get('id'));
			this.isSTAEdit = Boolean(params.get('isSTAEdit'));
			this.parentCARId = Number(params.get('parentCARId'));

			this.pageNo = Number(params.get('pageNo'));
			this.pageSize = Number(params.get('pageSize'));
			this.sortingParameters = Array(params.get('sortParam'));
			this.filterParammeters = Array(params.get('filterParam'));
			this.isViewAlleCARs = params.get('isViewAlleCARs');

			console.log('isStaEdit: ' + this.isSTAEdit);
			if (this.mode === 'edit') {
				this.screenName = 'Edit eCAR Request';
			}
		});
		this.dateTime = new Date();
		this.yearRange = (this.dateTime.getFullYear() - 100) + ':' + this.dateTime.getFullYear();
	}

	onSubmit(userAction: string, currentPage: string) {
		this.onNextPage();
		this.checkCapacityRequirementSource();
		if (userAction) {
			if (userAction === 'REJECTED' || userAction === 'STADRAFT') {
				userAction = 'DRAFT';
			}
			this.carRequest.userAction = userAction;
			if (this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason === null) {
				if (null == this.carRequest.isPSUpdatedToClearData || this.carRequest.isPSUpdatedToClearData !== true) {
					this.carRequest.isPSUpdatedToClearData = false;
				}
				this.carRequest.keepHistoricalPSInRejRecall = true;
			}
			if (userAction === 'SUBMIT') {
				if (this.isNoteMandatory && !this.carRequest.notes) {
					this.utilService.pushMsg('error', 'Error', 'Notes are mandatory based on APPC/MPPC provided');
					return false;
				}
				const requiredCapacityApw = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsApw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsApw : this.carRequest.gcpMcpvApw;
				const requiredCapacityMpw = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsMpw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsMpw : this.carRequest.gcpMcpvMpw;
				if (this.carRequest.commitmentAppc && this.carRequest.commitmentMppc && (this.carRequest.commitmentAppc < requiredCapacityApw || this.carRequest.commitmentMppc < requiredCapacityMpw) && this.carRequest.plannedCapacityApw >= requiredCapacityApw && this.carRequest.plannedCapacityMpw >= requiredCapacityMpw && !this.carRequest.notes && !this.totalAllocationPercentageGreaterThan100 && !this.containAssumptionsHasFalse) {
					this.isNoteMandatory = true;
					this.utilService.pushMsg('error', 'Error', 'Notes are mandatory based on APPC/MPPC provided');
					return false;
				}
				if (!this.allHeaderMandatoryFieldsFilled(false)) {
					this.utilService.pushMsg('error', 'Error', 'Please fill all mandatory fields in Header Section');
					return false;
				}
				if (!this.isPPCLessThanPlannedCapacity() && !this.totalAllocationPercentageGreaterThan100 && !this.containAssumptionsHasFalse) {
					if (this.carRequest.submissionScenarioObject.templateName.startsWith('Capacity')) {
						this.utilService.pushMsg('error', 'Error', 'PPC values are greater than Planned Capacity. Please correct it');
					} else {
						this.utilService.pushMsg('error', 'Error', 'PPC values are greater than Demonstrated Capacity. Please correct it');
					}
					return false;
				}
				if (this.capacityStudy && this.isReqNeedsAcknowledgment) {
					if (!this.carRequest.proposedPlanCode || this.carRequest.proposedPlanCode === null || this.carRequest.proposedPlanCode === -1) {
						this.utilService.pushMsg('error', 'Error', 'Please choose plan availability for insufficient capacity.');
						return false;
					}
					if (this.carRequest.proposedPlanCode && this.carRequest.proposedPlanCode === 1 && (!this.carRequest.proposedPlanDate || this.carRequest.proposedPlanDate === null || this.carRequest.proposedPlanDate === '')) {
						this.utilService.pushMsg('error', 'Error', 'Please choose proposed plan date.');
						return false;
					}
					if (this.carRequest.proposedPlanCode && this.carRequest.proposedPlanCode === 1 && this.carRequest.proposedPlanDate && this.planDate) {
						const proposedDate = this.planDate.setHours(0, 0, 0, 0);
						const currentDate = this.dateTime.setHours(0, 0, 0, 0);
						if (proposedDate < currentDate) {
							this.utilService.pushMsg('error', 'Error', 'Please choose current / future proposed plan date.');
							return false;
						}
					}
				} else if (this.carRequest.proposedPlanDate || this.carRequest.proposedPlanCode) {
					this.carRequest.proposedPlanCode = null;
					this.carRequest.proposedPlanDate = null;
				}
				this.carRequest.submittedByEmailId = sessionStorage.getItem('displayLeadMail');
				this.carRequest.submittedByName = sessionStorage.getItem('displayLeadName');
				this.carRequest.isAnyProcessInCARHasWarning = this.allProcessContainsAnyErrors === 'Warning' ? true : false;
			}
		} else if (currentPage === 'HEADER') {
			userAction = 'DRAFT';
			this.carRequest.userAction = userAction;
		}
		if (!this.isViewMode) {
			this.headerFormSubmitted = true;
			this.summaryFormSubmitted = true;
			if (!this.headerForm.valid) {
				return false;
			}
			if (this.carRequest.requirementType === 2) {
				if (this.carRequest.revisedRequirementsApw <= 0 || this.carRequest.revisedRequirementsApw.toString().trim() === '' || this.carRequest.revisedRequirementsMpw <= 0 || this.carRequest.revisedRequirementsMpw.toString().trim() === '') {
					return false;
				}
			} else if (this.carRequest.requirementType === 3) {
				if (this.carRequest.gcpMcpvApw <= 0 || this.carRequest.gcpMcpvApw.toString().trim() === '' || this.carRequest.gcpMcpvMpw <= 0 || this.carRequest.gcpMcpvMpw.toString().trim() === '') {
					return false;
				}
			}
			if (this.selectedPrimaryStream.id > 0) {
				this.carRequest.primaryStreamId = this.selectedPrimaryStream.id;
			}
		}
		/*Optimization
		this.setProcessDataDraftForSLChangeDetect();
		this.retrieveSharedLoadingIdBySiteCode();*/
		this.checkAndUpdatePrimaryAndSupportingStreamFlag(userAction, currentPage);
		if (this.carRequest.programCode.length > 150) {
			this.inValidProgramCode = true;
		} else {
			this.inValidProgramCode = false;
		}
		if (this.isProcessStreamConfirmed(this.carRequest, currentPage) && this.isHeaderFormValid(currentPage) && this.isPrimaryStreamValid(currentPage) && this.isSupportingStreamValid(currentPage) && this.isDeclarationValid(currentPage, userAction) && this.isSummaryFormValid(currentPage, userAction) && this.inValidProgramCode === false && this.showRevisedValidationError === false && this.showGcpMcpvValidationError === false && this.capacityRequirementSourceRequired === false && this.invalidModelYearFormat === false) {
			if (currentPage && currentPage === 'SUMMARY') {
				this.carRequest.keepHistoricalPSInRejRecall = true;
				this.carRequest.isPSUpdated = false;
			}
			if (this.mode === 'edit' || this.isViewMode || this.isNeweCAR) {
				this.carRequest.scenarioName = this.carRequest.submissionScenarioObject.scenarioName.toUpperCase();
				if (this.capacityStudy === true) {
					this.carRequest.gcpMcpvApw = null;
					this.carRequest.gcpMcpvMpw = null;
				}
				this.carRequest.loggedInUserId = this.loggedInUserId;
				if (userAction === 'SUBMIT') {
					this.carRequestService.checkHMPSLPFlagsOnSubmit(this.carRequest.reqId).subscribe(value => {
						if (value) {
							this.carRequestService.findById(this.carRequest.reqId).subscribe(carReq => {
								console.log('dont allow submit after validation check');
								this.carRequest.psPnMapTransList = carReq.psPnMapTransList;
								this.carRequest.primarySupportingMapTranList = carReq.primarySupportingMapTranList;
								this.allProcessContainsAnyErrors = 'Error';
								this.utilService.pushMsg('error', 'Error', 'Primary or Supporting Streams have data requiring review. Please Edit Process Details to refresh or update the data.');
								return false;
							}, err => {
								this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
							});
						} else {
							this.carRequest.recallReason = null;
							this.carRequestService.getMailFromCDSId(this.carRequest.keyContacts[0].cdsid).subscribe(ldapResponse => {
								this.apsService.retrieveAtrributesForEngineerKeyContactCheck(this.carRequest.keyContacts[0].cdsid).subscribe(engInAPS => {
									if (engInAPS && engInAPS.userRole.toLowerCase().includes('site') || engInAPS.userRole.toLowerCase().includes('sta') || engInAPS.userRole.toLowerCase().includes('super user')) {
										// Get Supervisor cdsid from SIM. If not present, get it from LDAP
										if (this.carRequest.keyContacts.length > 4 && this.carRequest.keyContacts[3] && this.carRequest.keyContacts[3].cdsid && this.carRequest.keyContacts[3].cdsid === '' || this.carRequest.keyContacts[3].cdsid.length === 0) {
											console.log('Supervisor not present in the key contacts table');
											this.carRequestService.findCdsIds(this.carRequest.siteCodeObject).subscribe(response => {
												if (response && response.length > 0 && response[0].prodStaSiteSpvCDS && response[0].prodStaSiteSpvCDS !== '') {
													this.carRequestService.getMailFromCDSId(response[0].prodStaSiteSpvCDS).subscribe(supervisorLdapResponse => {
														this.carRequest.keyContacts[3].name = supervisorLdapResponse.fordDisplayName;
														this.carRequest.keyContacts[3].mail = supervisorLdapResponse.mail;
														this.carRequest.keyContacts[3].cdsid = supervisorLdapResponse.userId;
														this.carRequest.keyContacts[3].phone = supervisorLdapResponse.telephoneNumber;
														this.carRequest.keyContacts[3].organizationName = supervisorLdapResponse.company;
														this.updateReqOnSubmit(currentPage);
													}, err => {
														console.log('Site Supervisor taken from SIM is not present in LDAP');
														this.updateSupervisorAndReq(currentPage);
													});
												} else {
													console.log('No SiteCode present or No Supervisor there in SIM');
													this.updateSupervisorAndReq(currentPage);
												}
											}, err => {
												this.updateSupervisorAndReq(currentPage);
											});
										} else {
											this.updateReqOnSubmit(currentPage);
										}
									} else {
										this.utilService.pushMsg('error', 'Error', 'The selected Site Engineer doesn\'t have Site Engineer role, Please change');
									}
								}, err => {
									this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
								});
							}, err => {
								this.utilService.pushMsg('error', 'Error', 'The selected Site Engineer does not exist in the directory, please update.');
								console.log('Site Engineer not present in LDAP');
							});
						}
					}, err => {
						this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
					});
				} else {
					this.updateReqOnSubmit(currentPage);
				}
			} else {
				this.carRequest.scenarioName = this.carRequest.submissionScenarioObject.scenarioName.toUpperCase();
				if (this.capacityStudy === true) {
					this.carRequest.gcpMcpvApw = null;
					this.carRequest.gcpMcpvMpw = null;
				}
				this.carRequestService.create(this.carRequest).subscribe(value => {
					if (currentPage === 'SaveAndContinueHeader' || currentPage === 'SaveAndContinuePrimary' || currentPage === 'SaveAndContinueSupporting' || currentPage === 'SaveAndContinueDeclaration') {
						this.populateErrorFlagValuesForComparison(this.carRequest);
						this.carRequest.reqId = value.reqId;
						this.carRequest.createdBy = value.createdBy;
						this.carRequest.createdDate = value.createdDate;
						this.carRequest.keyContacts = value.keyContacts;
						this.carRequest.partNumDisplay = value.partNumDisplay;
						this.carRequest.updatedDate = value.updatedDate;
						this.isNeweCAR = true;
						this.disableSiteCode = true;
						this.utilService.pushMsg('success', 'Success', 'eCAR #' + value.reqId + ' has been created successfully');
						this.stepper.next();
					} else {
						console.log('Sending Car req user action;- ' + this.carRequest.userAction);
						//const message: string = this.buildDisplayMessage(this.carRequest.userAction, value.reqId);
						sessionStorage.setItem('CarRequestSubmitSuccess', JSON.stringify({
							'status': 'success',
							'message': value.submissionMessage
						}));
						this.isNeweCAR = false;
						if (this.mode === 'create') {
							this.router.navigate(['home-page/0/10/undefined/undefined/false']);
						} else {
							this.router.navigate(['home-page/' + this.pageNo + '/' + this.pageSize + '/' + this.sortingParameters + '/' + this.filterParammeters + '/' + this.isViewAlleCARs]);
						}
					}
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Unable to save request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			}
		} else {
			console.log('Validation Fails');
		}
	}

	updateSupervisorAndReq(currentPage: string) {
		this.carRequestService.getMailFromCDSId(this.carRequest.keyContacts[0].cdsid).subscribe(ldapResponse => {
			if (!this.carRequest.keyContacts[3].cdsid || this.carRequest.keyContacts[3].cdsid !== ldapResponse.fordManagerCdsid) {
				this.carRequestService.getMailFromCDSId(ldapResponse.fordManagerCdsid).subscribe(managerLdapResponse => {
					this.carRequest.keyContacts[3].name = managerLdapResponse.fordDisplayName;
					this.carRequest.keyContacts[3].mail = managerLdapResponse.mail;
					this.carRequest.keyContacts[3].cdsid = managerLdapResponse.userId;
					this.carRequest.keyContacts[3].phone = managerLdapResponse.telephoneNumber;
					this.carRequest.keyContacts[3].organizationName = managerLdapResponse.company;
					this.checkHMPSLPFlagAndUpdateReq(currentPage);
				}, err => {
					console.log('Site Supervisor not present in LDAP');
				});
			} else {
				this.updateReqOnSubmit(currentPage);
			}
		}, err => {
			console.log('Site Engineer not present in LDAP');
		});
	}

	checkHMPSLPFlagAndUpdateReq(currentPage: string) {
		this.carRequestService.checkHMPSLPFlagsOnSubmit(this.carRequest.reqId).subscribe(value => {
			console.log('HMP, SLP Flags check ' + value);
			if (value) {
				this.allProcessContainsAnyErrors = 'Error';
				this.utilService.pushMsg('error', 'Error', 'Primary or Supporting Streams have data requiring review. Please Edit Process Details to refresh or update the data.');
			} else {
				this.updateReqOnSubmit(currentPage);
			}
		});
	}

	updateReqOnSubmit(currentPage) {
		if (this.carRequest.userAction === 'SUBMIT') {
			this.carRequest.lastSubmittedDate = this.carRequest.updatedDate;
		}
		this.carRequestService.update(this.carRequest.reqId, this.carRequest).subscribe(value => {
			this.carRequest.keyContacts = value.keyContacts;
			if (currentPage === 'SaveAndContinueHeader' || currentPage === 'SaveAndContinuePrimary' || currentPage === 'SaveAndContinueSupporting' || currentPage === 'SaveAndContinueDeclaration') {
				if (value && currentPage === 'SaveAndContinuePrimary') {
					this.populateSuppStreamDataOnPrimaryStreamSave(value);
				}
				if (this.mode === 'create' && currentPage !== 'SaveAndContinueDeclaration') {
					this.populatePrimaryStreamData(value);
					this.populateSuppStreamDataOnPrimaryStreamSave(value);
				}
				if (this.mode === 'create' && currentPage === 'SaveAndContinueDeclaration') {
					this.populatePrimaryStreamDataToSummary();
					this.populateSuppStreamDataToSummary();
				}
				this.stepper.next();
			} else {
				if (this.isPrint) {
					this.isPrint = false;
					const windowSize = 'width=' + (window.innerWidth - 100) + ',height=' + (window.innerHeight + 500);
					window.open(window.location.origin + '/#/eprint/' + this.carRequest.reqId, 'popup', windowSize);
				} else {
					sessionStorage.setItem('CarRequestSubmitSuccess', JSON.stringify({
						'status': 'success',
						'message': value.submissionMessage
					}));
					this.isNeweCAR = false;
					if (this.mode === 'create') {
						this.router.navigate(['home-page/0/10/undefined/undefined/false']);
					} else {
						this.router.navigate(['home-page/' + this.pageNo + '/' + this.pageSize + '/' + this.sortingParameters + '/' + this.filterParammeters + '/' + this.isViewAlleCARs]);
					}
				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Unable to update request , Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private checkAndUpdatePrimaryAndSupportingStreamFlag(userAction: string, currentPage: string) {
		if ((userAction === 'DRAFT' && currentPage === 'HEADER') || (userAction === 'DRAFT' && currentPage === 'PRIMARY') || (userAction === 'DRAFT' && currentPage === 'SECONDARY')) {
			this.checkForFlag();
		}
	}

	private populatePrimaryStreamData(carReq: CarRequest) {
		this.carRequest.psPnMapTransList = _.reverse(carReq.psPnMapTransList);
		this.populateDetailsForPrimaryStream();
	}

	private populatePrimaryStreamDataToSummary() {
		this.populateDetailsForPrimaryStream();
	}

	populateDetailsForPrimaryStream(): void {
		if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			const ids = this.carRequest.psPnMapTransList.map(psPnMapTran => psPnMapTran.processNameId);
			const pNameIds = ids.map(id => id).join('-');
			this.processNameService.findProcessNameByIdList(pNameIds).subscribe(processNameList => {
				const idMap = new Map();
				processNameList.forEach(pn => idMap.set(pn.id, pn));
				this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
					psPnMapTran.isPrimaryStream = true;
					psPnMapTran.processName = idMap.get(psPnMapTran.processNameId).processName;
					psPnMapTran.processDesc = idMap.get(psPnMapTran.processNameId).processDesc;
					psPnMapTran.formSubmitted = psPnMapTran.processDataList && psPnMapTran.processDataList.length > 0 && (psPnMapTran.processDataList[0].reqOee > 0 || psPnMapTran.processDataList[0].processDataId !== 0);
				});
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	private populateSuppStreamDataToSummary() {
		this.populateDetailsForSupportingStream(false);
	}

	private populateSuppStreamDataOnPrimaryStreamSave(carReq: CarRequest) {
		this.carRequest.primarySupportingMapTranList = carReq.primarySupportingMapTranList;
		this.populateDetailsForSupportingStream(true);
	}

	populateDetailsForSupportingStream(isSort: boolean) {
		if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			const arr = [];
			this.carRequest.primarySupportingMapTranList.forEach(priSuppMapTran => arr.push(...priSuppMapTran.psPnMapTranList));
			const ids = arr.map(psPnMapTran => psPnMapTran.processNameId);
			const pNameIds = ids.map(id => id).join('-');
			this.processNameService.findProcessNameByIdList(pNameIds).subscribe(processNameList => {
				const idMap = new Map();
				processNameList.forEach(pn => idMap.set(pn.id, pn));
				this.carRequest.primarySupportingMapTranList.forEach(priSuppMapTran => {
					this.processStreamService.findProcessStreamById(priSuppMapTran.supportingProcessStreamId).subscribe(processStream => {
						priSuppMapTran.processStreamName = processStream.processStreamName;
					}, error => {
						this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
					});
					if (priSuppMapTran.psPnMapTranList && priSuppMapTran.psPnMapTranList.length > 0) {
						priSuppMapTran.psPnMapTranList = isSort ? this.sortPsPnMapTranList(priSuppMapTran.psPnMapTranList) : priSuppMapTran.psPnMapTranList;
						priSuppMapTran.psPnMapTranList.forEach(psPnMapTran => {
							psPnMapTran.isPrimaryStream = false;
							psPnMapTran.processName = idMap.get(psPnMapTran.processNameId).processName;
							psPnMapTran.processDesc = idMap.get(psPnMapTran.processNameId).processDesc;

							psPnMapTran.formSubmitted = psPnMapTran.processDataList && psPnMapTran.processDataList.length > 0 && (psPnMapTran.processDataList[0].reqOee > 0 || psPnMapTran.processDataList[0].processDataId !== 0);
						});

					}
				});
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	reset(form: NgForm) {
		form.resetForm(Object.assign({}, emptyCarRequest));
		this.carRequest = Object.assign({}, emptyCarRequest);
		this.resetHeaderDropdown();
	}

	private resetHeaderDropdown() {
		/*this.carRequest.siteCodeObject = {name: 'Select SiteCode', code: 'invalid'};
		this.carRequest.siteCodeObject.code = 'invalid';*/
		this.carRequest.siteCodeObject = '';
		this.carRequest.capacityRequirementSourceObject = {name: '', code: 'invalid'};
		this.carRequest.capacityRequirementSource = '';
		this.carRequest.partPPAPLevelObject = {name: '', code: -1};
		this.carRequest.partPpapLevel = 0;
		this.carRequest.submissionScenarioObject = {
			templateName: '',
			id: -1,
			scenarioName: '',
			scenarioDesc: '',
			capacityStudy: false,
			rfqScenario: false,
			currentProdRun: null
		};
		this.carRequest.submissionScenario = 0;
	}

	onHeaderNext(isSaveAndContinue: boolean) {
		if (!this.isProcessStreamAvailable && (this.carRequest.requestStatus === 'REJECTED' || (this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason))) {
			this.stepper.next();
		} else {
			if (this.carRequest.programCode.length > 150) {
				this.inValidProgramCode = true;
			} else {
				this.inValidProgramCode = false;
			}
			if (this.carRequest.capacityRequirementSourceObject === null) {
				this.capacityRequirementSourceObjectRequired = true;
			} else {
				this.capacityRequirementSourceObjectRequired = false;
			}
			this.checkCapacityRequirementSource();
			this.headerFormSubmitted = true;
			/*commented temporarily: to handle newly on-boarded supplier when the Site code not available in SIM or for Tear 2 suppliers the system should allow them create nwe eCAR
			commented by: MSETHUR4
			if (this.mode !== 'view' && !this.supplierSiteDetails.siteDetailsLoaded) {
				this.showSupplierSiteErrorMessage();
				return false;
			}*/
			if (isSaveAndContinue && this.carRequest.requestStatus !== 'REJECTED' && this.carRequest.requestStatus !== 'STADRAFT') {
				this.carRequest.requestStatus = 'DRAFT';
			}
			this.checkForFlag();

			if (this.isHeaderFormValid('HEADER')) {
				if (isSaveAndContinue) {
					if (this.allHeaderMandatoryFieldsFilled(true)) {
						this.onSubmit('DRAFT', 'SaveAndContinueHeader');
					}
				}
			}
		}
	}

	isNumeric(value) {
		return /^-?\d+$/.test(value);
	}

	private checkForFlag() {
		if (this.mode === 'edit' || (this.mode === 'create' && this.isNeweCAR)) {
			if (this.carRequest.requestStatus !== 'SUBMITTED' && this.carRequest.requestStatus !== 'COMPLETED' && this.carRequest.requestStatus !== 'APPROVED' && this.carRequest.requestStatus !== 'ACKNOWLEDGED') {
				console.log('checking flag');
				this.checkFlagForRequirementsApwOrMpw();
				if (this.carRequest.requestStatus !== 'STADRAFT') {
					/*Optimization
					this.checkFlagForHMP();
					this.checkFlagForSL();*/
				}
				this.checkFlagForScenario();
				this.checkFlagForCapacityReqChange();
				this.checkFlagForPartPrefixBaseSuffix();
				if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
					this.carRequest.primarySupportingMapTranList.forEach(pSuppTran => {
						this.carRequestService.findRGPNextPresentAcrossStreams(this.carRequest.reqId, pSuppTran.primaryProcessStreamId, pSuppTran.primaryProcessNameId).subscribe(processDataList => {
							pSuppTran.psPnMapTranList.forEach(psPnTran => {
								this.manageEditButtonForSupportingStream(pSuppTran, psPnTran, processDataList);
							});
						});
					});
				}
			}
		}
	}

	private checkCapacityRequirementSource() {
		if (this.capacityStudy === true && this.carRequest.capacityRequirementSource === null) {
			this.capacityRequirementSourceRequired = true;
		} else {
			this.capacityRequirementSourceRequired = false;
		}
		this.capacityReqSourceForCapacityStudyFromDraft = '';
		if (this.capacityStudy) {
			this.capacityReqSourceForCapacityStudyFromDraft = this.carRequest.capacityRequirementSource;
		}
	}

	private checkFlagForRequirementsApwOrMpw() {
		if (this.carRequest.requirementType === 1) {
			if (this.requirementsAPWFromDraft !== this.carRequest.paRequirementsApw || this.requirementsMPWFromDraft !== this.carRequest.paRequirementsMpw) {
				if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
					this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
						psPnMapTran.processDataList.forEach(processData => {
							processData.apwMpwChange = true;
							if (processData.isCopiedCar) {
								processData.isCopiedCar = false;
							}
						});
					});
				}
				this.carRequest.primarySupportingMapTranList.forEach(supportingTransList => {
					supportingTransList.psPnMapTranList.forEach(psPnMapTranList => {
						psPnMapTranList.processDataList.forEach(supportingProcessData => {
							supportingProcessData.apwMpwChange = true;
							if (supportingProcessData.isCopiedCar) {
								supportingProcessData.isCopiedCar = false;
							}
						});
					});
				});
			}
		} else if (this.carRequest.requirementType === 2) {
			if (this.requirementsAPWFromDraft !== this.carRequest.revisedRequirementsApw || this.requirementsMPWFromDraft !== this.carRequest.revisedRequirementsMpw) {
				if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
					this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
						psPnMapTran.processDataList.forEach(processData => {
							processData.apwMpwChange = true;
						});
					});
				}
				this.carRequest.primarySupportingMapTranList.forEach(supportingTransList => {
					supportingTransList.psPnMapTranList.forEach(psPnMapTranList => {
						psPnMapTranList.processDataList.forEach(supportingProcessData => {
							supportingProcessData.apwMpwChange = true;
						});
					});
				});
			}
		} else {
			if (this.requirementsAPWFromDraft !== this.carRequest.gcpMcpvApw || this.requirementsMPWFromDraft !== this.carRequest.gcpMcpvMpw) {
				if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
					this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
						psPnMapTran.processDataList.forEach(processData => {
							processData.apwMpwChange = true;
						});
					});
				}
				this.carRequest.primarySupportingMapTranList.forEach(supportingTransList => {
					supportingTransList.psPnMapTranList.forEach(psPnMapTranList => {
						psPnMapTranList.processDataList.forEach(supportingProcessData => {
							supportingProcessData.apwMpwChange = true;
						});
					});
				});
			}
		}
	}

	private allHeaderMandatoryFieldsFilled(checkAlert: boolean): boolean {
		if (this.carRequest.isLinkCar) {
			if (this.carRequest.selectedCarIdForLinkageObject.code === 'invalid' || this.carRequest.selectedProcessNameIdForLinkageObject.code === 'invalid' || this.carRequest.selectedProcessNameIdForLinkageObject.code === 'invalid') {
				return false;
			}
		}
		if (!this.carRequest.siteCodeObject || this.carRequest.siteCodeObject === '' || !this.carRequest.prefix || this.carRequest.prefix === '' || !this.carRequest.base || this.carRequest.base === '' || !this.carRequest.suffix || this.carRequest.suffix === '') {
			return true;
		}
		if (this.carRequest.requirementType === 2) {
			if (this.carRequest.revisedRequirementsApw <= 0 || this.carRequest.revisedRequirementsApw.toString().trim() === '' || this.carRequest.revisedRequirementsMpw <= 0 || this.carRequest.revisedRequirementsMpw.toString().trim() === '') {
				return true;
			}
		} else if (this.carRequest.requirementType === 3) {
			if (this.carRequest.gcpMcpvApw <= 0 || this.carRequest.gcpMcpvApw.toString().trim() === '' || this.carRequest.gcpMcpvMpw <= 0 || this.carRequest.gcpMcpvMpw.toString().trim() === '') {
				return true;
			}
		}
		if (!this.carRequest.supplierName || this.carRequest.supplierName === '' || !this.carRequest.partName || this.carRequest.partName === '') {
			this.areAllHeaderMandatoryFieldsFilled = false;
		} else if (!this.carRequest.modelYear || this.carRequest.modelYear === '' || !this.carRequest.programCode || this.carRequest.programCode === '') {
			this.areAllHeaderMandatoryFieldsFilled = false;
		} else if (this.capacityStudy !== true && (!this.carRequest.priorityObject || this.carRequest.priorityObject.code === 'invalid' || !this.carRequest.partPPAPLevelObject || this.carRequest.partPPAPLevelObject.code === -1 || !this.carRequest.capacityRequirementSourceObject || this.carRequest.capacityRequirementSourceObject.code === 'invalid')) {
			this.areAllHeaderMandatoryFieldsFilled = false;
		} else if (this.capacityStudy !== true && this.carRequest.capacityRequirementSourceObject.code !== 'invalid' && this.isDescriptionValid()) {
			this.areAllHeaderMandatoryFieldsFilled = false;
		} else if (this.capacityStudy && this.carRequest.capacityRequirementSource !== '' && this.isDescriptionValid()) {
			this.areAllHeaderMandatoryFieldsFilled = false;
		} else if (!this.carRequest.keyContacts[0].name || this.carRequest.keyContacts[0].name === '' || !this.carRequest.keyContacts[1].name || this.carRequest.keyContacts[1].name === '' || !this.carRequest.keyContacts[2].name || this.carRequest.keyContacts[2].name === '') {
			this.areAllHeaderMandatoryFieldsFilled = false;
		} else if (!this.carRequest.keyContacts[0].mail || this.carRequest.keyContacts[0].mail === '' || !this.carRequest.keyContacts[1].mail || this.carRequest.keyContacts[1].mail === '' || !this.carRequest.keyContacts[2].mail || this.carRequest.keyContacts[2].mail === '') {
			this.areAllHeaderMandatoryFieldsFilled = false;
		} else if (!this.carRequest.keyContacts[0].phone || this.carRequest.keyContacts[0].phone === '' || !this.carRequest.keyContacts[1].phone || this.carRequest.keyContacts[1].phone === '' || !this.carRequest.keyContacts[2].phone || this.carRequest.keyContacts[2].phone === '') {
			this.areAllHeaderMandatoryFieldsFilled = false;
		} else if (this.capacityStudy === true && this.carRequest.capacityRequirementSource === null) {
			this.areAllHeaderMandatoryFieldsFilled = false;
		} else if (this.capacityStudy === false && this.carRequest.capacityRequirementSourceObject === null) {
			this.areAllHeaderMandatoryFieldsFilled = false;
		} else if (this.carRequest && this.carRequest.keyContacts[0].name && !this.carRequest.keyContacts[0].type.toLowerCase().includes('site') && !this.carRequest.keyContacts[0].type.toLowerCase().includes('sta') && !this.carRequest.keyContacts[0].type.toLowerCase().includes('super user')) {
			this.areAllHeaderMandatoryFieldsFilled = false;
		} else {
			this.areAllHeaderMandatoryFieldsFilled = true;
			return true;
		}
		if (checkAlert && !this.areAllHeaderMandatoryFieldsFilled && ((this.capacityStudy === false && this.carRequest.capacityRequirementSourceObject !== null) || (this.capacityStudy === true && this.carRequest.capacityRequirementSource !== null))) {
			this.confirmationService.confirm({
				message: 'One or more mandatory fields are not filled in, Are you sure you want to proceed?',
				header: 'Confirmation',
				icon: 'pi pi-info-circle',
				accept: () => {
					this.onSubmit('DRAFT', 'SaveAndContinueHeader');
				},
				reject: () => {
				}
			});
		}
		return this.areAllHeaderMandatoryFieldsFilled;
	}

	private isPPCLessThanPlannedCapacity(): boolean {
		const onePartDiscAPW = this.isOnePartDiscrepancyAPW();
		const onePartDiscMPW = this.isOnePartDiscrepancyMPW();
		if (this.carRequest && this.carRequest.commitmentMppc && this.carRequest.commitmentAppc && this.carRequest.plannedCapacityApw && this.carRequest.plannedCapacityMpw) {
			if (((onePartDiscAPW && this.carRequest.commitmentAppc > this.carRequest.plannedCapacityApw + 1) || (!onePartDiscAPW && this.carRequest.commitmentAppc > this.carRequest.plannedCapacityApw)) ||
				((onePartDiscMPW && this.carRequest.commitmentMppc > this.carRequest.plannedCapacityMpw + 1) || (!onePartDiscMPW && this.carRequest.commitmentMppc > this.carRequest.plannedCapacityMpw))) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}

	private isOnePartDiscrepancyAPW(): boolean {
		const requiredAPW = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsApw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsApw : this.carRequest.gcpMcpvApw;
		if (this.carRequest.plannedCapacityApw + 1 === requiredAPW && this.carRequest.commitmentAppc === requiredAPW) {
			return true;
		}
		return false;

	}

	private isOnePartDiscrepancyMPW(): boolean {
		const requiredMPW = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsMpw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsMpw : this.carRequest.gcpMcpvMpw;
		if (this.carRequest.plannedCapacityMpw + 1 === requiredMPW && this.carRequest.commitmentMppc === requiredMPW) {
			return true;
		}
		return false;

	}

	calculateAPPCMPPCDemonstratedCapacity() {
		const plannedAPW = [];
		const plannedMPW = [];
		const phase0PlannedAPW = [];
		const phase0PlannedMPW = [];
		const bottleNeckAPWMap = new Map<number, number>();
		const bottleNeckMPWMap = new Map<number, number>();
		const processSeqMap = new Map<number, number>();
		let bottleNeckProcessAPW, bottleNeckProcessMPW;

		let psPnMapTransList = _.cloneDeep(this.carRequest.psPnMapTransList);
		psPnMapTransList = _.reverse(psPnMapTransList);
		psPnMapTransList.forEach((psPnMapTran, index) => {
			processSeqMap.set(psPnMapTran.processNameId, index);
			let percentageScrap = 1;
			const weeklyPartEstimateAPW = psPnMapTran.processDataList[0].weekPartEstimate;
			const weeklyPartEstimateMPW = psPnMapTran.processDataList[1].weekPartEstimate;

			const phase0APW = Math.floor((psPnMapTran.processDataList[0].netAvailableTime * 3600 / psPnMapTran.processDataList[0].plannedNetIdealCycleTime) * (psPnMapTran.processDataList[0].phaseOEE / 100));
			const phase0MPW = Math.floor((psPnMapTran.processDataList[1].netAvailableTime * 3600 / psPnMapTran.processDataList[0].plannedNetIdealCycleTime) * (psPnMapTran.processDataList[0].phaseOEE / 100));
			for (let i = index + 1; i < psPnMapTransList.length; i++) {
				if (psPnMapTransList[i].processDataList[0].isApw) {
					let plannedRemainingPercentage = (100 - psPnMapTransList[i].processDataList[0].scrappedPercent) / 100;
					if (this.carRequest.submissionScenarioObject.templateName !== 'Capacity Planning') {
						const actualRemainingPercentage = (100 - psPnMapTransList[i].processDataList[0].noOfPartsScrappedPercent) / 100;
						plannedRemainingPercentage = Math.min(plannedRemainingPercentage, actualRemainingPercentage);	//if PPAP0 or PPAP3 - find the minimum Scrap % from Planned and Actual values
					}
					percentageScrap = percentageScrap * plannedRemainingPercentage;
				}
			}
			plannedAPW.push(weeklyPartEstimateAPW * percentageScrap);
			plannedMPW.push(weeklyPartEstimateMPW * percentageScrap);
			bottleNeckAPWMap.set(psPnMapTran.processNameId, Math.floor(weeklyPartEstimateAPW * percentageScrap));
			bottleNeckMPWMap.set(psPnMapTran.processNameId, Math.floor(weeklyPartEstimateMPW * percentageScrap));
			if (this.carRequest.submissionScenarioObject.templateName === 'Phase 0 PPAP') {
				phase0PlannedAPW.push(phase0APW * percentageScrap);
				phase0PlannedMPW.push(phase0MPW * percentageScrap);
			}
		});
		const plannedCapacityAPW = Math.floor(Math.min(...plannedAPW));
		const plannedCapacityMPW = Math.floor(Math.min(...plannedMPW));
		console.log('Planned APW: ' + plannedCapacityAPW + ' Planned MPW: ' + plannedCapacityMPW);
		const phase0DemonstratedCapacityAPW = Math.floor(Math.min(...phase0PlannedAPW));
		const phase0DemonstratedCapacityMPW = Math.floor(Math.min(...phase0PlannedMPW));
		if (plannedAPW && plannedMPW && !this.totalAllocationPercentageGreaterThan100 && !this.containAssumptionsHasFalse) {
			this.carRequest.plannedCapacityApw = plannedCapacityAPW ? plannedCapacityAPW : 0;
			this.carRequest.plannedCapacityMpw = plannedCapacityMPW ? plannedCapacityMPW : 0;
		}
		if (phase0PlannedAPW && phase0PlannedMPW) {
			this.carRequest.phase0DemonstratedCapacityApw = phase0DemonstratedCapacityAPW ? phase0DemonstratedCapacityAPW : 0;
			this.carRequest.phase0DemonstratedCapacityMpw = phase0DemonstratedCapacityMPW ? phase0DemonstratedCapacityMPW : 0;
		}

		bottleNeckAPWMap.forEach((value, key) => {
			if (value === plannedCapacityAPW) {
				bottleNeckProcessAPW = key;
			}
		});
		bottleNeckMPWMap.forEach((value, key) => {
			if (value === plannedCapacityMPW) {
				bottleNeckProcessMPW = key;
			}
		});
		if (bottleNeckProcessAPW === bottleNeckProcessMPW) {
			this.carRequest.bottleNeckProcessNameId = bottleNeckProcessAPW;
		} else {
			if (processSeqMap.get(bottleNeckProcessAPW) < processSeqMap.get(bottleNeckProcessMPW)) {
				this.carRequest.bottleNeckProcessNameId = bottleNeckProcessAPW;
			} else {
				this.carRequest.bottleNeckProcessNameId = bottleNeckProcessMPW;
			}
		}
	}

	isPlannedCapacityLessThanReqCapacity() {
		this.isPlannedLessThanReqCapacity = false;
		this.lessPlannedCapacityMpw = false;
		this.lessPlannedCapacityApw = false;
		const requiredCapacityApw = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsApw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsApw : this.carRequest.gcpMcpvApw;
		const requiredCapacityMpw = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsMpw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsMpw : this.carRequest.gcpMcpvMpw;
		const onePartDiscAPW = this.isOnePartDiscrepancyAPW();
		const onePartDiscMPW = this.isOnePartDiscrepancyMPW();
		if (this.carRequest && this.carRequest.plannedCapacityApw && this.carRequest.plannedCapacityMpw && requiredCapacityApw && requiredCapacityMpw) {
			if (((onePartDiscAPW && requiredCapacityApw > this.carRequest.plannedCapacityApw + 1) || (!onePartDiscAPW && requiredCapacityApw > this.carRequest.plannedCapacityApw)) ||
				((onePartDiscMPW && requiredCapacityMpw > this.carRequest.plannedCapacityMpw + 1) || (!onePartDiscMPW && requiredCapacityMpw > this.carRequest.plannedCapacityMpw))) {
				this.isPlannedLessThanReqCapacity = true;
			}
		}
		if (requiredCapacityApw > this.carRequest.plannedCapacityApw) {
			this.lessPlannedCapacityApw = true;
		} else {
			this.lessPlannedCapacityApw = false;
		}
		if (requiredCapacityMpw > this.carRequest.plannedCapacityMpw) {
			this.lessPlannedCapacityMpw = true;
		} else {
			this.lessPlannedCapacityMpw = false;
		}
	}

	onPrimaryStreamFormNext(isSaveAndContinue: boolean) {
		if (!this.isProcessStreamAvailable && (this.carRequest.requestStatus === 'REJECTED' || (this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason))) {
			this.stepper.next();
		} else {
			this.onNextPage();
			this.primaryStreamFormSubmitted = true;
			if (this.isValidPrimaryStreamForm()) {
				if (this.carRequest.requestStatus === 'DRAFT' || (this.isProcessStreamAvailable && this.carRequest.requestStatus === 'REJECTED') || this.carRequest.requestStatus === 'STADRAFT') {
					this.retrieveProcessStreamTree(isSaveAndContinue);
				}
			}
			this.onSupportingStreamPage = true;
		}
	}

	onSecondaryStreamFormNext(isSaveAndContinue: boolean) {
		if (!this.isProcessStreamAvailable && (this.carRequest.requestStatus === 'REJECTED' || (this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason))) {
			this.stepper.next();
		} else {
			this.onNextPage();
			if (isSaveAndContinue) {
				this.onSubmit('DRAFT', 'SaveAndContinueSupporting');
			}
			this.onSupportingStreamPage = false;
		}
	}

	private isValidSupportingStream() {
		this.supportingStreamFormSubmitted = true;
		let isValidSupporting = false;
		if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			isValidSupporting = this.isAllSupportingStreamProcessDataValid();
		} else {
			isValidSupporting = true;
		}
		return isValidSupporting;
	}

	isAllSupportingStreamProcessDataValid() {
		let isValid = true;
		this.carRequest.primarySupportingMapTranList.forEach((psPnMapTranList, index) => {
			psPnMapTranList.psPnMapTranList.forEach((psPnMapTran) => {
				if (psPnMapTran.processDataList.length > 0) {
					if ((psPnMapTran.processDataList[0].resultStatus === 'Incomplete') || (psPnMapTran.processDataList[1].resultStatus === 'Incomplete')) {
						if (isValid) {
							isValid = false;
							this.utilService.pushMsg('error', 'Incomplete', 'One or more processes in the Supporting Stream have incomplete data. Please review and populate the mandatory fields.');
						}
					} else if ((psPnMapTran.processDataList[0].resultStatus === 'Valid' || psPnMapTran.processDataList[0].resultStatus === 'Warning' || psPnMapTran.processDataList[0].resultStatus === 'Risk') && (psPnMapTran.processDataList[1].resultStatus === 'Valid' || psPnMapTran.processDataList[1].resultStatus === 'Warning' || psPnMapTran.processDataList[1].resultStatus === 'Risk')) {
						isValid = isValid ? true : false;
					} else {
						if (isValid) {
							isValid = false;
							this.utilService.pushMsg('error', 'Error', 'One or more processes in the Supporting Stream have data in error state. Please review and revise.');
						}
					}
				}
			});
		});
		return isValid;
	}

	isValidPrimaryStreamForm() {
		let isValid = true;
		if (this.selectedPrimaryStream.id <= 0) {
			isValid = false;
			this.utilService.pushMsg('error', 'Error', 'Please select any primary stream');
		}
		return isValid;
	}

	isAllProcessDataValidForPrimaryStream() {
		let isValid = true;
		if (this.carRequest.psPnMapTransList != null && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach((psPnMapTran, index) => {
				if (psPnMapTran.processDataList.length > 0) {
					if ((psPnMapTran.processDataList[0].resultStatus === 'Incomplete') || (psPnMapTran.processDataList[1].resultStatus === 'Incomplete')) {
						if (isValid) {
							isValid = false;
							this.utilService.pushMsg('error', 'Incomplete', 'One or more processes in the Primary Stream have incomplete data. Please review and populate the mandatory fields.');
						}
					} else if ((psPnMapTran.processDataList[0].resultStatus === 'Valid' || psPnMapTran.processDataList[0].resultStatus === 'Warning' || psPnMapTran.processDataList[0].resultStatus === 'Risk') && (psPnMapTran.processDataList[1].resultStatus === 'Valid' || psPnMapTran.processDataList[1].resultStatus === 'Warning' || psPnMapTran.processDataList[1].resultStatus === 'Risk')) {
						isValid = isValid ? true : false;
					} else {
						if (isValid) {
							isValid = false;
							this.utilService.pushMsg('error', 'Error', 'One or more processes in the Primary Stream have data in an error state. Please review and revise.');
						}
					}
				}
			});
		}
		return isValid;
	}

	isDataExistToDisplayConfirmDialogBox() {
		let isDataEntered = false;
		if (this.carRequest != null && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach((psPnMapTran, index) => {
				if (psPnMapTran.processDataList.length > 0) {
					if (psPnMapTran.processDataList[0].resultStatus === 'Valid') {
						isDataEntered = true;
					}
				}
			});
		}
		return isDataEntered;
	}

	showDialogBoxIfPrimaryStreamExist(event) {
		this.previousPrimaryStream = this.selectedPrimaryStream;
		this.selectedPrimaryStream = event;
		this.retrieveMapParameter(this.selectedPrimaryStream.id);
		if (this.selectedPrimaryStream.id > 0) {
			if (this.previousPrimaryStream.id < 0) {
				this.resetPrimaryAndSupportingStream();
				this.loadProcessNamesByPrimaryStreamId(this.selectedPrimaryStream);
			} else if (this.previousPrimaryStream.id > 0) {
				this.confirmationService.confirm({
					message: ' Are you sure you want to change Process Stream ? \n\tAll the Data saved in the existing process stream will be lost',
					header: 'Confirmation',
					icon: 'pi pi-info-circle',
					accept: () => {
						this.previousPrimaryStream = this.selectedPrimaryStream;
						this.selectedPrimaryStream = event;
						this.carRequestService.resetStream(this.carRequest).subscribe(value => {
							console.log(value);
						}, error => {
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
						this.resetPrimaryAndSupportingStream();
						this.loadProcessNamesByPrimaryStreamId(this.selectedPrimaryStream);
					},
					reject: () => {
						this.selectedPrimaryStream = this.previousPrimaryStream;
					}
				});
			} else {
				this.resetPrimaryAndSupportingStream();
				this.loadProcessNamesByPrimaryStreamId(this.selectedPrimaryStream);
			}
		} else {
			this.resetPrimaryAndSupportingStream();
		}
	}

	private resetPrimaryAndSupportingStream() {
		this.carRequest.removeProcessStreamData = false;
		this.isPrimaryStreamReset = true;
		this.carRequest.primaryStreamId = this.selectedPrimaryStream.id;
		this.carRequest.psPnMapTransList = [];
		this.carRequest.primarySupportingMapTranList = [];
	}

	loadProcessNamesByPrimaryStreamId(currentPrimaryStream: ProcessStream) {
		this.processNames = [];
		if (currentPrimaryStream.id > 0) {
			this.processStreamService.findProcessStreamById(currentPrimaryStream.id).subscribe(value => {
				this.processStream = value;
				this.loadPrimaryProcessName();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	loadPrimaryProcessName() {
		this.processNames = [];
		if ((this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason === null) || ((this.carRequest.requestStatus === 'REJECTED' || (this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason !== null)) && this.carRequest.keepHistoricalPSInRejRecall === false && this.isProcessStreamAvailable)) {
			this.processStreamService.retrieveSelectedProcessNameByProcessStreamId(this.selectedPrimaryStream.id).subscribe(value => {
				this.processNames = value;
				this.psProcessNamesOptions = value;
				this.psProcessNamesOptions.unshift(new ProcessName(emptyProcessNameDropDown));
				this.buildPsPnMapTransList();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.processStreamService.retrieveSelectedProcessNameByProcessStreamIdAndReqId(this.selectedPrimaryStream.id, this.carRequest.reqId).subscribe(value => {
				this.processNames = value;
				this.psProcessNamesOptions = value;
				this.psProcessNamesOptions.unshift(new ProcessName(emptyProcessNameDropDown));
				this.buildPsPnMapTransList();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	buildPsPnMapTransList() {
		if ((this.mode === 'edit' || this.mode === 'view') && this.carRequest.psPnMapTransList != null && this.carRequest.psPnMapTransList.length > 0) {
			// this.reversByOrderForEdit();
			this.carRequest.psPnMapTransList.forEach(value => {
				value.isEditButtonDisabled = true;
				value.isPrimaryStream = true;
			});
			this.carRequest.psPnMapTransList.forEach(processStreamProcessNameObject => {
				/*Optimization
				this.loadDemonOeeMap(processStreamProcessNameObject.processNameId, this.carRequest.siteCode);
				this.loadHMPOeeMap(processStreamProcessNameObject.processNameId, true);*/
				processStreamProcessNameObject.processName = this.psProcessNamesOptions.find(psProcessName => psProcessName.id === processStreamProcessNameObject.processNameId).processName;
				this.manageEditButton(processStreamProcessNameObject);
			});
			/*Optimization
			this.carRequest.primarySupportingMapTranList.forEach(primarySupportingMap => {
				primarySupportingMap.psPnMapTranList.forEach(psPnMapTranListValue => {
					this.loadDemonOeeMap(psPnMapTranListValue.processNameId, this.carRequest.siteCode);
					this.loadHMPOeeMap(psPnMapTranListValue.processNameId, false);
					//	psPnMapTranListValue.processName = this.psProcessNamesOptions.find(psProcessName => psProcessName.id === psPnMapTranListValue.processNameId).processName;
				});
			});*/
		} else {
			this.carRequest.psPnMapTransList = [];
			const processStreamProcessNameMap = _.reverse(this.processStream.processStreamProcessNameMap);
			processStreamProcessNameMap.forEach(processStreamProcessNameObject => {
				this.buildSinglePsPnMapObject(processStreamProcessNameObject);
			});
		}
	}

	onApprove(status, currentPage) {
		if (status === 'ACKNOWLEDGE') {
			this.isRejected = true;
			if (this.carRequest.comment === null || this.carRequest.comment === '') {
				this.utilService.pushMsg('error', 'Error', 'Please enter Acknowledge Comments');
				return false;
			}
		}
		if (!this.carRequest.keyContacts[3].name || this.carRequest.keyContacts[3].name.length === 0 || this.noMatchFoundSiteSupervisorCDSID === true || this.noValueSelecte === true) {
			this.noValueSelecte = false;
			this.siteSupervisorRequired = true;
		}
		console.log('deviation value ' + this.carRequest.isDeviationApproved);
		if (this.carRequest.isAnyProcessContainsAPWGreaterThan5 && this.carRequest.deviationReasonCode === 3 && this.loggedInUser && this.loggedInUser.includes('SiteEngineer') && (!this.carRequest.isDeviationApproved || this.carRequest.isDeviationApproved === 'false')) {
			this.utilService.pushMsg('error', 'Error', 'Please approve deviation for the non-standard operating pattern(s) identified');
		} else {
			let confirmationMsg = '';
			let cnt = 0;
			const declarationResponse = this.isMandatoryQuestionsValid && (this.carRequest.declarationQuestionsTranList[0].yesNoAnswer === 'No' || this.carRequest.declarationQuestionsTranList[4].yesNoAnswer === 'No' ||
				(this.declarationQuestionTranTmpList[0].yesNoAnswer !== 'NA' && this.declarationQuestionTranTmpList[1].openTypeAnswer.trim() !== this.declarationQuestionTranTmpList[2].openTypeAnswer.trim()));
			const appcMppcRiskResponse = this.isAPPCCommitmentLower || this.isAPPCCommitmentHigher || this.isMPPCCommitmentLower || this.isMPPCCommitmentHigher;
			const appcMppcWarningResponse = this.isAPPCCommitment10PercentHigher || this.isMPPCCommitment10PercentHigher;
			if (this.allProcessContainsAnyErrors === 'Risk' || this.allProcessContainsAnyErrors === 'Warning' || this.carRequest.isAnyProcessContainsAPWGreaterThan5 || declarationResponse || appcMppcRiskResponse || appcMppcWarningResponse) {
				if (this.allProcessContainsAnyErrors === 'Risk' || this.allProcessContainsAnyErrors === 'Warning') {
					cnt++;
					confirmationMsg = cnt + '.  One or more Processes shows a Warning or Risk\n\t';
				}
				if (this.carRequest.isAnyProcessContainsAPWGreaterThan5) {
					cnt++;
					confirmationMsg += cnt + '.  Operating pattern for one or more Processes shows a Warning\n\t';
				}
				if (declarationResponse) {
					cnt++;
					confirmationMsg += cnt + '.  Declaration shows one or more Risks\n\t';
				}
				if (appcMppcRiskResponse && appcMppcWarningResponse) {
					cnt++;
					confirmationMsg += cnt + '.  APPC / MPPC is at Risk or Warning\n';
				} else if (appcMppcRiskResponse) {
					cnt++;
					confirmationMsg += cnt + '.  APPC / MPPC is at Risk\n';
				} else if (appcMppcWarningResponse) {
					cnt++;
					confirmationMsg += cnt + '.  APPC / MPPC is in Warning\n';
				}
			} else {
				if (['SiteEngineer', 'STASiteEngineer'].includes(this.loggedInUser) && this.workflowCheck === true) {
					if (this.noMatchFoundSiteSupervisorCDSID !== false || this.siteSupervisorRequired !== false) {
						this.siteSupervisorRequired = true;
					} else {
						this.carRequestService.getMailFromCDSId(this.carRequest.keyContacts[3].cdsid).subscribe(response => {
							this.apsService.retrieveAtrributesForEngineerKeyContactCheck(this.carRequest.keyContacts[3].cdsid).subscribe(supInAPS => {
								if (supInAPS && (supInAPS.userRole.toLowerCase().includes('site') || supInAPS.userRole.toLowerCase().includes('sta') || supInAPS.userRole.toLowerCase().includes('super user'))) {
									this.isSiteSupervisorPresent = true;
									this.supNotAvailableInAps = false;
									this.checkSupervisorRoleBeforeSubmit(status, currentPage);
								} else {
									this.isSiteSupervisorPresent = false;
									this.supNotAvailableInAps = true;
									this.utilService.pushMsg('error', 'Error', 'The selected Site Supervisor  doesn\'t have Site Supervisor role, Please change');
								}
							}, err => {
								this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
							});
						}, err => {
							this.isSiteSupervisorPresent = false;
							this.supNotAvailableInAps = false;
							this.utilService.pushMsg('error', 'Error', 'The selected Site Supervisor does not exist in the directory, please update.');
						});
					}
				} else {
					this.onSubmit(status, currentPage);
				}
			}

			if (confirmationMsg !== '') {
				if (status === 'APPROVE') {
					confirmationMsg = confirmationMsg + '\nAre you sure you want to approve?';
				} else {
					confirmationMsg = confirmationMsg + '\nAre you sure you want to acknowledge?';
				}
				this.confirmationService.confirm({
					message: confirmationMsg,
					header: 'Confirmation',
					icon: 'pi pi-info-circle',
					accept: () => {
						if (['SiteEngineer', 'STASiteEngineer'].includes(this.loggedInUser) && this.workflowCheck === true) {
							if (this.noMatchFoundSiteSupervisorCDSID !== false || this.siteSupervisorRequired !== false) {
								this.siteSupervisorRequired = true;
							} else {
								this.carRequestService.getMailFromCDSId(this.carRequest.keyContacts[3].cdsid).subscribe(response => {
									this.apsService.retrieveAtrributesForEngineerKeyContactCheck(this.carRequest.keyContacts[3].cdsid).subscribe(supInAPS => {
										if (supInAPS && (supInAPS.userRole.toLowerCase().includes('site') || supInAPS.userRole.toLowerCase().includes('sta') || supInAPS.userRole.toLowerCase().includes('super user'))) {
											this.isSiteSupervisorPresent = true;
											this.supNotAvailableInAps = false;
											this.checkSupervisorRoleBeforeSubmit(status, currentPage);
										} else {
											this.isSiteSupervisorPresent = false;
											this.supNotAvailableInAps = true;
											this.utilService.pushMsg('error', 'Error', 'The selected Site Supervisor  doesn\'t have Site Supervisor role, Please change');
										}
									}, err => {
										this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
									});
								}, err => {
									this.isSiteSupervisorPresent = false;
									this.supNotAvailableInAps = false;
									this.utilService.pushMsg('error', 'Error', 'The selected Site Supervisor does not exist in the directory, please update.');
								});
							}
						} else {
							this.onSubmit(status, currentPage);
						}
					},
					reject: () => {
						console.log('no action');
					}
				});
			}
		}
	}

	private reversByOrderForEdit() {
		const psPnMapTransList = _.reverse(this.carRequest.psPnMapTransList);
		this.carRequest.psPnMapTransList = [];
		this.carRequest.psPnMapTransList = psPnMapTransList;
	}

	private buildSinglePsPnMapObject(processStreamProcessNameObject: ProcessStreamProcessNameMap) {
		const psPnTrans = this.createPsPnTransObject(processStreamProcessNameObject);
		this.carRequest.psPnMapTransList.push(psPnTrans);
		/* Optimization
		this.loadDemonOeeMap(processStreamProcessNameObject.processNameId, this.carRequest.siteCode);*/
		this.manageEditButton(psPnTrans);
	}

	createPsPnTransObject(processStreamProcessNameObject: ProcessStreamProcessNameMap) {
		const psPnTrans = new PsPnMapTran({
			'reqId': 0,
			'processStreamId': 0,
			'processNameId': 0,
			'processName': null,
			'processOrderId': 0,
			'createdBy': '',
			'createdDate': null,
			'updatedBy': '',
			'updatedDate': null,
			'processDataList': [],
			'isEditButtonDisabled': true,
			'formSubmitted': false,
			'isPrimaryStream': true,
			'processDesc': '',
			'sharedLoadingLink': '',
			'masterDataFlag': false
		});
		psPnTrans.reqId = this.carRequest.reqId;
		psPnTrans.processNameId = processStreamProcessNameObject.processNameId;
		psPnTrans.processName = this.processNames.find(value => value.id === processStreamProcessNameObject.processNameId).processName;
		psPnTrans.processDesc = this.processNames.find(value => value.id === processStreamProcessNameObject.processNameId).processDesc;
		psPnTrans.processStreamId = this.selectedPrimaryStream.id;
		psPnTrans.processOrderId = processStreamProcessNameObject.processOrderId;
		this.setEmptyProcessData(psPnTrans);
		return psPnTrans;
	}

	setEmptyProcessData(psPnTrans: PsPnMapTran) {
		psPnTrans.processDataList = [];
		psPnTrans.processDataList.push(Object.assign({}, emptyProcessData));
		psPnTrans.processDataList.push(Object.assign({}, emptyProcessData));
	}

	loadDemonOeeMap(processNameId: number, siteCode: string) {
		let demonOee = 0;
		this.historicalMfgPerfObj = {
			processId: processNameId,
			siteCode: this.carRequest.siteCode
		};
		if (!this.carRequest.requestStatus || this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED') {
			this.hmpService.findHistMfgPerfRecords(this.historicalMfgPerfObj).subscribe((response: HistoricalMfgPerf[]) => {
				this.historicalmfgperfs = response;
				demonOee = this.utilService.calculateAverageOEE(this.historicalmfgperfs);
				this.demonOEEList.push(new DemonOEEMap({'id': processNameId, 'demonOee': demonOee}));
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.hmpService.findHistMfgPerfRecordsByCarId(this.historicalMfgPerfObj, this.carRequest.reqId).subscribe((response: HistoricalMfgPerf[]) => {
				this.historicalmfgperfs = response;
				demonOee = this.utilService.calculateAverageOEE(this.historicalmfgperfs);
				this.demonOEEList.push(new DemonOEEMap({'id': processNameId, 'demonOee': demonOee}));
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	findDemonOee(psPnMapTransObject: PsPnMapTran) {
		if (this.carRequest.requestStatus === 'STADRAFT') {
			return psPnMapTransObject.processDataList[0].averageHisOee;
		} else {
			const demonOEEMap = this.demonOEEList.find(value => value.id === psPnMapTransObject.processNameId);
			if (demonOEEMap) {
				return demonOEEMap.demonOee;
			} else {
				return 0;
			}
		}
	}

	manageEditButton(psPnMapTransObject: PsPnMapTran) {
		this.enableFirstProcessEditButton();
		if (psPnMapTransObject.processDataList && psPnMapTransObject.processDataList.length > 0) {
			if (psPnMapTransObject.processDataList[0].reqGoodPartsNext && psPnMapTransObject.processDataList[1].reqGoodPartsNext && psPnMapTransObject.processDataList[0].reqGoodPartsNext > 0 && psPnMapTransObject.processDataList[1].reqGoodPartsNext > 0) {
				const psPnMapTran = this.carRequest.psPnMapTransList.find(value => value.processOrderId === (psPnMapTransObject.processOrderId - 1));
				if (psPnMapTran) {
					psPnMapTran.isEditButtonDisabled = false;
				}
			}
		}
	}

	enableFirstProcessEditButton() {
		this.carRequest.psPnMapTransList[0].isEditButtonDisabled = false;
	}

	assignPsPnMapTransList(psPnMapTransIncomeObject: PsPnMapTran) {
		if (psPnMapTransIncomeObject.isPrimaryStream) {
			this.carRequest.psPnMapTransList.forEach((psPnMapTran, index) => {
				if (psPnMapTran.processNameId === psPnMapTransIncomeObject.processNameId) {
					const psPnTranTem = Object.assign({}, psPnMapTransIncomeObject);
					psPnMapTran.processDataList[0] = psPnTranTem.processDataList[0];
					psPnMapTran.processDataList[1] = psPnTranTem.processDataList[1];
					psPnMapTran.formSubmitted = psPnTranTem.formSubmitted;
					//this.carRequest.psPnMapTransList[index] = Object.assign({}, psPnMapTransObject);
				}
			});
			this.manageEditButton(psPnMapTransIncomeObject);
		} else {
			if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
				this.carRequest.primarySupportingMapTranList.forEach((primarySupportingMapTran, supportingIndex) => {
					if (primarySupportingMapTran.supportingProcessStreamId === psPnMapTransIncomeObject.processStreamId) {
						primarySupportingMapTran.psPnMapTranList.forEach((psPnMapTran, psPnIndex) => {
							if (psPnMapTran.processNameId === psPnMapTransIncomeObject.processNameId) {
								this.carRequest.primarySupportingMapTranList[supportingIndex].psPnMapTranList[psPnIndex] = Object.assign({}, psPnMapTransIncomeObject);
							}
						});
						this.carRequestService.findRGPNextPresentAcrossStreams(this.carRequest.reqId, primarySupportingMapTran.primaryProcessStreamId, primarySupportingMapTran.primaryProcessNameId).subscribe(processDataList => {
							this.manageEditButtonForSupportingStream(primarySupportingMapTran, psPnMapTransIncomeObject, processDataList);
						});
					}
				});
			}
		}
	}

	retrieveProcessStreamTree(isSaveAndContinue: boolean) {
		if (this.mode === 'create' || this.isPrimaryStreamReset) {
			this.processStreamService.findProcessStreamTreeById(this.selectedPrimaryStream.id).subscribe(value => {
				this.filterAndBuildSupportingMap(value);
				if (isSaveAndContinue) {
					this.onSubmit('DRAFT', 'SaveAndContinuePrimary');
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			if ((this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason === null) || ((this.carRequest.requestStatus === 'REJECTED' || (this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason !== null)) && this.isProcessStreamAvailable && this.carRequest.keepHistoricalPSInRejRecall === false)) {
				this.processStreamService.findProcessStreamTreeById(this.selectedPrimaryStream.id).subscribe(value => {
					this.filterAndBuildSupportingMap(value);
					if (isSaveAndContinue) {
						this.onSubmit('DRAFT', 'SaveAndContinuePrimary');
					}
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			} else {
				this.processStreamService.findProcessStreamTranTreeById(this.carRequest.primaryStreamId, this.carRequest.reqId).subscribe(value1 => {
					this.filterAndBuildSupportingMap(value1);
					if (isSaveAndContinue) {
						this.onSubmit('DRAFT', 'SaveAndContinuePrimary');
					}
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			}
		}
	}

	filterAndBuildSupportingMap(processStreams: ProcessStream[]) {
		const suppStreamNamesInStreamTree = [];
		let suppStreamNamesInCarReq = [];
		processStreams.forEach(processStream => {
			if (!processStream.isPrimaryStream) {
				suppStreamNamesInStreamTree.push(processStream.processStreamName);
				if (this.carRequest.primarySupportingMapTranList.length > 0) {
					this.carRequest.primarySupportingMapTranList.forEach(primarySuppMapTran => {
						suppStreamNamesInCarReq.push(primarySuppMapTran.processStreamName);
					});
				}
			}
		});

		suppStreamNamesInCarReq = Array.from(new Set(suppStreamNamesInCarReq));
		if (suppStreamNamesInStreamTree.length > suppStreamNamesInCarReq.length) {
			console.log('Adding new supporting stream');
			const distinctSuppStreamNames = suppStreamNamesInStreamTree.filter(suppStream => suppStreamNamesInCarReq.indexOf(suppStream) < 0);
			processStreams.forEach(processStream => {
				distinctSuppStreamNames.forEach(suppStreamName => {
					if (!processStream.isPrimaryStream && processStream.processStreamName === suppStreamName) {
						const supportingMap = this.buildSupportingMap(processStream);
						this.carRequest.primarySupportingMapTranList.push(supportingMap);
					}
				});
			});
		}
	}

	buildSupportingMap(supportingStream: ProcessStream) {
		let supportingMap = null;
		supportingMap = new PrimarySupportingMapTran(Object.assign({}, dummyPrimarySupportingMapTran));
		supportingMap.primaryProcessStreamId = supportingStream.primarySupportingMap['primaryProcessStreamId'];
		supportingMap.supportingProcessStreamId = supportingStream.primarySupportingMap['supportingProcessStreamId'];
		supportingMap.primaryProcessNameId = supportingStream.primarySupportingMap['primaryProcessNameId'];
		supportingMap.processStreamName = supportingStream.processStreamName;
		supportingMap.originProcessStreamId = this.selectedPrimaryStream.id;
		supportingMap.carReqId = this.carRequest.reqId;
		supportingMap.psPnMapTranList = [];
		const supportingProcessNameMap = _.reverse(supportingStream.processStreamProcessNameMap);
		supportingProcessNameMap.forEach(supportingProcessNameObject => {
			supportingMap.psPnMapTranList.push(this.buildSinglePsPnMapObjectSupporting(supportingStream, supportingProcessNameObject));
		});
		return supportingMap;
	}

	buildSinglePsPnMapObjectSupporting(processStream: ProcessStream, supportingProcessNameObject: ProcessStreamProcessNameMap): PsPnMapTran {
		const psPnTrans = this.createPsPnTransObjectSupporting(processStream, supportingProcessNameObject);
		/* Optimization
		this.loadDemonOeeMap(supportingProcessNameObject.processNameId, this.carRequest.siteCode);*/
		//	this.manageEditButtonSupporting(psPnTrans); -- this method is not working for enalbing the edit button
		return psPnTrans;
	}

	createPsPnTransObjectSupporting(processStream: ProcessStream, supportingProcessNameObject: ProcessStreamProcessNameMap): PsPnMapTran {
		const psPnTrans = Object.assign({}, new PsPnMapTran({
			'reqId': 0,
			'processStreamId': 0,
			'processNameId': 0,
			'processName': null,
			'processOrderId': 0,
			'createdBy': '',
			'createdDate': null,
			'updatedBy': '',
			'updatedDate': null,
			'processDataList': [],
			'isEditButtonDisabled': false,
			'formSubmitted': false,
			'isPrimaryStream': false,
			'processDesc': '',
			'sharedLoadingLink': '',
			'masterDataFlag': false
		}));
		psPnTrans.reqId = this.carRequest.reqId;
		psPnTrans.processNameId = supportingProcessNameObject.processNameId;
		psPnTrans.processName = processStream['availableProcessNames'].find(value => value.id === supportingProcessNameObject.processNameId).processName;
		psPnTrans.processDesc = processStream['availableProcessNames'].find(value => value.id === supportingProcessNameObject.processNameId).processDesc;
		psPnTrans.processStreamId = supportingProcessNameObject.processStreamId;
		psPnTrans.processOrderId = supportingProcessNameObject.processOrderId;
		this.setEmptyProcessData(psPnTrans);
		/* Optimization
		this.loadDemonOeeMap(supportingProcessNameObject.processNameId, this.carRequest.siteCode);*/
		return psPnTrans;
	}

	onProcessStreamMappingView() {
		this.router.navigate([this.processStreamMappingPath + this.selectedPrimaryStream.id]);
	}

	manageEditButtonSupporting(psPnMapTransObject: PsPnMapTran) {
		this.carRequest.primarySupportingMapTranList.forEach(primarySupportingMapTran => {
			//primarySupportingMapTran.psPnMapTranList[0].isEditButtonDisabled = false;
			primarySupportingMapTran.psPnMapTranList.forEach(psPnMapTran => {
				psPnMapTran.isEditButtonDisabled = false;
				/*if (psPnMapTran.processDataList && psPnMapTran.processDataList.length > 0 && psPnMapTran.processDataList[0].reqOee > 0) {
					const psPnMapTranTemp = primarySupportingMapTran.psPnMapTranList.find(value => value.processOrderId === (psPnMapTransObject.processOrderId - 1));
					if (psPnMapTranTemp) {
						psPnMapTranTemp.isEditButtonDisabled = false;
					}
				}*/
			});
		});
	}

	/*manageSupportingStreamReqGoodParts() {
		this.carRequest.primarySupportingMapTranList.forEach((primarySupportingMapTran, index) => {
			this.carRequest.psPnMapTransList.forEach((psPnMapTran, psPnMapTranIndex) => {
				if (psPnMapTran.processNameId === primarySupportingMapTran.primaryProcessNameId) {
					if (this.carRequest.primarySupportingMapTranList[index].psPnMapTranList[0]) {
						this.carRequest.primarySupportingMapTranList[index].psPnMapTranList[0].processDataList[0].reqGoodPartsNext = psPnMapTran.processDataList[0].reqGoodPartsNext;
					}
				}
			});
		});
	}*/

	private fetchDeclarationQuestions(template: string) {
		this.declarationQuestionsService.findByTemplate(template).subscribe(masterObject => {
			const masterQuestions = [];
			this.declarationQuestionTranTmpList = [];
			masterObject.forEach(question => {
				masterQuestions.push(new DeclarationQuestionsTran(question));
			});
			if (this.carRequest && this.carRequest.declarationQuestionsTranList && this.carRequest.declarationQuestionsTranList.length > 0) {
				const existingQuestionList = this.carRequest.declarationQuestionsTranList.filter(function (cv) {
					return masterQuestions.find(function (e) {
						return e.masterQuestionId === cv.masterQuestionId;
					});
				});
				const mergedArray = [...existingQuestionList, ...masterQuestions];
				const set = new Set();
				const unionArray = mergedArray.filter(item => {
					if (!set.has(item.masterQuestionId) && (template === item.template || item.template === 'All')) {
						set.add(item.masterQuestionId);
						return true;
					}
					return false;
				}, set);
				this.declarationQuestionTranTmpList = unionArray;
			} else {
				masterQuestions.forEach(question => {
					this.declarationQuestionTranTmpList.push(new DeclarationQuestionsTran(question));
				});
			}
			this.questionLoaded = true;
			// For fixing question arrangement issue: Sorting the question list by its ID
			this.declarationQuestionTranTmpList.sort(function (left, right) {
				return left.masterQuestionId - right.masterQuestionId;
			});
			this.disableDeclarationFields();
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});

	}

	private fetchDeclarationQuestionsFromMaster(template: string) {
		this.declarationQuestionsService.findByTemplate(template).subscribe(questions => {
			questions.forEach(question => {
				this.declarationQuestionTranTmpList.push(new DeclarationQuestionsTran(question));
			});
		});
	}

	selectionChange($event: StepperSelectionEvent) {
		if ($event.selectedIndex === 2) {
			this.onSupportingStreamPage = true;
			if (this.isNeweCAR) {
				this.populateDetailsForSupportingStream(true);
			}
		} else {
			this.onSupportingStreamPage = false;
		}
		if (this.mode === 'edit' && this.carRequest.capacityRequirementSource === null && (this.carRequest.requestStatus === 'STADRAFT' || this.carRequest.requestStatus === 'DRAFT')) {
			const dialogueRefCarReq = this.dialogService.open(OndeleteDialogueboxComponent, {
				header: 'Alert',
				width: '50%',
				contentStyle: {'max-height': '700px', 'overflow': 'auto'},
				data: {
					'message': 'CapacitySourceAlertOnEditeCAR'
				}
			});
			dialogueRefCarReq.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
				this.stepper.selectedIndex = 0;
			});
		} else {
			this.onNextPage();
			this.populateOriginPage($event);
			this.checkForFlag();
			if (($event.selectedIndex === 1 && this.mode === 'view') || this.carRequest.requestStatus === 'STADRAFT') {
				this.primaryStreamForm.form.disable(true);
			} else if ($event.selectedIndex === 2) {
				if (this.selectedPrimaryStream && this.selectedPrimaryStream.id && this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length === 0) {
					if (this.mode !== 'view') {
						this.retrieveProcessStreamTree(false);
					}
				}
			}
			if ($event.selectedIndex === 3 && !this.questionLoaded) {
				this.fetchDeclarationQuestions(this.carRequest.submissionScenarioObject.templateName);
				this.questionLoaded = true;
			}
			if ($event.selectedIndex === 4) {
				this.checkSummary = true;
				if (this.selectedPrimaryStream && this.selectedPrimaryStream.id) {
					this.carRequestService.pushViewStreamStatus(true);
				}
				this.carRequestService.pushAnchorsStreamStatus(true);
				this.allHeaderMandatoryFieldsFilled(false);
				this.isAllMandatoryQuestionariesValid();
				this.populateAwDaysPerWeek();
				this.printLabel = 'Save & Print';
				if (this.mode === 'view') {
					this.printLabel = 'Print';
					this.isSaveAndPrint = false;
				}
				this.summaryScreenProcessDetailBoxDataLoad();
				this.allProcessContainsAnyErrors = this.utilService.isPrimaryAndSecondaryStreamProcessContainsErrors(this.carRequest);
				if (this.carRequest.requestStatus !== 'SUBMITTED' && this.carRequest.requestStatus !== 'APPROVED' && this.carRequest.requestStatus !== 'ACKNOWLEDGED' && this.carRequest.requestStatus !== 'COMPLETED') {
					this.calculateAPPCMPPCDemonstratedCapacity();
				}
				this.validateAndShowAPPCAndMPPCWarning();
				this.isPlannedCapacityLessThanReqCapacity();
				if (!this.hideProcessDetailsValidation) {
					this.processNameService.findProcessNameById(this.carRequest.bottleNeckProcessNameId).subscribe(processNameObj => {
						this.bottleNeckProcessName = processNameObj.processName;
					});
				}
				this.hmpService.triggerHMPChartSubject.next(this.carRequest);
				this.checkAcknowledgmentWorkflow();
			}
			if ($event.selectedIndex !== 3) {
				this.isEditDeclDate = false;
			}
			if ($event.selectedIndex !== 4 && this.checkSummary) {
				this.carRequestService.pushDeleteAnchorsStream(true);
				this.checkSummary = false;
				this.isEditProposedDate = false;
			}
			if ($event.selectedIndex === 4 && (this.carRequest.requestStatus === 'SUBMITTED' || this.carRequest.requestStatus === 'APPROVED' || this.carRequest.requestStatus === 'ACKNOWLEDGED' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'COMPLETED')) {
				this.carRequestService.findReqHistoriesById(this.carRequest.reqId).subscribe(response => {
					this.reqStateHistories = response;
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			}
		}
	}

	private populateOriginPage($event: StepperSelectionEvent) {
		if ($event.selectedIndex === 0) {
			this.originPage = 'HEADER';
		} else if ($event.selectedIndex === 1) {
			this.originPage = 'PRIMARY';
		} else if ($event.selectedIndex === 2) {
			this.originPage = 'SUPPORTING';
		} else if ($event.selectedIndex === 3) {
			this.originPage = 'DECLARATION';
		} else if ($event.selectedIndex === 4) {
			this.originPage = 'SUMMARY';
		}
	}

	private populateAwDaysPerWeek() {
		this.carRequest.isAnyProcessContainsAPWGreaterThan5 = this.utilService.isPrimaryAndSecondaryStreamProcessContainsAPWGreaterThan5(this.carRequest);
		if (!this.carRequest.isAnyProcessContainsAPWGreaterThan5) {
			this.carRequest.isAnyProcessContainsAPWGreaterThan5 = this.utilService.isPrimaryAndSecondaryStreamProcessContainsMPWGreaterThan6(this.carRequest);
		}
		if (this.carRequest.isAnyProcessContainsAPWGreaterThan5) {
			this.carRequest.deviationReasonCode = this.carRequest.deviationReasonCode ? this.carRequest.deviationReasonCode : -1;
			this.carRequest.deviationReasonObject = this.deviationReasonOptions.find(value1 => value1.code === this.carRequest.deviationReasonCode);
		}
	}

	checkAcknowledgmentWorkflow() {
		if (this.loggedInUser.includes('ECAR_UPDATE')) {
			if (null != this.carRequest.carVisibilitySupplier) {
				this.carRequest.carVisibilityObjectSupplier = this.carVisibilitySupplierOption.find(value2 => value2.code === this.carRequest.carVisibilitySupplier);
			}
		} else {
			if (null != this.carRequest.carVisibilityFord) {
				this.carRequest.carVisibilityObjectFord = this.carVisibilityFordOption.find(value2 => value2.code === this.carRequest.carVisibilityFord);
			}
		}
		if (this.areAllHeaderMandatoryFieldsFilled && this.isMandatoryQuestionsValid && this.allProcessContainsAnyErrors !== 'Error' && this.allProcessContainsAnyErrors !== 'Incomplete') {
			const appc = this.carRequest.commitmentAppc ? this.carRequest.commitmentAppc : 0;
			const mppc = this.carRequest.commitmentMppc ? this.carRequest.commitmentMppc : 0;
			this.carRequestService.isAcknowledgmentWorkflow(this.carRequest.reqId, appc, mppc, this.carRequest.plannedCapacityApw, this.carRequest.plannedCapacityMpw).subscribe(isAckWorkflow => {
				this.isReqNeedsAcknowledgment = false;
				console.log('before ' + this.isReqNeedsAcknowledgment);
				if (isAckWorkflow) {
					this.isReqNeedsAcknowledgment = true;
					console.log('after ' + this.isReqNeedsAcknowledgment);
					if (this.isReqNeedsAcknowledgment && this.capacityStudy) {
						this.carRequest.proposedPlanCode = this.carRequest.proposedPlanCode ? this.carRequest.proposedPlanCode : -1;
						this.carRequest.proposedPlanObject = this.proposedPlanOptions.find(value1 => value1.code === this.carRequest.proposedPlanCode);
						this.maxPlanDateTime = new Date();
						this.maxPlanDateTime.setDate(this.maxPlanDateTime.getDate() + 28);
						this.planYearRange = new Date().getFullYear() + ':' + this.maxPlanDateTime.getFullYear();
						if (this.carRequest.proposedPlanDate) {
							this.planDate = new Date(this.carRequest.proposedPlanDate);
							this.planDateDisplay = Date.parse(this.utilService.convertDateStringInFormat(this.carRequest.proposedPlanDate));
						}
					}
				} else if (this.capacityStudy && (this.carRequest.proposedPlanDate || this.carRequest.proposedPlanCode)) {
					this.carRequest.proposedPlanCode = null;
					this.carRequest.proposedPlanDate = null;
				}
			});
		}
	}

	onReject(status, currentPage: string) {
		if (status === 'REJECT') {
			this.isRejected = true;
		}
		if (this.carRequest.comment === null || this.carRequest.comment === '') {
			this.utilService.pushMsg('error', 'Error', 'Please enter Reject Comments');
		} else if (['SiteEngineer', 'STASiteEngineer'].includes(this.loggedInUser) && this.workflowCheck) {
			this.carRequestService.getMailFromCDSId(this.carRequest.keyContacts[3].cdsid).subscribe(response => {
				this.isSiteSupervisorPresent = true;
				this.enableRejection = false;
				this.checkSupervisorRoleBeforeSubmit(status, currentPage);
			}, err => {
				this.isSiteSupervisorPresent = false;
				this.utilService.pushMsg('error', 'Error', 'The selected Site Supervisor does not exist in the directory, please update.');
			});
		} else {
			this.enableRejection = false;
			this.onSubmit(status, currentPage);
		}
	}

	/**
	 * method name 	:	validateQuestionarie
	 * Description	:	This method used to update the answers to common field 'answer'. from diff type of answer properties based on ansType property
	 * return		: 	boolean
	 * */
	private updateQuestionarieAnswer() {
		const updatedQustnList: DeclarationQuestionsTran[] = [];
		this.declarationQuestionTranTmpList.forEach(question => {
			if (question.ansType === 'Descriptive') {
				question.answer = question.openTypeAnswer;
			} else if (question.ansType === 'Yes / No') {
				question.answer = question.yesNoAnswer;
				question.optionStr = JSON.stringify(question.optionObj);
			} else if (question.ansType === 'Date Picker' && question.dateDisplayAnswer) {
				question.dateAnswer = question.dateDisplayAnswer.toDateString();
				question.answer = question.dateAnswer;
				question.dateAnswerDisplay = Date.parse(this.utilService.convertDateStringInFormat(question.dateAnswer));
				console.log('Received dateAnswer is ' + question.dateAnswer);
			}
			updatedQustnList.push(question);
		});
		this.declarationQuestionTranTmpList = updatedQustnList;
	}

	/**
	 * method name 	:	isAllMandatoryQuestionariesValid
	 * Description	:	This method used to validate the questionnaires list, all the mandatory fields were answered or not.
	 * return		: 	boolean
	 * */
	private isAllMandatoryQuestionariesValid() {
		this.updateQuestionarieAnswer();
		if (this.declarationQuestionTranTmpList && this.declarationQuestionTranTmpList.length > 0) {
			this.isMandatoryQuestionsValid = true;
			this.declarationQuestionTranTmpList.forEach((question, index) => {
				if (question.mandatory) {
					if (!question.answer || question.answer === null || question.answer === '') {
						this.isMandatoryQuestionsValid = false;
					}
					if (this.isMandatoryQuestionsValid && !this.hideProcessDetailsValidation && index === 0 && question.answer === 'No' && !this.carRequest.submissionScenarioObject['rfqScenario'] && (!question.comments || question.comments === '')) {
						this.isMandatoryQuestionsValid = false;
					}
					if (this.isMandatoryQuestionsValid && index === 3 && question.answer === 'Others' && (!question.comments || question.comments === '')) {
						this.isMandatoryQuestionsValid = false;
					}
					if (this.isMandatoryQuestionsValid && question.question === 'Has this eCAR been created for a family of parts? (e.g. LH/RH) If so, you must enter the additional part numbers in the comments below.' && question.answer === 'Yes' && (!question.comments || question.comments === '')) {
						this.isMandatoryQuestionsValid = false;
					}
					if (this.isMandatoryQuestionsValid && !this.hideProcessDetailsValidation && index === 2 && !this.carRequest.submissionScenarioObject['rfqScenario'] && this.declarationQuestionTranTmpList[0].yesNoAnswer !== 'NA' &&
						question.answer && this.declarationQuestionTranTmpList[1].openTypeAnswer &&
						this.declarationQuestionTranTmpList[1].openTypeAnswer.trim() !== question.answer.trim() && (!question.comments || question.comments === '')) {
						this.isMandatoryQuestionsValid = false;
					}
					if (this.isMandatoryQuestionsValid && !this.hideProcessDetailsValidation && index === 4 && !this.carRequest.submissionScenarioObject['rfqScenario'] && this.declarationQuestionTranTmpList[4].yesNoAnswer === 'No' && (!question.comments || question.comments === '')) {
						this.isMandatoryQuestionsValid = false;
					}
				}
			});
		}
		return this.isMandatoryQuestionsValid;
	}

	onRowDataChange(id: any, ansType: any, selectedAnswer: DeclarationQuestionsTran, event): boolean {
		if (id === 0 && event === 'NA') {
			this.declarationQuestionTranTmpList[1].disableProp = true;
			this.declarationQuestionTranTmpList[2].disableProp = true;
			this.declarationQuestionTranTmpList[3].disableProp = true;
			//Clear fields while disabling
			this.declarationQuestionTranTmpList[1].openTypeAnswer = null;
			this.declarationQuestionTranTmpList[2].openTypeAnswer = null;
			this.declarationQuestionTranTmpList[3].yesNoAnswer = null;
			this.declarationQuestionTranTmpList[1].comments = null;
			this.declarationQuestionTranTmpList[2].comments = null;
			this.declarationQuestionTranTmpList[3].comments = null;
			//remove mandatory
			this.declarationQuestionTranTmpList[1].mandatory = false;
			this.declarationQuestionTranTmpList[2].mandatory = false;
			this.declarationQuestionTranTmpList[3].mandatory = false;
			this.declarationQuestionTranTmpList[id].commentsPlaceholder = 'Please provide sub tier supplier details (if applicable)';
		} else if (id === 0) {
			this.declarationQuestionTranTmpList[1].disableProp = false;
			this.declarationQuestionTranTmpList[2].disableProp = false;
			this.declarationQuestionTranTmpList[3].disableProp = false;
			//set mandatory
			this.declarationQuestionTranTmpList[1].mandatory = true;
			this.declarationQuestionTranTmpList[2].mandatory = true;
			this.declarationQuestionTranTmpList[3].mandatory = true;
			if (event === 'No') {
				this.declarationQuestionTranTmpList[id].commentsPlaceholder = 'Please provide rationale (if applicable)';
			} else {
				this.declarationQuestionTranTmpList[id].commentsPlaceholder = 'Please provide sub tier supplier details (if applicable)';
			}
		}
		if (id === 1 || id === 2) {
			if (!this.isNumber(event)) {
				if (event !== null && event !== '') {
					this.declarationQuestionTranTmpList[id].openTypeAnswer = event.replace(/\D/g, '');
					this.utilService.pushMsg('error', 'Error', 'Only accepts Numeric value.');
				}
			}
		}
		if (id === 4 && event === 'No') {
			this.declarationQuestionTranTmpList[id].commentsPlaceholder = 'Please provide rationale (if applicable)';
		}
		return true;
	}

	isStandardDeclQuestionValid(): boolean {
		const firstQuestion = this.declarationQuestionTranTmpList[0];
		const subQuestion = this.declarationQuestionTranTmpList[1];
		const subQuestion2 = this.declarationQuestionTranTmpList[2];
		const thirdQuestion = this.declarationQuestionTranTmpList[3];
		const fifthQuestion = this.declarationQuestionTranTmpList[5];
		if (this.mode === 'view' && this.declarationQuestionTranTmpList.length > 0) {
			this.declarationQuestionTranTmpList.forEach(declarationQuestionTranTmp => {
				declarationQuestionTranTmp.disableProp = true;
			});
		} else {
			if (firstQuestion.yesNoAnswer === 'NA') {
				this.declarationQuestionTranTmpList[1].disableProp = true;
				this.declarationQuestionTranTmpList[2].disableProp = true;
				this.declarationQuestionTranTmpList[3].disableProp = true;
			} else {
				this.declarationQuestionTranTmpList[1].disableProp = false;
				this.declarationQuestionTranTmpList[2].disableProp = false;
				this.declarationQuestionTranTmpList[3].disableProp = false;
			}
		}
		return true;
	}

	isNumber(value: string | number): boolean {
		return ((value != null) &&
			(value !== '') &&
			!isNaN(Number(value.toString())));
	}

	private retrieveAvailablePrimaryStreamBySiteCode() {
		if (this.carRequest && this.carRequest.siteCodeObject && this.carRequest.siteCodeObject) {
			if (this.mode === 'create' || ((this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason === null) || this.carRequest.requestStatus === 'STADRAFT' || (!!this.availablePrimaryStream && this.availablePrimaryStream.length > 1 && this.carRequest.requestStatus === 'REJECTED' && this.isProcessStreamAvailable))) {
				this.processStreamService.findAllPrimaryProcessStreamByCreatedUser(this.carRequest.siteCodeObject).subscribe(availablePs => {
					if (availablePs && availablePs.length > 0) {
						this.availablePrimaryStream = availablePs;
						this.availablePrimaryStream.unshift(emptyProcessStreamDroprdown);
						if (this.mode === 'edit' || this.mode === 'view') {
							this.selectedPrimaryStream = this.availablePrimaryStream.find(value1 => value1.id === this.carRequest.primaryStreamId);
							if (!this.selectedPrimaryStream) {
								this.selectedPrimaryStream = emptyProcessStreamDroprdown;
							}
							this.loadProcessNamesByPrimaryStreamId(this.selectedPrimaryStream);
							this.mapId = this.selectedPrimaryStream.id;
						}
					} else {
						this.availablePrimaryStream = [];
						this.availablePrimaryStream.push(emptyProcessStreamDroprdown);
					}
				});
			} else {
				this.processStreamService.findAllPrimaryProcessStreamEditFromTrans(this.carRequest.siteCodeObject, this.carRequest.reqId).subscribe(availablePs => {
					if (availablePs && availablePs.length > 0) {
						this.availablePrimaryStream = availablePs;
						this.availablePrimaryStream.unshift(emptyProcessStreamDroprdown);
						if (this.mode === 'edit' || this.mode === 'view') {
							this.selectedPrimaryStream = this.availablePrimaryStream.find(value1 => value1.id === this.carRequest.primaryStreamId);
							this.loadProcessNamesByPrimaryStreamId(this.selectedPrimaryStream);
							this.mapId = this.selectedPrimaryStream.id;
						}
					} else {
						this.availablePrimaryStream = [];
						this.availablePrimaryStream.push(emptyProcessStreamDroprdown);
					}
				});
			}
		}
	}

	private retrieveSupplierSite(siteCode: string) {
		this.supplierSiteService.findBySiteCode(siteCode).subscribe(response => {
			this.supplierSiteDetails = response;
			this.siteSupCdsId = this.supplierSiteDetails.staSiteEngCdsId;
			this.supplierSiteDetails.siteDetailsLoaded = true;
			this.carRequest.q1StatusCode = this.supplierSiteDetails.q1StatusCode;
			this.carRequest.q1StatusDesc = this.supplierSiteDetails.q1StatusDesc;
			this.carRequest.supplierName = this.supplierSiteDetails.supplierName;
			this.loadPPAPFromSupplierDetails(this.supplierSiteDetails.prodPPAPLevel);
			this.manageSupplierName(this.carRequest.supplierName);
			this.carRequest.keyContacts[0].name = this.supplierSiteDetails.staSiteEngName;
			if (this.supplierSiteDetails && this.supplierSiteDetails.staSiteEngCdsId) {
				if (this.config.env !== 'local') {
					this.carRequestService.getMailFromCDSId(this.supplierSiteDetails.staSiteEngCdsId).subscribe(ldapResponse => {
						console.log('ldapResponse');
						this.carRequest.keyContacts[0].mail = ldapResponse.mail;
					});
				} else {
					this.carRequest.keyContacts[0].mail = this.supplierSiteDetails.staSiteEngCdsId.concat('@ford.com');
				}
			} else {
				this.carRequest.keyContacts[0].mail = '';
			}
			this.carRequest.keyContacts[0].phone = this.supplierSiteDetails.staSiteEngPhone;
			this.carRequest.keyContacts[0].cdsid = this.supplierSiteDetails.staSiteEngCdsId;
			if (this.supplierSiteDetails && this.supplierSiteDetails.staSiteEngCdsId) {
				this.apsService.retrieveAtrributesForEngineerKeyContactCheck(this.supplierSiteDetails.staSiteEngCdsId).subscribe(apsAttribute => {
					if (apsAttribute) {
						this.carRequest.keyContacts[0].type = 'STASiteEngineer';
						this.selectedSiteEng = this.carRequest.keyContacts[0];
						this.engNotAvailableInAps = false;
					} else {
						this.engNotAvailableInAps = true;
					}
				});
			}
		}, error => {
			this.supplierSiteDetails = emptySupplierSiteDetails;
			this.supplierSiteDetails.siteDetailsLoaded = false;
			this.carRequest.q1StatusCode = this.supplierSiteDetails.q1StatusCode;
			this.carRequest.q1StatusDesc = this.supplierSiteDetails.q1StatusDesc;
			this.carRequest.supplierName = this.supplierSiteDetails.supplierName;
			this.carRequest.keyContacts[0].name = this.supplierSiteDetails.staSiteEngName;
			this.carRequest.keyContacts[0].mail = this.supplierSiteDetails.staSiteEngCdsId;
			this.carRequest.keyContacts[0].phone = this.supplierSiteDetails.staSiteEngPhone;
			this.carRequest.keyContacts[0].cdsid = this.supplierSiteDetails.staSiteEngCdsId;
			this.selectedSiteEng = this.carRequest.keyContacts[0];

			this.loadPPAPFromSupplierDetails(0);
			// this.showSupplierSiteErrorMessage();
		});
	}

	onSiteCodeChange() {
		this.retrieveAvailablePrimaryStreamBySiteCode();
		this.retrieveSupplierSite(this.carRequest.siteCodeObject);
		this.addFormInvalidIfSiteCodeDropdownInValid();
		this.isSiteCodeAvailableInSIM(this.carRequest.siteCode);
	}

	private loadPPAPFromSupplierDetails(prodPPAPLevel: number) {
		if (!this.carRequest.priority || this.carRequest.priority !== 'Yes') {
			this.partPPAPLevelOptions = emptyPartPPAPLevelDropdown;
			if (prodPPAPLevel && prodPPAPLevel !== 0) {
				this.carRequest.partPPAPLevelObject = this.partPPAPLevelOptions.find(value => value.code === prodPPAPLevel);
				this.carRequest.partPpapLevel = this.carRequest.partPPAPLevelObject.code;
				if (this.carRequest.partPpapLevel === 3) {
					this.partPPAPLevelOptions = [{name: '3', code: 3}, {name: '5', code: 5}];
				}
			} else {
				this.carRequest.partPPAPLevelObject = this.partPPAPLevelOptions.find(value => value.code === 5);
				this.carRequest.partPpapLevel = this.carRequest.partPPAPLevelObject.code;
			}
			if (this.carRequest.partPpapLevel === 5) {
				this.partPPAPLevelOptions = [{name: '5', code: 5}];
			}
		}
	}

	private manageSupplierName(supplierName: string) {
		if (supplierName && supplierName.trim() !== '') {
			this.disableSupplierName = true;
		}
	}

	private manageEditButtonForSupportingStream(primarySupportingMapTran: PrimarySupportingMapTran, psPnMapTransIncomeObject: PsPnMapTran, processDataList: ProcessData[]) {
		primarySupportingMapTran.psPnMapTranList.forEach(psPnMapTransObject => {
			if (psPnMapTransObject.processDataList && psPnMapTransObject.processDataList.length > 0) {
				if (psPnMapTransObject.processDataList[0].reqGoodPartsNext && psPnMapTransObject.processDataList[1].reqGoodPartsNext && psPnMapTransObject.processDataList[0].reqGoodPartsNext > 0 && psPnMapTransObject.processDataList[1].reqGoodPartsNext > 0) {
					const psPnMapSupport = primarySupportingMapTran.psPnMapTranList.find(value => value.processOrderId === (psPnMapTransIncomeObject.processOrderId - 1));
					if (psPnMapSupport) {
						psPnMapSupport.isEditButtonDisabled = false;
					}
				} else {
					if (processDataList && processDataList.length > 0 && processDataList[0].reqGoodPartsNext && processDataList[1].reqGoodPartsNext && processDataList[0].reqGoodPartsNext > 0 && processDataList[1].reqGoodPartsNext > 0) {
						psPnMapTransObject.isEditButtonDisabled = false;
						if (psPnMapTransObject.processDataList[0].reqGoodPartsNext && psPnMapTransObject.processDataList[1].reqGoodPartsNext && psPnMapTransObject.processDataList[0].reqGoodPartsNext > 0 && psPnMapTransObject.processDataList[1].reqGoodPartsNext > 0) {
							const psPnMapSupport = primarySupportingMapTran.psPnMapTranList.find(value => value.processOrderId === (psPnMapTransIncomeObject.processOrderId - 1));
							if (psPnMapSupport) {
								psPnMapSupport.isEditButtonDisabled = false;
							}
						}
					} else {
						psPnMapTransObject.isEditButtonDisabled = true;
					}
				}
			}
		});
	}

	onDeclarationQuestionNext(isSaveAndContinue: boolean) {
		this.updateQuestionarieAnswer();
		this.isAllMandatoryQuestionariesValid();
		this.carRequest.declarationQuestionsTranList = this.declarationQuestionTranTmpList;
		if (!this.isStandardDeclQuestionValid()) {
			return false;
		}
		if (this.questionLoaded) {
			if (isSaveAndContinue) {
				this.onSubmit('DRAFT', 'SaveAndContinueDeclaration');
			}
			this.declarationFormSubmitted = true;
			this.stepper.next();
		} else {
			this.utilService.pushMsg('error', 'Error', 'Questions are being loaded, please wait');
			return false;
		}
	}

	onSummaryPrevious() {
		if (this.declarationFormSubmitted || this.checkSummary) {
			this.carRequestService.pushDeleteAnchorsStream(true);
		}
	}

	ngOnDestroy() {
		this.onSummaryPrevious();
	}

	private isApwMpwChangeForPrimaryAndSupporting(isPrimary: boolean) {
		let isValid = true;
		if (isPrimary) {
			this.carRequest.psPnMapTransList.forEach(psPnMapTranList => {
				if (psPnMapTranList.processDataList[0].resultStatus) {
					if (psPnMapTranList.processDataList[0].apwMpwChange === true) {
						isValid = false;
					}
					if (psPnMapTranList.processDataList[0].hmpChange === true) {
						isValid = false;
					}
					if (psPnMapTranList.processDataList[0].sharedLoadChange === true) {
						isValid = false;
					}
					if (psPnMapTranList.processDataList[0].scrapChange === true) {
						isValid = false;
					}
					if (psPnMapTranList.processDataList[0].scenarioChange === true) {
						isValid = false;
					}
				}
			});
		} else {
			this.carRequest.primarySupportingMapTranList.forEach(supporingMapTransList => {
				supporingMapTransList.psPnMapTranList.forEach(psPnMapTranList => {
					if (psPnMapTranList.processDataList[0].resultStatus) {
						if (psPnMapTranList.processDataList[0].apwMpwChange === true) {
							isValid = false;
						}
						if (psPnMapTranList.processDataList[0].hmpChange === true) {
							isValid = false;
						}
						if (psPnMapTranList.processDataList[0].sharedLoadChange === true) {
							isValid = false;
						}
						if (psPnMapTranList.processDataList[0].scrapChange === true) {
							isValid = false;
						}
						if (psPnMapTranList.processDataList[0].scenarioChange === true) {
							isValid = false;
						}
					}
				});
			});
		}
		return isValid;
	}

	private checkFlagForHMP() {

		this.primaryHMPValFromDraft.forEach(primaryDraftValues => {
			this.primaryHMPValFromMaster.forEach(primaryMasterValues => {
				if (primaryDraftValues.id === primaryMasterValues.id) {
					if (primaryDraftValues.demonOee !== primaryMasterValues.demonOee) {
						if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
							this.carRequest.psPnMapTransList.forEach(psPnMapTranList => {
								if (psPnMapTranList.processNameId === primaryDraftValues.id) {
									psPnMapTranList.processDataList[0].hmpChange = true;
								}
							});
						}
					}
				}
			});
		});

		this.secondaryHMPValFromDraft.forEach(secondaryDraftValues => {
			this.secondaryHMPValFromMaster.forEach(secondaryMasterValues => {
				if (secondaryDraftValues.id === secondaryMasterValues.id) {
					if (secondaryDraftValues.demonOee !== secondaryMasterValues.demonOee) {
						this.carRequest.primarySupportingMapTranList.forEach(psMapTranList => {
							psMapTranList.psPnMapTranList.forEach(psPnMapTranList => {
								if (psPnMapTranList.processNameId === secondaryDraftValues.id) {
									psPnMapTranList.processDataList[0].hmpChange = true;
								}
							});
						});
					}
				}
			});
		});
	}

	loadHMPOeeMap(processNameId: number, isPrimary: boolean) {
		let histOee = 0;
		this.historicalMfgPerfObjTemp = {
			processId: processNameId,
			siteCode: this.carRequest.siteCode
		};
		this.hmpService.findHistMfgPerfRecords(this.historicalMfgPerfObjTemp).subscribe((response: HistoricalMfgPerf[]) => {
			this.historicalmfgperfsTemp = response;
			histOee = this.utilService.calculateAverageOEE(this.historicalmfgperfsTemp);
			if (isPrimary) {
				this.primaryHMPValFromMaster.push(new DemonOEEMap({'id': processNameId, 'demonOee': histOee}));
			} else {
				this.secondaryHMPValFromMaster.push(new DemonOEEMap({'id': processNameId, 'demonOee': histOee}));
			}
			this.primaryHMPValFromMaster.reverse();
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	retrieveSharedLoadingIdBySiteCode() {
		this.primarySLAllocationFromMaster = [];
		this.secondarySLAllocationFromMaster = [];
		const siteCode = this.carRequest.siteCode;
		if (this.carRequest && this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
				this.manageSharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(siteCode, psPnMapTran.processNameId.toString()).subscribe(value => {
					const sharedLoadingRoot = value;
					if (sharedLoadingRoot) {
						let isProcessExist: Boolean = false;
						this.primarySLAllocationFromMaster.forEach(primSLAllocationMaster => {
							if (primSLAllocationMaster.processNameId !== psPnMapTran.processNameId) {
								isProcessExist = isProcessExist ? true : false;
							} else {
								isProcessExist = true;
							}
						});
						if (!isProcessExist) {
							this.primarySLAllocationFromMaster.push(new ProcessNameIdAllocationPercentMap({
								'processNameId': sharedLoadingRoot.processNameId,
								'allocationPercent': [this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.capacityRequirementSource === 'What-If' ? _.round(sharedLoadingRoot.totalAllocationPercentageCapacityAPW, 2) : _.round(sharedLoadingRoot.totalAllocationPercentageAPW, 2), this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.capacityRequirementSource === 'What-If' ? _.round(sharedLoadingRoot.totalAllocationPercentageCapacityMPW, 2) : _.round(sharedLoadingRoot.totalAllocationPercentageMPW, 2)]
							}));
						}
					}
				});
			});
			if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
				this.carRequest.primarySupportingMapTranList.forEach(primarySupportMapTran => {
					primarySupportMapTran.psPnMapTranList.forEach(psPnMapTran => {
						this.manageSharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(siteCode, psPnMapTran.processNameId.toString()).subscribe(value => {
							const sharedLoadingRoot = value;
							if (sharedLoadingRoot) {
								let isProcessExist: Boolean = false;
								this.secondarySLAllocationFromMaster.forEach(secondarySLAllocationFromMaster => {
									if (secondarySLAllocationFromMaster.processNameId !== psPnMapTran.processNameId) {
										isProcessExist = isProcessExist ? true : false;
									} else {
										isProcessExist = true;
									}
								});
								if (!isProcessExist) {
									this.secondarySLAllocationFromMaster.push(new ProcessNameIdAllocationPercentMap({
										'processNameId': sharedLoadingRoot.processNameId,
										'allocationPercent': [this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.capacityRequirementSource === 'What-If' ? _.round(sharedLoadingRoot.totalAllocationPercentageCapacityAPW, 2) : _.round(sharedLoadingRoot.totalAllocationPercentageAPW, 2), this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.capacityRequirementSource === 'What-If' ? _.round(sharedLoadingRoot.totalAllocationPercentageCapacityMPW, 2) : _.round(sharedLoadingRoot.totalAllocationPercentageMPW, 2)]
									}));
								}
							}
						});
					});
				});
			}
		}
	}

	checkFlagForSL() {
		this.primarySLAllocationFromDraft.forEach(primaryDraftValues => {
			this.primarySLAllocationFromMaster.forEach(primaryMasterValues => {
				if (primaryDraftValues.processNameId === primaryMasterValues.processNameId) {
					if (primaryDraftValues.allocationPercent[0].toString() !== primaryMasterValues.allocationPercent[0].toString() ||
						primaryDraftValues.allocationPercent[1].toString() !== primaryMasterValues.allocationPercent[1].toString()) {
						if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
							this.carRequest.psPnMapTransList.forEach(psPnMapTranList => {
								if (!psPnMapTranList.processDataList[0].dedicated) {
									if (psPnMapTranList.processNameId === primaryDraftValues.processNameId) {
										psPnMapTranList.processDataList[0].sharedLoadChange = true;
									}
								}
							});
						}
					}
					if (this.capacityStudy && this.capacityReqSourceForCapacityStudyFromDraft && this.capacityReqSourceForCapacityStudyFromDraft !== this.carRequest.capacityRequirementSource) {
						if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
							this.carRequest.psPnMapTransList.forEach(psPnMapTranList => {
								if (!psPnMapTranList.processDataList[0].dedicated) {
									if (psPnMapTranList.processNameId === primaryDraftValues.processNameId) {
										psPnMapTranList.processDataList[0].sharedLoadChange = true;
									}
								}
							});
						}
					}
				}
			});
		});

		this.secondarySLAllocationFromDraft.forEach(secondaryDraftValues => {
			this.secondarySLAllocationFromMaster.forEach(secondaryMasterValues => {
				if (secondaryDraftValues.processNameId === secondaryMasterValues.processNameId) {
					if ((secondaryDraftValues.allocationPercent[0].toString() !== secondaryMasterValues.allocationPercent[0].toString()) ||
						(secondaryDraftValues.allocationPercent[1].toString() !== secondaryMasterValues.allocationPercent[1].toString())) {
						this.carRequest.primarySupportingMapTranList.forEach(psMapTranList => {
							psMapTranList.psPnMapTranList.forEach(psPnMapTranList => {
								if (!psPnMapTranList.processDataList[0].dedicated) {
									if (psPnMapTranList.processNameId === secondaryDraftValues.processNameId) {
										psPnMapTranList.processDataList[0].sharedLoadChange = true;
									}
								}
							});
						});
					}
					if (this.capacityStudy && this.capacityReqSourceForCapacityStudyFromDraft !== this.carRequest.capacityRequirementSource) {
						this.carRequest.primarySupportingMapTranList.forEach(psMapTranList => {
							psMapTranList.psPnMapTranList.forEach(psPnMapTranList => {
								if (!psPnMapTranList.processDataList[0].dedicated) {
									if (psPnMapTranList.processNameId === secondaryDraftValues.processNameId) {
										psPnMapTranList.processDataList[0].sharedLoadChange = true;
									}
								}
							});
						});
					}
				}
			});
		});
	}

	showErrorMessage(psPnMapTran: PsPnMapTran, index: number) {
		let comma = false;
		let actualMessage: string[];
		actualMessage = new Array();
		if (psPnMapTran && psPnMapTran.processDataList && psPnMapTran.processDataList.length > 0 && psPnMapTran.processDataList[0].resultStatus) {
			if (psPnMapTran.processDataList[0].apwMpwChange || psPnMapTran.processDataList[0].hmpChange || psPnMapTran.processDataList[1].hmpChange || psPnMapTran.processDataList[0].sharedLoadChange || psPnMapTran.processDataList[1].sharedLoadChange || psPnMapTran.processDataList[0].scrapChange || psPnMapTran.processDataList[0].scenarioChange || psPnMapTran.processDataList[0].partPrefixBaseSuffixChange || psPnMapTran.processDataList[0].capacityRequirementChange || psPnMapTran.processDataList[0].psChange || psPnMapTran.processDataList[1].psChange) {
				actualMessage[index] = 'There have been changes to the following :';
				comma = false;
				if (psPnMapTran.processDataList[0].hmpChange === true || psPnMapTran.processDataList[1].hmpChange === true) {
					actualMessage[index] += ' Historical Manufacturing Performance';
					comma = true;
				}
				if (psPnMapTran.processDataList[0].sharedLoadChange === true || psPnMapTran.processDataList[1].sharedLoadChange === true) {
					if (comma) {
						actualMessage[index] += ', Shared Loading Plan';
					} else {
						actualMessage[index] += ' Shared Loading Plan';
						comma = true;
					}
				}
				if (psPnMapTran.processDataList[0].apwMpwChange === true) {
					if (comma) {
						actualMessage[index] += ', APW/MPW Requirements';
					} else {
						actualMessage[index] += ' APW/MPW Requirements';
						comma = true;
					}
				}

				if (psPnMapTran.processDataList[0].scrapChange === true) {
					if (comma) {
						actualMessage[index] += ', Required Good Parts';
					} else {
						actualMessage[index] += ' Required Good Parts';
						comma = true;
					}
				}

				if (psPnMapTran.processDataList[0].scenarioChange === true) {
					if (comma) {
						actualMessage[index] += ', Submission Scenario';
					} else {
						actualMessage[index] += ' Submission Scenario';
						comma = true;
					}
				}

				if (psPnMapTran.processDataList[0].capacityRequirementChange === true) {
					if (comma) {
						actualMessage[index] += ', Capacity Requirement Source';
					} else {
						actualMessage[index] += ' Capacity Requirement Source';
						comma = true;
					}
				}

				if (psPnMapTran.processDataList[0].psChange === true || psPnMapTran.processDataList[1].psChange === true) {
					if (comma) {
						actualMessage[index] += ', Process Stream';
					} else {
						actualMessage[index] += ' Process Stream';
						comma = true;
					}
				}

				if (psPnMapTran.processDataList[0].partPrefixBaseSuffixChange === true) {
					if (comma) {
						actualMessage[index] += ', Part Name';
					} else {
						actualMessage[index] += ' Part Name';
					}
				}
				return actualMessage[index] += '. Please review the data';
			}
		}
	}

	private retrieveAndDisplaySupplierLeadDetailsOnKeyContacts() {
		const displayLeadName = sessionStorage.getItem('displayLeadName');
		if (displayLeadName !== null && displayLeadName !== 'undefined') {
			this.carRequest.keyContacts[1].name = displayLeadName;
		}
		const displayLeadMail = sessionStorage.getItem('displayLeadMail');
		if (displayLeadMail !== null && displayLeadMail !== 'undefined') {
			this.carRequest.keyContacts[1].mail = sessionStorage.getItem('displayLeadMail');
		}
		const leadCdsid = sessionStorage.getItem('displayLeadCdsid');
		if (leadCdsid !== null && leadCdsid !== 'undefined') {
			this.carRequest.keyContacts[1].cdsid = sessionStorage.getItem('displayLeadCdsid');
		}
		const displayLeadPhone = sessionStorage.getItem('displayLeadPhone').split('-').join('');
		if (displayLeadPhone !== null && displayLeadPhone !== undefined) {
			this.carRequest.keyContacts[1].phone = displayLeadPhone;
		}
	}

	private loadContactTypeOnKeyContacts() {
		this.carRequest.keyContacts[0].type = (this.carRequest.keyContacts[0].type !== null && this.carRequest.keyContacts[0].type !== '') ? this.carRequest.keyContacts[0].type : 'STASiteEngineer';
		this.carRequest.keyContacts[1].type = (this.carRequest.keyContacts[1].type !== null && this.carRequest.keyContacts[1].type !== '') ? this.carRequest.keyContacts[1].type : 'SupplierLead';
		this.carRequest.keyContacts[2].type = (this.carRequest.keyContacts[2].type !== null && this.carRequest.keyContacts[2].type !== '') ? this.carRequest.keyContacts[2].type : 'FordBuyer';
		this.carRequest.keyContacts[3].type = (this.carRequest.keyContacts[3].type !== null && this.carRequest.keyContacts[3].type !== '') ? this.carRequest.keyContacts[3].type : 'STASiteSupervisor';
		this.carRequest.keyContacts[4].type = (this.carRequest.keyContacts[4].type !== null && this.carRequest.keyContacts[4].type !== '') ? this.carRequest.keyContacts[4].type : 'CapacityStudyBuyer';
	}

	searchKeywordInLdap(event, isSiteEngineer: string) {
		this.siteSupervisorRequired = false;
		this.noMatchFoundFordBuyer = false;
		this.noMatchFoundSiteEngineer = false;
		this.noMatchFoundSiteSupervisorCDSID = false;
		this.noMatchFoundCapacityStudyBuyer = false;
		this.ldapResponse = [];
		const cdsid = event.query;
		if (cdsid && cdsid.length > 2) {
			console.log('calling service...');
			this.carRequestService.searchFordBuyer(cdsid).subscribe(ldapResponse => {
				console.log('ldapResponse');
				this.ldapResponse = ldapResponse;
				if (isSiteEngineer === 'staSiteEngin') {
					this.fordSiteEngSuggestions = this.buildKeyContactsFromLdapResponse(ldapResponse);
				} else if (isSiteEngineer === 'fordBuyer') {
					this.fordBuyerSuggestions = this.buildKeyContactsFromLdapResponse(ldapResponse);
				} else if (isSiteEngineer === 'SiteSupervisor') {
					this.fordSiteSupervisorSuggestions = this.buildKeyContactsFromLdapResponse(ldapResponse);
				} else {
					this.fordCapacityStudyBuyerSuggestions = this.buildKeyContactsFromLdapResponse(ldapResponse);
				}
			}, error => {
				if (isSiteEngineer === 'staSiteEngin') {
					this.noMatchFoundSiteEngineer = true;
					this.fordSiteEngSuggestions = [];
				} else if (isSiteEngineer === 'fordBuyer') {
					this.noMatchFoundFordBuyer = true;
					this.fordBuyerSuggestions = [];
				} else if (isSiteEngineer === 'SiteSupervisor') {
					this.noMatchFoundSiteSupervisorCDSID = true;
					this.noValueSelecte = false;
					this.fordSiteSupervisorSuggestions = [];
				} else {
					this.noMatchFoundCapacityStudyBuyer = true;
					this.fordCapacityStudyBuyerSuggestions = [];
				}
			});
		}
	}

	private buildDisplayNameWithDesignation(ldapResponse: LdapResponse[]) {
		return ldapResponse.map(value => value.fordDisplayName + ' - ' + value.fordDesignation);
	}

	buildKeyContactsFromLdapResponse(ldapResponse: LdapResponse[]): any[] {
		const keyContactsDropDownArray = [];
		ldapResponse.forEach(res => {
			const keyContactsNew: KeyContacts = new KeyContacts(emptyKeyContacts);
			keyContactsNew.name = res.fordDisplayName + ' - ' + res.fordDesignation;
			keyContactsNew.mail = res.mail;
			keyContactsNew.phone = res.telephoneNumber;
			keyContactsNew.cdsid = res.userId;
			keyContactsNew.organizationName = res.company;
			keyContactsDropDownArray.push(keyContactsNew);
		});
		return keyContactsDropDownArray;
	}

	populateBuyerDetails(isSiteEngineer: string) {
		console.log('populateBuyerDetails');
		const fordBuyerIndexKeyContacts: number = isSiteEngineer === 'staSiteEngin' ? 0 : isSiteEngineer === 'fordBuyer' ? 2 : isSiteEngineer === 'SiteSupervisor' ? 3 : 4;
		const contactType = isSiteEngineer === 'staSiteEngin' ? 'STASiteEngineer' : isSiteEngineer === 'fordBuyer' ? 'FordBuyer' : isSiteEngineer === 'SiteSupervisor' ? 'STASiteSupervisor' : 'CapacityStudyBuyer';
		const ldapResponse: KeyContacts = fordBuyerIndexKeyContacts === 0 ? this.selectedSiteEng :
			fordBuyerIndexKeyContacts === 2 ? this.selectedFordBuyer :
				fordBuyerIndexKeyContacts === 3 ? this.selectedFordSiteSupervisor :
					this.selectedFordCapacityStudyBuyer;
		const selectedDisplayName = this.retrieveDisplayName(ldapResponse.name);
		this.resetKeyContact(fordBuyerIndexKeyContacts, contactType);
		if (selectedDisplayName && ldapResponse) {
			console.log('filtered ldapResponses');
			this.carRequest.keyContacts[fordBuyerIndexKeyContacts].name = selectedDisplayName;
			this.carRequest.keyContacts[fordBuyerIndexKeyContacts].cdsid = ldapResponse.cdsid;
			this.carRequest.keyContacts[fordBuyerIndexKeyContacts].mail = ldapResponse.mail;
			this.carRequest.keyContacts[fordBuyerIndexKeyContacts].phone = this.replaceSPLChar(ldapResponse.phone);
			this.carRequest.keyContacts[fordBuyerIndexKeyContacts].organizationName = ldapResponse.organizationName;
			if (isSiteEngineer === 'staSiteEngin') {
				this.apsService.retrieveAtrributesForEngineerKeyContactCheck(this.carRequest.keyContacts[fordBuyerIndexKeyContacts].cdsid).subscribe(apsAttribute => {
					if (apsAttribute) {
						this.carRequest.keyContacts[fordBuyerIndexKeyContacts].type = apsAttribute.userRole;
						this.selectedSiteEng = this.carRequest.keyContacts[0];
						this.engNotAvailableInAps = false;
					} else {
						this.engNotAvailableInAps = true;
					}
				});
			} else if (fordBuyerIndexKeyContacts === 2) {
				this.selectedFordBuyer = new KeyContacts(this.carRequest.keyContacts[2]);
			} else if (fordBuyerIndexKeyContacts === 4) {
				this.selectedFordCapacityStudyBuyer = new KeyContacts(this.carRequest.keyContacts[4]);
			} else if (fordBuyerIndexKeyContacts === 3) {
				this.apsService.retrieveAtrributesForEngineerKeyContactCheck(this.carRequest.keyContacts[fordBuyerIndexKeyContacts].cdsid).subscribe(apsAttribute => {
					if (apsAttribute) {
						this.carRequest.keyContacts[fordBuyerIndexKeyContacts].type = apsAttribute.userRole;
						this.selectedFordSiteSupervisor = new KeyContacts(this.carRequest.keyContacts[3]);
						this.supNotAvailableInAps = false;
						this.siteSupervisorRequired = false;
						this.noMatchFoundSiteSupervisorCDSID = false;
						this.noValueSelecte = false;
						this.isSiteSupervisorPresent = true;
					} else {
						this.supNotAvailableInAps = true;
					}
				});
			}
		}
	}

	private resetKeyContact(fordBuyerIndexKeyContacts: number, contactType) {
		this.carRequest.keyContacts[fordBuyerIndexKeyContacts].type = contactType;
		this.carRequest.keyContacts[fordBuyerIndexKeyContacts].cdsid = '';
		this.carRequest.keyContacts[fordBuyerIndexKeyContacts].mail = '';
		this.carRequest.keyContacts[fordBuyerIndexKeyContacts].phone = null;
		this.carRequest.keyContacts[fordBuyerIndexKeyContacts].organizationName = '';
	}

	private retrieveDisplayName(displayNameWithDesignation: string): string {
		if (displayNameWithDesignation && displayNameWithDesignation.length > 0) {
			return displayNameWithDesignation.split(') -')[0].trim() + ')';
		}
	}

	private replaceSPLChar(phone: string): string {
		const searchRegExp = /-/gi;
		if (phone && phone.length > 0) {
			const phoneString = phone.replace(searchRegExp, '');
			return phoneString;
		}
	}

	loadSiteCodes() {
		this.siteCodes = this.siteCodeService.fetchSiteCodes();
	}

	validateEmailInFDS(isSiteEngineer: boolean) {
		const fordBuyerIndexKeyContacts: number = isSiteEngineer ? 0 : 2;
		const mailId = this.carRequest.keyContacts[fordBuyerIndexKeyContacts].mail;
		this.carRequestService.isValidMailId(mailId).subscribe(isValid => {
			if (!isValid) {
				if (isSiteEngineer) {
					this.headerForm.form.controls['staSiteEnginMail'].markAsTouched();
					this.headerForm.form.controls['staSiteEnginMail'].setErrors({'email': true});
				} else {
					this.headerForm.form.controls['fordBuyerMail'].markAsTouched();
					this.headerForm.form.controls['fordBuyerMail'].setErrors({'email': true});
				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	searchCdsId() {
		if (this.carRequest.keyContacts.length > 4 && this.carRequest.keyContacts[3] && this.carRequest.keyContacts[3].cdsid && this.carRequest.keyContacts[3].cdsid === '' || this.carRequest.keyContacts[3].cdsid.length === 0) {
			this.carRequestService.findCdsIds(this.carRequest.siteCodeObject).subscribe(response => {
				if (response && response.length > 0 && response[0].prodStaSiteSpvCDS && response[0].prodStaSiteSpvCDS !== '') {
					this.carRequest.keyContacts[3].cdsid = response[0].prodStaSiteSpvCDS;
					this.carRequest.keyContacts[3].mail = response[0].prodStaSiteSpvCDS + '@FORD.COM';
					this.carRequest.keyContacts[3].name = response[0].prodStaSiteSpvName;
					this.carRequest.keyContacts[3].phone = response[0].prodStaSiteSpvPhone;
					this.noMatchFoundSiteSupervisorCDSID = false;
				} else {
					console.log('No SiteCode present or No Supervisor there in SIM');
					this.noMatchFoundSiteSupervisorCDSID = true;
					this.noValueSelecte = false;
				}
			});
		}
	}

	paRequiredMPWAPWCheck() {
		const reqMPW = this.carRequest.paRequirementsMpw;
		const reqAPW = this.carRequest.paRequirementsApw;
		if (reqAPW > reqMPW) {
			this.headerForm.form.controls['paRequirementsMpw'].markAsTouched();
			this.headerForm.form.controls['paRequirementsMpw'].setErrors({'mpwCheck': true});
		}
	}

	revisedRequiredMPWAPWCheck() {
		const reqMPW = this.carRequest.revisedRequirementsMpw;
		const reqAPW = this.carRequest.revisedRequirementsApw;
		if (this.carRequest.revisedRequirementsMpw && this.carRequest.revisedRequirementsApw && this.carRequest.revisedRequirementsApw.toString().trim() !== '' && this.carRequest.revisedRequirementsMpw.toString().trim() !== '') {
			if (reqAPW > reqMPW) {
				this.showRevisedValidationError = true;
			} else {
				this.showRevisedValidationError = false;
			}
		}
	}

	gcpMcpvRequiredMPWAPWCheck() {
		this.showGcpMcpvValidationError = false;
		const reqMPW = this.carRequest.gcpMcpvMpw;
		const reqAPW = this.carRequest.gcpMcpvApw;
		if (this.carRequest.gcpMcpvMpw && this.carRequest.gcpMcpvApw && this.carRequest.gcpMcpvApw.toString().trim() !== '' && this.carRequest.gcpMcpvMpw.toString().trim() !== '') {
			if (reqAPW > reqMPW) {
				this.showGcpMcpvValidationError = true;
			} else {
				this.showGcpMcpvValidationError = false;
			}
		}
	}

	clearGcpMcpvValidation() {
		this.showGcpMcpvValidationError = false;
	}

	clearRevicedValidation() {
		this.showRevisedValidationError = false;
	}

	private checkFlagForScenario() {
		let templateChange = 'false';
		if (this.submissionScenarioObjectDraft.scenarioName !== this.carRequest.submissionScenarioObject.scenarioName) {
			const thirtyDaysAgo = moment().subtract(30, 'days').valueOf();
			if (Date.parse(String(this.carRequest.updatedDate)) < thirtyDaysAgo) {
				templateChange = 'updatedThirtyDaysAgo';
			} else if (this.submissionScenarioObjectDraft.templateName !== this.carRequest.submissionScenarioObject.templateName) {
				templateChange = 'true with ' + this.submissionScenarioObjectDraft.templateName + ' to ' + this.carRequest.submissionScenarioObject.templateName;
			}
			if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
				this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
					if (templateChange === 'updatedThirtyDaysAgo') {
						const apwProcessDataId = psPnMapTran.processDataList[0].processDataId;
						const mpwProcessDataId = psPnMapTran.processDataList[1].processDataId;
						this.setEmptyProcessData(psPnMapTran);
						psPnMapTran.processDataList[0].processDataId = apwProcessDataId;
						psPnMapTran.processDataList[0].reqId = psPnMapTran.reqId;
						psPnMapTran.processDataList[0].processStreamId = psPnMapTran.processStreamId;
						psPnMapTran.processDataList[0].processNameId = psPnMapTran.processNameId;
						psPnMapTran.processDataList[0].resultStatus = 'Error';
						psPnMapTran.processDataList[1].processDataId = mpwProcessDataId;
						psPnMapTran.processDataList[1].reqId = psPnMapTran.reqId;
						psPnMapTran.processDataList[1].processStreamId = psPnMapTran.processStreamId;
						psPnMapTran.processDataList[1].processNameId = psPnMapTran.processNameId;
						psPnMapTran.processDataList[1].resultStatus = 'Error';
					}
					psPnMapTran.processDataList.forEach(processData => {
						processData.scenarioChange = true;
						processData.scenarioTemplateChange = templateChange;
						if (processData.isCopiedCar) {
							processData.isCopiedCar = false;
						}
					});
				});
			}
			this.carRequest.primarySupportingMapTranList.forEach(supportingTransList => {
				supportingTransList.psPnMapTranList.forEach(psPnMapTran => {
					if (templateChange === 'updatedThirtyDaysAgo') {
						const apwProcessDataId = psPnMapTran.processDataList[0].processDataId;
						const mpwProcessDataId = psPnMapTran.processDataList[1].processDataId;
						this.setEmptyProcessData(psPnMapTran);
						psPnMapTran.processDataList[0].processDataId = apwProcessDataId;
						psPnMapTran.processDataList[0].reqId = psPnMapTran.reqId;
						psPnMapTran.processDataList[0].processStreamId = psPnMapTran.processStreamId;
						psPnMapTran.processDataList[0].processNameId = psPnMapTran.processNameId;
						psPnMapTran.processDataList[0].resultStatus = 'Error';
						psPnMapTran.processDataList[1].processDataId = mpwProcessDataId;
						psPnMapTran.processDataList[1].reqId = psPnMapTran.reqId;
						psPnMapTran.processDataList[1].processStreamId = psPnMapTran.processStreamId;
						psPnMapTran.processDataList[1].processNameId = psPnMapTran.processNameId;
						psPnMapTran.processDataList[1].resultStatus = 'Error';
					}
					psPnMapTran.processDataList.forEach(supportingProcessData => {
						supportingProcessData.scenarioChange = true;
						supportingProcessData.scenarioTemplateChange = templateChange;
						if (supportingProcessData.isCopiedCar) {
							supportingProcessData.isCopiedCar = false;
						}
					});
				});
			});
		}
	}

	private checkFlagForCapacityReqChange() {
		if (this.capacityRequirementSourceDraft !== this.carRequest.capacityRequirementSource && (this.carRequest.capacityRequirementSource === 'Confirmed' || this.carRequest.capacityRequirementSource === 'What-If')) {
			if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
				this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
					psPnMapTran.processDataList.forEach(processData => {
						processData.capacityRequirementChange = true;
						if (processData.isCopiedCar) {
							processData.isCopiedCar = false;
						}
					});
				});
			}
			this.carRequest.primarySupportingMapTranList.forEach(supportingTransList => {
				supportingTransList.psPnMapTranList.forEach(psPnMapTranList => {
					psPnMapTranList.processDataList.forEach(supportingProcessData => {
						supportingProcessData.capacityRequirementChange = true;
						if (supportingProcessData.isCopiedCar) {
							supportingProcessData.isCopiedCar = false;
						}
					});
				});
			});
		} else {
			if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
				this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
					psPnMapTran.processDataList.forEach(processData => {
						processData.capacityRequirementChange = false;
					});
				});
			}
			if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
				this.carRequest.primarySupportingMapTranList.forEach(supportingTransList => {
					supportingTransList.psPnMapTranList.forEach(psPnMapTranList => {
						psPnMapTranList.processDataList.forEach(supportingProcessData => {
							supportingProcessData.capacityRequirementChange = false;
						});
					});
				});
			}
		}
	}

	private checkFlagForPartPrefixBaseSuffix() {
		if (this.partPrefixBaseSuffixDraft !== this.carRequest.prefix.concat('-' + this.carRequest.base).concat('-' + this.carRequest.suffix)) {
			if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
				this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
					psPnMapTran.processDataList.forEach(processData => {
						processData.partPrefixBaseSuffixChange = true;
						if (processData.isCopiedCar) {
							processData.isCopiedCar = false;
						}
					});
				});
			}
			this.carRequest.primarySupportingMapTranList.forEach(supportingTransList => {
				supportingTransList.psPnMapTranList.forEach(psPnMapTranList => {
					psPnMapTranList.processDataList.forEach(supportingProcessData => {
						supportingProcessData.partPrefixBaseSuffixChange = true;
						if (supportingProcessData.isCopiedCar) {
							supportingProcessData.isCopiedCar = false;
						}
					});
				});
			});
		}
	}

	private buildDisplayMessage(userAction: string, reqId: number): string {
		console.log('Entered into build and display:- useraction:- ' + userAction + ' reqId:- ' + reqId);
		let message;
		if (userAction === 'SUBMIT') {
			message = ' CAR Request #' + reqId + ' has been submitted successfully!';
		} else if (userAction === 'APPROVE') {
			message = ' CAR Request #' + reqId + ' has been approved successfully!';
		} else if (userAction === 'ACKNOWLEDGE') {
			message = ' CAR Request #' + reqId + ' has been acknowledged successfully!';
		} else if (userAction === 'REJECT') {
			message = ' CAR Request #' + reqId + ' has been rejected successfully!';
		} else if (userAction === 'DRAFT') {
			message = ' CAR Request #' + reqId + ' has been drafted successfully!';
		}
		console.log('return message:- ' + message);
		return message;
	}

	private validateAndShowAPPCAndMPPCWarning() {
		this.validateAndShowAPPCWarning(false);
		this.validateAndShowMPPCWarning(false);
	}

	private validateAndShowAPPCWarning(isChangedFromSummary: boolean) {
		this.isAPPCCommitmentLower = false;
		this.isAPPCCommitmentHigher = false;
		this.isAPPCCommitment10PercentHigher = false;
		this.carRequest.commitmentAppc = this.carRequest.commitmentAppc ? this.carRequest.commitmentAppc : null;
		const requiredAPW = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsApw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsApw : this.carRequest.gcpMcpvApw;
		const requiredMPW = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsMpw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsMpw : this.carRequest.gcpMcpvMpw;
		const onePartDisc = this.isOnePartDiscrepancyAPW();
		if (this.carRequest && this.carRequest.commitmentAppc) {
			if (requiredAPW >= 0 && this.carRequest.commitmentAppc < requiredAPW) {
				this.isAPPCCommitmentLower = true;
			} else if (this.carRequest.plannedCapacityApw > 0 && (onePartDisc && this.carRequest.commitmentAppc > this.carRequest.plannedCapacityApw + 1) || (!onePartDisc && this.carRequest.commitmentAppc > this.carRequest.plannedCapacityApw)) {
				this.isAPPCCommitmentHigher = true;
			} else if (requiredAPW >= 0 && this.carRequest.commitmentAppc > (requiredAPW * 1.1) &&
				((onePartDisc && this.carRequest.commitmentAppc <= this.carRequest.plannedCapacityApw + 1) || (!onePartDisc && this.carRequest.commitmentAppc <= this.carRequest.plannedCapacityApw))) {
				this.isAPPCCommitment10PercentHigher = true;
			}
		}
		if (isChangedFromSummary && this.carRequest.commitmentAppc && this.carRequest.commitmentMppc) {
			if (this.isReqNeedsAcknowledgment && requiredAPW <= this.carRequest.commitmentAppc && requiredMPW <= this.carRequest.commitmentMppc) {
				this.checkAcknowledgmentWorkflow();
			}
			if (!this.isReqNeedsAcknowledgment && (requiredAPW > this.carRequest.commitmentAppc || requiredMPW > this.carRequest.commitmentMppc)) {
				this.checkAcknowledgmentWorkflow();
			}
		}
	}

	private validateAndShowMPPCWarning(isChangedFromSummary: boolean) {
		const requiredAPW = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsApw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsApw : this.carRequest.gcpMcpvApw;
		const requiredMPW = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsMpw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsMpw : this.carRequest.gcpMcpvMpw;
		if (this.carRequest.commitmentAppc && this.carRequest.commitmentMppc && (this.carRequest.commitmentAppc < requiredAPW || this.carRequest.commitmentMppc < requiredMPW) && this.carRequest.plannedCapacityApw >= requiredAPW && this.carRequest.plannedCapacityMpw >= requiredMPW && !this.carRequest.notes && !this.totalAllocationPercentageGreaterThan100 && !this.containAssumptionsHasFalse) {
			this.isNoteMandatory = true;
		} else {
			this.isNoteMandatory = false;
		}
		this.isMPPCCommitmentLower = false;
		this.isMPPCCommitmentHigher = false;
		this.isMPPCCommitment10PercentHigher = false;
		this.carRequest.commitmentMppc = this.carRequest.commitmentMppc ? this.carRequest.commitmentMppc : null;
		const onePartDisc = this.isOnePartDiscrepancyMPW();
		if (this.carRequest && this.carRequest.commitmentMppc) {
			if (requiredMPW >= 0 && this.carRequest.commitmentMppc < requiredMPW) {
				this.isMPPCCommitmentLower = true;
			} else if (this.carRequest.plannedCapacityMpw > 0 && ((onePartDisc && this.carRequest.commitmentMppc > this.carRequest.plannedCapacityMpw + 1) || (!onePartDisc && this.carRequest.commitmentMppc > this.carRequest.plannedCapacityMpw))) {
				this.isMPPCCommitmentHigher = true;
			} else if (requiredMPW >= 0 && this.carRequest.commitmentMppc > (requiredMPW * 1.1) && ((onePartDisc && this.carRequest.commitmentMppc <= this.carRequest.plannedCapacityMpw + 1)
				|| (!onePartDisc && this.carRequest.commitmentMppc <= this.carRequest.plannedCapacityMpw))) {
				this.isMPPCCommitment10PercentHigher = true;
			}
		}
		if (isChangedFromSummary && this.carRequest.commitmentAppc && this.carRequest.commitmentMppc) {
			if (this.isReqNeedsAcknowledgment && requiredAPW <= this.carRequest.commitmentAppc && requiredMPW <= this.carRequest.commitmentMppc) {
				this.checkAcknowledgmentWorkflow();
			}
			if (!this.isReqNeedsAcknowledgment && (requiredAPW > this.carRequest.commitmentAppc || requiredMPW > this.carRequest.commitmentMppc)) {
				this.checkAcknowledgmentWorkflow();
			}
		}
	}

	ValidatePhoneNumber(event: KeyboardEvent) {
		const ASCIICode = (event.which) ? event.which : event.keyCode;
		if ((ASCIICode > 64 && ASCIICode < 91) || (ASCIICode > 96 && ASCIICode < 123)) {
			return false;
		}
		return true;
	}

	isHeaderFormValid(currentPage: string) {
		let apwMpwValid = false;
		if (this.carRequest && this.carRequest.paRequirementsApw && this.carRequest.paRequirementsApw !== 0 && this.carRequest.paRequirementsMpw !== 0 && this.carRequest.paRequirementsMpw >= this.carRequest.paRequirementsApw && this.carRequest.capacityRequirementSource !== null
			&& (!this.carRequest.keyContacts[0].name || this.carRequest.keyContacts[0].name && (this.carRequest.keyContacts[0].type.toLowerCase().includes('site') || this.carRequest.keyContacts[0].type.toLowerCase().includes('sta') || this.carRequest.keyContacts[0].type.toLowerCase().includes('super user'))) && !this.engNotAvailableInAps) {
			apwMpwValid = true;
		}
		if (apwMpwValid) {
			if (currentPage && (currentPage === 'HEADER' || currentPage === 'SaveAndContinueHeader')) {
				let isValid = true;
				if (this.carRequest.submissionScenarioObject.id === -1) {
					isValid = false;
				}
				this.carRequest.prefix = this.carRequest.prefix.trim();
				this.carRequest.base = this.carRequest.base.trim();
				this.carRequest.suffix = this.carRequest.suffix.trim();
				this.loadContactTypeOnKeyContacts();
				return isValid;
			} else {
				return true;
			}
		}
		return false;
	}

	private isDescriptionValid() {
		let isDescriptionConditionallyRequired = this.carRequest.capacityRequirementSourceObject && this.carRequest.capacityRequirementSourceObject.code === 'Other' && this.carRequest.reqDescription === '';
		if (!isDescriptionConditionallyRequired) {
			isDescriptionConditionallyRequired = this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.reqDescription === '';
		}
		return isDescriptionConditionallyRequired;
	}

	private addFormInvalidIfSiteCodeDropdownInValid() {
		if (this.headerForm && this.headerForm.form && this.headerForm.form.controls['siteCode']) {
			if (this.carRequest && this.carRequest.siteCodeObject && this.carRequest.siteCodeObject === '') {
				this.headerForm.form.controls['siteCode'].setErrors({'incorrect': true});
				//this.headerDropdownValidationEnabled = true;
			} else {
				this.headerForm.form.controls['siteCode'].setErrors(null);
			}
		}
	}

	private addFormInvalidIfPriorityDropdownInValid() {
		if (this.headerForm && this.headerForm.form && this.headerForm.form.controls['priority']) {
			if (this.carRequest && this.carRequest.priorityObject && this.carRequest.priorityObject.code === '') {
				this.headerForm.form.controls['priority'].setErrors({'incorrect': true});
			} else {
				this.headerForm.form.controls['priority'].setErrors(null);
			}
		}
	}

	private addFormInvalidIfPPAPLevelDropdownInValid() {
		if (this.headerForm && this.headerForm.form && this.headerForm.form.controls['partPPAPLevel']) {
			if (this.carRequest && this.carRequest.partPPAPLevelObject && this.carRequest.partPPAPLevelObject.code === -1) {
				this.headerForm.form.controls['partPPAPLevel'].setErrors({'incorrect': true});
			} else {
				this.headerForm.form.controls['partPPAPLevel'].setErrors(null);
			}
		}
	}

	private addFormInvalidIfSubmissionScenarioDropdownInValid() {
		if (this.headerForm && this.headerForm.form && this.headerForm.form.controls['submissionScenario']) {
			if (this.carRequest && this.carRequest.submissionScenarioObject) {
				if (this.carRequest.submissionScenarioObject.id === -1) {
					this.headerForm.form.controls['submissionScenario'].setErrors({'incorrect': true});
				} else {
					this.headerForm.form.controls['submissionScenario'].setErrors(null);
					if (this.carRequest.submissionScenarioObject['capacityStudy'] === true) {
						this.descriptionLabel = 'Capacity Study Number';
					} else {
						this.descriptionLabel = 'Description';
					}
				}
			}
		}
	}

	private addFormInvalidIfCapacityRequireSourceDropdownInValid() {
		if (this.headerForm && this.headerForm.form && this.headerForm.form.controls['capacityRequirementSource']) {
			if (this.carRequest && this.carRequest.capacityRequirementSourceObject && this.carRequest.capacityRequirementSourceObject.code === 'invalid') {
				this.headerForm.form.controls['capacityRequirementSource'].setErrors({'incorrect': true});
			} else {
				this.headerForm.form.controls['capacityRequirementSource'].setErrors(null);
			}
		}

	}

	private isPrimaryStreamValid(currentPage: string) {
		if (currentPage && (currentPage === 'PRIMARY' || currentPage === 'SaveAndContinuePrimary')) {
			return this.isValidPrimaryStreamForm();
		} else {
			return true;
		}
	}

	private isSupportingStreamValid(currentPage: string) {
		if (currentPage && (currentPage === 'SECONDARY' || currentPage === 'SaveAndContinueSupporting')) {
			return this.isValidSupportingStream;
		} else {
			return true;
		}
	}

	private isDeclarationValid(currentPage: string, userAction: string) {
		this.updateQuestionarieAnswer();
		this.carRequest.declarationQuestionsTranList = this.declarationQuestionTranTmpList;
		if (userAction && userAction === 'SUBMIT') {
			if (this.questionLoaded) {
				if (!this.isAllMandatoryQuestionariesValid()) {
					this.utilService.pushMsg('error', 'Error', 'Please fill All mandatory fields in Declaration Tab');
					return false;
				} else if (!this.isStandardDeclQuestionValid()) {
					return false;
				} else {
					return true;
				}
			} else {
				if (!this.isMandatoryQuestionsValid) {
					this.utilService.pushMsg('error', 'Error', 'Please fill All mandatory fields in Declaration Tab');
				}
				return false;
			}
		} else {
			return true;
		}
	}

	private isSummaryFormValid(currentPage: string, userAction: string) {
		let isValid: boolean;
		if (currentPage && currentPage === 'SUMMARY' && userAction === 'SUBMIT') {
			const isPrimaryValid = this.isValidPrimaryStreamForm();
			const isAllProcessDataValidPrimary = this.isAllProcessDataValidForPrimaryStream();
			const isSecondaryValid = this.isValidSupportingStream();
			let isAPPCInSummaryValid = this.isCommitmentFieldsAreValid();
			const isDeviationInSummaryValid = this.isDeviationReasonValid();
			const isAllProcessDataValidFromExternalDependencyError = this.isAllProcessDataValidFromExternalDependencyError();
			if (this.carRequest.isLinkCar) {
				if (this.carRequest.parentCarStatus === 'DRAFT') {
					isAPPCInSummaryValid = false;
					this.utilService.pushMsg('error', 'Error', 'Parent eCAR is in Draft status, Please Submit parent eCAR in order to proceed');
				}
			}
			isValid = isPrimaryValid && isAllProcessDataValidPrimary && isSecondaryValid && isAPPCInSummaryValid && isAllProcessDataValidFromExternalDependencyError && isDeviationInSummaryValid;
		} else {
			isValid = true;
		}
		return isValid;
	}

	private isCommitmentFieldsAreValid() {
		let isValid: boolean;
		if (this.carRequest && this.carRequest.commitmentMppc && this.carRequest.commitmentMppc !== 0 && this.carRequest.commitmentAppc && this.carRequest.commitmentAppc !== 0) {
			isValid = true;
		} else {
			this.utilService.pushMsg('error', 'Error', 'Please enter the APPC MPPC commitments');
			isValid = false;
		}
		return isValid;
	}

	private isDeviationReasonValid() {
		let isValid: boolean;
		if (this.carRequest && this.carRequest.isAnyProcessContainsAPWGreaterThan5) {
			if (this.carRequest.deviationReasonCode && this.carRequest.deviationReasonCode !== -1) {
				isValid = true;
			} else {
				this.utilService.pushMsg('error', 'Error', 'Please select any Deviation Reason');
				isValid = false;
			}
		} else {
			isValid = true;
		}
		return isValid;
	}

	private addFormInvalidIfDeviationReasonDropdownInValid() {
		if (this.summaryForm && this.summaryForm.form && this.summaryForm.form.controls['deviationReasonCode']) {
			if (this.carRequest && this.carRequest.deviationReasonObject && this.carRequest.deviationReasonObject.code === -1) {
				this.summaryForm.form.controls['deviationReasonCode'].setErrors({'incorrect': true});
			} else {
				this.summaryForm.form.controls['deviationReasonCode'].setErrors(null);
			}
		}
	}

	private showSupplierSiteErrorMessage() {
		this.confirmationService.confirm({
			message: 'Please select an active production site code or contact your STA Site Engineer <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(or Buyer, if STA is not known) for assistance.',
			header: 'Alert',
			icon: 'pi pi-times-circle',
			acceptLabel: 'OK',
			rejectVisible: false
		});
	}

	onChangePriority() {
		if (this.carRequest.priority === 'Yes') {
			this.carRequest.partPPAPLevelObject = this.partPPAPLevelOptions.find(value => value.code === 5);
			this.carRequest.partPpapLevel = this.carRequest.partPPAPLevelObject.code;
		}
		this.addFormInvalidIfPriorityDropdownInValid();
	}

	saveCarRequest(saveCAR: boolean) {
		if ((this.mode === 'edit' || this.mode === 'create') && saveCAR) {
			this.carRequest.userAction = 'DRAFT';
			this.carRequest.scenarioName = this.carRequest.submissionScenarioObject.scenarioName.toUpperCase();
			this.carRequest.loggedInUserId = this.loggedInUserId;
			this.carRequestService.update(this.carRequest.reqId, this.carRequest).subscribe(value => {
				console.log('Sending Car req user action;- ' + this.carRequest.userAction);
				if (this.isNeweCAR) {
					if (this.onSupportingStreamPage) {
						this.populateSuppStreamDataOnPrimaryStreamSave(value);
					} else {
						this.populatePrimaryStreamData(value);
					}
				}
				const message: string = this.buildDisplayMessage(this.carRequest.userAction, value.reqId);
				this.ngOnInit();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Unable to update request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	showConfirmationDialog($event: MouseEvent) {
		if (!this.isViewMode) {
			this.confirmationService.confirm({
				message: ' Are you sure you want to cancel? \n\tAny data entered since the last draft was saved will be lost.',
				header: 'Confirmation',
				icon: 'pi pi-info-circle',
				accept: () => {
					this.onSummaryPrevious();
					if (this.mode === 'create') {
						this.router.navigate(['home-page/0/10/undefined/undefined/false']);
					} else {
						this.router.navigate(['home-page/' + this.pageNo + '/' + this.pageSize + '/' + this.sortingParameters + '/' + this.filterParammeters + '/' + this.isViewAlleCARs]);
					}
				},
				reject: () => {
				}
			});
		} else {
			this.onSummaryPrevious();
			this.router.navigate(['home-page/' + this.pageNo + '/' + this.pageSize + '/' + this.sortingParameters + '/' + this.filterParammeters + '/' + this.isViewAlleCARs]);
		}
	}

	isHavingValidEcarStatus() {
		return this.carRequest && this.carRequest.requestStatus ? true : false;
	}

	setFormInvalidIfDropdownEmpty() {
		if (this.originPage === 'HEADER') {
			this.addFormInvalidIfSiteCodeDropdownInValid();
			this.addFormInvalidIfSubmissionScenarioDropdownInValid();
			/*this.addFormInvalidIfPriorityDropdownInValid();
			this.addFormInvalidIfPPAPLevelDropdownInValid();
			this.addFormInvalidIfCapacityRequireSourceDropdownInValid();*/
		}
	}

	validateModelYear(event: any) {
		//checking entered value is between 0 and 9 or dot
		if (event.key >= '0' && event.key <= '9' || event.key === '.') {
		} else {
			return false;
		}
		const tarValue = event.target.value.toString();
		const spltarr = tarValue.split('.');
		if (spltarr.length > 1) {
			if (event.key === '.') {
				return false;
			}
			if (spltarr[1].length === 2) {
				return false;
			}
			if (spltarr[1].length >= 1) {
				if ((spltarr[1] === '0' && event.key === '0') || (spltarr[1] === '2' && event.key === '5') || (spltarr[1] === '5' && event.key === '0') || (spltarr[1] === '7' && event.key === '5')) {
					this.isValidModelYear = true;
				} else {
					this.isValidModelYear = false;
				}
			}
		} else {
			this.isValidModelYear = true;
		}
	}

	validateModelYearOnChange() {
		const modelyear = this.carRequest.modelYear;
		const spltarr = modelyear.split('.');
		if (spltarr.length < 2) {
			this.isValidModelYear = true;
		}
	}

	private isAllProcessDataValidFromExternalDependencyError() {
		if (this.utilService.isAllProcessDataExternalDependencyValidationScanDone) {
			if (!this.utilService.isAllProcessDataValidFromExternalDependencyError) {
				this.utilService.pushMsg('error', 'Error', 'Primary or Supporting Streams have data requiring review. Please Edit Process Details to refresh or update the data.');
				return false;
			} else {
				return true;
			}
		} else {
			throw new Error('All Process Data External Dependency Validation Scan not Done');
		}
	}

	populateErrorFlagValuesForComparison(carReq: CarRequest) {
		if (carReq.paRequirementsApw !== null || carReq.revisedRequirementsApw !== null || carReq.gcpMcpvApw !== null) {
			console.log('set draft value in not null');
			this.requirementsAPWFromDraft = carReq.requirementType === 1 ? carReq.paRequirementsApw : (carReq.requirementType === 2 ? carReq.revisedRequirementsApw : carReq.gcpMcpvApw);
			this.requirementsMPWFromDraft = carReq.requirementType === 1 ? carReq.paRequirementsMpw : (carReq.requirementType === 2 ? carReq.revisedRequirementsMpw : carReq.gcpMcpvMpw);
			this.partPrefixBaseSuffixDraft = carReq.prefix.concat('-' + this.carRequest.base).concat('-' + this.carRequest.suffix);
			this.submissionScenarioObjectDraft = carReq.submissionScenarioObject;
			this.capacityRequirementSourceDraft = carReq.capacityRequirementSource;
		}
	}

	private retrieveQ1FromSupplierSite(siteCode: string) {
		this.supplierSiteService.findBySiteCode(siteCode).subscribe(response => {
			const supplierSiteDetails = response;
			this.supplierSiteDetails.siteDetailsLoaded = true;
			this.carRequest.q1StatusCode = supplierSiteDetails.q1StatusCode;
			this.carRequest.q1StatusDesc = supplierSiteDetails.q1StatusDesc;
		}, error => {
			const supplierSiteDetails = emptySupplierSiteDetails;
			this.supplierSiteDetails.siteDetailsLoaded = false;
			this.carRequest.q1StatusCode = supplierSiteDetails.q1StatusCode;
			this.carRequest.q1StatusDesc = supplierSiteDetails.q1StatusDesc;
			//this.showSupplierSiteErrorMessage();
		});
	}

	parseInt(code: string, number: number) {
		return parseInt(code, number);
	}

	loadCarDropdownLinkCAR() {
		this.disableForEcarLink = true;
		if (this.mode === 'edit') {
			this.carRequestService.loadEcarIdOptions().subscribe(value => {
				this.carIdLinkOptions = value;
				if (this.carRequest && this.carRequest.selectedCarIdForLinkage) {
					this.carRequest.selectedCarIdForLinkageObject = this.carIdLinkOptions.find(value1 => value1.code === this.carRequest.selectedCarIdForLinkage.toString());
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.carRequestService.loadEcarWithParentId(this.carRequest.selectedCarIdForLinkage).subscribe(value => {
				this.carIdLinkOptions = value;
				if (this.carRequest && this.carRequest.selectedCarIdForLinkage) {
					this.carRequest.selectedCarIdForLinkageObject = this.carIdLinkOptions.find(value1 => value1.code === this.carRequest.selectedCarIdForLinkage.toString());
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	unloadCarDropdownLinkCAR(event) {
		if (this.disableForEcarLink) {
			this.disableForEcarLink = false;
			this.resetHeaderLinkEcar(event);
		}
		this.retrieveAndDisplaySupplierLeadDetailsOnKeyContacts();
	}

	loadStreamDropdownLinkCAR() {
		if (this.carRequest && this.carRequest.selectedCarIdForLinkageObject && this.carRequest.selectedCarIdForLinkageObject.code !== 'invalid') {
			this.populateStreamDropdown(this.carRequest.selectedCarIdForLinkageObject.code.toString());
		} else if (this.carRequest && this.carRequest.selectedCarIdForLinkage) { //edit mode
			this.populateStreamDropdown(this.carRequest.selectedCarIdForLinkage.toString());
		}
	}

	private populateStreamDropdown(carId: string) {
		this.carRequestService.findAllProcessStreamByCarId(carId).subscribe(value => {
			this.streamNameDropDownOptions = value;
			if (this.carRequest && this.carRequest.selectedStreamIdForLinkage && this.streamNameDropDownOptions && this.streamNameDropDownOptions.length > 0) {
				this.carRequest.selectedStreamIdForLinkageObject = this.streamNameDropDownOptions.find(value1 => value1.code === this.carRequest.selectedStreamIdForLinkage.toString());
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private resetHeaderLinkEcar(event) {
		console.log(this.carRequest);
		//if (this.carRequest && this.carRequest.requestStatus && event) {
		console.log('new value ' + event.code);
		console.log('old value ' + this.carRequest.selectedCarIdForLinkage.toString());
		//const isValid = event && this.carRequest && this.carRequest.selectedStreamIdForLinkage && this.carRequest.selectedCarIdForLinkage.toString() !== event.code;
		//console.log('is selection is diff than old val ? ' + isValid);
		//if (isValid) {
		this.carRequest.selectedStreamIdForLinkage = 0;
		this.carRequest.selectedStreamIdForLinkageObject = this.emptyCar.selectedStreamIdForLinkageObject;

		this.carRequest.selectedProcessNameIdForLinkage = 0;
		this.carRequest.selectedProcessNameIdForLinkageObject = this.emptyCar.selectedProcessNameIdForLinkageObject;
		this.emptyHeaderDataLinkEcar();
		this.resetPrimaryStreamPage();
		//this.carRequest.selectedCarIdForLinkage = event.code;
		//}
		//}
	}

	resetPrimaryStreamPage() {
		this.carRequest.primaryStreamId = 0;
		this.carRequest.psPnMapTransList = [];
		this.carRequest.primarySupportingMapTranList = [];
		this.selectedPrimaryStream = emptyProcessStreamDroprdown;
	}

	loadProcessNameDropdownLinkCar() {
		this.carRequestService.findAllProcessByStreamId(this.carRequest.selectedCarIdForLinkage.toString(), this.carRequest.selectedStreamIdForLinkage.toString()).subscribe(value => {
			this.processNameDropDownOptions = value;
			if (this.carRequest && this.carRequest.selectedProcessNameIdForLinkage) {
				this.carRequest.selectedProcessNameIdForLinkageObject = this.processNameDropDownOptions.find(value1 => value1.code === this.carRequest.selectedProcessNameIdForLinkage.toString());
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	inputNumberValidationWithSingleDecimal($event: KeyboardEvent) {
		return this.utilService.inputNumberValidationWithSingleDecimal(event);
	}

	populateParenteEcarInfo(carId: number, streamId: number, processNameId: number) {
		this.carRequestService.findByIdForEcarLink(carId, streamId, processNameId).subscribe(parentCarRequest => {
			this.carRequest.parentCarStatus = parentCarRequest.requestStatus;
			this.carRequest.siteCode = parentCarRequest.siteCode;
			this.carRequest.supplierName = parentCarRequest.supplierName;
			this.carRequest.partName = parentCarRequest.partName;
			this.carRequest.prefix = parentCarRequest.prefix;
			this.carRequest.base = parentCarRequest.base;
			this.carRequest.suffix = parentCarRequest.suffix;
			this.carRequest.priority = parentCarRequest.priority;
			this.carRequest.partPpapLevel = parentCarRequest.partPpapLevel;
			this.carRequest.submissionScenario = parentCarRequest.submissionScenario;
			this.carRequest.capacityRequirementSource = parentCarRequest.capacityRequirementSource;
			this.prePopulateHeaderDropdown();
			if (this.mode === 'create') {
				this.capacityReqSrcCheck(parentCarRequest);
				if (this.capacityStudy) {
					this.hideCapacityStudyElements = false;
				}
			}
			this.carRequest.q1StatusCode = parentCarRequest.q1StatusCode;
			this.carRequest.q1StatusDesc = parentCarRequest.q1StatusDesc;
			this.carRequest.modelYear = parentCarRequest.modelYear;
			this.carRequest.programCode = parentCarRequest.programCode;
			this.carRequest.reqDescription = parentCarRequest.reqDescription;
			this.carRequest.keyContacts = parentCarRequest.keyContacts;
			this.carRequest.paRequirementsApw = parentCarRequest.paRequirementsApw;
			this.carRequest.paRequirementsMpw = parentCarRequest.paRequirementsMpw;
			if (parentCarRequest.revisedRequirementsApw && parentCarRequest.revisedRequirementsApw.toString().trim() !== '' && parentCarRequest.revisedRequirementsMpw && parentCarRequest.revisedRequirementsMpw.toString().trim() !== '') {
				this.carRequest.revisedRequirementsApw = parentCarRequest.revisedRequirementsApw;
				this.carRequest.revisedRequirementsMpw = parentCarRequest.revisedRequirementsMpw;
			}
			if (parentCarRequest.gcpMcpvApw && parentCarRequest.gcpMcpvApw.toString().trim() !== '' && parentCarRequest.gcpMcpvMpw && parentCarRequest.gcpMcpvMpw.toString().trim() !== '') {
				this.carRequest.gcpMcpvApw = parentCarRequest.gcpMcpvApw;
				this.carRequest.gcpMcpvMpw = parentCarRequest.gcpMcpvMpw;
			}
			this.carRequest.requirementType = parentCarRequest.requirementType;
			//this.requirementsAPWFromDraft = this.carRequest.paRequirementsApw;
			//this.requirementsMPWFromDraft = this.carRequest.paRequirementsMpw;
			this.carRequest.declarationQuestionsTranList = parentCarRequest.declarationQuestionsTranList;
			this.retrieveAvailablePrimaryStreamBySiteCode();
			this.manageFormSubmitFlag();

			//emptying if exists
			this.emptyHMPandSLP();

			/*Optimization
			this.initializePrimaryStream();
			this.initializeSupportingStream();
			this.retrieveSharedLoadingIdBySiteCode();*/
			this.initializeDeclaration();
			this.submissionScenarioObjectDraft = this.carRequest.submissionScenarioObject;
			this.capacityRequirementSourceDraft = this.carRequest.capacityRequirementSource;
			this.partPrefixBaseSuffixDraft = this.carRequest.prefix.concat('-' + this.carRequest.base).concat('-' + this.carRequest.suffix);
			this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
			this.disableForEcarLink = true;
			this.initializeQ1Status();
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private emptyHeaderDataLinkEcar() {
		this.carRequest.siteCode = '';
		this.carRequest.supplierName = '';
		this.carRequest.partName = '';
		this.carRequest.prefix = '';
		this.carRequest.base = '';
		this.carRequest.suffix = '';
		this.carRequest.priority = '';
		this.carRequest.partPpapLevel = this.emptyCar.partPpapLevel;
		this.carRequest.submissionScenario = this.emptyCar.submissionScenario;
		this.carRequest.capacityRequirementSource = '';
		this.resetHeaderDropdown();
		this.carRequest.q1StatusCode = '';
		this.carRequest.q1StatusDesc = '';
		this.carRequest.modelYear = '';
		this.carRequest.programCode = '';
		this.carRequest.keyContacts = this.emptyCar.keyContacts;
		this.carRequest.paRequirementsApw = 0;
		this.carRequest.paRequirementsMpw = 0;
		this.carRequest.gcpMcpvApw = 0;
		this.carRequest.gcpMcpvMpw = 0;
		this.carRequest.revisedRequirementsApw = 0;
		this.carRequest.revisedRequirementsMpw = 0;
		this.carRequest.requirementType = 1;
		this.requirementsAPWFromDraft = 0;
		this.requirementsMPWFromDraft = 0;
		this.carRequest.keyContacts = this.buildEmptyKeyContact();
	}

	showScenarioDescription(showDialog: boolean) {
		this.showScenarioDescriptionDialog = showDialog;
	}

	private setProcessDataDraftForSLChangeDetect() {
		this.primarySLAllocationFromDraft = [];
		this.secondarySLAllocationFromDraft = [];
		if (this.carRequest && this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
				this.primarySLAllocationFromDraft.push(new ProcessNameIdAllocationPercentMap({
					'processNameId': psPnMapTran.processNameId,
					'allocationPercent': [_.round(psPnMapTran.processDataList[0].sharedLoadingTotalAllocPercent, 2), _.round(psPnMapTran.processDataList[1].sharedLoadingTotalAllocPercent, 2)]
				}));
			});
		}
		if (this.carRequest && this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			this.carRequest.primarySupportingMapTranList.forEach(primarySupportingMapTran => {
				primarySupportingMapTran.psPnMapTranList.forEach(psPnMapTran => {
					this.secondarySLAllocationFromDraft.push(new ProcessNameIdAllocationPercentMap({
						'processNameId': psPnMapTran.processNameId,
						'allocationPercent': [_.round(psPnMapTran.processDataList[0].sharedLoadingTotalAllocPercent, 2), _.round(psPnMapTran.processDataList[1].sharedLoadingTotalAllocPercent, 2)]
					}));
				});
			});
		}
	}

	onPrint() {
		if (this.isSaveAndPrint && this.carRequest.requestStatus && (this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'STADRAFT')) {
			this.isPrint = true;
			this.onSubmit('DRAFT', 'SUMMARY');
		} else {
			const windowSize = 'width=' + (window.innerWidth - 100) + ',height=' + (window.innerHeight + 500);
			window.open(window.location.origin + '/#/eprint/' + this.carRequest.reqId, 'popup', windowSize);
		}
	}

	onPrimePanelClick(primeMapView: boolean) {
		this.primeMapView = primeMapView;
		this.showPrimaryProcessStream = !this.showPrimaryProcessStream;
		this.hisoricalPrimeMapView = false;
		this.showHistoricalPrimaryProcessStream = false;
		this.SupportMapView = false;
		this.showSecondaryProcessStream = false;
		this.hisoricalSupportMapView = false;
		this.showHistoricalSecondaryProcessStream = false;
	}

	onHistoricalPrimePanelClick(primeMapView: boolean) {
		this.hisoricalPrimeMapView = primeMapView;
		this.showHistoricalPrimaryProcessStream = !this.showHistoricalPrimaryProcessStream;
		this.primeMapView = false;
		this.showPrimaryProcessStream = false;
		this.SupportMapView = false;
		this.showSecondaryProcessStream = false;
		this.hisoricalSupportMapView = false;
		this.showHistoricalSecondaryProcessStream = false;
	}

	onSupportPanelClick(supportMapView: boolean) {
		this.SupportMapView = supportMapView;
		this.showSecondaryProcessStream = !this.showSecondaryProcessStream;
		this.primeMapView = false;
		this.showPrimaryProcessStream = false;
		this.hisoricalPrimeMapView = false;
		this.showHistoricalPrimaryProcessStream = false;
		this.hisoricalSupportMapView = false;
		this.showHistoricalSecondaryProcessStream = false;
	}

	onHistoricalSupportPanelClick(supportMapView: boolean) {
		this.hisoricalSupportMapView = supportMapView;
		this.showHistoricalSecondaryProcessStream = !this.showHistoricalSecondaryProcessStream;
		this.primeMapView = false;
		this.showPrimaryProcessStream = false;
		this.hisoricalPrimeMapView = false;
		this.showHistoricalPrimaryProcessStream = false;
		this.SupportMapView = false;
		this.showSecondaryProcessStream = false;
	}

	onNextPage() {
		this.primeMapView = false;
		this.hisoricalPrimeMapView = false;
		this.SupportMapView = false;
		this.hisoricalSupportMapView = false;
		this.showPrimaryProcessStream = false;
		this.showHistoricalPrimaryProcessStream = false;
		this.showSecondaryProcessStream = false;
		this.showHistoricalSecondaryProcessStream = false;
	}

	reCalleCAR() {
		let confirmationMsg = '';
		this.carRequest.recallReason = this.recallReasonField;
		confirmationMsg = 'Are you sure that you want to recall this record?  It will revert to DRAFT status and will no longer be visible to Ford.';
		this.confirmationService.confirm({
			message: confirmationMsg,
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.carRequestService.reCalleCAR(this.carRequest).subscribe(value => {
					this.router.navigate(['home-page/' + this.pageNo + '/' + this.pageSize + '/' + this.sortingParameters + '/' + this.filterParammeters + '/' + this.isViewAlleCARs]);
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			},
			reject: () => {
				console.log('no action');
			}
		});
	}

	private userRoleCheckForRejection(siteSupCdsId: string, siteEngCdsID: string) {
		if (this.loggedInUser && (this.loggedInUser.includes('Engineer') || this.loggedInUser.includes('Supervisor'))) {
			this.userIdService.userID.subscribe((value: string) => {
				this.userId = value.toLowerCase();
			});
			if (siteSupCdsId.toLowerCase() === this.userId || siteEngCdsID.toLowerCase() === this.userId) {
				this.enableRejection = true;
			}
		}
	}

	capacityStudyCheck() {
		if (this.carRequest.submissionScenarioObject.capacityStudy === true) {
			this.capacityStudy = true;
			this.carRequest.requirementType = 2;
			this.carRequest.capacityRequirementSource = null;
			this.carRequest.capacityRequirementSourceObject = null;
			this.carRequest.gcpMcpvApw = null;
			this.carRequest.gcpMcpvMpw = null;
			this.hideCapacityStudyElements = false;
			this.showGcpMcpvValidationError = false;
		} else {
			this.capacityRequirementSourceRequired = false;
			this.capacityStudy = false;
			this.carRequest.requirementType = 1;
			this.carRequest.revisedRequirementsApw = null;
			this.carRequest.revisedRequirementsMpw = null;
			this.carRequest.keyContacts[4].name = '';
			if (this.carRequest.capacityRequirementSource === 'Confirmed' || this.carRequest.capacityRequirementSource === 'What-If') {
				this.carRequest.capacityRequirementSourceObject = null;
			} else {
				this.carRequest.capacityRequirementSourceObject = this.carRequest.capacityRequirementSourceObject;
			}
			this.hideCapacityStudyElements = true;
		}
	}

	validateContactDetails(contact: string) {
		if (contact === 'CapacityStudyBuyer') {
			if (this.carRequest.keyContacts[4].cdsid.length === 0) {
				this.noMatchFoundCapacityStudyBuyer = true;
			} else {
				this.noMatchFoundCapacityStudyBuyer = false;
			}
		} else if (contact === 'SiteSupervisor') {
			if (this.carRequest.keyContacts[3].cdsid.length === 0) {
				this.siteSupervisorRequired = false;
				this.noMatchFoundSiteSupervisorCDSID = false;
				this.noValueSelecte = true;
			} else {
				this.noValueSelecte = false;
			}
		}
	}

	clearCapacityStudyCheck() {
		this.capacityRequirementSourceRequired = false;
	}

	isSiteCodeAvailableInSIM(siteCode: string) {
		if (siteCode && siteCode.length > 4) {
			this.processNameService.isSiteCodePresentInSIM(siteCode).subscribe(isPresent => {
				this.isSitePresentInSIM = isPresent;
			});
		}
	}

	private buildSharedLoadingLink() {
		if (this.carRequest && this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
				if (psPnMapTran && psPnMapTran.processDataList && psPnMapTran.processDataList.length > 0) {
					if (!psPnMapTran.processDataList[0].dedicated) {
						if (!this.carRequest.requestStatus || this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'STADRAFT') {
							this.manageSharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(this.carRequest.siteCode, psPnMapTran.processNameId.toString()).subscribe(value => {
								if (value) {
									psPnMapTran.sharedLoadingLink = window.location.origin + '/#/create-shared-loading/edit/' + value.id.toString();
									psPnMapTran.sharedLoadingLink += '/false/0/0';
									if (this.mode === 'view' || this.carRequest.requestStatus === 'STADRAFT') {
										psPnMapTran.sharedLoadingLink += '/Y/0/10';
									} else {
										psPnMapTran.sharedLoadingLink += '/N/0/10';
									}
									console.log('slp link @@ ' + psPnMapTran.sharedLoadingLink);
								}
							});
						} else {
							this.manageSharedLoadingService.retrieveSharedLoadingByCarIdAndSiteAndProcess(this.carRequest.reqId, this.carRequest.siteCode, psPnMapTran.processNameId).subscribe(value => {
								if (value) {
									psPnMapTran.sharedLoadingLink = window.location.origin + '/#/create-shared-loading/edit/' + value.id.toString();
									psPnMapTran.sharedLoadingLink += '/true/' + this.carRequest.reqId + '/' + psPnMapTran.processNameId + '/N/0/10';
								}
							});
						}
					}
				}
			});
		}
		if (this.carRequest && this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			this.carRequest.primarySupportingMapTranList.forEach(supportingTransList => {
				if (supportingTransList && supportingTransList.psPnMapTranList && supportingTransList.psPnMapTranList.length > 0) {
					supportingTransList.psPnMapTranList.forEach(psPnMapTran => {
						if (psPnMapTran && psPnMapTran.processDataList && psPnMapTran.processDataList.length > 0) {
							if (!psPnMapTran.processDataList[0].dedicated) {
								if (!this.carRequest.requestStatus || this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'STADRAFT') {
									this.manageSharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(this.carRequest.siteCode, psPnMapTran.processNameId.toString()).subscribe(value => {
										if (value) {
											psPnMapTran.sharedLoadingLink = window.location.origin + '/#/create-shared-loading/edit/' + value.id.toString();
											psPnMapTran.sharedLoadingLink += '/false/0/0';
											if (this.mode === 'view' || this.carRequest.requestStatus === 'STADRAFT') {
												psPnMapTran.sharedLoadingLink += '/Y/0/10';
											} else {
												psPnMapTran.sharedLoadingLink += '/N/0/10';
											}
										}
									});
								} else {
									this.manageSharedLoadingService.retrieveSharedLoadingByCarIdAndSiteAndProcess(this.carRequest.reqId, this.carRequest.siteCode, psPnMapTran.processNameId).subscribe(value => {
										if (value) {
											psPnMapTran.sharedLoadingLink = window.location.origin + '/#/create-shared-loading/edit/' + value.id.toString();
											psPnMapTran.sharedLoadingLink += '/true/' + this.carRequest.reqId + '/' + psPnMapTran.processNameId + '/N/0/10';
										}
									});
								}
							}
						}
					});
				}
			});
		}
	}

	validateModelYearFormat() {
		if (this.carRequest.modelYear && !this.isNumeric(this.carRequest.modelYear)) {
			this.invalidModelYearFormat = true;
		} else {
			this.invalidModelYearFormat = false;
		}
	}

	private summaryScreenProcessDetailBoxDataLoad() {
		this.carRequestService.findById(this.carRequest.reqId).subscribe(value => {
			this.carRequest.psPnMapTransList = value.psPnMapTransList;
			this.carRequest.primarySupportingMapTranList = value.primarySupportingMapTranList;
			this.buildSharedLoadingLink();
			this.isPlannedCapacityLessThanReqCapacity();
			const requiredCapacityApw = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsApw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsApw : this.carRequest.gcpMcpvApw;
			const requiredCapacityMpw = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsMpw : this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsMpw : this.carRequest.gcpMcpvMpw;
			if (this.carRequest.commitmentAppc && this.carRequest.commitmentMppc && (this.carRequest.commitmentAppc < requiredCapacityApw || this.carRequest.commitmentMppc < requiredCapacityMpw) && this.carRequest.plannedCapacityApw >= requiredCapacityApw && this.carRequest.plannedCapacityMpw >= requiredCapacityMpw && !this.carRequest.notes && !this.totalAllocationPercentageGreaterThan100 && !this.containAssumptionsHasFalse) {
				this.isNoteMandatory = true;
			} else {
				this.isNoteMandatory = false;
			}
			this.totalAllocationPercentageGreaterThan100 = false;
			this.containAssumptionsHasFalse = false;
			this.masterDataFlagDuplicateCheck();
			if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
				this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
					psPnMapTran.isPrimaryStream = true;
					if (psPnMapTran.processDataList) {
						for (let i = 0; i < 2; i++) {
							const processData = psPnMapTran.processDataList[i];
							//psPnMapTran.processDataList.forEach(processData => {
							if (!this.totalAllocationPercentageGreaterThan100 && !processData.dedicated && ((processData.totalAllocationPercentageAPW && processData.totalAllocationPercentageAPW > 100) || (processData.totalAllocationPercentageMPW && processData.totalAllocationPercentageMPW > 100))) {
								this.totalAllocationPercentageGreaterThan100 = true;
							}
							if (!this.containAssumptionsHasFalse && ((!processData.dedicated && !processData.isContainAssumptions) ||
								(processData.dedicated && !processData.isContainAssumptions
									&& processData.dedicatedTotalAllocPercent < 100))) {
								this.containAssumptionsHasFalse = true;
							}
							//});
						}
					}
				});
			}
			if ((!this.totalAllocationPercentageGreaterThan100 || !this.containAssumptionsHasFalse) && this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
				this.carRequest.primarySupportingMapTranList.forEach(priSupMapList => {
					if (priSupMapList.psPnMapTranList && priSupMapList.psPnMapTranList.length > 0) {
						priSupMapList.psPnMapTranList.forEach(psPnMapTran => {
							if (psPnMapTran.processDataList) {
								for (let i = 0; i < 2; i++) {
									const processData = psPnMapTran.processDataList[i];
									//psPnMapTran.processDataList.forEach(processData => {
									if (!this.totalAllocationPercentageGreaterThan100 && !processData.dedicated && ((processData.totalAllocationPercentageAPW && processData.totalAllocationPercentageAPW > 100) || (processData.totalAllocationPercentageMPW && processData.totalAllocationPercentageMPW > 100))) {
										this.totalAllocationPercentageGreaterThan100 = true;
									}
									if (!this.containAssumptionsHasFalse && ((!processData.dedicated && !processData.isContainAssumptions) ||
										(processData.dedicated && !processData.isContainAssumptions
											&& processData.dedicatedTotalAllocPercent < 100))) {
										this.containAssumptionsHasFalse = true;
									}
									//});
								}
							}
						});
					}
				});
			}
			this.manageFormSubmitFlag();
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	staEngReassign(option: boolean) {
		this.allowEngChange = option;
		if (this.allowEngChange) {
			this.oldSiteEng = JSON.parse(JSON.stringify(this.selectedSiteEng));
			this.selectedSiteEng = null;
			this.carRequest.keyContacts[0].mail = '';
			this.carRequest.keyContacts[0].phone = '';
		}

		if (this.carRequest.keyContacts[0].name && this.allowEngChange && this.curSiteEngcdsid === '') {
			this.curSiteEngcdsid = this.carRequest.keyContacts[0].cdsid;
		}
		if (this.curSiteEngcdsid !== '' && !this.allowEngChange) {
			this.noMatchFoundSiteEngineer = false;
			this.carRequest.keyContacts[0] = this.oldSiteEng;
			this.selectedSiteEng = this.carRequest.keyContacts[0];
		}
	}

	onSave() {
		this.allowEngChange = false;
		if (this.curSiteEngcdsid !== '' && this.curSiteEngcdsid !== this.carRequest.keyContacts[0].cdsid) {
			this.carRequest.keyContacts[0].type = 'STASiteEngineer';
			this.carRequestService.reAssignCAR(this.carRequest).subscribe(value => {
				this.router.navigate(['home-page/' + this.pageNo + '/' + this.pageSize + '/' + this.sortingParameters + '/' + this.filterParammeters + '/' + this.isViewAlleCARs]);
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.carRequest.keyContacts[0] = this.oldSiteEng;
			this.selectedSiteEng = this.carRequest.keyContacts[0];
			this.utilService.pushMsg('error', 'Error', 'Same value can not be updated. please choose other site engineer');
		}
	}

	reassignSiteSupervisor(option: boolean) {
		this.allowSupervisorChange = option;
		if (this.allowSupervisorChange) {
			this.oldSupervisor = JSON.parse(JSON.stringify(this.selectedFordSiteSupervisor));
			this.selectedFordSiteSupervisor = null;
			this.siteSupervisorRequired = true;
		}

		if (!this.allowSupervisorChange) {
			this.carRequest.keyContacts[3] = this.oldSupervisor;
			this.selectedFordSiteSupervisor = this.carRequest.keyContacts[3];
			this.siteSupervisorRequired = false;
			this.noValueSelecte = false;
			this.noMatchFoundSiteSupervisorCDSID = false;
		}
	}

	updateSiteSupervisor() {
		this.allowSupervisorChange = false;
		if (!!this.carRequest.keyContacts[3]) {
			if (this.carRequest.keyContacts[3].cdsid !== this.oldSupervisor.cdsid) {
				this.carRequest.keyContacts[3].type = 'STASiteSupervisor';
				const params = new HttpParams().set('keyContactType', 'SiteSupervisor');
				this.carRequestService.reAssignCARWithParams(this.carRequest, params).subscribe(value => {
					this.router.navigate(['home-page/' + this.pageNo + '/' + this.pageSize + '/' + this.sortingParameters + '/' + this.filterParammeters + '/' + this.isViewAlleCARs]);
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			} else {
				this.utilService.pushMsg('error', 'Error', 'Same value can not be updated. please choose other Site Supervisor');
			}
		}
	}

	updateRequest() {
		if (this.carRequest.userAction === 'SUBMIT') {
			this.carRequest.lastSubmittedDate = this.carRequest.updatedDate;
		}
		this.carRequestService.update(this.carRequest.reqId, this.carRequest).subscribe(value => {
			if (value) {
				this.disableReassign = true;
				this.utilService.pushMsg('success', 'Success', 'Site Engineer updated successfully');
				this.router.navigate(['home-page/' + this.pageNo + '/' + this.pageSize + '/' + this.sortingParameters + '/' + this.filterParammeters + '/' + this.isViewAlleCARs]);
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Unable to update request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	checkCommentsLength(event: ClipboardEvent, comments: string): void {
		if ((comments ? comments.length : 0) + event.clipboardData.getData('text').length > this.commentsMaxLength) {
			this.utilService.pushMsg('error', 'Error',
				'The content exceeds 1000 characters and so the extra characters have been removed. Please review.');
		}
	}

	initializeKeyContactsSelection(): void {
		this.selectedSiteEng = this.carRequest.keyContacts[0];
		this.selectedFordBuyer = this.carRequest.keyContacts[2];
		this.selectedFordSiteSupervisor = this.carRequest.keyContacts[3];
		this.selectedFordCapacityStudyBuyer = this.carRequest.keyContacts[4];
	}

	siteEngValidation() {
		if (this.allowEngChange && this.carRequest.keyContacts[0].mail === '') {
			this.carRequest.keyContacts[0].phone = '';
			this.selectedSiteEng = null;
		}
	}

	private masterDataFlagDuplicateCheck() {
		const supportStreamList: any[] = [];

		if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach(primaryMap => {
				supportStreamList.push(primaryMap.processNameId);
				let count = 0;
				supportStreamList.forEach(suppStream => {
					if (suppStream === primaryMap.processNameId) {
						count += 1;
					}
				});
				if (count === 1) {
					primaryMap.masterDataFlag = true;
				} else {
					primaryMap.masterDataFlag = false;
				}
			});
		}

		if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			this.carRequest.primarySupportingMapTranList.forEach((primarySupportMap, index) => {
				primarySupportMap.psPnMapTranList.forEach(primaryMap => {
					supportStreamList.push(primaryMap.processNameId);
					let count = 0;
					supportStreamList.forEach(suppStream => {
						if (suppStream === primaryMap.processNameId) {
							count += 1;
						}
					});
					if (count === 1) {
						primaryMap.masterDataFlag = true;
					} else {
						primaryMap.masterDataFlag = false;
					}
				});
			});
		}
	}

	private checkNonSiteUser(userId: string, userRole: string) {
		if (userRole.includes('Manager') || userRole.includes('Engineer') || userRole.includes('Supervisor')) {
			this.carRequestService.isPrimeUser(userId).subscribe(isValid => {
				if (isValid) {
					this.isNonSiteUser = true;
				} else {
					this.siteCodes = this.siteCodeService.fetchSiteCodes();
					this.isNonSiteUser = false;
				}
			});
		} else if (!userRole.toLowerCase().includes('ecar') && !userRole.includes('Developer')) {
			this.isNonSiteUser = true;
		} else {
			this.siteCodes = this.siteCodeService.fetchSiteCodes();
			this.isNonSiteUser = false;
		}
	}

	checkSupervisorRoleBeforeSubmit(status: string, currentPage: string) {
		this.apsService.retrieveAtrributesForEngineerKeyContactCheck(this.carRequest.keyContacts[3].cdsid).subscribe(apsAttribute => {
			if (apsAttribute) {
				this.carRequest.keyContacts[3].type = apsAttribute.userRole;
				if (apsAttribute.userRole.toLowerCase().includes('site')) {
					this.onSubmit(status, currentPage);
				} else {
					this.utilService.pushMsg('error', 'Error', 'The selected user doesn\'t have Site Supervisor role, Please change.');
				}
			} else {
				this.carRequest.keyContacts[3].type = '';
				this.utilService.pushMsg('error', 'Error', 'The selected user doesn\'t have Site Supervisor role, Please change.');
			}
		});
	}

	onProcessStreamSelection(keepHistoricalPS: boolean) {
		this.onNextPage();
		this.hideProcessStreamInfo = true;
		this.carRequest.isPSUpdated = false;
		this.carRequest.keepHistoricalPSInRejRecall = keepHistoricalPS;
		this.carRequest.userAction = 'DRAFT';
		this.carRequest.loggedInUserId = this.loggedInUserId;
		this.carRequestService.update(this.carRequest.reqId, this.carRequest).subscribe(value => {
			if (value) {
				this.selectedPrimaryStream = this.availablePrimaryStream.find(value2 => value2.id === this.carRequest.primaryStreamId);
				this.loadProcessNamesByPrimaryStreamId(this.selectedPrimaryStream);
				this.loadCarDataForEditAndView();
			}
		});
	}

	private loadCarDataForEditAndView() {
		this.carRequestService.findById(this.carId).subscribe(value => {
			this.processStreamService.findProcessStreamById(value.primaryStreamId).subscribe(value1 => {
				if (value1) {
					this.isProcessStreamAvailable = true;
					this.processStreamRemovedAlert = true;
				} else {
					this.isProcessStreamAvailable = false;
					this.processStreamRemovedAlert = false;
				}
				this.carRequest.isProcessStreamAvailable = this.isProcessStreamAvailable;
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
			if (value.isAlertRequired && (value.requestStatus === 'DRAFT' || value.requestStatus === 'REJECTED' || value.requestStatus === 'STADRAFT')) {
				this.showAlertPopupMessage();
			}
			if (value.requestStatus === 'DRAFT' && value.recallReason === null && value.isPSUpdated && this.mode === 'edit') {
				this.showAlertPopupMessageForProcessStreamChanges();
			}
			this.isSiteCodeAvailableInSIM(value.siteCode);
			this.capacityReqSrcCheck(value);
			if (value.isLinkCar) {
				this.carRequest = value;
				if (this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'STADRAFT' || this.carRequest.requestStatus === 'REJECTED') {
					this.isSubmitted = false;
				} else {
					this.isSubmitted = true;
				}
				if (this.carRequest.keyContacts.length > 3) {
					this.siteSupCdsId = this.carRequest.keyContacts[3].cdsid;
				}
				if (value.workflowId === 1 || value.workflowId === 2 || value.workflowId === 3 || value.workflowId === 4 || value.workflowId === 9) {
					this.workflowCheck = true;
				}
				this.requirementsAPWFromDraft = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsApw : (this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsApw : this.carRequest.gcpMcpvApw);
				this.requirementsMPWFromDraft = this.carRequest.requirementType === 1 ? this.carRequest.paRequirementsMpw : (this.carRequest.requirementType === 2 ? this.carRequest.revisedRequirementsMpw : this.carRequest.gcpMcpvMpw);
				this.loadCarDropdownLinkCAR();
				this.loadStreamDropdownLinkCAR();
				this.loadProcessNameDropdownLinkCar();
				this.prePopulateHeaderDropdown();
				this.populateParenteEcarInfo(value.selectedCarIdForLinkage, value.selectedStreamIdForLinkage, value.selectedProcessNameIdForLinkage);
				this.doCapacityStudyElementsPresentInClosedCars();
				this.initializeDeclaration();
				this.isOldECARWithinTheDateLimit();
			} else {
				if (value.keyContacts.length === 3) {
					value.keyContacts[3] = Object.assign({}, emptyKeyContacts);
					value.keyContacts[4] = Object.assign({}, emptyKeyContacts);
				}
				if (value.keyContacts.length === 4) {
					value.keyContacts[4] = Object.assign({}, emptyKeyContacts);
				}
				if (value.keyContacts.length > 3) {
					this.siteSupCdsId = value.keyContacts[3].cdsid;
				}
				this.userRoleCheckForRejection(this.siteSupCdsId, value.keyContacts[0].cdsid);
				if (value.workflowId === 1 || value.workflowId === 2 || value.workflowId === 3 || value.workflowId === 4 || value.workflowId === 9) {
					this.workflowCheck = true;
				}
				this.loadECar(value);
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private isProcessStreamConfirmed(carRequest: CarRequest, currentPage: string) {
		if (!this.isProcessStreamAvailable) {
			if (currentPage && currentPage === 'SUMMARY' && (carRequest.requestStatus === 'REJECTED' || (carRequest.requestStatus === 'DRAFT' && carRequest.recallReason !== null)) && carRequest.isPSUpdated && !this.hideProcessStreamInfo) {
				this.utilService.pushMsg('error', 'Error', 'Please confirm the process stream in Primary process stream Tab');
				return false;
			} else {
				return true;
			}
		} else if (currentPage && currentPage === 'SUMMARY' && (carRequest.requestStatus === 'REJECTED' || (carRequest.requestStatus === 'DRAFT' && carRequest.recallReason !== null)) && carRequest.isPSUpdated) {
			this.utilService.pushMsg('error', 'Error', 'Please confirm the process stream in Primary process stream Tab');
			return false;
		} else {
			return true;
		}

	}

	private sortPsPnMapTranList(psPnMapTranList: PsPnMapTran[]): PsPnMapTran[] {
		return psPnMapTranList.sort((psPn1, psPn2) => psPn2.processOrderId - psPn1.processOrderId);
	}

	onHistoricalProcessStreamSelecion(keepHistoricalPS: boolean) {
		this.hideProcessStreamInfo = true;
		this.processStreamRemovedAlert = true;
		if (!keepHistoricalPS) {
			this.onNextPage();
			this.carRequest.removeProcessStreamData = true;
			this.carRequest.isPSUpdated = false;
			this.carRequest.userAction = 'DRAFT';
			this.carRequest.loggedInUserId = this.loggedInUserId;
			this.carRequest.keepHistoricalPSInRejRecall = false;
			this.isProcessStreamAvailable = true;
			this.carRequestService.update(this.carRequest.reqId, this.carRequest).subscribe(value => {
				if (value) {
					this.isPrimaryStreamReset = true;
					this.carRequest.psPnMapTransList = [];
					this.carRequest.primarySupportingMapTranList = [];
					this.processStreamService.findAllPrimaryProcessStreamEdit(this.carRequest.siteCodeObject).subscribe(availablePs => {
						if (availablePs && availablePs.length > 0) {
							this.availablePrimaryStream = availablePs;
							this.availablePrimaryStream.unshift(emptyProcessStreamDroprdown);
							this.selectedPrimaryStream = emptyProcessStreamDroprdown;
						} else {
							this.availablePrimaryStream = [];
							this.availablePrimaryStream.push(emptyProcessStreamDroprdown);
						}
					});
				}
			});
		}
	}

	fetchSiteCodes(event: any) {
		const searchString = event.query;
		if (searchString && searchString.length > 2) {
			this.apsService.retrieveAttributesBySearchString(searchString).subscribe(apsAttribute => {
				if (apsAttribute && apsAttribute.siteCodes) {
					this.siteCodeSuggestions = this.buildDisplaySiteCodes(apsAttribute);
				}
			});
		}
	}

	private buildDisplaySiteCodes(apsAttribute: ApsAttributes) {
		return apsAttribute.siteCodes.map(value => value.length > 4 ? value : '');
	}
}
