import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeclarationQuestion, emptyDCLRTNQuestion} from '../../../models/declaration-question';
import {MessageService} from 'primeng/api';
import {ManageDeclarationQuestionsService} from '../../../services/master/manage-declaration-questions/manage-declaration-questions.service';
import {answerOptions, DropdownOption, optionGroup, template} from '../../../models/dropdown-option';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UtilService} from '../../../services/util.service';


@Component({
	selector: 'create-declaration-questions',
	templateUrl: './create-declaration-questions.component.html',
	styleUrls: ['./create-declaration-questions.component.css']
})
export class CreateDeclarationQuestionsComponent implements OnInit {
	private mode: string;
	private displayName: string;
	private declarationQuestionObj: DeclarationQuestion = new DeclarationQuestion(emptyDCLRTNQuestion);

	private optionType: DropdownOption[];
	private ansType: DropdownOption[];
	private template: DropdownOption[];

	private buttonName: string;
	private isFormEditable = true;

	private createForm: FormGroup;
	private createdBy: string;
	private createdDate: number;

	constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private declarationQuestionsService: ManageDeclarationQuestionsService, private messageService: MessageService, private router: Router, private utilService: UtilService) {
		this.template = template;
		this.ansType = answerOptions;
		this.optionType = optionGroup;
	}

	ngOnInit() {
		this.retrieveRouteParameter();
		this.findQuestion();

		this.createForm = this.formBuilder.group({
			question: ['', Validators.required],
			selectedTemplate: ['', Validators.required],
			selectedAnsType: ['', Validators.required],
			selectedOptionType: new FormControl(),
			activeStatus: [true],
			mandatoryStatus: [true]
		});
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.mode = params.get('mode');
			this.declarationQuestionObj.id = Number(params.get('id'));
			if (this.mode === 'create') {
				this.displayName = 'Create New Question';
				this.buttonName = 'Create New';
			} else if (this.mode === 'edit') {
				this.displayName = 'Edit Question';
				this.buttonName = 'Update';
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private findQuestion() {
		if (this.mode === 'edit') {
			this.findById(this.declarationQuestionObj.id);
		}
	}

	private onCreate(newQuestion: DeclarationQuestion) {
		this.declarationQuestionsService.create(newQuestion).subscribe(success => {
			this.utilService.pushMsg('success', 'Success', 'New Question created successfully');
			this.resetFields();
			this.moveToManage();
		}, createError => {
			this.utilService.pushMsg('error', 'Error', 'Error while creating new Question');
		});
	}

	private update(updateQuestion: DeclarationQuestion) {
		this.declarationQuestionsService.update(updateQuestion.id, updateQuestion).subscribe(success => {
			this.utilService.pushMsg('success', 'Success', 'Question has been successfully modified');
			this.resetFields();
			this.moveToManage();
		}, createError => {
			this.utilService.pushMsg('error', 'Error', 'Error while Updating Question');
		});
	}

	private findById(id: number) {
		this.declarationQuestionsService.findById(id).subscribe(value => {
			this.createForm.patchValue({
				question: value.question,
				selectedTemplate: this.template.find(temp => temp.code === value.template),
				selectedAnsType: this.ansType.find(temp => temp.code === value.ansType),
				activeStatus: value.active,
				mandatoryStatus: value.mandatory
			});
			this.isFormEditable = value.editable;
			this.createdBy = value.createdBy;
			this.createdDate = value.createdDate;
			if (!value.editable) {
				this.createForm.get('question').disable();
				this.createForm.get('selectedTemplate').disable();
				this.createForm.get('selectedAnsType').disable();
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	submit() {
		if (this.createForm.invalid) {
			this.utilService.pushMsg('error', 'Error', 'Form is not Valid');
			return;
		} else if (this.createForm.valid) {
			this.declarationQuestionObj.question = this.createForm.get('question').value;
			this.declarationQuestionObj.template = this.createForm.get('selectedTemplate').value.code;
			this.declarationQuestionObj.ansType = this.createForm.get('selectedAnsType').value.code;
			this.declarationQuestionObj.active = this.createForm.get('activeStatus').value;
			this.declarationQuestionObj.mandatory = this.createForm.get('mandatoryStatus').value;
			this.declarationQuestionObj.editable = this.isFormEditable;
			if (this.mode === 'create') {
				this.onCreate(this.declarationQuestionObj);
			} else if (this.mode === 'edit') {
				this.declarationQuestionObj.createdBy = this.createdBy;
				this.declarationQuestionObj.createdDate = this.createdDate;
				this.update(this.declarationQuestionObj);
			}
			return;
		}
	}

	private moveToManage() {
		this.router.navigate(['manage-declaration-questions']);
	}

	private resetFields() {
		this.createForm.reset();
	}

	cancel() {
		this.utilService.pushMsg('info', 'Information', 'Operation Cancelled');
		this.moveToManage();
	}
}
