import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Config} from '../../../config';
import {Observable} from 'rxjs';
import {ProcessName} from '../../../models/process-name';
import {map} from 'rxjs/operators';
import {SiteCode} from '../../../models/site-code';

@Injectable({
	providedIn: 'root'
})
export class ProcessNameService {
	private config = new Config();
	private httpUrl = `${this.config.url}/processName`;
	private httpUrlFindAllProcessName = `${this.config.url}/processName/findAll`;

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<ProcessName[]> {
		return this.http.get(this.httpUrl).pipe(map((processNames: ProcessName[]) => {
			return processNames.map(processName => {
				return new ProcessName(processName);
			});
		}));
	}

	findAllProcessName(pageSizeRequest) {
		let params = new HttpParams().set('page', pageSizeRequest.page).set('size', pageSizeRequest.size);
		if (pageSizeRequest.sort && pageSizeRequest.sort.length > 0) {
			params = params.set('sort', pageSizeRequest.sort);
		}
		if (pageSizeRequest.filters && pageSizeRequest.filters.length > 0) {
			params = params.set('filters', pageSizeRequest.filters);
		}
		if (pageSizeRequest.userRole && pageSizeRequest.userRole.length > 0) {
			params = params.set('userRole', pageSizeRequest.userRole);
		}
		if (pageSizeRequest.siteCodes && pageSizeRequest.siteCodes.length > 0) {
			params = params.set('siteCodes', pageSizeRequest.siteCodes);
		}
		return this.http.get(this.httpUrlFindAllProcessName, {params});
	}

	create(processName: ProcessName): Observable<ProcessName> {
		return this.http.post<ProcessName>(this.httpUrl, processName);
	}

	update(id: number, processName: ProcessName): Observable<ProcessName> {
		return this.http.put<ProcessName>(this.httpUrl + '/' + id, processName);
	}

	delete(id: number): Observable<ProcessName> {
		return this.http.delete<ProcessName>(this.httpUrl + '/' + id);
	}

	findAllProcessBySiteCode(siteCode: string): Observable<ProcessName[]> {
		return this.http.get(this.httpUrl + '/' + siteCode).pipe(map((processNames: ProcessName[]) => {
			return processNames.map(processName => {
				return new ProcessName(processName);
			});
		}));
	}

	findAllActiveProcessBySiteCode(siteCode: string): Observable<ProcessName[]> {
		return this.http.get(this.httpUrl + '/activeProcessNames/' + siteCode).pipe(map((processNames: ProcessName[]) => {
			return processNames.map(processName => {
				return new ProcessName(processName);
			});
		}));
	}

	findAllProcessNamesBySiteCodes(): Observable<ProcessName[]> {
		return this.http.get(this.httpUrl + '/findProcessNameBySiteCodes').pipe(map((processNames: ProcessName[]) => {
			return processNames.map(processName => {
				return new ProcessName(processName);
			});
		}));
	}

	checkeCARAssociatedWithProcessNameId(processNameId: number) {
		return this.http.get(this.httpUrl + '/findeCARExists/' + processNameId).pipe(value => {
			return value;
		});
	}

	checkProcessNameActive(processNameIds) {
		const pNameIds = processNameIds.map(id => id).join('-');
		return this.http.get(this.httpUrl + '/findProcessActiveStatus/' + pNameIds);
	}

	findProcessNameById(id: number): Observable<ProcessName> {
		console.log('id is ' + id);
		return this.http.get<ProcessName>(this.httpUrl + '/findProcessNameById/' + id);
	}

	isSiteCodePresentInSIM(siteCode: string): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrl + '/isSiteCodePresentInSIM/' + siteCode);
	}

	getAllSiteCodesFromProcess(): Observable<SiteCode[]> {
		return this.http.get<SiteCode[]>(this.httpUrl + '/getAllSiteCodesInSystem');
	}

	findProcessNameByIdList(ids): Observable<ProcessName[]> {
		return this.http.get<ProcessName[]>(this.httpUrl + '/findProcessNameByIdList/' + ids);
	}
}
