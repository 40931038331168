import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {
	chartColors,
	ChartData,
	dataSetTemplate, linkColors,
	OEEChart,
	oeeChartTemplate,
	SharedLoadingChart,
	sharedLoadingChartTemplate
} from '../models/chart-data';
import {ActivatedRoute} from '@angular/router';
import {CarRequestService} from '../services/request/car-request.service';
import {CarRequest, deviationReasonDropdown} from '../models/car-request';
import {ManageSubmissionScenario} from '../models/manage-submission-scenario';
import {emptyProcessStreamModel, ProcessStream} from '../models/process-stream';
import {ManageSubmissionScenarioService} from '../services/master/manage-submission-scenario/manage-submission-scenario.service';
import {RequestStateHistory} from '../models/request-state-history';
import {HistoricalMfgPerf} from '../models/historical-mfgperf';
import {DemonOEEMap} from '../models/processid-demon-oee';
import {HistoricalMfgPerfService} from '../services/master/historical-mfgperf/historical-mfgperf.service';
import {UtilService} from '../services/util.service';
import {PsPnMapTran} from '../models/pspnmaptran';
import * as moment from 'moment';
import {DatePipe} from '@angular/common';
import * as _ from 'lodash';
import {ChartService} from '../services/chart/chart.service';
import {ProcessStreamService} from '../services/master/process-stream/process-stream.service';
import {SharedLoadingRoot} from '../models/shared-loading-root';
import {SharedLoading} from '../models/shared-loading';
import {ManageSharedLoadingService} from '../services/master/manage-shared-loading/manage-shared-loading.service';
import {jsPlumb} from 'jsplumb';
import {ProcessData} from '../models/process-data';
import {DeclarationQuestionsTran} from '../models/declaration-question-trans';
import {SharedLoadingPlanHolder} from '../models/shared-loading-plan';
import {ProcessNameService} from '../services/master/process-name/process-name.service';
import {PrimarySupportingMapTran} from '../models/primary-supporting-map-tran';


@Component({
	selector: 'ecar-print',
	templateUrl: './ecar-print.component.html',
	styleUrls: ['./ecar-print.component.css']
})
export class EcarPrintComponent implements OnInit, AfterViewInit, OnDestroy {
	carRequest: CarRequest;
	carId: number;
	selectedPrimaryStream: ProcessStream = emptyProcessStreamModel;

	demonOEEList: DemonOEEMap[] = [];

	allProcessContainsAnyErrors = 'Valid';
	declarationStatus = 'OK';
	public HstMfgPrimaryStreamChart: OEEChart[] = [];
	public HstMfgSupportingStreamCharts: OEEChart[][] = [];

	primaryProcessStreamChart: OEEChart;
	supportingProcessStreamChart: OEEChart[] = [];

	cols3: any[];
	pspnMap: any[];

	options: any;
	reqStateHistories: RequestStateHistory[];

	//viewProces Stream
	processStreamTree: ProcessStream[] = [];
	isConnectionMade = false;
	jsPlumbInstance;
	//viewProces Stream ends

	//SLP Chart
	isThereAnySHChartAvailable = false;
	isSharedLoadingExistInPS = false;
	isSharedLoadingExistInSS = false;
	sharedLoadingChartForPrimaryStream: SharedLoadingChart[];
	sharedLoadingChartForSupportingStream: SharedLoadingChart[][];
	sharedLoadingRoots: SharedLoadingRoot[];

	//SLP Chart ends

	processDataList: ProcessData[];
	supportingProcessDataList: any[];
	apsAttributes: any;
	hideCapacityStudyElements: boolean;
	bottleNeckProcessName: string;
	hideProcessDetailsValidation: boolean;

	totalAllocationPercentageGreaterThan100 = false;
	containAssumptionsHasFalse = false;

	pspnMapTabSplit: any[];
	processDataListArray: any[];
	processDataListObj: any[];

	constructor(private activatedRoute: ActivatedRoute, private carRequestService: CarRequestService, private manageSubmissionScenarioService: ManageSubmissionScenarioService, private hmpService: HistoricalMfgPerfService, private utilService: UtilService, private datePipe: DatePipe, private chartService: ChartService, private processStreamService: ProcessStreamService, private sharedLoadingService: ManageSharedLoadingService, private processNameService: ProcessNameService) {
	}

	ngOnInit() {
		this.carRequestService.hideBulletinsOnPrintPreview(true);
		this.processDataList = [];
		this.retrieveRouteParameter();
		this.jsPlumbInstance = jsPlumb.getInstance();
		this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
		this.carRequestService.findById(this.carId).subscribe(value => {
			this.carRequest = value;
			this.manageSubmissionScenarioService.findById(this.carRequest.submissionScenario).subscribe((scenario: ManageSubmissionScenario) => {
				this.carRequest.submissionScenarioObject = scenario;
				this.carRequest.scenarioName = this.carRequest.submissionScenarioObject.templateName;
				const reversedPsPnMapTransList = _.reverse(this.carRequest.psPnMapTransList);
				if (reversedPsPnMapTransList) {
					reversedPsPnMapTransList.forEach(psPnMapTran => {
						psPnMapTran = this.validateProcessDataList(psPnMapTran);
						this.processDataList.push(psPnMapTran.processDataList[0]);
						this.processDataList.push(psPnMapTran.processDataList[1]);
					});
					this.processDataListObj = [];
					this.processDataListArray = [];
					this.processDataList.forEach(pData => {
						if (this.processDataListObj.length < 29) {
							this.processDataListObj.push(pData);
						} else {
							this.processDataListObj.push(pData);
							this.processDataListArray.push(this.processDataListObj);
							this.processDataListObj = [];
						}
					});
					this.processDataListArray.push(this.processDataListObj);
					console.log(this.processDataList);
					this.supportingProcessDataList = [];
					if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
						this.carRequest.primarySupportingMapTranList.forEach(supportingMap => {
							let supportingProcess = {};
							let processNameList = [];
							let processDataList = [];
							let matchFlag = false;
							_.reverse(supportingMap.psPnMapTranList);
							supportingMap.psPnMapTranList.forEach(supportingPspnMap => {
								if (processNameList.length < 14) {
									processNameList.push(supportingPspnMap.processName);
									supportingPspnMap = this.validateProcessDataList(supportingPspnMap);
									processDataList.push(supportingPspnMap.processDataList[0]);
									processDataList.push(supportingPspnMap.processDataList[1]);
								} else {
									processNameList.push(supportingPspnMap.processName);
									supportingPspnMap = this.validateProcessDataList(supportingPspnMap);
									processDataList.push(supportingPspnMap.processDataList[0]);
									processDataList.push(supportingPspnMap.processDataList[1]);
									this.supportProcessDataConstruction(processNameList, processDataList, supportingMap, matchFlag, supportingProcess);
									supportingProcess = {};
									processNameList = [];
									processDataList = [];
									matchFlag = false;
								}
							});
							this.supportProcessDataConstruction(processNameList, processDataList, supportingMap, matchFlag, supportingProcess);
						});
					}
					console.log('supporting stream');
					console.log(this.supportingProcessDataList);
					this.preparePrimaryLabel();
					this.findPrimaryStreamById(this.carRequest.primaryStreamId);
					console.log(this.carRequest);
					/*Optimization
					this.carRequest.psPnMapTransList.forEach(processStreamProcessNameObject => {
						this.loadDemonOeeMap(processStreamProcessNameObject.processNameId);
					});*/
					// tslint:disable-next-line:no-shadowed-variable
					if ((this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason === null) || ((this.carRequest.requestStatus === 'REJECTED' || (this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason !== null)) && this.carRequest.keepHistoricalPSInRejRecall === false && this.carRequest.isProcessStreamAvailable)) {
						this.processStreamService.findProcessStreamTreeById(this.carRequest.primaryStreamId).subscribe(procStream => {
							this.processStreamTree = procStream;
						});
					} else {
						this.processStreamService.findProcessStreamTranTreeById(this.carRequest.primaryStreamId, this.carRequest.reqId).subscribe(procStream1 => {
							this.processStreamTree = procStream1;
						});
					}
					//hmpchart
					this.loadChartData();
					this.retrieveSharedLoadingIdBySiteCodeAndBuildChart();
					this.carRequestService.findReqHistoriesById(this.carId).subscribe(response => {
						this.reqStateHistories = response;
					});
					this.allProcessContainsAnyErrors = this.utilService.isPrimaryAndSecondaryStreamProcessContainsErrors(this.carRequest);
					this.carRequest.isAnyProcessContainsAPWGreaterThan5 = this.utilService.isPrimaryAndSecondaryStreamProcessContainsAPWGreaterThan5(this.carRequest);
					if (this.carRequest.isAnyProcessContainsAPWGreaterThan5) {
						this.carRequest.deviationReasonCode = this.carRequest.deviationReasonCode ? this.carRequest.deviationReasonCode : -1;
						this.carRequest.deviationReasonObject = deviationReasonDropdown.find(value1 => value1.code === this.carRequest.deviationReasonCode);
					}
				}
				this.updateDeclarationQuestionAnswers();
				this.declarationStatus = this.declarationQuestionStatus();
				this.isSupplierSubmittedWithinDateLimitForClosedCARs();
				this.totalAllocationAndcontainAssumptionsCheck();
				this.isOldECARWithinTheDateLimit();
				this.processNameService.findProcessNameById(this.carRequest.bottleNeckProcessNameId).subscribe(processNameObj => {
					this.bottleNeckProcessName = processNameObj.processName;
				});
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private isSupplierSubmittedWithinDateLimitForClosedCARs() {
		if (this.carRequest.requestStatus === 'SUBMITTED' || this.carRequest.requestStatus === 'COMPLETED' || this.carRequest.requestStatus === 'APPROVED' || this.carRequest.requestStatus === 'ACKNOWLEDGED') {
			this.carRequestService.isSupplierSubmittedWithinDateLimit(this.carRequest.reqId).subscribe(isSubmitted => {
				if (isSubmitted) {
					this.hideCapacityStudyElements = true;
				} else {
					this.hideCapacityStudyElements = false;
				}
			});
		} else {
			this.hideCapacityStudyElements = false;
		}
	}

	private isOldECARWithinTheDateLimit() {
		if (this.carRequest.requestStatus === 'SUBMITTED' || this.carRequest.requestStatus === 'COMPLETED' || this.carRequest.requestStatus === 'APPROVED' || this.carRequest.requestStatus === 'ACKNOWLEDGED') {
			this.carRequestService.isOldECARWithinTheDateLimit(this.carRequest.reqId).subscribe(isSubmitted => {
				if (isSubmitted) {
					this.hideProcessDetailsValidation = true;
				} else {
					this.hideProcessDetailsValidation = false;
				}
			});
		} else {
			this.hideProcessDetailsValidation = false;
		}
	}

	private validateProcessDataList(psPnMapTran: PsPnMapTran): PsPnMapTran {
		if (!psPnMapTran.processDataList[0].isApw) {
			psPnMapTran.processDataList[0].isApw = true;
		}
		psPnMapTran.processDataList[0].theoreticalParts = _.round(psPnMapTran.processDataList[0].theoreticalParts);
		psPnMapTran.processDataList[1].theoreticalParts = _.round(psPnMapTran.processDataList[1].theoreticalParts);
		psPnMapTran.processDataList[0].changeOverTimeWeekly = _.round(psPnMapTran.processDataList[0].changeOverTimeWeekly);
		psPnMapTran.processDataList[1].changeOverTimeWeekly = _.round(psPnMapTran.processDataList[1].changeOverTimeWeekly);
		psPnMapTran.processDataList[0].allocPercent = _.round(psPnMapTran.processDataList[0].allocPercent, 1);
		psPnMapTran.processDataList[1].allocPercent = _.round(psPnMapTran.processDataList[1].allocPercent, 1);
		psPnMapTran.processDataList[0].equipmentAvailability = _.round(psPnMapTran.processDataList[0].equipmentAvailability, 1);
		psPnMapTran.processDataList[0].weekPartEstimate = Math.floor(psPnMapTran.processDataList[0].weekPartEstimate);
		psPnMapTran.processDataList[1].weekPartEstimate = Math.floor(psPnMapTran.processDataList[1].weekPartEstimate);
		psPnMapTran.processDataList[0].netIdleCycleTime = _.round(psPnMapTran.processDataList[0].netIdleCycleTime, 2);
		psPnMapTran.processDataList[0].netAvailableTime = _.round(psPnMapTran.processDataList[0].netAvailableTime, 2);
		psPnMapTran.processDataList[1].netAvailableTime = _.round(psPnMapTran.processDataList[1].netAvailableTime, 2);
		psPnMapTran.processDataList[0].reqJph = _.round(((3600 / psPnMapTran.processDataList[0].netIdleCycleTime) * psPnMapTran.processDataList[0].reqOee) / 100, 1);
		psPnMapTran.processDataList[1].reqJph = _.round(((3600 / psPnMapTran.processDataList[0].netIdleCycleTime) * psPnMapTran.processDataList[1].reqOee) / 100, 1);
		psPnMapTran.processDataList[0].reqJph = psPnMapTran.processDataList[0].reqJph === 1 / 0 ? 0 : psPnMapTran.processDataList[0].reqJph;
		psPnMapTran.processDataList[1].reqJph = psPnMapTran.processDataList[1].reqJph === 1 / 0 ? 0 : psPnMapTran.processDataList[1].reqJph;
		psPnMapTran.processDataList[0].avaiRemainPercent = _.round(psPnMapTran.processDataList[0].avaiRemainPercent, 1);
		psPnMapTran.processDataList[1].avaiRemainPercent = _.round(psPnMapTran.processDataList[1].avaiRemainPercent, 1);
		const partSumAPW = psPnMapTran.processDataList[0].theoreticalParts - ((psPnMapTran.processDataList[0].avaiRemainPercent * psPnMapTran.processDataList[0].theoreticalParts) / 100);
		psPnMapTran.processDataList[0].isContainAssumptions = partSumAPW <= psPnMapTran.processDataList[0].theoreticalParts;
		const partSumMPW = psPnMapTran.processDataList[1].theoreticalParts - ((psPnMapTran.processDataList[1].avaiRemainPercent * psPnMapTran.processDataList[1].theoreticalParts) / 100);
		psPnMapTran.processDataList[1].isContainAssumptions = partSumMPW <= psPnMapTran.processDataList[1].theoreticalParts;
		psPnMapTran.processDataList[0].availablePerformanceEfficiency = _.round(psPnMapTran.processDataList[0].availablePerformanceEfficiency);
		psPnMapTran.processDataList[0].demonJph = _.round(((3600 / psPnMapTran.processDataList[0].netIdleCycleTime) * psPnMapTran.processDataList[0].averageHisOee) / 100, 1);
		psPnMapTran.processDataList[0].demonJph = psPnMapTran.processDataList[0].demonJph === 1 / 0 ? 0 : psPnMapTran.processDataList[0].demonJph;
		if (!this.carRequest.submissionScenarioObject.templateName.toLowerCase().startsWith('capacity')) {
			psPnMapTran.processDataList[0].plannedNetIdealCycleTime = _.round(psPnMapTran.processDataList[0].plannedNetIdealCycleTime, 2);
			psPnMapTran.processDataList[0].phaseJPH = _.round(((3600 / psPnMapTran.processDataList[0].plannedNetIdealCycleTime) * psPnMapTran.processDataList[0].phaseOEE) / 100, 1);
			psPnMapTran.processDataList[0].phaseJPH = !psPnMapTran.processDataList[0].phaseJPH ? 0 : psPnMapTran.processDataList[0].phaseJPH;
			psPnMapTran.processDataList[0].performanceEfficiency = _.round(psPnMapTran.processDataList[0].performanceEfficiency, 1);
			psPnMapTran.processDataList[0].noOfPartsScrappedPercent = _.round(psPnMapTran.processDataList[0].noOfPartsScrappedPercent, 1);
			psPnMapTran.processDataList[0].noOfPartsReworkedPercent = _.round(psPnMapTran.processDataList[0].noOfPartsReworkedPercent, 1);
			psPnMapTran.processDataList[0].qualityRate = _.round(psPnMapTran.processDataList[0].qualityRate, 1);
		}
		if (psPnMapTran.processDataList[0].sharedLoadingTotalAllocPercent) {
			psPnMapTran.processDataList[0].sharedLoadingTotalAllocPercent = _.round(psPnMapTran.processDataList[0].sharedLoadingTotalAllocPercent, 1);
			psPnMapTran.processDataList[1].sharedLoadingTotalAllocPercent = _.round(psPnMapTran.processDataList[1].sharedLoadingTotalAllocPercent, 1);
		}
		if (psPnMapTran.processDataList[0].totalAllocationPercentageAPW) {
			psPnMapTran.processDataList[0].totalAllocationPercentageAPW = _.round(psPnMapTran.processDataList[0].totalAllocationPercentageAPW, 1);
			psPnMapTran.processDataList[1].totalAllocationPercentageAPW = _.round(psPnMapTran.processDataList[1].totalAllocationPercentageMPW, 1);
		}
		if (psPnMapTran.processDataList[1].totalAllocationPercentageCapacityMPW && psPnMapTran.processDataList[0].totalAllocationPercentageAPW < psPnMapTran.processDataList[0].totalAllocationPercentageCapacityAPW) {
			psPnMapTran.processDataList[0].totalAllocationPercentageCapacityMPW = _.round(psPnMapTran.processDataList[0].totalAllocationPercentageCapacityAPW, 1);
			psPnMapTran.processDataList[1].totalAllocationPercentageCapacityMPW = _.round(psPnMapTran.processDataList[1].totalAllocationPercentageCapacityMPW, 1);
		} else {
			psPnMapTran.processDataList[0].totalAllocationPercentageCapacityMPW = null;
			psPnMapTran.processDataList[1].totalAllocationPercentageCapacityMPW = null;
		}
		return psPnMapTran;
	}

	findPrimaryStreamById(id) {
		this.processStreamService.findProcessStreamById(id).subscribe(value => {
			this.selectedPrimaryStream = value;
			this.buildOEEChart();
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.carId = Number(params.get('id'));
		});
	}

	loadDemonOeeMap(processNameId: number) {
		let demonOee = 0;
		const historicalMfgPerfObj = {
			processId: processNameId,
			siteCode: this.carRequest.siteCode
		};
		if (!this.carRequest.requestStatus || this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED') {
			this.hmpService.findHistMfgPerfRecords(historicalMfgPerfObj).subscribe((response: HistoricalMfgPerf[]) => {
				demonOee = this.utilService.calculateAverageOEE(response);
				this.demonOEEList.push(new DemonOEEMap({'id': processNameId, 'demonOee': demonOee}));
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.hmpService.findHistMfgPerfRecordsByCarId(historicalMfgPerfObj, this.carRequest.reqId).subscribe((response: HistoricalMfgPerf[]) => {
				demonOee = this.utilService.calculateAverageOEE(response);
				this.demonOEEList.push(new DemonOEEMap({'id': processNameId, 'demonOee': demonOee}));
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	//HMP Chart starts
	loadChartData() {
		if (this.carRequest.requestStatus !== null && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'STADRAFT' && this.carRequest.requestStatus !== 'REJECTED') {
			this.hmpService.findAllHMPByCarId(this.carRequest.reqId).subscribe((resp: HistoricalMfgPerf[]) => {
				this.buildPrimaryStreamHistoricalMfgChartData(resp);
				this.buildSupportingStreamHistoricalMfgChartData(resp);
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			const processNameIds = [];
			this.carRequest.psPnMapTransList.forEach(value => {
				processNameIds.push(value.processNameId);
			});
			if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
				this.carRequest.primarySupportingMapTranList.forEach(supportingProcessList => {
					supportingProcessList.psPnMapTranList.forEach(supportingProcess => {
						processNameIds.push(supportingProcess.processNameId);
					});
				});
			}
			this.hmpService.findAllHMPBySiteCodeProcessIds(this.carRequest.siteCode, processNameIds).subscribe((resp: HistoricalMfgPerf[]) => {
				this.buildPrimaryStreamHistoricalMfgChartData(resp);
				this.buildSupportingStreamHistoricalMfgChartData(resp);
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	private buildPrimaryStreamHistoricalMfgChartData(hmpData: HistoricalMfgPerf[]) {
		this.HstMfgPrimaryStreamChart = [];
		this.HstMfgPrimaryStreamChart = this.buildProcessDataChartData(hmpData, this.carRequest.psPnMapTransList);
	}

	private buildSupportingStreamHistoricalMfgChartData(hmpData: HistoricalMfgPerf[]) {
		this.HstMfgSupportingStreamCharts = [];
		let HstMfgSupportingStreamChart: OEEChart[];
		if (this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			this.carRequest.primarySupportingMapTranList.forEach(supportingMap => {
				const supportingStreamName = supportingMap.processStreamName;
				HstMfgSupportingStreamChart = this.buildProcessDataChartData(hmpData, supportingMap.psPnMapTranList);
				HstMfgSupportingStreamChart.map(value => value.processStreamName = supportingStreamName + ': ' + value.processStreamName);
				this.HstMfgSupportingStreamCharts.push(HstMfgSupportingStreamChart);
			});
		}
	}

	buildProcessDataChartData(hmpData: HistoricalMfgPerf[], psPnMapTransList: PsPnMapTran[]) {
		const chartData = [];
		psPnMapTransList.forEach(psPnTran => {
			const oee = [];
			const rOEE = [];
			const labels = [];
			let requiredOEE = 0;
			let demonOEEMax;
			let yMax;
			const filteredHmpData = hmpData.filter(value => value.processId === psPnTran.processNameId);

			const rAPW = psPnTran.processDataList[0].reqOee;
			const rMPW = psPnTran.processDataList[1].reqOee;
			requiredOEE = rAPW > rMPW ? rAPW : rMPW;

			//Sort the response by date
			filteredHmpData.sort(function (left, right) {
				return moment.utc(left.hmfDate).diff(moment.utc(right.hmfDate));
			});
			filteredHmpData.forEach((historicalObj) => {
				oee.push(historicalObj.oee);
				rOEE.push(requiredOEE);
				if (historicalObj.hmfDate) {
					labels.push(this.datePipe.transform(historicalObj.hmfDate, 'dd-MMM-yyyy'));
				}
			});
			demonOEEMax = Math.max(...oee);
			const tempChart = _.cloneDeep(oeeChartTemplate);
			if (demonOEEMax < 100 && requiredOEE < 100) {
				yMax = 100;
			} else {
				if (demonOEEMax > 100) {
					yMax = Math.ceil(demonOEEMax / 20) * 20;
					tempChart.isDemonOEEExceeds100Percent = true;
				}
				if (requiredOEE > 100) {
					yMax = yMax > Math.ceil(requiredOEE / 20) * 20 ? yMax : Math.ceil(requiredOEE / 20) * 20;
					tempChart.isReqOEEExceeds100Percent = true;
				}
			}

			tempChart.processStreamName = psPnTran.processName;
			tempChart.oeeChart = {
				labels: labels,
				datasets: [
					{
						type: 'line',
						label: 'Demonstrated OEE',
						borderColor: '#5DADE2',
						borderWidth: 2,
						fill: false,
						data: oee,
						pointRadius: 5
					},
					{
						type: 'line',
						label: 'Required OEE',
						borderColor: '#EC7063',
						borderWidth: 1,
						fill: false,
						data: rOEE,
						pointRadius: 0
					}
				]
			};
			tempChart.oeeChartOptions = {
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true,
							max: yMax,
							callback: function (value, index, values) {
								return value + ' %';
							}
						}
					}],
					xAxes: [{
						ticks: {
							suggestedMax: 25,
							beginAtZero: true
						}
					}]
				}
			};
			chartData.push(tempChart);
		});
		return chartData;
	}

	//HMP Chart ends

	//OEE Chart starts
	buildOEEChart() {
		this.primaryProcessStreamChart = _.cloneDeep(oeeChartTemplate);
		if (this.selectedPrimaryStream && this.selectedPrimaryStream.processStreamName) {
			this.primaryProcessStreamChart = this.chartService.buildOEEPrimaryStreamChartData(this.carRequest, this.selectedPrimaryStream.processStreamName);
		}
		this.supportingProcessStreamChart = this.chartService.buildOEESupportingStreamChartData(this.carRequest);
	}

	//OEE Chart ends

	//SLP Chart Starts
	checkForFreeAllocationMessage(chartData: ChartData): boolean {
		let showMessage = false;
		if (chartData && chartData.datasets && chartData.datasets.length > 0) {
			const freeAllocation = chartData.datasets[0].data[chartData.datasets[0].data.length - 1];
			if (freeAllocation > 0 && freeAllocation <= 10) {
				showMessage = true;
			}
		}
		return showMessage;
	}

	getFreeAllocation(chartData: ChartData): number {
		let freeAllocation = 0;
		if (chartData && chartData.datasets && chartData.datasets.length > 0) {
			freeAllocation = chartData.datasets[0].data[chartData.datasets[0].data.length - 1];
		}
		return freeAllocation;
	}

	retrieveSharedLoadingIdBySiteCodeAndBuildChart() {
		this.buildChartDataForPrimaryStream();
		this.buildChartDataForSupportingStream();
	}

	buildChartDataForPrimaryStream() {
		this.sharedLoadingChartForPrimaryStream = [];
		if (this.carRequest && this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach((psPnMapTranObject: PsPnMapTran) => {
				if (psPnMapTranObject && psPnMapTranObject.processDataList[0] && !psPnMapTranObject.processDataList[0].dedicated) {
					this.isThereAnySHChartAvailable = true;
					if (this.carRequest.requestStatus !== null && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'STADRAFT' && this.carRequest.requestStatus !== 'REJECTED') {
						this.sharedLoadingService.retrieveSharedLoadingByCarIdAndSiteAndProcess(this.carRequest.reqId, this.carRequest.siteCode, psPnMapTranObject.processNameId).subscribe(value => {
							if (value) {
								const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, psPnMapTranObject.processName, value);
								if (sharedLoadingChartTemp) {
									this.sharedLoadingChartForPrimaryStream.push(sharedLoadingChartTemp);
								}
							}
						}, error => {
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					} else {
						this.sharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(this.carRequest.siteCode, psPnMapTranObject.processNameId.toString()).subscribe(value => {
							if (value) {
								const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, psPnMapTranObject.processName, value);
								if (sharedLoadingChartTemp) {
									this.sharedLoadingChartForPrimaryStream.push(sharedLoadingChartTemp);
								}
							}
						}, error => {
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					}
				}
			});
		}
	}

	buildChartDataFromPsPnMapTranObject(psPnMapTranObject: PsPnMapTran, processStreamName: string, slRoot: SharedLoadingRoot): SharedLoadingChart {
		let sharedLoadingChartTemp: SharedLoadingChart;
		this.isSharedLoadingExistInPS = true;
		sharedLoadingChartTemp = this.buildAPWAndMPWChartData(slRoot, psPnMapTranObject, processStreamName);
		return sharedLoadingChartTemp;
	}

	buildAPWAndMPWChartData(sharedLoadingRoot: SharedLoadingRoot, psPnMapTranObject: PsPnMapTran, processStreamName: string): SharedLoadingChart {
		const sharedLoadingChartTemp = _.cloneDeep(sharedLoadingChartTemplate);
		sharedLoadingChartTemp.processName = psPnMapTranObject.processName;
		sharedLoadingChartTemp.processStreamName = processStreamName;
		const sharedLoadingAPW = sharedLoadingRoot.sharedLoadingList[0];
		const sharedLoadingMPW = sharedLoadingRoot.sharedLoadingList[1];

		//APW
		sharedLoadingChartTemp.apwChart = this.extractAndBuildChartData(sharedLoadingAPW);

		//MPW
		sharedLoadingChartTemp.mpwChart = this.extractAndBuildChartData(sharedLoadingMPW);
		sharedLoadingChartTemp.mpwChart.labels = sharedLoadingChartTemp.apwChart.labels;
		sharedLoadingChartTemp.mpwChart.datasets[0].backgroundColor = sharedLoadingChartTemp.apwChart.datasets[0].backgroundColor;
		sharedLoadingChartTemp.mpwChart.datasets[0].hoverBackgroundColor = sharedLoadingChartTemp.apwChart.datasets[0].hoverBackgroundColor;
		return sharedLoadingChartTemp;
	}

	extractAndBuildChartData(sharedLoading: SharedLoading): ChartData {
		const chartData: ChartData = {
			'labels': [],
			'datasets': [],
			'isSharedLoadingExceeds100Percent': false,
			'height': 0
		};
		const vLineList: string [] = [];

		//Data
		const dataSetAPW = _.cloneDeep(dataSetTemplate);
		const allocPercentData = [];
		let totalAllocation = 0;
		let remainingAllocation = 0;

		//Color
		dataSetAPW.backgroundColor = [];
		dataSetAPW.hoverBackgroundColor = [];

		const isCapacityStudyScenario = this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.capacityRequirementSource === 'What-If' ? true : false;
		//Handling for Capacity Study Scenario
		const sharedLoadingPlanHolderList = [];
		sharedLoading.sharedLoadingPlanList.forEach((sharedLoadingPlan, slpIndex) => {
			const sharedLoadingPlanHolder = new SharedLoadingPlanHolder();
			if (sharedLoadingPlan.isCapacity) {
				let indexValue = 0;
				sharedLoadingPlanHolderList.forEach((slPlanHolder, indx) => {
					if (slPlanHolder.apw.groupId === sharedLoadingPlan.groupId) {
						indexValue = indx;
					}
				});
				sharedLoadingPlanHolderList[indexValue].capacityAPW = sharedLoadingPlan;
			} else {
				sharedLoadingPlanHolder.apw = sharedLoadingPlan;
				sharedLoadingPlanHolderList.push(sharedLoadingPlanHolder);
			}
		});
		sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, slpIndex) => {
			if (vLineList && vLineList.length > 0 && vLineList.includes(sharedLoadingPlanHolder.apw.vehLine)) {
				const index = vLineList.indexOf(sharedLoadingPlanHolder.apw.vehLine);
				if (isCapacityStudyScenario && sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired) {
					allocPercentData[index] = allocPercentData[index] + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
				} else {
					allocPercentData[index] = allocPercentData[index] + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
				}
			} else {
				vLineList.push(sharedLoadingPlanHolder.apw.vehLine);
				if (isCapacityStudyScenario && sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired) {
					allocPercentData.push(_.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1));
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
				} else {
					allocPercentData.push(_.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1));
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
				}
				dataSetAPW.backgroundColor.push(chartColors[slpIndex].backgroundColor);
				dataSetAPW.hoverBackgroundColor.push(chartColors[slpIndex].hoverBackgroundColor);
			}
		});
		vLineList.push('Free');
		chartData.labels = vLineList;
		totalAllocation = _.round(totalAllocation, 1);
		remainingAllocation = 100 - totalAllocation;
		if (remainingAllocation < 0) {
			chartData.isSharedLoadingExceeds100Percent = true;
		}
		remainingAllocation = _.round(remainingAllocation, 1);
		remainingAllocation = remainingAllocation <= 0 ? 0 : remainingAllocation;
		allocPercentData.push(remainingAllocation);

		dataSetAPW.data = allocPercentData;
		dataSetAPW.backgroundColor.push(chartColors[chartColors.length - 1].backgroundColor);
		dataSetAPW.hoverBackgroundColor.push(chartColors[chartColors.length - 1].hoverBackgroundColor);
		chartData.datasets[0] = dataSetAPW;
		return chartData;
	}

	buildChartDataForSupportingStream() {
		this.sharedLoadingChartForSupportingStream = [];
		if (this.carRequest && this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			this.carRequest.primarySupportingMapTranList.forEach((primarySupportingMapTran) => {
				if (primarySupportingMapTran && primarySupportingMapTran.psPnMapTranList && primarySupportingMapTran.psPnMapTranList.length > 0) {
					const sharedLoadingChartForSupportingStreamTemp = [];
					primarySupportingMapTran.psPnMapTranList.forEach((psPnMapTranObject, i) => {
						if (psPnMapTranObject && psPnMapTranObject.processDataList[0] && !psPnMapTranObject.processDataList[0].dedicated) {
							this.isThereAnySHChartAvailable = true;
							this.isSharedLoadingExistInSS = true;
							if (this.carRequest.requestStatus !== null && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'STADRAFT' && this.carRequest.requestStatus !== 'REJECTED') {
								this.sharedLoadingService.retrieveSharedLoadingByCarIdAndSiteAndProcess(this.carRequest.reqId, this.carRequest.siteCode, psPnMapTranObject.processNameId).subscribe(value => {
									if (value) {
										const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, primarySupportingMapTran.processStreamName, value);
										if (sharedLoadingChartTemp) {
											sharedLoadingChartForSupportingStreamTemp.push(sharedLoadingChartTemp);
											if (i === primarySupportingMapTran.psPnMapTranList.length - 1 && sharedLoadingChartForSupportingStreamTemp && sharedLoadingChartForSupportingStreamTemp.length > 0) {
												this.sharedLoadingChartForSupportingStream.push(sharedLoadingChartForSupportingStreamTemp);
												console.log('final this.sharedLoadingChartForSupportingStreamTemp ' + this.sharedLoadingChartForSupportingStream.length);
											}
										}
									}
								}, error => {
									this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
								});
							} else {
								this.sharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(this.carRequest.siteCode, psPnMapTranObject.processNameId.toString()).subscribe(value => {
									if (value) {
										const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, primarySupportingMapTran.processStreamName, value);
										if (sharedLoadingChartTemp) {
											sharedLoadingChartForSupportingStreamTemp.push(sharedLoadingChartTemp);
											if (i === primarySupportingMapTran.psPnMapTranList.length - 1 && sharedLoadingChartForSupportingStreamTemp && sharedLoadingChartForSupportingStreamTemp.length > 0) {
												this.sharedLoadingChartForSupportingStream.push(sharedLoadingChartForSupportingStreamTemp);
												console.log('final this.sharedLoadingChartForSupportingStreamTemp ' + this.sharedLoadingChartForSupportingStream.length);
											}
										}
									}
								}, error => {
									this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
								});
							}
						}
					});
				}
			});
		}
	}

	//SLP Chart ends

	// viewProcess stream
	connectProcessNames() {
		if (!this.isConnectionMade) {
			this.jsPlumbInstance = jsPlumb.getInstance();
			const psSize = this.processStreamTree.length;
			for (let curTreeIndex = 0; curTreeIndex < psSize; curTreeIndex++) {
				const curProcessStream = this.processStreamTree[curTreeIndex];
				const availableProcessNames = curProcessStream.availableProcessNames;
				const pnSize = availableProcessNames.length;
				for (let curPNIndex = 0; curPNIndex < pnSize; curPNIndex++) { // n-1 loop to make connection
					if (curPNIndex < pnSize - 1) {
						const sourceId = 'div-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex].id;
						const targetId = 'div-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex + 1].id;

						const connectJson = this.buildConnection(sourceId, targetId);
						this.jsPlumbInstance.connect(connectJson);
					}

					if (curTreeIndex !== 0) { // other than parent
						const priSuppoMap = curProcessStream.primarySupportingMap;
						const primaryProcessStreamId = priSuppoMap['primaryProcessStreamId'];
						const supportingProcessStreamId = priSuppoMap['supportingProcessStreamId'];
						const primaryProcessNameId = priSuppoMap['primaryProcessNameId'];

						const sourceIdPriSupp = 'div-' + primaryProcessStreamId + '-' + primaryProcessNameId;
						const targerIdPriSupp = 'div-' + supportingProcessStreamId + '-' + availableProcessNames[availableProcessNames.length - 1].id;
						const connectJsonPriSupp = this.buildPriSuppConnection(sourceIdPriSupp, targerIdPriSupp, linkColors[curTreeIndex].colorCode);
						this.jsPlumbInstance.connect(connectJsonPriSupp);
					}
					this.isConnectionMade = true;
				}
			}
		}
	}

	private buildPriSuppConnection(sourceIdPriSupp: string, targerIdPriSupp: string, strokeColor: string) {
		const connectJson = {
			source: targerIdPriSupp,
			target: sourceIdPriSupp,
			anchors: ['Right', 'Right'],
			paintStyle: {stroke: strokeColor, strokeWidth: 2},
			endpoint: 'Blank',
			endpointStyle: {fill: 'yellow'},
			connector: ['Bezier', {curviness: 30}],
			overlays: [['Arrow', {
				location: 1,
				id: 'arrow',
				length: 14,
				foldback: 0.4
			}]]
		};
		return connectJson;
	}

	private buildConnection(sourceId, targetId) {
		const connectJson = {
			source: sourceId,
			target: targetId,
			anchors: ['Right', 'Left'],
			endpoint: 'Blank',
			endpointStyle: {fill: 'blue'},
			paintStyle: {stroke: '#03a108', strokeWidth: 1},
			hoverPaintStyle: {strokeStyle: '#dbe300'},
			connector: ['Flowchart', {cornerRadius: 2, alwaysRespectStubs: false}],
			overlays: [['Arrow', {
				location: 1,
				id: 'arrow',
				length: 14,
				foldback: 0.4
			}]]
		};
		return connectJson;
	}

	// viewProcess stream ends

	preparePrimaryLabel() {
		this.cols3 = [];
		this.cols3 = [
			{
				field: 'subHeader',
				header: 'A4) Planned Departmental Operating  Pattern & Net Available Time for All Customers',
				scenario: 'All'
			},
			{field: 'daysPerWeek', header: 'B) Days/Week', scenario: 'All'},
			{field: 'shiftsPerDay', header: 'C) Shifts/Day', scenario: 'All'},
			{field: 'hrsPerShift', header: 'D) Total hours/Shift', scenario: 'All'},
			{field: 'downTime', header: 'E) Downtime (min/shift)', scenario: 'All'},
			{field: 'dedicated', header: 'F) Process Type', scenario: 'All', mergeData: true},
			{
				field: 'planMinChan',
				header: 'G1) Minutes per Changeover (into this part #)',
				scenario: 'All',
				mergeData: true
			},
			{
				field: 'planChangFreq',
				header: 'G2) Changeover Frequency / Week (into this part #)',
				scenario: 'All',
				mergeData: true
			},
			{field: 'allocPercent', header: 'F1) Allocation Percent', scenario: 'All'},
			{field: 'netAvailableTime', header: 'G) Net Available Time (hrs / week)', scenario: 'All'},
			{field: 'subHeader', header: 'A5) Required Good Parts / Week', scenario: 'All'},
			{field: 'scrappedPercent', header: 'H) Planned scrap percentage', scenario: 'All', mergeData: true},
			{
				field: 'reqGoodPartsNext',
				header: 'J) Req\'d Good Parts / Week to Support Next Process',
				scenario: 'All'
			},
			{field: 'subHeader', header: 'A6) Required OEE  (Overall Equipment Effectiveness)', scenario: 'All'},
			{field: 'idealCycleTime', header: 'K) Ideal Cycle Time (sec/cycle)', scenario: 'All', mergeData: true},
			{
				field: 'noToolsMachParallel',
				header: 'L) # of Tools / Machines in parallel (planned)',
				scenario: 'All',
				mergeData: true
			},
			{
				field: 'noIdentityParts',
				header: 'M) # of identical parts per Cycle (planned)',
				scenario: 'All',
				mergeData: true
			},
			{
				field: 'netIdleCycleTime',
				header: 'N) Net Ideal Cycle Time  (sec/part)',
				scenario: 'All',
				mergeData: true
			},
			{field: 'theoreticalParts', header: 'P) Theoretical Parts per week at 100% OEE', scenario: 'All'},
			{field: 'reqOee', header: 'Q) Required OEE', scenario: 'All'},
			{field: 'reqJph', header: 'Required JPH', scenario: 'All'},
			{field: 'partsReworkedPercent', header: 'R) Planned Rework percentage', scenario: 'All', mergeData: true},
			{field: 'isContainAssumptions', header: 'S) Assumptions can be contained?', scenario: 'All'},
			{
				field: 'avaiRemainPercent',
				header: 'T) % Remaining for Availability & Performance Efficiency losses',
				scenario: 'All'
			},
			{field: 'otherAssumptionX', header: 'Other Assumptions', scenario: 'All', mergeData: true},
			{
				field: 'subHeader',
				header: 'A7) Planned Headcount/ Capital Equipment & Shared Process / Total Allocation Plan',
				scenario: 'All'
			},
			{field: 'plannedHeadCount', header: 'Planned Headcount (direct labor)', scenario: 'All', mergeData: true},
			{field: 'capitalEquipment', header: 'Capital Equipment', scenario: 'All', mergeData: true},
			{
				field: 'totalAllocationPercentageAPW',
				header: 'U) Total % Allocation from Shared Loading Plan Sheet',
				scenario: 'All'
			},
			{
				field: 'totalAllocationPercentageCapacityMPW',
				header: 'What-If Total % Allocation from Shared Loading Plan Sheet',
				scenario: 'All'
			},
			{
				field: 'subHeader',
				header: 'B1) Supplier Demonstrated OEE  (Overall Equipment Effectiveness)  - Historical Performance',
				scenario: 'Capacity Planning'
			},
			{field: 'supplierName', header: 'V) Supplier Name', scenario: 'Capacity Planning', mergeData: true},
			{field: 'supplierLocation', header: 'W) Supplier Location', scenario: 'Capacity Planning', mergeData: true},
			{field: 'siteCodeSurProcess', header: 'X) Site Code', scenario: 'Capacity Planning', mergeData: true},
			{
				field: 'surCustomerProcess',
				header: 'Y) Surrogate Program',
				scenario: 'Capacity Planning',
				mergeData: true
			},
			{
				field: 'averageHisOee',
				header: 'Z) Average Historical OEE',
				scenario: 'Capacity Planning',
				mergeData: true
			},
			{
				field: 'demonJph',
				header: 'Demonstrated JPH',
				scenario: 'Capacity Planning',
				mergeData: true
			},
			{
				field: 'weekPartEstimate',
				header: 'B2) Process Specific Weekly Part Estimate',
				scenario: 'Capacity Planning'
			},
			{
				field: 'subHeader',
				header: 'B) Supplier Demonstrated OEE - Phase 0 PPAP (Run @ Rate)',
				scenario: 'Phase 0 PPAP'
			},
			{field: 'subHeader', header: 'B1) Equipment Availability', scenario: 'Phase 0 PPAP'},
			{
				field: 'totalAvailableTime',
				header: 'V) Total Available Time (minutes)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'plannedDownTime',
				header: 'W) Contractual Downtime (minutes)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'netAvailableTimePhase',
				header: 'X) Net Available Time (minutes)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'changeOverTimeActual',
				header: 'Y) Changeover Time Actual (minutes)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'changeOverTimeWeekly',
				header: 'Z) Changeover Time weekly (minutes)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'observedUnplannedDownTime',
				header: 'AB) Observed Downtime (incremental to W): Planned & Unplanned (minutes)',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{field: 'operatingTime', header: 'AC) Operating Time (minutes)', scenario: 'Phase 0 PPAP', mergeData: true},
			{
				field: 'equipmentAvailability',
				header: 'AD) Equipment Availability (%)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{field: 'subHeader', header: 'B2) Performance Efficiency', scenario: 'Phase 0 PPAP'},
			{field: 'totalPartsRun', header: 'AE) Total Parts Run', scenario: 'Phase 0 PPAP', mergeData: true},
			{
				field: 'noOfCurrentToolsORMachines',
				header: 'AF1) # of current tool or machines in parallel',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{
				field: 'noOfIdenticalPartsProduced',
				header: 'AF2) # of identical parts produced',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{
				field: 'plannedNetIdealCycleTime',
				header: 'AF) Planned Net Ideal Cycle Time (sec/part)',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{
				field: 'performanceEfficiency',
				header: 'AG) Performance Efficiency (%)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'availablePerformanceEfficiency',
				header: 'AH) Availability and/or Performance Efficiency(minutes)',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{
				field: 'subHeader',
				header: 'B3) Quality Rate & B4) Overall Equipment Effectiveness (OEE)',
				scenario: 'Phase 0 PPAP'
			},
			{field: 'noOfPartsScrapped', header: 'AJ) # of Parts Scrapped', scenario: 'Phase 0 PPAP', mergeData: true},
			{
				field: 'noOfPartsScrappedPercent',
				header: '% of Parts Scrapped',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{field: 'noOfPartsReworked', header: 'AK) # of Parts Reworked', scenario: 'Phase 0 PPAP', mergeData: true},
			{
				field: 'noOfPartsReworkedPercent',
				header: '% of Parts Reworked',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{field: 'qualityRate', header: 'AL) Quality Rate (%)', scenario: 'Phase 0 PPAP', mergeData: true},
			{field: 'phaseOEE', header: 'AM) Phase 0 OEE (%)', scenario: 'Phase 0 PPAP', mergeData: true},
			{field: 'phaseJPH', header: 'Phase JPH', scenario: 'Phase 0 PPAP', mergeData: true},
			{field: 'weekPartEstimate', header: 'B5) Weekly Parts Estimate', scenario: 'Phase 0 PPAP'},
			{
				field: 'observedAvgCycleTime',
				header: 'B6) Observed Average Cycle Time(sec/cycle)',
				scenario: 'Phase 0 PPAP', mergeData: true
			},
			{
				field: 'actualHeadCount',
				header: 'Actual Headcount (Direct Labor)',
				scenario: 'Phase 0 PPAP',
				mergeData: true
			},
			{
				field: 'subHeader',
				header: 'B) Supplier Demonstrated OEE - Phase 3 PPAP (Capacity Verification)',
				scenario: 'Phase 3 PPAP', mergeData: true
			},
			{field: 'subHeader', header: 'B1) Equipment Availability', scenario: 'Phase 3 PPAP'},
			{
				field: 'totalAvailableTime',
				header: 'V) Total Available Time (minutes)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'plannedDownTime',
				header: 'W) Contractual Downtime (minutes)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'netAvailableTimePhase',
				header: 'X) Net Available Time (minutes)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'changeOverTimeActual',
				header: 'Y) Changeover Time Actual (minutes)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'changeOverTimeWeekly',
				header: 'Z) Changeover Time weekly (minutes)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'observedUnplannedDownTime',
				header: 'AB) Observed Downtime (incremental to W): Planned & Unplanned (minutes)',
				scenario: 'Phase 3 PPAP', mergeData: true
			},
			{field: 'operatingTime', header: 'AC) Operating Time (minutes)', scenario: 'Phase 3 PPAP', mergeData: true},
			{
				field: 'equipmentAvailability',
				header: 'AD) Equipment Availability (%)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{field: 'subHeader', header: 'B2) Performance Efficiency', scenario: 'Phase 3 PPAP'},
			{field: 'totalPartsRun', header: 'AE) Total Parts Run', scenario: 'Phase 3 PPAP', mergeData: true},
			{
				field: 'plannedNetIdealCycleTime',
				header: 'AF) Planned Net Ideal Cycle Time (sec/part)',
				scenario: 'Phase 3 PPAP', mergeData: true
			},
			{
				field: 'performanceEfficiency',
				header: 'AG) Performance Efficiency (%)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'availablePerformanceEfficiency',
				header: 'AH) Availability and/or Performance Efficiency(minutes)',
				scenario: 'Phase 3 PPAP', mergeData: true
			},
			{
				field: 'subHeader',
				header: 'B3) Quality Rate & B4) Overall Equipment Effectiveness (OEE)',
				scenario: 'Phase 3 PPAP'
			},
			{field: 'noOfPartsScrapped', header: 'AJ) # of Parts Scrapped', scenario: 'Phase 3 PPAP', mergeData: true},
			{
				field: 'noOfPartsScrappedPercent',
				header: '% of Parts Scrapped',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{field: 'noOfPartsReworked', header: 'AK) # of Parts Reworked', scenario: 'Phase 3 PPAP', mergeData: true},
			{
				field: 'noOfPartsReworkedPercent',
				header: '% of Parts Reworked',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{
				field: 'qualityRate',
				header: 'AL) Quality Rate (%)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{field: 'phaseOEE', header: 'AM) Phase 3 OEE (%)', scenario: 'Phase 3 PPAP', mergeData: true},
			{field: 'phaseJPH', header: 'Phase JPH', scenario: 'Phase 3 PPAP', mergeData: true},
			{field: 'weekPartEstimate', header: 'B5) Weekly Parts Estimate', scenario: 'Phase 3 PPAP'},
			{
				field: 'observedAvgCycleTime',
				header: 'B6) Observed Average Cycle Time(sec/cycle)',
				scenario: 'Phase 3 PPAP', mergeData: true
			},
			{
				field: 'actualHeadCount',
				header: 'Actual Headcount (Direct Labor)',
				scenario: 'Phase 3 PPAP',
				mergeData: true
			},
			{field: 'resultStatusMessage', header: 'Capacity Analysis Results', scenario: 'All'}
		];
		this.pspnMap = [];
		this.pspnMapTabSplit = [];
		//this.pspnMap = this.carRequest.psPnMapTransList;
		this.carRequest.psPnMapTransList.forEach(pspn => {
			if (this.pspnMap.length < 14) {
				this.pspnMap.push(pspn);
			} else {
				this.pspnMap.push(pspn);
				this.pspnMapTabSplit.push(this.pspnMap);
				this.pspnMap = [];
			}
		});
		this.pspnMapTabSplit.push(this.pspnMap);
	}

	private updateDeclarationQuestionAnswers() {
		const updatedQustnList: DeclarationQuestionsTran[] = [];
		this.carRequest.declarationQuestionsTranList.forEach(question => {
			if (question.ansType === 'Descriptive') {
				question.answer = question.openTypeAnswer;
			} else if (question.ansType === 'Yes / No') {
				question.answer = question.yesNoAnswer;
				question.optionStr = JSON.stringify(question.optionObj);
			} else if (question.ansType === 'Date Picker' && question.dateAnswer) {
				if (question.dateAnswer) {
					const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
						'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
					];
					const date = new Date(question.dateAnswer);
					question.answer = date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear();
				}
			}
			updatedQustnList.push(question);
		});
		this.carRequest.declarationQuestionsTranList = updatedQustnList;
	}

	declarationQuestionStatus() {
		let isMandatoryQuestionsValid = true;
		this.carRequest.declarationQuestionsTranList.forEach(question => {
			if (question.mandatory) {
				if (!question.answer || question.answer === '' || question.answer === null) {
					isMandatoryQuestionsValid = false;
				}
				if (isMandatoryQuestionsValid && question.question === 'Supplier used the following method to complete sub-tier supplier capacity analysis:' && question.answer === 'Others' && (!question.comments || question.comments === '')) {
					isMandatoryQuestionsValid = false;
				}
				if (isMandatoryQuestionsValid && question.question === 'Has this eCAR been created for a family of parts? (e.g. LH/RH) If so, you must enter the additional part numbers in the comments below.' && question.answer === 'Yes' && (!question.comments || question.comments === '')) {
					isMandatoryQuestionsValid = false;
				}
			}
		});
		if (!isMandatoryQuestionsValid) {
			return 'Incomplete';
		} else if (this.carRequest.declarationQuestionsTranList[0].yesNoAnswer === 'No' || this.carRequest.declarationQuestionsTranList[4].yesNoAnswer === 'No' ||
			(this.carRequest.declarationQuestionsTranList[0].yesNoAnswer !== 'NA' && this.carRequest.declarationQuestionsTranList[1].openTypeAnswer !== this.carRequest.declarationQuestionsTranList[2].openTypeAnswer)) {
			return 'Risk';
		} else {
			return 'OK';
		}
	}

	ngOnDestroy() {
		this.jsPlumbInstance.deleteEveryConnection();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.connectProcessNames();
			// window.print();
		}, 6000);
		this.connectProcessNames();
	}

	@HostListener('window:afterprint')
	onAfterPrint() {
		window.close();
	}

	@HostListener('window:beforeprint')
	onBeforePrint() {
		this.jsPlumbInstance.repaintEverything();
	}

	onPrint() {
		const landscape = false;
		const head = document.head || document.getElementsByTagName('head')[0];
		const style = document.createElement('style');
		style.type = 'text/css';
		style.media = 'print';

		style.appendChild(document.createTextNode(landscape ?
			'@page { size: A3 landscape; margin: auto;}' :
			'@page { size: A3;  margin: auto; }'));

		head.appendChild(style);
		window.print();
	}

	private totalAllocationAndcontainAssumptionsCheck() {
		this.totalAllocationPercentageGreaterThan100 = false;
		this.containAssumptionsHasFalse = false;
		if (this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			this.carRequest.psPnMapTransList.forEach(psPnMapTran => {
				if (psPnMapTran.processDataList) {
					psPnMapTran.processDataList.forEach(processData => {
						if (!this.totalAllocationPercentageGreaterThan100 && !processData.dedicated && ((processData.totalAllocationPercentageAPW && processData.totalAllocationPercentageAPW > 100) || (processData.totalAllocationPercentageMPW && processData.totalAllocationPercentageMPW > 100))) {
							this.totalAllocationPercentageGreaterThan100 = true;
						}
						if (!this.containAssumptionsHasFalse && ((!processData.dedicated && !processData.isContainAssumptions) ||
							(processData.dedicated && !processData.isContainAssumptions
								&& processData.dedicatedTotalAllocPercent < 100))) {
							this.containAssumptionsHasFalse = true;
						}
					});
				}
			});
		}
		if ((!this.totalAllocationPercentageGreaterThan100 || !this.containAssumptionsHasFalse) && this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			this.carRequest.primarySupportingMapTranList.forEach(priSupMapList => {
				if (priSupMapList.psPnMapTranList && priSupMapList.psPnMapTranList.length > 0) {
					priSupMapList.psPnMapTranList.forEach(psPnMapTran => {
						if (psPnMapTran.processDataList) {
							psPnMapTran.processDataList.forEach(processData => {
								if (!this.totalAllocationPercentageGreaterThan100 && !processData.dedicated && ((processData.totalAllocationPercentageAPW && processData.totalAllocationPercentageAPW > 100) || (processData.totalAllocationPercentageMPW && processData.totalAllocationPercentageMPW > 100))) {
									this.totalAllocationPercentageGreaterThan100 = true;
								}
								if (!this.containAssumptionsHasFalse && ((!processData.dedicated && !processData.isContainAssumptions) ||
									(processData.dedicated && !processData.isContainAssumptions
										&& processData.dedicatedTotalAllocPercent < 100))) {
									this.containAssumptionsHasFalse = true;
								}
							});
						}
					});
				}
			});
		}
	}

	private supportProcessDataConstruction(processNameList: any[], processDataList: any[], supportingMap: PrimarySupportingMapTran, matchFlag: boolean, supportingProcess: {}) {
		this.supportingProcessDataList.forEach(supProData => {
			if (supProData.processStreamName === supportingMap.processStreamName) {
				matchFlag = true;
			}
		});
		if (matchFlag) {
			supportingProcess['processStreamName'] = supportingMap.processStreamName + ' (Continued)';
		} else {
			supportingProcess['processStreamName'] = supportingMap.processStreamName;
		}
		supportingProcess['processNameList'] = processNameList;
		supportingProcess['processDataList'] = processDataList;
		this.supportingProcessDataList.push(supportingProcess);
	}
}
