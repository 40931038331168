import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {jsPlumb} from 'jsplumb';
import {ProcessStreamService} from '../../../services/master/process-stream/process-stream.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProcessStream} from '../../../models/process-stream';
import {CarRequestService} from '../../../services/request/car-request.service';
import {CarRequest} from '../../../models/car-request';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UtilService} from '../../../services/util.service';
import {linkColors} from '../../../models/chart-data';

@Component({
	selector: 'summary-process-stream',
	templateUrl: './summary-process-stream.component.html',
	styleUrls: ['./summary-process-stream.component.css']
})
export class SummaryProcessStreamComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() primaryStreamId: number;
	@Input() carRequest: CarRequest;
	@Input() isLinked: boolean;
	@Input() isCapacityStudy: boolean;
	@Input() capacityRequirementSource: string;
	@Input() hideCapacityStudyAttributes: boolean;
	@Input() hideBottleNeckProcess: boolean;

	isConnectionMade = false;
	jsPlumbInstance;
	jsPlumbParentInstance;
	common = {
		anchors: ['BottomCenter', 'TopCenter'],
		endpoints: ['Dot', 'Blank']
	};
	processStreamTree: ProcessStream[] = [];
	parentProcessStreamTree: ProcessStream[] = [];
	parentCAR: CarRequest;
	isParent = false;
	private unsubscribe$: Subject<any> = new Subject<any>();

	constructor(private utilService: UtilService, private router: Router, private route: ActivatedRoute, private processStreamService: ProcessStreamService, private carRequestService: CarRequestService) {
	}

	ngOnInit() {
		this.carRequestService.showViewStreamSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
			if (this.primaryStreamId && val) {
				if (this.processStreamTree.length < 1) {
					if ((this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason === null) || ((this.carRequest.requestStatus === 'REJECTED' || (this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason !== null)) && this.carRequest.keepHistoricalPSInRejRecall === false && this.carRequest.isProcessStreamAvailable)) {
						this.processStreamService.findProcessStreamTreeById(this.primaryStreamId).subscribe(value => {
							this.processStreamTree = value;
							if (this.isLinked) {
								this.carRequestService.findById(this.carRequest.selectedCarIdForLinkage).subscribe(parentCAR => {
									this.isParent = true;
									this.parentCAR = parentCAR;
									if (this.parentProcessStreamTree.length < 1) {
										this.processStreamService.findProcessStreamTreeById(this.parentCAR.primaryStreamId).subscribe(parentStreamTree => {
											this.parentProcessStreamTree = parentStreamTree;
										}, error => {
											this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
										});
									}
								}, error => {
									this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
								});
							}
						}, error => {
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					} else {
						this.processStreamService.findProcessStreamTranTreeById(this.primaryStreamId, this.carRequest.reqId).subscribe(value => {
							this.processStreamTree = value;
							if (this.isLinked) {
								this.carRequestService.findById(this.carRequest.selectedCarIdForLinkage).subscribe(parentCAR => {
									this.isParent = true;
									this.parentCAR = parentCAR;
									if (this.parentProcessStreamTree.length < 1) {
										this.processStreamService.findProcessStreamTranTreeById(this.parentCAR.primaryStreamId, this.carRequest.reqId).subscribe(parentStreamTree => {
											this.parentProcessStreamTree = parentStreamTree;
										}, error => {
											this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
										});
									}
								}, error => {
									this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
								});
							}
						}, error => {
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					}

				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	ngAfterViewInit(): void {
		this.carRequestService.showAnchorsStreamSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
			if (this.primaryStreamId && val) {
				setTimeout(() => {
					if (this.processStreamTree && this.processStreamTree.length > 0) {
						if (this.jsPlumbInstance) {
							this.jsPlumbInstance.reset();
						}
						this.isConnectionMade = false;
						this.connectProcessNames(this.processStreamTree, true);
					}
					if (this.isLinked) {
						if (this.parentProcessStreamTree && this.parentProcessStreamTree.length > 0) {
							if (this.jsPlumbParentInstance) {
								this.jsPlumbParentInstance.reset();
							}
							this.isConnectionMade = false;
							this.connectProcessNames(this.parentProcessStreamTree, false);
						}
					}
				}, 4000);
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
		this.carRequestService.destroyAnchorsStreamSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
			if (val) {
				this.isConnectionMade = true;
				if (this.jsPlumbInstance) {
					this.jsPlumbInstance.reset();
				}
				if (this.isLinked && this.jsPlumbParentInstance) {
					this.jsPlumbParentInstance.reset();
				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	connectProcessNames(processStreamTree: ProcessStream[], isChild: boolean) {
		if (!this.isConnectionMade) {
			if (isChild) {
				this.jsPlumbInstance = jsPlumb.getInstance();
			} else {
				this.jsPlumbParentInstance = jsPlumb.getInstance();
			}
			const psSize = processStreamTree.length;
			for (let curTreeIndex = 0; curTreeIndex < psSize; curTreeIndex++) {
				const curProcessStream = processStreamTree[curTreeIndex];
				const availableProcessNames = curProcessStream.availableProcessNames;
				const pnSize = availableProcessNames.length;
				for (let curPNIndex = 0; curPNIndex < pnSize; curPNIndex++) { // n-1 loop to make connection
					if (curPNIndex < pnSize - 1) {
						let sourceId;
						let targetId;
						if (isChild) {
							sourceId = 'div-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex].id;
							targetId = 'div-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex + 1].id;
							const connectJson = this.buildConnection(sourceId, targetId);
							this.jsPlumbInstance.connect(connectJson);
						} else {
							sourceId = 'parentdiv-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex].id;
							targetId = 'parentdiv-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex + 1].id;
							const connectJson = this.buildConnection(sourceId, targetId);
							this.jsPlumbParentInstance.connect(connectJson);
						}
					}

					if (curTreeIndex !== 0) { // other than parent
						const priSuppoMap = curProcessStream.primarySupportingMap;
						const primaryProcessStreamId = priSuppoMap['primaryProcessStreamId'];
						const supportingProcessStreamId = priSuppoMap['supportingProcessStreamId'];
						const primaryProcessNameId = priSuppoMap['primaryProcessNameId'];

						let sourceIdPriSupp;
						let targetIdPriSupp;
						if (isChild) {
							sourceIdPriSupp = 'div-' + primaryProcessStreamId + '-' + primaryProcessNameId;
							targetIdPriSupp = 'div-' + supportingProcessStreamId + '-' + availableProcessNames[availableProcessNames.length - 1].id;
							const connectJsonPriSupp = this.buildPriSuppConnection(sourceIdPriSupp, targetIdPriSupp, linkColors[curTreeIndex].colorCode);
							this.jsPlumbInstance.connect(connectJsonPriSupp);
						} else {
							sourceIdPriSupp = 'parentdiv-' + primaryProcessStreamId + '-' + primaryProcessNameId;
							targetIdPriSupp = 'parentdiv-' + supportingProcessStreamId + '-' + availableProcessNames[availableProcessNames.length - 1].id;
							const connectJsonPriSupp = this.buildPriSuppConnection(sourceIdPriSupp, targetIdPriSupp, linkColors[curTreeIndex].colorCode);
							this.jsPlumbParentInstance.connect(connectJsonPriSupp);
						}
					}
					this.isConnectionMade = true;
					if (isChild) {
						this.jsPlumbInstance.repaintEverything();
					} else {
						this.jsPlumbParentInstance.repaintEverything();
					}
				}
			}
		}
	}

	private buildConnection(sourceId, targetId) {
		const connectJson = {
			source: sourceId,
			target: targetId,
			anchors: ['Right', 'Left'],
			endpoint: 'Blank',
			endpointStyle: {fill: 'blue'},
			paintStyle: {stroke: '#03a108', strokeWidth: 1},
			hoverPaintStyle: {strokeStyle: '#dbe300'},
			connector: ['Flowchart', {cornerRadius: 2, alwaysRespectStubs: false}],
			overlays: [['Arrow', {
				location: 1,
				id: 'arrow',
				length: 14,
				foldback: 0.4
			}]]
		};
		return connectJson;
	}

	private buildPriSuppConnection(sourceIdPriSupp: string, targetIdPriSupp: string, strokeColor: string) {
		const connectJson = {
			source: targetIdPriSupp,
			target: sourceIdPriSupp,
			anchors: ['Right', 'Right'],
			paintStyle: {stroke: strokeColor, strokeWidth: 2},
			endpoint: 'Blank',
			endpointStyle: {fill: 'yellow'},
			connector: ['Bezier', {curviness: 120}],
			overlays: [['Arrow', {
				location: 1,
				id: 'arrow',
				length: 14,
				foldback: 0.4
			}]]
		};
		return connectJson;
	}

	ngOnDestroy() {
		if (this.jsPlumbInstance) {
			this.jsPlumbInstance.deleteEveryConnection();
		}
		if (this.jsPlumbParentInstance) {
			this.jsPlumbParentInstance.deleteEveryConnection();
		}
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		this.unsubscribe$.unsubscribe();
	}

	openSLP(sharedLoadingLink: string | undefined) {
		console.log('### sharedLoadingLink in summary ### ' + sharedLoadingLink);
		window.open(sharedLoadingLink);
	}
}
