import {Component, OnInit} from '@angular/core';
import {SiteCode} from '../../../models/site-code';
import {SiteSupervisorSiteEngineerApproval} from '../../../models/sitesupervisor-siteengineer-approval';
import {UrlConsumerService} from '../../../oauth/url-consumer.service';
import {UtilService} from '../../../services/util.service';
import {DialogService} from 'primeng/api';
import {OnDeleteDisplayDialogueModel} from '../../../models/on-delete-display-dialogue-model';
import {OndeleteDialogueboxComponent} from '../../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {SupervisorApprovalFlowService} from '../../../services/master/supervisor-approval-flow/supervisor-approval-flow.service';

@Component({
	selector: 'supervisor-approval-flow-conditions',
	templateUrl: './supervisor-approval-flow-conditions.component.html',
	styleUrls: ['./supervisor-approval-flow-conditions.component.css']
})
export class SupervisorApprovalFlowConditionsComponent implements OnInit {

	availableEngineerList: SiteCode[] = [];
	allEngineerListFromSIM: string[];
	selectedEngineerObject: SiteCode;
	selectedEngineer: string;
	selectedEngineerListForApproval: string[] = [];
	siteSpvSiteEngObj: SiteSupervisorSiteEngineerApproval;
	loggedInSupervisor: string;
	selectedSupervisorApprovalConditions: SiteSupervisorSiteEngineerApproval[];
	disableAddButton = true;

	constructor(private urlConsumerService: UrlConsumerService, private spvApprovalService: SupervisorApprovalFlowService, private utilService: UtilService, private dialogService: DialogService) {
	}

	ngOnInit() {
		this.loggedInSupervisor = sessionStorage.getItem('userId');
		this.getEngineersFromSIM(this.loggedInSupervisor);
	}

	private getEngineersFromSIM(loggedInSupervisor: string) {
		this.spvApprovalService.getSiteEngineerListFromSIM(loggedInSupervisor).subscribe(engineerList => {
			this.allEngineerListFromSIM = engineerList;
			this.findAllSelectedEngineerApprovalConditions();
		});
	}

	onClose() {
		this.selectedEngineerObject = null;
	}

	onAdd() {
		this.siteSpvSiteEngObj = {
			siteEngCdsId: this.selectedEngineer
		};
		this.spvApprovalService.createSiteEngineerCondition(this.siteSpvSiteEngObj).subscribe((value) => {
			if (value) {
				this.utilService.pushMsg('success', 'Success', 'Site Engineer has been added successfully');
				this.findAllSelectedEngineerApprovalConditions();
				this.onClose();
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Unable to add Site Engineer, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	findAllSelectedEngineerApprovalConditions() {
		this.selectedEngineerListForApproval = [];
		if (this.allEngineerListFromSIM && this.allEngineerListFromSIM.length > 0) {
			this.spvApprovalService.findBySiteEngineersForSupervisorApproval(this.allEngineerListFromSIM).subscribe(conditionList => {
				this.selectedSupervisorApprovalConditions = conditionList;
				if (this.selectedSupervisorApprovalConditions && this.selectedSupervisorApprovalConditions.length > 0) {
					this.selectedSupervisorApprovalConditions.forEach(selectedEngineerAppObj => {
						this.selectedEngineerListForApproval.push(selectedEngineerAppObj.siteEngCdsId);
					});
				}
				this.findAvailableUnselectedEngineers();
			});
		}
	}

	private findAvailableUnselectedEngineers() {
		if (this.selectedEngineerListForApproval.length > 0) {
			this.spvApprovalService.getAvailableSiteEngineerList(this.allEngineerListFromSIM, this.selectedEngineerListForApproval).subscribe((siteEngineerList) => {
				this.disableAddButton = siteEngineerList.length > 0 ? false : true;
				this.availableEngineerList = siteEngineerList;
				console.log('availableEngineerList length ' + this.availableEngineerList.length);
			});
		} else {
			this.spvApprovalService.getAllAvailableSiteEngineerList(this.allEngineerListFromSIM).subscribe((siteEngineerList) => {
				if (siteEngineerList.length > 0) {
					this.disableAddButton = false;
				}
				this.availableEngineerList = siteEngineerList;
			});
		}
	}

	onDelete(siteSpvSiteEngineer: SiteSupervisorSiteEngineerApproval) {
		const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
			header: 'Confirmation',
			width: '30%',
			contentStyle: {'max-height': '500px', 'overflow': 'auto'}
		});

		deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
			if (deleteModel != null) {
				if (deleteModel.isDeleted) {
					this.spvApprovalService.deleteSiteEngineerCondition(siteSpvSiteEngineer.siteEngCdsId).subscribe(deletionSuccess => {
						if (deletionSuccess) {
							this.utilService.pushMsg('success', 'Success', 'Site Engineer for your approval is deleted successfully');
							this.findAllSelectedEngineerApprovalConditions();
						}
					}, error => {
						this.utilService.pushMsg('error', 'Error', 'Delete operation failed, please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
					});
				} else {
					console.log('Do not Delete response from dialogue box');
				}
			}
		});
	}
}
