import {Component, OnInit} from '@angular/core';
import {SiteCodeService} from '../../../services/sitecode.service';
import {SiteCode} from '../../../models/site-code';
import {EngineerApprovalFlowService} from '../../../services/master/engineer-approval-flow/engineer-approval-flow.service';
import {UtilService} from '../../../services/util.service';
import {SiteEngineerSiteCodeApproval} from '../../../models/siteengineer-sitecode-approval';
import {DialogService} from 'primeng/api';
import {OnDeleteDisplayDialogueModel} from '../../../models/on-delete-display-dialogue-model';
import {OndeleteDialogueboxComponent} from '../../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {UrlConsumerService} from '../../../oauth/url-consumer.service';

@Component({
	selector: 'engineer-approval-flow-conditions',
	templateUrl: './engineer-approval-flow-conditions.component.html',
	styleUrls: ['./engineer-approval-flow-conditions.component.css']
})
export class EngineerApprovalFlowConditionsComponent implements OnInit {

	availableSiteCodeList: SiteCode[] = [];
	allSiteCodeListFromSIM: string[];
	selectedSiteCodeObject: SiteCode;
	selectedSiteCode: string;
	selectedSiteCodeListForApproval: string[] = [];
	siteEngSiteCodeObj: SiteEngineerSiteCodeApproval;
	loggedInEngineer: string;
	selectedSiteCodeApprovalConditions: SiteEngineerSiteCodeApproval[];
	disableAddButton = true;

	constructor(private urlConsumerService: UrlConsumerService, private siteCodeService: SiteCodeService, private engineerApprovalService: EngineerApprovalFlowService, private utilService: UtilService, private dialogService: DialogService) {
	}

	ngOnInit() {
		this.loggedInEngineer = sessionStorage.getItem('userId');
		this.getSiteCodesFromSIM(this.loggedInEngineer);
	}

	private getSiteCodesFromSIM(loggedInEngineer: string) {
		this.engineerApprovalService.getSiteCodeListFromSIM(loggedInEngineer).subscribe(siteList => {
			this.allSiteCodeListFromSIM = siteList;
			this.findAllSelectedSiteCodeApprovalConditions();
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	onClose() {
		this.selectedSiteCodeObject = null;
	}

	onAdd() {
		this.siteEngSiteCodeObj = {
			siteCode: this.selectedSiteCode
		};
		this.engineerApprovalService.createSiteCodeCondition(this.siteEngSiteCodeObj).subscribe((value) => {
			if (value) {
				this.utilService.pushMsg('success', 'Success', 'Site Code has been added successfully');
				this.findAllSelectedSiteCodeApprovalConditions();
				this.onClose();
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Unable to add Site Code, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private findAllSelectedSiteCodeApprovalConditions() {
		this.selectedSiteCodeListForApproval = [];
		if (this.allSiteCodeListFromSIM && this.allSiteCodeListFromSIM.length > 0) {
			this.engineerApprovalService.findBySiteCodesForEngineerApproval(this.allSiteCodeListFromSIM).subscribe(conditionList => {
				this.selectedSiteCodeApprovalConditions = conditionList;
				if (this.selectedSiteCodeApprovalConditions && this.selectedSiteCodeApprovalConditions.length > 0) {
					this.selectedSiteCodeApprovalConditions.forEach(selectedSiteCodeAppObj => {
						this.selectedSiteCodeListForApproval.push(selectedSiteCodeAppObj.siteCode);
					});
				}
				this.findAvailableUnselectedSiteCodes();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	private findAvailableUnselectedSiteCodes() {
		if (this.selectedSiteCodeListForApproval.length > 0) {
			this.engineerApprovalService.getAvailableSiteCodeList(this.allSiteCodeListFromSIM, this.selectedSiteCodeListForApproval).subscribe((siteCodeList) => {
				this.disableAddButton = siteCodeList.length > 0 ? false : true;
				this.availableSiteCodeList = siteCodeList;
				console.log('availableSiteCodeList length ' + this.availableSiteCodeList.length);
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			this.engineerApprovalService.getAllAvailableSiteCodeList(this.allSiteCodeListFromSIM).subscribe((siteCodeList) => {
				if (siteCodeList.length > 0) {
					this.disableAddButton = false;
				}
				this.availableSiteCodeList = siteCodeList;
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	onDelete(siteEngSiteCode: SiteEngineerSiteCodeApproval) {
		const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
			header: 'Confirmation',
			width: '30%',
			contentStyle: {'max-height': '500px', 'overflow': 'auto'}
		});

		deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
			if (deleteModel != null) {
				if (deleteModel.isDeleted) {
					this.engineerApprovalService.deleteSiteCodeCondition(siteEngSiteCode.siteCode).subscribe(deletionSuccess => {
						if (deletionSuccess) {
							this.utilService.pushMsg('success', 'Success', 'Site Code for your approval is deleted successfully');
							this.findAllSelectedSiteCodeApprovalConditions();
						}
					}, error => {
						this.utilService.pushMsg('error', 'Error', 'Delete operation failed, please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
					});
				} else {
					console.log('Do not Delete response from dialogue box');
				}
			}
		});
	}
}