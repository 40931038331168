import {AfterViewInit, Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {SiteCode} from '../../../models/site-code';
import {ActivatedRoute, Router} from '@angular/router';
import {ProcessNameService} from '../../../services/master/process-name/process-name.service';
import {ProcessName} from '../../../models/process-name';
import {DialogService, MessageService} from 'primeng/api';
import {ProcessStreamService} from '../../../services/master/process-stream/process-stream.service';
import {CarmessageService} from '../../../services/message/carmessage.service';
import {ProcessStream} from '../../../models/process-stream';
import {SiteCodeService} from '../../../services/sitecode.service';
import {UtilService} from '../../../services/util.service';
import {CarRequestService} from '../../../services/request/car-request.service';
import {HttpParams} from '@angular/common/http';
import {OndeleteDialogueboxComponent} from '../../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../../models/on-delete-display-dialogue-model';
import {ApsAttributes} from '../../../models/aps-attributes';
import {ApsService} from '../../../aps.service';

@Component({
	selector: 'create-process-stream',
	templateUrl: './create-process-stream.component.html',
	styleUrls: ['./create-process-stream.component.css']
})
export class CreateProcessStreamComponent implements OnInit, AfterViewInit {
	@ViewChildren('availableProcessNamePicklist') availableProcessNamePicklist: QueryList<any>;
	siteCodes: string[];
	selectedSiteCode: string;
	isPrimary = true;
	processStreamName: string;
	availableProcessNames: any[];
	selectedProcessNames: any[];
	private mode: string;
	screenName: string;
	private processStreamId: number;
	private processStream: ProcessStream = new ProcessStream({
		availableProcessNames: [],
		connectingProcessNameId: 0,
		createdBy: '',
		createdDate: 0,
		id: 0,
		isPrimaryStream: false,
		mapToProcessName: 0,
		mapToProcessStream: 0,
		mapToStreamId: 0,
		originProcessStreamId: 0,
		primarySupportingMap: null,
		processStreamName: '',
		processStreamProcessNameMap: null,
		siteCode: '',
		status: '',
		updatedBy: '',
		updatedDate: 0,
		validationErrors: null
	});
	availablePrimaryStream: ProcessStream[];
	availabeMapToStream: ProcessStream[];
	selectedMapToStream: ProcessStream;
	selectedPrimaryStream: ProcessStream;
	private allList: ProcessStream[] = [];
	availableConnectingProcessNamesForMapToPS: ProcessName[];
	selectedConnectingProcessNamesForMapToPS: ProcessName;
	emptyProcessStreamDroprdown = {
		connectingProcessNameId: 0,
		createdBy: '',
		createdDate: 0,
		id: 0,
		isPrimaryStream: false,
		mapToProcessName: 0,
		mapToProcessStream: 0,
		mapToStreamId: 0,
		primarySupportingMap: [],
		processStreamName: 'Please Select Stream',
		processStreamProcessNameMap: [],
		siteCode: '',
		status: '',
		updatedBy: '',
		updatedDate: 0,
		validationErrors: [],
		originProcessStreamId: 0,
		availableProcessNames: []
	};
	emptyProcessNameDropDown = {
		active: false,
		createdBy: '',
		createdDate: 0,
		id: 0,
		processDesc: '',
		processName: 'Select Process Name',
		siteCode: '',
		processTypeName: '',
		automationType: '',
		processTypeId: 0,
		updatedBy: '',
		updatedDate: 0
	};
	private currentProcessStreamEdit: ProcessStream;
	disableIsPrimaryRadio = false;
	inActiveProcessExists = false;
	inActiveProcessNames: string[];
	isViewMode: boolean;
	viewProcessStreamId: number;
	isSitePresentInSIM = true;
	isTokenOnlyUser: boolean;
	visualizeProcessStream: boolean;
	private rootProcessStreamId: number;
	private selectionChange = false;
	private isNonSiteUser: boolean;
	private processStreamOld: ProcessStream;
	private currentPage: number;
	private currentPageSize: number;
	siteCodeSuggestions: string[];
	placeholder = 'Please Enter Site Code';

	constructor(private apsService: ApsService, public dialogService: DialogService, private siteCodeService: SiteCodeService, private route: ActivatedRoute, private carMessagesService: CarmessageService, private processStreamService: ProcessStreamService, private messageService: MessageService, private processNameService: ProcessNameService, private router: Router, private utilService: UtilService, private carRequestService: CarRequestService) {
	}

	ngOnInit() {
		this.processStream['isPrimaryStream'] = true;
		this.retrieveRouteParameter();
		this.checkNonSiteUser(sessionStorage.getItem('userId'), JSON.parse(sessionStorage.getItem('attributes')).userRole);

		if (this.mode === 'create') {
			//this.retrieveAvailablePrimaryStream();
			// this.retrieveAvailablePrimaryStreamForEdit(this.selectedSiteCode.code);
			this.retrieveAllSupportingStreanByPrimaryStreamId();
			this.isPrimary = true;
			this.screenName = 'Create Process Stream';
			this.isViewMode = false;
		} else {
			this.disableIsPrimaryRadio = true;
			//this.populatePickList();
			this.processStreamService.retrieveSelectedProcessNameByProcessStreamId(this.processStreamId).subscribe(value => {
				this.selectedProcessNames = value;
				this.processStreamService.retrieveAvailableProcessNameByProcessStreamId(this.processStreamId).subscribe(availableProcesses => {
					this.availableProcessNames = availableProcesses;
					this.findProcessStreamByIdForEdit(this.processStreamId);
					if (this.mode === 'edit') {
						this.screenName = 'Edit Process Stream';
						this.isViewMode = false;
					} else if (this.mode === 'view') {
						this.screenName = 'View Process Stream';
						this.isViewMode = true;
					}
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
		this.loadSiteCodes();
	}

	// tslint:disable-next-line:use-life-cycle-interface
	ngAfterViewInit() {
		this.availableProcessNamePicklist.changes.subscribe(_ => {
			if (this.availableProcessNames) {
				this.availableProcessNames.sort((a, b) => {
					// Use toUpperCase() to ignore character casing
					const processNameObjectA = a.processName.toUpperCase();
					const processNameObjectB = b.processName.toUpperCase();
					let comparison = 0;
					if (processNameObjectA > processNameObjectB) {
						comparison = 1;
					} else if (processNameObjectA < processNameObjectB) {
						comparison = -1;
					}
					return comparison;
				});
			}
		});
	}

	private retrieveRouteParameter() {
		this.route.paramMap.subscribe(params => {
			this.mode = params.get('mode');
			this.processStreamId = Number(params.get('id'));
			this.currentPage = Number(params.get('pageNo'));
			this.currentPageSize = Number(params.get('pageSize'));
		});
	}

	private findAllProcessNames() {
		this.processNameService.findAll().subscribe((response: ProcessName[]) => {
			this.availableProcessNames = response;
			this.selectedProcessNames = [];
		});
	}

	private populatePickList() {
		this.processStreamService.retrieveSelectedProcessNameByProcessStreamId(this.processStreamId).subscribe(value => {
			this.selectedProcessNames = value;
		});

		this.processStreamService.retrieveAvailableProcessNameByProcessStreamId(this.processStreamId).subscribe(value => {
			this.availableProcessNames = value;
		});
	}

	private setUpDateForCreateOrUpdate() {
		this.processStream['processNameList'] = this.selectedProcessNames;
		this.processStream['siteCode'] = this.selectedSiteCode;
		if (!this.processStream.isPrimaryStream) {
			this.processStream['mapToStreamId'] = this.selectedMapToStream.id;
			this.processStream['connectingProcessNameId'] = this.selectedConnectingProcessNamesForMapToPS.id;
			this.processStream['originProcessStreamId'] = this.selectedPrimaryStream.id;
		}
	}

	private selectedConnectingProcessNameInvalidErrorMsg() {
		this.utilService.pushMsg('error', 'Error', 'Please select any Process Name of Map to Stream.');
		return false;
	}

	private selectedMapToStreamInvalidErrorMsg() {
		this.utilService.pushMsg('error', 'Error', 'Please select any Process Stream to Map with current stream.');
		return false;
	}

	private showPrimaryStreamInvalidErrorMsg() {
		this.utilService.pushMsg('error', 'Error', 'Please select any Primary Process Stream.');
		return false;
	}

	onCancel() {
		const pageNo = this.currentPage;
		const pageSize = this.currentPageSize;
		this.router.navigate(['manage-process-stream/' + pageNo + '/' + pageSize]);
	}

	show() {
		this.carMessagesService.displayMessage({
			severity: 'info',
			summary: 'Process Stream',
			detail: 'is created successfully'
		});
		this.router.navigate(['manage-process-stream']);
	}

	findProcessStreamByIdForEdit(processStreamId: number) {
		console.log('findProcessStreamById calling');
		this.processStreamService.findProcessStreamById(processStreamId).subscribe(value => {
			this.processStream = value;
			this.currentProcessStreamEdit = value;
			this.processStreamOld = JSON.parse(JSON.stringify(value));
			if (this.isNonSiteUser) {
				/*this.siteCodes = [];
				this.selectedSiteCode = '';
				this.siteCodes.push(this.selectedSiteCode);*/
				this.placeholder = this.processStream.siteCode;
			} else {
				//this.selectedSiteCode = this.siteCodes.find(siteCode => siteCode === this.processStream.siteCode);
				this.placeholder = this.processStream.siteCode;
			}
			this.selectedSiteCode = this.processStream.siteCode;
			//this.retrieveAvailablePrimaryStream();
			this.isSiteCodeAvailableInSIM(this.selectedSiteCode);
			this.retrieveAvailablePrimaryStreamForEdit(this.selectedSiteCode);
			this.retrieveAllSupportingStreanByPrimaryStreamId();
			this.checkInActiveProcessNameInSelectedProcessList();
			if (this.mode === 'view') {
				if (this.processStream.isPrimaryStream) {
					this.viewProcessStreamId = this.processStreamId;
				} else {
					this.viewProcessStreamId = this.processStream.originProcessStreamId;
				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private loadAvailablePrimaryStreamForEditPrepopulate() {
		if (this.currentProcessStreamEdit) {
			this.selectedPrimaryStream = this.availablePrimaryStream.find(ps => ps.id === this.currentProcessStreamEdit.originProcessStreamId);
			console.log('*****************this.selectedPrimaryStream');
			this.retrieveAllSupportingStreanByPrimaryStreamId();
		}
	}

	loadMaptoStreamForEdit() {
		if (this.currentProcessStreamEdit) {
			this.selectedMapToStream = this.availabeMapToStream.find(stream => stream.id === this.currentProcessStreamEdit.mapToProcessStream);
			console.log('this.selectedMapToStream');
			this.retrieveAllProcessNamesByMapToPS();
			this.selectedConnectingProcessNamesForMapToPS = this.availableConnectingProcessNamesForMapToPS.find(pn => pn.id === this.currentProcessStreamEdit.connectingProcessNameId);
			console.log('this.selectedConnectingProcessNamesForMapToPS');
		}
	}

	loadConnectingProcessNameForEdit() {
		if (this.currentProcessStreamEdit) {
			this.selectedConnectingProcessNamesForMapToPS = this.availableConnectingProcessNamesForMapToPS.find(pn => pn.id === this.currentProcessStreamEdit.connectingProcessNameId);
			console.log('this.selectedConnectingProcessNamesForMapToPS');
		}
	}

	private retrieveAvailablePrimaryStream() {
		this.processStreamService.findAllPrimaryProcessStream().subscribe(value => {
			this.availablePrimaryStream = value;
			if (this.availablePrimaryStream !== null) {
				this.availablePrimaryStream.unshift(this.emptyProcessStreamDroprdown);
			}
			this.loadAvailablePrimaryStreamForEditPrepopulate();
		});
	}

	private retrieveAvailablePrimaryStreamForEdit(code: string) {
		if (code !== '') {
			console.log('siteCode' + code);
			this.processStreamService.findAllPrimaryProcessStreamEdit(code).subscribe(value => {
				this.availablePrimaryStream = value;
				this.availablePrimaryStream.unshift(this.emptyProcessStreamDroprdown);
				this.loadAvailablePrimaryStreamForEditPrepopulate();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	private retrieveAvailablePrimaryStreamForCreateNewStream(code: string) {
		if (code !== '') {
			console.log('siteCode' + code);
			this.processStreamService.findAllPrimaryProcessStreamForCreateNew(code).subscribe(value => {
				this.availablePrimaryStream = value;
				this.availablePrimaryStream.unshift(this.emptyProcessStreamDroprdown);
				this.loadAvailablePrimaryStreamForEditPrepopulate();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	private retrieveAllSupportingStreanByPrimaryStreamId() {
		console.log('retrieveAllSupportingStreanByPrimaryStreamId');
		this.availabeMapToStream = [this.emptyProcessStreamDroprdown];
		this.availableConnectingProcessNamesForMapToPS = [this.emptyProcessNameDropDown];
		if (this.selectedPrimaryStream) {
			if (this.selectedPrimaryStream.id !== 0) {
				this.processStreamService.findProcessStreamTreeById(this.selectedPrimaryStream.id).subscribe(value => {
					this.availabeMapToStream = [];
					this.allList = [];
					this.allList.unshift(this.emptyProcessStreamDroprdown);
					Array.prototype.push.apply(this.allList, value);
					this.availabeMapToStream = this.allList;
					this.availabeMapToStream = this.availabeMapToStream.filter(currentProcessStreamName => currentProcessStreamName.processStreamName !== this.processStream.processStreamName);
					this.loadMaptoStreamForEdit();
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			}
		}
	}

	retrieveAllProcessNamesByMapToPS() {
		console.log('retrieveAllProcessNamesByMapToPS');
		this.availableConnectingProcessNamesForMapToPS = [];
		if (this.selectedMapToStream) {
			this.processStreamService.retrieveSelectedProcessNameByProcessStreamId(this.selectedMapToStream.id).subscribe(value => {
				this.availableConnectingProcessNamesForMapToPS = value;
				this.availableConnectingProcessNamesForMapToPS.unshift(this.emptyProcessNameDropDown);
				this.loadConnectingProcessNameForEdit();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
	}

	onSubmit() {
		console.log('onSubmit');
		if (this.validateData()) {
			this.setUpDateForCreateOrUpdate();
			if (this.mode === 'edit') {
				this.processStreamService.update(this.processStreamId, this.processStream).subscribe((value) => {
					sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
						'status': 'success',
						'message': 'Process Stream is updated successfully!'
					}));
					let isChanged = false;
					if (this.processStreamOld.processStreamProcessNameMap.length !== this.processStream.processNameList.length) {
						isChanged = true;
					} else {
						this.processStream.processNameList.forEach((pn, index) => {
							const psPnOld = this.processStreamOld.processStreamProcessNameMap.find(psProcessName => psProcessName.processNameId === pn.id);
							if (!psPnOld || psPnOld.processOrderId !== index + 1) {
								isChanged = true;
							}
						});
					}
					if (!this.processStream.isPrimaryStream) {
						if (this.processStreamOld.mapToProcessStream !== this.selectedMapToStream.id) {
							isChanged = true;
						} else if (this.processStreamOld.connectingProcessNameId !== this.selectedConnectingProcessNamesForMapToPS.id) {
							isChanged = true;
						}
					}
					let isStreamNameChanged = false;
					if (!isChanged && this.processStreamOld.processStreamName !== this.processStream.processStreamName) {
						isChanged = true;
						isStreamNameChanged = true;
					}
					if (isChanged) {
						this.updatePSChangedFlag(isStreamNameChanged);
					}
					this.router.navigate(['manage-process-stream']);
				}, error => {
					console.log(error);
					this.utilService.pushMsg('error', 'Error', error.error);
				});
			} else {
				console.log('Process Stream Data Before Create');
				if (this.processStream.id) {
					this.processStream.id = null;
				}
				if (this.processStream.primarySupportingMap) {
					this.processStream.primarySupportingMap = null;
				}
				this.processStreamService.create(this.processStream).subscribe((value) => {
					sessionStorage.setItem('ProcessNameCreateSuccess', JSON.stringify({
						'status': 'success',
						'message': 'Process Stream is created successfully!'
					}));
					if (!this.processStream.isPrimaryStream) {
						this.updatePSChangedFlag();
					}
					this.router.navigate(['manage-process-stream']);
				}, error => {
					console.log(error);
					console.log(error.error);
					this.utilService.pushMsg('error', 'Error', error.error);
				});
			}
		}
	}

	updatePSChangedFlag(isStreamNameChanged?: boolean) {
		let params = new HttpParams().set('primaryStreamId', this.processStream.isPrimaryStream ? this.processStream.id.toString() : this.processStream.originProcessStreamId.toString())
			.set('isPrimary', this.processStream.isPrimaryStream.toString())
			.set('isChanged', this.mode);
		if (isStreamNameChanged) {
			params = params.set('isStreamNameChanged', isStreamNameChanged.toString());
		}
		if (!this.processStream.isPrimaryStream && this.processStream.id) {
			params = params.set('supportingStreamId', this.processStream.id.toString());
		}
		this.carRequestService.updatePSChangedFlag(params).subscribe(res => {
			console.log('PSChanged Flag is updated successfully');
		});
	}

	private validateData(): boolean {
		let isValid = true;
		if (this.mode === 'edit') {
			if (this.processStream.isPrimaryStream) {
				isValid = this.primaryStreamFieldsValidationForEdit();
			} else {
				isValid = this.primaryStreamFieldsValidationForEdit();
				if (isValid) {
					isValid = this.supportingStreamFieldsValidationForEdit();
				}
			}
		} else {
			if (this.processStream.isPrimaryStream) {
				isValid = this.primaryStreamFieldsValidationForCreate();
			} else {
				isValid = this.primaryStreamFieldsValidationForCreate();
				if (isValid) {
					isValid = this.supportingStreamFieldsValidationForCreate();
				}
			}
		}
		if (isValid && this.selectedProcessNames.length === 0) {
			isValid = this.showSelectedProcessNamesEmptyErrorMsg();
		}
		return isValid;
	}

	private supportingStreamFieldsValidationForCreate() {
		let isValid = true;
		if (!this.selectedPrimaryStream) {
			isValid = this.showPrimaryStreamInvalidErrorMsg();
		} else if (!this.selectedMapToStream) {
			isValid = this.selectedMapToStreamInvalidErrorMsg();
		} else if (!this.selectedConnectingProcessNamesForMapToPS) {
			isValid = this.selectedConnectingProcessNameInvalidErrorMsg();
		}
		return isValid;
	}

	private supportingStreamFieldsValidationForEdit() {
		let isValid = true;
		if (!this.selectedPrimaryStream || this.selectedPrimaryStream.id === 0) {
			isValid = this.showPrimaryStreamInvalidErrorMsg();
		} else if (!this.selectedMapToStream || this.selectedMapToStream.id === 0) {
			isValid = this.selectedMapToStreamInvalidErrorMsg();
		} else if (!this.selectedConnectingProcessNamesForMapToPS || this.selectedConnectingProcessNamesForMapToPS.id === 0) {
			isValid = this.selectedConnectingProcessNameInvalidErrorMsg();
		}
		return isValid;
	}

	private primaryStreamFieldsValidationForEdit() {
		let isValid = true;
		if (this.selectedSiteCode === '') {
			isValid = this.showSiteCodeMandatoryErrorMsg();
		} else if (this.processStream.processStreamName.trim() === '') {
			isValid = this.processStreamNameMandatoryErrorMsg();
		}
		return isValid;
	}

	private primaryStreamFieldsValidationForCreate() {
		let isValid = true;
		if (!this.selectedSiteCode) {
			isValid = this.showSiteCodeMandatoryErrorMsg();
		} else if (!this.processStream.processStreamName || this.processStream.processStreamName.trim() === '') {
			isValid = this.processStreamNameMandatoryErrorMsg();
		}
		return isValid;
	}

	private showSelectedProcessNamesEmptyErrorMsg() {
		this.utilService.pushMsg('error', 'Error', 'Please select any Process Name from Pick list.');
		return false;
	}

	private processStreamNameMandatoryErrorMsg() {
		this.utilService.pushMsg('error', 'Error', 'Please enter valid Process Stream Name.');
		return false;
	}

	private showSiteCodeMandatoryErrorMsg() {
		this.utilService.pushMsg('error', 'Error', 'Please choose any Site Code.');
		return false;
	}

	onChangeSiteCode() {
		this.findAllActiveProcessNamesBySiteCode();
		this.retrieveAvailablePrimaryStreamForCreateNewStream(this.selectedSiteCode);
		this.isSiteCodeAvailableInSIM(this.selectedSiteCode);
	}

	private findAllProcessNamesBySiteCode() {
		this.processNameService.findAllProcessBySiteCode(this.selectedSiteCode).subscribe((response: ProcessName[]) => {
			this.availableProcessNames = response;
			this.selectedProcessNames = [];
		});
	}

	private findAllActiveProcessNamesBySiteCode() {
		this.processNameService.findAllActiveProcessBySiteCode(this.selectedSiteCode).subscribe((response: ProcessName[]) => {
			this.availableProcessNames = response;
			this.selectedProcessNames = [];
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	loadSiteCodes() {
		this.isTokenOnlyUser = this.utilService.isUserHasOnlyTokenOnly();
		//this.siteCodes = this.siteCodeService.fetchSiteCodes();
	}

	private checkInActiveProcessNameInSelectedProcessList() {
		const processNameIds = this.selectedProcessNames.map(processName => processName.id);
		this.processNameService.checkProcessNameActive(processNameIds).subscribe((value: string[]) => {
			if (value.length > 0) {
				this.inActiveProcessNames = value;
				this.inActiveProcessExists = true;
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	isSiteCodeAvailableInSIM(siteCode: string) {
		if (siteCode && siteCode.length > 4) {
			this.processNameService.isSiteCodePresentInSIM(siteCode).subscribe(isPresent => {
				this.isSitePresentInSIM = isPresent;
			});
		}
	}

	onVisualize() {
		if (this.mode === 'create') {
			if (this.processStream.isPrimaryStream && this.validateData()) {
				this.processStream.availableProcessNames = this.selectedProcessNames;
				this.rootProcessStreamId = null;
				this.visualizeProcessStream = true;
			}
			if (!this.processStream.isPrimaryStream) {
				this.showSuppStreamVisualize();
			}
		} else {
			if (this.processStream.isPrimaryStream && this.validateData()) {
				this.processStream.availableProcessNames = this.selectedProcessNames;
				this.rootProcessStreamId = null;
				this.visualizeProcessStream = true;
			}
			if (!this.processStream.isPrimaryStream && this.validateData()) {
				this.processStream.availableProcessNames = this.selectedProcessNames;
				this.rootProcessStreamId = this.processStream.originProcessStreamId;
				this.visualizeProcessStream = true;
				this.processStream.mapToStreamId = this.selectedMapToStream.id;
				if (this.selectionChange) {
					this.setUpDateForCreateOrUpdate();
				}
			}
		}
	}

	hideVisualize() {
		this.visualizeProcessStream = false;
	}

	private showSuppStreamVisualize() {
		if (this.validateData()) {
			this.setUpDateForCreateOrUpdate();
			this.processStream.availableProcessNames = this.selectedProcessNames;
			this.processStream.primarySupportingMap = null;
			this.rootProcessStreamId = this.processStream.originProcessStreamId;
			this.processStream.id = Math.random();
			this.visualizeProcessStream = true;
		}
	}

	private checkNonSiteUser(userId: string, userRole: string) {
		if (userRole.includes('Manager') || userRole.includes('Engineer') || userRole.includes('Supervisor')) {
			this.carRequestService.isPrimeUser(userId).subscribe(isValid => {
				if (isValid) {
					this.isNonSiteUser = true;
				} else {
					//this.siteCodes = this.siteCodeService.fetchSiteCodes();
					this.isNonSiteUser = false;
				}
			});
		} else if (!userRole.toLowerCase().includes('ecar') && !userRole.includes('Developer')) {
			this.isNonSiteUser = true;
		} else {
			//this.siteCodes = this.siteCodeService.fetchSiteCodes();
			this.isNonSiteUser = false;
		}
	}

	onAddStream() {
		console.log('On Edit: id' + this.selectedPrimaryStream.id);
		this.availabeMapToStream = [this.emptyProcessStreamDroprdown];
		this.availableConnectingProcessNamesForMapToPS = [this.emptyProcessNameDropDown];
		this.processStreamService.findAllReqStatusByProcessStreamId(this.selectedPrimaryStream.id, true).subscribe(value => {
			if ((value.length === 1 && value[0] === 'DRAFT') || (value.length > 1 && value.includes('DRAFT') && !value.includes('REJECTED'))) {
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '50%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'},
					data: {
						'message': 'displaySupportProcessStreamCreateForDraft'
					}
				});
				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.retrieveAllSupportingStreanByPrimaryStreamId();
						} else {
							const pageNo = this.currentPage;
							const pageSize = this.currentPageSize;
							this.router.navigate(['manage-process-stream/' + pageNo + '/' + pageSize]);
						}
					}
				});
			} else if ((value.length === 1 && value[0] === 'REJECTED') || (value.length > 1 && !value.includes('DRAFT') && value.includes('REJECTED'))) {
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '50%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'},
					data: {
						'message': 'displaySupportProcessStreamCreateForReject'
					}
				});
				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.retrieveAllSupportingStreanByPrimaryStreamId();
						} else {
							const pageNo = this.currentPage;
							const pageSize = this.currentPageSize;
							this.router.navigate(['manage-process-stream/' + pageNo + '/' + pageSize]);
						}
					}
				});
			} else if (value.length > 1 && value.includes('DRAFT') && value.includes('REJECTED')) {
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '50%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'},
					data: {
						'message': 'displaySupportProcessStreamCreateForDraftAndReject'
					}
				});
				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.retrieveAllSupportingStreanByPrimaryStreamId();
						} else {
							const pageNo = this.currentPage;
							const pageSize = this.currentPageSize;
							this.router.navigate(['manage-process-stream/' + pageNo + '/' + pageSize]);
						}
					}
				});
			} else if (value.length >= 1 && !value.includes('DRAFT') && !value.includes('REJECTED')) {
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '50%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'},
					data: {
						'message': 'ProcessStreamEditorDeleteConfirmBoxForNonDraft'
					}
				});
				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.retrieveAllSupportingStreanByPrimaryStreamId();
						} else {
							const pageNo = this.currentPage;
							const pageSize = this.currentPageSize;
							this.router.navigate(['manage-process-stream/' + pageNo + '/' + pageSize]);
						}
					}
				});
			} else {
				console.log('Else On Edit: id' + this.selectedPrimaryStream.id);
				this.retrieveAllSupportingStreanByPrimaryStreamId();
			}
		});
	}

	fetchSiteCodes(event: any) {
		const searchString = event.query;
		if (searchString && searchString.length > 2) {
			this.apsService.retrieveAttributesBySearchString(searchString).subscribe(apsAttribute => {
				if (apsAttribute && apsAttribute.siteCodes) {
					this.siteCodeSuggestions = this.buildDisplaySiteCodes(apsAttribute);
				}
			});
		}
	}

	private buildDisplaySiteCodes(apsAttribute: ApsAttributes) {
		return apsAttribute.siteCodes.map(value => value.length > 4 ? value : '');
	}
}
