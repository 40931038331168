import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

import {map} from 'rxjs/operators';
import {CarRequest} from '../../models/car-request';
import {RequestStateHistory} from '../../models/request-state-history';
import {LdapResponse} from '../../models/ldap-response';
import {Option} from '../../models/option';
import {ProcessData} from '../../models/process-data';

@Injectable({
	providedIn: 'root'
})
export class CarRequestService {

	private config = new Config();
	private httpUrlBase = `${this.config.url}`;
	private httpUrl = `${this.config.url}/carRequest`;
	private httpUrlEcarLinkDropdownOptions = `${this.config.url}/carRequest/options/carid`;
	private httpUrlStreamDropdownOptions = `/processStream/options`;
	private httpCopyUrl = `${this.config.url}/carRequest/copy`;
	private httpUrlFindAlleCARByProcessNameId = `${this.config.url}/carRequest/findAlleCARByProcessNameId`;
	private httpUrlValidatePrimeUser = `${this.config.url}/ldap/lookup/check/`;
	private httpUrlValidateJTypeUser = `${this.config.url}/ldap/lookup/checkJType/`;
	private httpUrlCheckHMPSLPFlags = `${this.config.url}/carRequest/checkHMPSLPFlags/`;
	private httpUrlCheckProcessStreamAvailability = `${this.config.url}/carRequest/processStreamAvailabilityCheck`;
	showViewStreamSubject = new Subject<boolean>();
	showAnchorsStreamSubject = new Subject<boolean>();
	destroyAnchorsStreamSubject = new Subject<boolean>();
	disableBulletinsOnPrintSubject = new Subject<boolean>();

	constructor(private http: HttpClient) {
	}

	pushViewStreamStatus(selectedStream: boolean) {
		this.showViewStreamSubject.next(selectedStream);
	}

	pushAnchorsStreamStatus(selectedStream: boolean) {
		this.showAnchorsStreamSubject.next(selectedStream);
	}

	pushDeleteAnchorsStream(outOfScopeViewStream: boolean) {
		this.destroyAnchorsStreamSubject.next(outOfScopeViewStream);
	}

	hideBulletinsOnPrintPreview(isPrintPreview: boolean) {
		this.disableBulletinsOnPrintSubject.next(isPrintPreview);
	}

	findAll(): Observable<CarRequest[]> {
		return this.http.get(this.httpUrl).pipe(map((carRequests: CarRequest[]) => {
			return carRequests.map(carRequest => {
				return new CarRequest(carRequest);
			});
		}));
	}

	findingAll(pageSizeRequest) {
		let params = new HttpParams().set('page', pageSizeRequest.page).set('size', pageSizeRequest.size);
		if (pageSizeRequest.sort && pageSizeRequest.sort.length > 0) {
			params = params.set('sort', pageSizeRequest.sort);
		}
		if (pageSizeRequest.filters && pageSizeRequest.filters.length > 0) {
			params = params.set('filters', pageSizeRequest.filters);
		}
		if (pageSizeRequest.userRole && pageSizeRequest.userRole.length > 0) {
			params = params.set('userRole', pageSizeRequest.userRole);
		}
		if (pageSizeRequest.siteCodes && pageSizeRequest.siteCodes.length > 0) {
			params = params.set('siteCodes', pageSizeRequest.siteCodes);
		}
		return this.http.get(this.httpUrl + '/findAllForHomePage', {params});
	}

	create(carRequest: CarRequest): Observable<CarRequest> {
		return this.http.post<CarRequest>(this.httpUrl, carRequest);
	}

	update(id: number, carRequest: CarRequest): Observable<CarRequest> {
		return this.http.put<CarRequest>(this.httpUrl + '/' + id, carRequest);
	}

	delete(id: number): Observable<CarRequest> {
		return this.http.delete<CarRequest>(this.httpUrl + '/' + id);
	}

	findById(id: number): Observable<CarRequest> {
		return this.http.get<CarRequest>(this.httpUrl + '/' + id);
	}

	findByIdForEcarLink(carId: number, streamId: number, processNameId: number): Observable<CarRequest> {
		const http = this.httpUrl + '/carId/' + carId + '/processStreamId/' + streamId + '/processNameId/' + processNameId;
		return this.http.get<CarRequest>(http);
	}

	copy(id: number, isCopyFromSiteEngineer: boolean): Observable<CarRequest> {
		return this.http.post<CarRequest>(this.httpCopyUrl + '/' + isCopyFromSiteEngineer, id);
	}

	findReqHistoriesById(id: number): Observable<RequestStateHistory[]> {
		return this.http.get<RequestStateHistory[]>(this.httpUrl + '/history/' + id);
	}

	searchFordBuyer(cdsid: string): Observable<LdapResponse[]> {
		return this.http.get<LdapResponse[]>(this.httpUrlBase + '/ldap/lookup/' + cdsid);
	}

	isValidMailId(mailId: string): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrlBase + '/ldap/lookup/mail/' + mailId);
	}

	getMailFromCDSId(cdsid: string): Observable<LdapResponse> {
		return this.http.get<LdapResponse>(this.httpUrlBase + '/ldap/lookup/mailFromCDSId/' + cdsid);
	}

	resetStream(carRequest: CarRequest): Observable<CarRequest> {
		return this.http.put<CarRequest>(this.httpUrl + '/resetStreamData/', carRequest);
	}

	loadEcarIdOptions(): Observable<Option[]> {
		return this.http.get<Option[]>(this.httpUrlEcarLinkDropdownOptions);
	}

	loadEcarWithParentId(parentId: number) {
		return this.http.get<Option[]>(this.httpUrlEcarLinkDropdownOptions + '/parentId/' + parentId);
	}

	findAllProcessStreamByCarId(carId: string): Observable<Option[]> {
		return this.http.get<Option[]>(this.httpUrl + '/' + carId + this.httpUrlStreamDropdownOptions);
	}

	findAllProcessByStreamId(carId: string, streamId: string): Observable<Option[]> {
		return this.http.get<Option[]>(this.httpUrl + '/' + carId + '/processStream/' + streamId + '/processName/options');
	}

	/** Finding eCAR list by proessdata id */
	findAlleCARByProcessDataId(processNameId: number, currentPartNumber, date, mode): Observable<number[]> {
		console.log('FindProcessName associated Ecars ' + processNameId);
		return this.http.get<number[]>(this.httpUrlFindAlleCARByProcessNameId + '/' + processNameId + '/' + currentPartNumber + '/' + date + '/' + mode);
	}

	findRGPNextPresentAcrossStreams(carRequestId: number, primaryProcessStreamId: number, primaryProcessNameId: number): Observable<ProcessData[]> {
		return this.http.get<ProcessData[]>(this.httpUrl + '/findRGPNextPresentAcrossStreams/' + carRequestId + '/' + primaryProcessStreamId + '/' + primaryProcessNameId);
	}

	healthCheck(): Observable<string> {
		return this.http.get(this.httpUrl + '/healthCheck', {responseType: 'text'});
	}

	findCdsIds(code: string): Observable<any[]> {
		return this.http.get<any[]>(this.httpUrl + '/findCdsIds' + '/' + code);
	}

	reCalleCAR(carRequest: CarRequest): Observable<CarRequest> {
		return this.http.post<CarRequest>(this.httpUrl + '/reCalleCAR/', carRequest);
	}

	isSupplierSubmittedWithinDateLimit(reqId: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrl + '/isSupplierSubmittedWithinDateLimit/' + reqId);
	}

	isOldECARWithinTheDateLimit(reqId: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrl + '/isOldECARWithinTheDateLimit/' + reqId);
	}

	isAcknowledgmentWorkflow(reqId: number, commitmentAppc: number, commitmentMppc: number, plannedCapacityApw: number, plannedCapacityMpw: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrl + '/isAcknowledgmentWorkflow/' + reqId + '/' + commitmentAppc + '/' + commitmentMppc + '/' + plannedCapacityApw + '/' + plannedCapacityMpw);
	}

	isPrimeUser(cdsId: string): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrlValidatePrimeUser + cdsId);
	}

	isJTypeUser(cdsId: string): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrlValidateJTypeUser + cdsId);
	}

	updateHMPFlagForOEEChange(siteCode: string, processId: number, averageOEE: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrl + '/updateHMPFlagForOEEChange/' + siteCode + '/' + processId + '/' + averageOEE);
	}

	updateSLPFlagForTotalAllocChange(siteCode: string, processId: number, totalAllocAPW: number, totalAllocMPW: number, totalAllocCapacityAPW: number, totalAllocCapacityMPW: number, isPlanCntPartNameChange: boolean): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrl + '/updateSLPFlagForTotalAllocChange/' + siteCode + '/' + processId + '/' + totalAllocAPW + '/' + totalAllocMPW + '/' + totalAllocCapacityAPW + '/' + totalAllocCapacityMPW + '/' + isPlanCntPartNameChange);
	}

	updateSLPFlagOnSLRDelete(siteCode: string, processId: number, isLinkedWithECAR: boolean): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrl + '/updateSLPFlagOnSLRDelete/' + siteCode + '/' + processId + '/' + isLinkedWithECAR);
	}

	checkHMPSLPFlagsOnSubmit(id: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrlCheckHMPSLPFlags + id);
	}

	reAssignCAR(carRequest: CarRequest): Observable<CarRequest> {
		return this.http.post<CarRequest>(this.httpUrl + '/reAssignCAR/', carRequest);
	}

	reAssignCARWithParams(carRequest: CarRequest, params?: HttpParams): Observable<CarRequest> {
		return this.http.post<CarRequest>(this.httpUrl + '/reAssignCAR/', carRequest, {params});
	}

	processStreamAvailabilityCheck(reqId: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrlCheckProcessStreamAvailability + '/' + reqId);
	}

	updatePSChangedFlag(params: HttpParams) {
		return this.http.get(this.httpUrl + '/updatePSFlagInCAR', {params});
	}
}
