import {Component, OnInit} from '@angular/core';
import {ManageSharedLoadingService} from '../../../services/master/manage-shared-loading/manage-shared-loading.service';
import {SharedLoading} from '../../../models/shared-loading';
import {DialogService, LazyLoadEvent, Message} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedLoadingRoot} from '../../../models/shared-loading-root';
import {Policies} from '../../../models/aps-policies';
import {UtilService} from '../../../services/util.service';
import {ApsAttributes} from '../../../models/aps-attributes';
import {OndeleteDialogueboxComponent} from '../../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../../models/on-delete-display-dialogue-model';
import {PagingHelper} from '../../../models/paging/paging-helper';
import {CarRequestService} from '../../../services/request/car-request.service';

@Component({
	selector: 'manage-shared-loading',
	templateUrl: './manage-shared-loading.component.html',
	styleUrls: ['./manage-shared-loading.component.css']
})
export class ManageSharedLoadingComponent implements OnInit {
	sharedLoadingRoots: SharedLoadingRoot[];
	displayMessage: Message[] = [];
	policies: Policies;
	disableCreateButton: boolean;
	disableDeleteButton: boolean;
	apsAttributes: ApsAttributes;
	first = 0;
	totalSLRecordPages = 0;
	currentPage = 0;
	currentPageSize = 10;
	pageDetailsFrom = 0;
	pageDetailsTo = 0;
	siteCodes: string;
	private isPrimeUser: boolean;
	private pageNo = 0;
	private pageSize = 10;
	selectedPage = 0;
	private initialLoad: boolean;

	constructor(private activatedRoute: ActivatedRoute, private utilService: UtilService, private router: Router, private service: ManageSharedLoadingService, public dialogService: DialogService, public carRequestService: CarRequestService) {
	}

	ngOnInit() {
		this.checkAndShowSuccessMsg();
		//this.findAll();
		this.findAllPolicies();
		this.retrieveRouteParameter();
	}

	checkAndShowSuccessMsg() {
		let success = sessionStorage.getItem('SharedLoadingCreateSuccess');
		if (success) {
			success = JSON.parse(success);
			this.displayMessage.push({severity: 'info', summary: '', detail: success['message']});
			sessionStorage.removeItem('SharedLoadingCreateSuccess');
			setTimeout(() => {
				this.displayMessage = [];
			}, 2000);
		}
	}

	private findAll() {
		this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
		if (this.apsAttributes.userRole === 'Super User' || this.apsAttributes.userRole === 'Buyer' || this.apsAttributes.userRole === 'STA User' || this.apsAttributes.userRole === 'Capacity Planner' || this.apsAttributes.userRole === 'Developer') {
			this.service.findAll().subscribe((response: SharedLoadingRoot[]) => {
				this.sharedLoadingRoots = response;
			});
		} else {
			this.service.findAllBySiteCodes().subscribe((response: SharedLoadingRoot[]) => {
				this.sharedLoadingRoots = response;
			});
		}
	}

	onCreate() {
		this.router.navigate(['create-shared-loading/create/0/false/0/0/N/0/10']);
	}

	onEdit(id: number) {
		const currentPage = this.currentPage;
		const currentPageSize = this.currentPageSize;
		this.router.navigate(['create-shared-loading/edit/' + id + '/false/0/0/N/' + currentPage + '/' + currentPageSize]);
	}

	onCopy(id: number) {
		const currentPage = this.currentPage;
		const currentPageSize = this.currentPageSize;
		this.router.navigate(['create-shared-loading/copy/' + id + '/false/0/0/N/' + currentPage + '/' + currentPageSize]);
	}

	onDelete(id: number) {
		console.log('calling dialog ref');
		let deleteDialogueRef;
		this.service.findById(id).subscribe(root => {
			this.service.isSharedLoadingLinkedWithAnyCAR(root.siteCode, root.processNameId).subscribe(isLinkedWithECAR => {
				if (isLinkedWithECAR) {
					deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '50%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'},
						data: {
							'message': 'eCARLinkedSL'
						}
					});
				} else {
					deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
						header: 'Confirmation',
						width: '30%',
						contentStyle: {'max-height': '500px', 'overflow': 'auto'}
					});
				}
				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.carRequestService.updateSLPFlagOnSLRDelete(root.siteCode, root.processNameId, isLinkedWithECAR).subscribe(triggered => {
								this.service.delete(id).subscribe(value => {
									if (value) {
										sessionStorage.setItem('SharedLoading', JSON.stringify({
											'status': 'success',
											'message': 'Shared Loading is deleted successfully!'
										}));
									} else {
										sessionStorage.setItem('SharedLoading', JSON.stringify({
											'status': 'success',
											'message': 'Deletion is not successful, Please try again!.'
										}));
									}
									setTimeout(() => {
										this.displayMessage = [];
									}, 2000);
									this.checkAndMsg();
									this.findAllByPaging(this.currentPage, this.currentPageSize);
								}, error => {
									sessionStorage.setItem('SharedLoading', JSON.stringify({
										'status': 'success',
										'message': 'Unable to delete Shared Loading!.'
									}));
									this.checkAndMsg();
								});
							});
						} else {
							console.log('dont Delete response from dialogue box');
						}
					}
				});
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	checkAndMsg() {
		let success = sessionStorage.getItem('SharedLoading');
		if (success) {
			success = JSON.parse(success);
			this.displayMessage.push({severity: 'info', summary: '', detail: success['message']});
			sessionStorage.removeItem('SharedLoading');
			setTimeout(() => {
				this.displayMessage = [];
			}, 2000);
		}
	}

	private findAllPolicies() {
		this.policies = JSON.parse(sessionStorage.getItem('policies'));
		const policyName = 'eCAR:ManageSharedLoading';
		this.disableCreateButton = this.utilService.checkPermittedUpdatePolicy(this.policies, policyName) ? false : true;
		this.disableDeleteButton = this.utilService.checkPermittedDeletePolicy(this.policies, policyName) ? false : true;
	}

	onView(id: any) {
		const currentPage = this.currentPage;
		const currentPageSize = this.currentPageSize;
		this.router.navigate(['create-shared-loading/view/' + id + '/false/0/0/N/' + currentPage + '/' + currentPageSize]);
	}

	loadSharedLoadingLazy(event: LazyLoadEvent) {
		if (!event.sortField || (event.sortField && event.sortField.toString() !== 'processName' && event.sortField.toString() !== 'demonstratedOEE')) {
			this.selectedPage = this.pageNo * this.pageSize;
			this.currentPage = event.first / event.rows;
			this.currentPageSize = event.rows;
			if (this.pageNo > 0 && this.currentPage === 0 && !this.initialLoad) {
				this.initialLoad = true;
				this.selectedPage = this.pageNo * this.pageSize;
				this.currentPage = this.pageNo;
			}
			if (this.pageSize === 0 || this.pageSize === 10 || event.rows === 10) {
				this.currentPageSize = 10;
			}
			if (this.pageSize === 25 || event.rows === 25) {
				this.currentPageSize = 25;
			}
			if (this.pageSize === 50 || event.rows === 50) {
				this.currentPageSize = 50;
			}
			if (this.pageSize > 50 || event.rows > 50) {
				this.currentPageSize = 100;
			}
			const sortingParam = [];
			if (event.sortField) {
				console.log('event.sortfield ' + event.sortField.toString());
				if (event.sortOrder < 0) {
					sortingParam.push(event.sortField + ',asc');
				} else {
					sortingParam.push(event.sortField + ',desc');
				}
			}
			const filters = [];
			for (const field of Object.keys(event.filters)) {
				filters.push(field + ':' + event.filters[field].value);
			}
			this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
			this.checkPrimeUser(sessionStorage.getItem('userId'), this.apsAttributes.userRole, this.currentPage, this.currentPageSize, sortingParam, filters);
		}
	}

	private findAllByPaging(first, size, sort?: String[], filters?: String[]) {
		let uRole = this.apsAttributes.userRole;
		if (this.isPrimeUser) {
			uRole = 'Prime User';
			this.siteCodes = 'hi';
		} else if (uRole.toLowerCase() === 'ecar_read' || uRole.toLowerCase() === 'ecar_update' || uRole.includes('Developer') || uRole.includes('SiteEngineer') || uRole.includes('Supervisor') || uRole.includes('Manager')) {
			if (uRole.toLowerCase() === 'ecar_update' || uRole.toLowerCase() === 'ecar_read') {
				this.siteCodes = this.apsAttributes.siteCodes.map(site => site).join('-');
			} else {
				this.siteCodes = 'hi';//this.apsAttributes.siteCodes.map(site => site).join('-');
			}
		} else {
			this.siteCodes = 'hi';
		}
		this.service.findingAll({
			page: first,
			size: size,
			sort: sort,
			filters: filters,
			userRole: uRole,
			siteCodes: this.siteCodes
		}).subscribe((pageableSharedLoadingList: PagingHelper) => {
			if (pageableSharedLoadingList.content) {
				this.sharedLoadingRoots = pageableSharedLoadingList.content;
				this.totalSLRecordPages = pageableSharedLoadingList.totalElements;
				this.pageDetailsFrom = ((pageableSharedLoadingList.pageable.pageNumber + 1) - 1) * pageableSharedLoadingList.pageable.pageSize + 1;
				this.pageDetailsTo = (pageableSharedLoadingList.pageable.pageNumber + 1) * pageableSharedLoadingList.pageable.pageSize;
				if (this.pageDetailsTo > this.totalSLRecordPages) {
					this.pageDetailsTo = this.totalSLRecordPages;
				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	private checkPrimeUser(userId: string, userRole: string, first, size, sort?: String[], filters?: String[]) {
		if (userRole.includes('Manager') || userRole.includes('Engineer') || userRole.includes('Supervisor')) {
			this.carRequestService.isPrimeUser(userId).subscribe(isValid => {
				if (isValid) {
					this.isPrimeUser = true;
					this.findAllByPaging(first, size, sort, filters);
				} else {
					this.isPrimeUser = false;
					this.findAllByPaging(first, size, sort, filters);
				}
			});
		} else {
			this.isPrimeUser = false;
			this.findAllByPaging(first, size, sort, filters);
		}
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.pageNo = Number(params.get('pageNo'));
			this.pageSize = Number(params.get('pageSize'));
		});
	}
}
